import { Grid } from "@mui/material";
import { CeoScheduleInfo } from "../../system/types/ceoschedule";
import {
  ModalContentBox,
  ModalTitleBox,
  ModalTitleIcon,
  ModalTitleText,
} from "../../styles/theme";
import { X } from "@phosphor-icons/react";
import { useRecoilValue } from "recoil";
import { CeoAtom } from "../../system/atom";
import {
  CeoScheduleModalContent,
  CeoScheduleModalContentFlexBox,
  CeoScheduleModalContentTitle,
  CeoScheduleModalInput,
  CeoSchedulerPreContent,
} from "../../styles/CeoScheduleStyle";
import {
  isoDateFormatter,
  isoDateKoFormatter,
} from "../Common/IsoDateFormatter";
interface CeoScheduleDetailedModalProps {
  handleClose: () => void;
  data: CeoScheduleInfo;
}

function CeoScheduleDetailedModal({
  handleClose,
  data,
}: CeoScheduleDetailedModalProps) {
  const ceoSchedulerOwner = useRecoilValue(CeoAtom);

  return (
    <>
      {data && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ModalTitleBox>
              <ModalTitleText variant="h5">
                {ceoSchedulerOwner} 일정{" "}
              </ModalTitleText>

              <ModalTitleIcon onClick={handleClose}>
                <X size={32} />
              </ModalTitleIcon>
            </ModalTitleBox>
            <ModalContentBox>
              <CeoScheduleModalContentFlexBox>
                <CeoScheduleModalContentTitle>
                  일정 시작 시간
                </CeoScheduleModalContentTitle>
                <CeoScheduleModalContent>
                  {isoDateKoFormatter(new Date(data.startTime))}
                </CeoScheduleModalContent>
              </CeoScheduleModalContentFlexBox>
              <CeoScheduleModalContentFlexBox>
                <CeoScheduleModalContentTitle>
                  일정 종료 시간
                </CeoScheduleModalContentTitle>
                <CeoScheduleModalContent>
                  {isoDateKoFormatter(new Date(data.endTime))}{" "}
                </CeoScheduleModalContent>
              </CeoScheduleModalContentFlexBox>
              <CeoScheduleModalContentFlexBox>
                <CeoScheduleModalContentTitle>
                  제목
                </CeoScheduleModalContentTitle>
                <CeoScheduleModalInput>
                  {data.scheduleSubject}
                </CeoScheduleModalInput>
              </CeoScheduleModalContentFlexBox>
              <CeoScheduleModalContentFlexBox>
                <CeoScheduleModalContentTitle>
                  내용
                </CeoScheduleModalContentTitle>
                <CeoScheduleModalInput>
                  <CeoSchedulerPreContent>
                    {data.scheduleContents}
                  </CeoSchedulerPreContent>
                </CeoScheduleModalInput>
              </CeoScheduleModalContentFlexBox>
            </ModalContentBox>{" "}
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default CeoScheduleDetailedModal;
