import React, { useEffect, useState } from "react";
import BreakfastTable from "../../components/InbodyMenu/BreakfastTable";
import TitleBox from "../../components/Common/TitleBox";
import { MessageComponent } from "@syncfusion/ej2-react-notifications";
import "../../styles/Components/BreakfastTable/BreakfastTable.css";

function BreakfastTableMain() {
  const Messagebox = {
    color: "#BBBBBB",
    borderBottom: "2px solid #D9D9D9",
    paddingBottom: "10px",
    margin: "15px 10px",
  };
  return (
    <div className="breakfastmain">
      <TitleBox title="주간 아침 메뉴 (본사)" />
      <div style={Messagebox}>메뉴는 업체 사정으로 변경될 수 있습니다.</div>
      <div>
        <BreakfastTable />
      </div>
    </div>
  );
}

export default BreakfastTableMain;
