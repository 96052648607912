import { Box, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { MainBox } from "../../styles/theme";
import { CheonanMenuApi, ErrorHandler } from "../../system/ApiService";
import { CheonanJsonData } from "../../system/types/interface";
import CheonanMenuTable from "./CheonanMenuTable";
import AlertModal from "../Common/AlertModal";

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function CheonanMenu() {
  const [data, setData] = useState<CheonanJsonData[]>([]);
  const [viewData, setViewData] = useState(false);
  const [nextData, setNextData] = useState<CheonanJsonData[]>([]);
  const [nextViewData, setNextViewData] = useState(false);
  const [activeNum, setActiveNum] = useState(0);
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // 날짜
  var currentDay = new Date();
  var theDayOfWeek = currentDay.getDay();
  var theYear = currentDay.getFullYear();
  var theMonth = currentDay.getMonth();
  var theDate = currentDay.getDate();

  const dateGap = [1, 5, 8, 12];
  var weekDate: any = [];
  var weekDateDisplay: any = [];
  var weekDateGetData: any = [];

  for (var i = 0; i < 4; i++) {
    var resultDay = new Date(
      theYear,
      theMonth,
      theDate + (dateGap[i] - theDayOfWeek)
    );
    var yyyy = resultDay.getFullYear();
    var premm = Number(resultDay.getMonth()) + 1;
    var predd = resultDay.getDate();

    var mm = String(premm).length === 1 ? "0" + `${premm}` : `${premm}`;
    var dd = String(predd).length === 1 ? "0" + `${predd}` : `${predd}`;

    weekDate[i] = yyyy + mm + dd;
    weekDateDisplay[i] = yyyy + "/" + mm + "/" + dd;
    weekDateGetData[i] = yyyy + "" + mm + "" + dd;
  }

  useEffect(() => {
    if (weekDateGetData[0].length > 0) {
      CheonanMenuApi.GetCheonanMenu(Number(weekDateGetData[0]))
        .then((res) => {
          if (res.data) {
            setData(JSON.parse(res.data.menuDataList));
            setViewData(true);
          }
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  }, []);

  useEffect(() => {
    if (weekDateGetData[2].length > 0) {
      CheonanMenuApi.GetCheonanMenu(Number(weekDateGetData[2]))
        .then((res) => {
          if (res.data) {
            setNextData(JSON.parse(res.data.menuDataList));
            setNextViewData(true);
          }
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  }, []);

  // css
  const regionBlock = {
    margin: "10px 10px",
    padding: "10px 10px",
  };

  return (
    <MainBox>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} textColor="primary">
          <Tab
            label={
              "이번 주 (" + weekDateDisplay[0] + "~" + weekDateDisplay[1] + ")"
            }
            {...a11yProps(0)}
          ></Tab>
          <Tab
            label={
              "다음 주 (" + weekDateDisplay[2] + "~" + weekDateDisplay[3] + ")"
            }
            {...a11yProps(1)}
          ></Tab>
        </Tabs>
      </Box>
      {value === 0 ? (
        <div style={regionBlock}>
          <CheonanMenuTable
            parent={{ data: data, activeNum: 0, viewData: viewData }}
          />
        </div>
      ) : (
        <div style={regionBlock}>
          <CheonanMenuTable
            parent={{ data: nextData, activeNum: 1, viewData: nextViewData }}
          />
        </div>
      )}
    </MainBox>
  );
}

export default CheonanMenu;
