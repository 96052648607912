import { TableCell, Typography, createTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { GRAYPALETITLE } from "./Color";
export const theme = createTheme({
  typography: {
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2.5rem",
    },
    h3: {
      fontSize: "2rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.2rem",
    },
    h6: {
      fontSize: "1rem",
    },
  },
  palette: {
    primary: {
      light: "#CC1F3B",
      main: "#ffffff",
      dark: "#182031",
      contrastText: "#000000",
    },
  },
});

export const PRIMARY = "#971B2F";
export const SECONDARY = "#5B6770";

export const MainBox = styled("div")(({ theme }) => ({
  marginTop: "30px",
  width: "1620px",
}));

export const BasicTable = styled("table")(({ theme }) => ({
  marginTop: "10px",
  width: "100%",
  borderTop: "1px solid #DCDFE3",
  textAlign: "center",
}));
export const BasicTr = styled("tr")(({ theme }) => ({
  borderTop: "1px solid #DCDFE3",
  borderBottom: "1px solid #DCDFE3",
  "&>th, &>td": {
    padding: "15px",
  },
  "&>th": {
    width: "130px",
  },
}));
export const MainTh = styled("th")(({ theme }) => ({
  border: "1px solid #E6E6E6",
  backgroundColor: "#F8F8F8",
  color: "#9B9B9B",
}));

export const KindTh = styled("th")(({ theme }) => ({
  border: "1px solid #E6E6E6",
  color: "#000000",
  fontWeight: "700",
}));

export const BasicTh = styled("th")(({ theme }) => ({
  border: "1px solid #E6E6E6",
  fontWeight: "500",
}));
export const BasicTd = styled("td")(({ theme }) => ({
  border: "1px solid #E6E6E6",
}));

export const ButtonAlign = styled("div")(({ theme }) => ({
  textAlign: "right",
  marginRight: "100px",
}));

export const ButtonLocation = styled("div")(({ theme }) => ({
  textAlign: "right",
  marginBottom: "20px",
}));

export const TableSub = styled("div")(({ theme }) => ({
  display: "inline-block",
  textAlign: "center",
  width: "205px",
  backgroundColor: "#718093",
  paddingTop: "14px",
  paddingBottom: "20px",
  color: "#fff",
  fontWeight: "800",
  border: "1px solid #718093",
  height: "50px",
}));

export const TableCon = styled("div")(({ theme }) => ({
  display: "inline-block",
  textAlign: "center",
  width: "260px",
  padding: "10px",
  border: "1px solid #718093",
  height: "50px",
}));

export const TableMain = styled("tr")(({ theme }) => ({
  textAlign: "center",
  padding: "10px",
  borderTop: "1px solid #718093",
  borderBottom: "1px solid #718093",
  "&>th, &>td": {
    padding: "15px",
  },
  "&>th": {
    width: "350px",
  },
}));

export const TableContent = styled("th")(({ theme }) => ({
  borderBottom: "1px solid #C7CBD1",
}));

export const TableSubContent = styled("td")(({ theme }) => ({
  textAlign: "center",
  padding: "10px",
  borderBottom: "1px solid #C7CBD1",
  height: "50px",
}));

export const TableBox = styled("table")(({ theme }) => ({
  textAlign: "center",
  padding: "10px",
  borderBottom: "1px solid #718093",
  borderTop: "1px solid #718093",
  height: "50px",
}));

export const DetailReview = styled("div")(({ theme }) => ({
  border: "1px solid #718093",
  float: "left",
  width: "32%",
  height: "150px",
  borderRadius: "8px",
  margin: "5px",
  padding: "7px",
  overflow: "scroll",
}));

export const EmptyBox = styled("div")(({ theme }) => ({
  textAlign: "center",
  marginTop: "70px",
}));

export const CloseButton = styled("div")(({ theme }) => ({
  marginTop: "32px",
  cursor: "pointer",
  float: "right",
}));

export const ModalTitle = styled("div")(({ theme }) => ({
  marginTop: "35px",
}));

export const PaginationMargin = styled("div")(({ theme }) => ({
  marginTop: "20px",
}));

export const TextBoxAlignCenter = styled("div")(({ theme }) => ({
  textAlign: "center",
}));

// 테이블
export const StyledHead = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#F8F8F8",
  borderTop: "1px solid #D9D9D9",
  borderBottom: "1px solid #D9D9D9",
  width: "100px",
  padding: "15px 0px",
  fontSize: "1rem",
  color: "#9B9B9B",
  fontWeight: "600",
}));

export const StyledHead2 = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#F8F8F8",
  borderTop: "1px solid #D9D9D9",
  borderBottom: "1px solid #D9D9D9",
  width: "200px",
  padding: "15px 0px",
  fontSize: "1rem",
  color: "#9B9B9B",
  fontWeight: "600",
}));

export const StyledHeadTitle = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#F8F8F8",
  borderTop: "1px solid #D9D9D9",
  borderBottom: "1px solid #D9D9D9",
  width: "600px",
  padding: "15px 0px",
  fontSize: "1rem",
  color: "#9B9B9B",
  fontWeight: "600",
}));

export const StyledBodyTitle = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px solid #E6E6E6",
  width: "600px",
  cursor: "pointer",
  fontSize: "1rem",
}));

export const StyledBody = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px solid #E6E6E6",
  width: "100px",
  fontSize: "1rem",
}));

export const StyledBody2 = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px solid #E6E6E6",
  width: "200px",
  fontSize: "1rem",
}));

export const StyledEmptyBody = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px solid #E6E6E6",
  padding: "300px 0px",
  fontSize: "1rem",
  textAlign: "center",
}));

export const CustomButtonRed = styled("button")({
  fontSize: "1rem",
  backgroundColor: `#FFF0F3`,
  color: "#F25C75",
  boxShadow: "none",
  borderRadius: "5px",
  cursor: "pointer",
  fontWeight: 600,
  width: "7rem",
  height: "2.5rem",
  border: "0px",
  margin: "5px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "#FFE6EB",
    boxShadow: "none",
    color: "#F25C75",
  },
});

export const CustomButtonWhite = styled("button")({
  fontSize: "1rem",
  backgroundColor: `#ffffff`,
  color: GRAYPALETITLE,
  border: "1px solid #DCDFE3",
  boxShadow: "none",
  borderRadius: "5px",
  width: "7rem",
  height: "2.5rem",
  fontWeight: 600,
  cursor: "pointer",
  margin: "5px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: `#DCDFE3`,
    boxShadow: "none",
    color: "#ffffff",
  },
});

export const ModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "80%",
  maxWidth: "80rem",
  bgcolor: "background.paper",
  border: "0px",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  borderRadius: "5px",
};

export const SmallModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "80%",
  maxWidth: "50rem",
  maxHeight: "35rem",
  bgcolor: "background.paper",
  border: "0px",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  borderRadius: "5px",
};

// modal
export const ModalTitleBox = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
}));

export const ModalTitleText = styled(Typography)(({ theme }) => ({
  color: "#4B4F5A",
  fontWeight: "bold",
}));

export const ModalTitleIcon = styled("div")({
  cursor: "pointer",
});
export const ModalContentBox = styled("div")(() => ({
  marginTop: "20px",
  fontSize: "1rem",
}));

// Input Box
export const InputBox = styled("input")(({ theme }) => ({
  width: "100%",
  height: "2.4rem",
  border: "1px solid #DCDFE3",
  borderRadius: "8px",
  paddingLeft: "10px",
  "&::placeholder": { color: "#B2B4B8", fontWeight: "300" },
  "&:hover": { border: "1px solid #81848B" },
  "&:focus": { border: "1px solid #81848B", outline: "none" },
  "&:active": { border: "1px solid #81848B" },
}));

export const TextareaBox = styled("textarea")({
  width: "100%",
  height: "100px",
  border: "1px solid #DCDFE3",
  resize: "none",
  borderRadius: "8px",
  paddingLeft: "10px",
  "&::placeholder": { color: "#B2B4B8", fontWeight: "300" },
  "&:hover": { border: "1px solid #81848B" },
  "&:focus": { border: "1px solid #81848B", outline: "none" },
  "&:active": { border: "1px solid #81848B" },
});
