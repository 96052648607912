import React, { useEffect, useState } from "react";
import DepartmentNoticeEditBoard from "../../components/DepartmentNotice/DepartmentNoticeEditBoard";
import TitleBox from "../../components/Common/TitleBox";
import { enableRipple } from "@syncfusion/ej2-base";
import {
  DropDownButtonComponent,
  ItemModel,
} from "@syncfusion/ej2-react-splitbuttons";
import { useUserState } from "../../system/context/UserContext";

function DepartmentNoticeEdit() {
  const user = useUserState();
  return (
    <div>
      <TitleBox title={`${user.teamName} 공지`} />
      <DepartmentNoticeEditBoard />
    </div>
  );
}

export default DepartmentNoticeEdit;
