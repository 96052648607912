import "../../../../src/styles/Components/Main/Portlet.css";

import { useEffect, useState } from "react";
import {
  NoticeBoxLayout,
  NoticeBoxTitle,
  NoticeViewAll,
  PaymentProgressBox,
  PaymentPortletHeader,
  PaymentPortletTableRow,
} from "../../../styles/Components/Main/MainPortal";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import TableRow from "@mui/material/TableRow";
import { GetDocumentListDto } from "../../../system/types/interface";
import { isoDateFormatter } from "../../Common/IsoDateFormatter";
import { CustomText } from "../../../styles/Components/Portlet/CustomText";
import CustomTextTooltip from "../../../styles/Components/Portlet/CustomTextTooltip";
import { ErrorHandler } from "../../../system/ApiService";
import AlertModal from "../../Common/AlertModal";
import Swal from "sweetalert2";
import { GREENCONTENTS } from "../../../styles/Color";
import { useMsal } from "@azure/msal-react";

function LongPaymentProgressPortlet() {
  const [data, setData] = useState<GetDocumentListDto[]>([]);
  const { instance } = useMsal();
  function moveToView() {
    window.open("https://groupware.weareinbody.com/");
  }
  useEffect(() => {
    let token = `Bearer ${sessionStorage.getItem("id_token")}` || "";

    fetch(`https://groupware.weareinbody.com/api/Document/list/dashboard/1`, {
      headers: {
        Authorization: token,
      },
      method: "GET",
    })
      .then((res) => {
        return res.ok ? res.json() : null;
      })
      .then((res) => {
        if (res) {
          setData(res);
        }
      });
  }, []);

  return (
    <>
      <NoticeBoxLayout>
        <NoticeBoxTitle variant="h6">상신함</NoticeBoxTitle>
        <NoticeViewAll onClick={moveToView}>바로가기</NoticeViewAll>
      </NoticeBoxLayout>
      <PaymentProgressBox>
        <TableContainer component={Paper}>
          <Table size="small" sx={{ tableLayout: "fixed" }}>
            <PaymentPortletHeader>
              <TableRow>
                <TableCell align="center" width={"20%"}>
                  <CustomText type="subtitle">상태</CustomText>
                </TableCell>
                <TableCell align="center" width={"60%"}>
                  <CustomText type="subtitle">문서명</CustomText>
                </TableCell>
                <TableCell align="center" width={"20%"}>
                  <CustomText type="subtitle">기안일</CustomText>
                </TableCell>
              </TableRow>
            </PaymentPortletHeader>
            <TableBody>
              {data && data.length < 1 && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <CustomText type="body" align="center" fullWidth>
                      조회된 데이터가 없습니다.
                    </CustomText>
                  </TableCell>
                </TableRow>
              )}

              {data?.map((data) => {
                return (
                  <PaymentPortletTableRow
                    key={data.id}
                    onClick={() => {
                      if (!!data.v4Id) {
                        window.open(
                          `https://inbodykr.sharepoint.com/sites/HQ/EApproval//Lists/ApprovalDocuments/DispForm.aspx?ID=${String(
                            data.v4Id
                          )}`
                        );
                      } else {
                        window.open(
                          `https://groupware.weareinbody.com/detail-document/` +
                            String(data.id)
                        );
                      }
                    }}
                  >
                    <TableCell align="center">
                      <CustomText type="body">{data.statusValue}</CustomText>
                    </TableCell>
                    <TableCell align="left">
                      <CustomTextTooltip type="body">
                        {data.title}
                      </CustomTextTooltip>
                    </TableCell>
                    <TableCell align="center">
                      <CustomText type="body">
                        {isoDateFormatter(new Date(data.created))}
                      </CustomText>
                    </TableCell>
                  </PaymentPortletTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </PaymentProgressBox>
    </>
  );
}

export default LongPaymentProgressPortlet;
