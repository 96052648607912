import React, { useEffect, useState } from "react";
import { borderRadius } from "@mui/system";
import Cheonan from "../../components/admin/Cheonan";
import HeadOffice from "../../components/admin/HeadOffice";
import TitleBox from "../../components/Common/TitleBox";
import { useUserState } from "../../system/context/UserContext";

import HeadOfficeRank from "../../components/admin/HeadOfficeRank";
import { MainBox } from "../../styles/theme";
import { Box, Tab, Tabs } from "@mui/material";

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function MenuAdmin() {
  const user = useUserState();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  // css
  const regionBlock = {
    margin: "10px 10px",
    padding: "10px 10px",
  };

  return (
    <>
      <div>
        <TitleBox title="인바디 주간 메뉴 설정" />
        <MainBox>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} textColor="primary">
              <Tab label="주간 아침 메뉴 (본사)" {...a11yProps(0)}></Tab>
              <Tab label="아침 메뉴 평가 (본사)" {...a11yProps(1)}></Tab>
              {/* 사번으로 권한 체크 */}
              {["20220914", "20160404", "202301c"].includes(user.employeeId) ? (
                <Tab label="주간 메뉴표 (천안)" {...a11yProps(2)}></Tab>
              ) : (
                ""
              )}
            </Tabs>
          </Box>
          {value === 0 ? (
            <div style={regionBlock}>
              <HeadOffice />
            </div>
          ) : (
            ""
          )}
          {value === 1 ? (
            <div style={regionBlock}>
              <HeadOfficeRank />
            </div>
          ) : (
            ""
          )}
          {value === 2 ? (
            <div style={regionBlock}>
              <Cheonan />
            </div>
          ) : (
            ""
          )}
        </MainBox>
      </div>
    </>
  );
}

export default MenuAdmin;
