import React, { useEffect, useState } from "react";
import DepartmentNoticeBoard from "../../components/DepartmentNotice/DepartmentNoticeBoard";
import TitleBox from "../../components/Common/TitleBox";
import {ItemModel } from '@syncfusion/ej2-react-splitbuttons';
import { useUserState } from '../../system/context/UserContext';

function DepartmentNoticeMain() {
    const items: ItemModel[] = [
        {
            text: 'Cut',
        }];
        const user = useUserState();
    return (
        <div>
            <TitleBox title={`${user.parentTeam} 공지`}  />
            <DepartmentNoticeBoard />
        </div>
    );
}

export default DepartmentNoticeMain;