import { CSSProperties, ReactElement } from "react";
import purify from "dompurify";
interface Props {
  data: string | undefined | null;
  className?: string | undefined;
  style?: CSSProperties | undefined;
}

function HtmlContent({ data, style }: Props): ReactElement {
  if (data) {
    return (
      <div
        aria-label="caution"
        style={style}
        className="editor-html-content"
        dangerouslySetInnerHTML={{
          __html: purify.sanitize(data, { ADD_ATTR: ["target"] }),
        }}
      ></div>
    );
  } else {
    return <div aria-label="No caution"></div>;
  }
}

export default HtmlContent;
