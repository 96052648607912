import { Typography } from "@mui/material";
import React from "react";

interface TitleBoxProps {
  title: string;
}

function TitleBox({ title }: TitleBoxProps) {
  return <div style={{ padding: "0px 10px", color: "#4B4F5A", fontWeight: "700", fontSize: "1.5rem" }}>{title}</div>;
}

export default TitleBox;
