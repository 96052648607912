import axios from "axios";
import React, { useEffect, useState } from "react";
import { useUserState } from "../../../system/context/UserContext";
import { ErrorHandler } from "../../../system/ApiService";
import AlertModal from "../../Common/AlertModal";
import { AttendanceAlarWithAwayTime } from "../../../system/types/interface";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
} from "@syncfusion/ej2-react-grids";
import { Box } from "@mui/material";
import { CustomButtonRed } from "../../../styles/theme";

interface AttendanceAlarmWithAwayTimeProps {
  workdate: string;
  handleAlarmClose: ()=>void;
}

export default function AttendanceAlarmWithAwayTime({
  workdate,
  handleAlarmClose
}: AttendanceAlarmWithAwayTimeProps) {
  const user = useUserState();
  const [data, setData] = useState<AttendanceAlarWithAwayTime[]>([]);

  useEffect(() => {
    axios
      .get(
        `https://insamansa2.weareinbody.com/api/attendance/list/awaytime/${workdate}/${user.employeeNo}`
      )
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [workdate, user]);

  const handleDelete = (id: number) => {
    if(window.confirm("이석시간을 삭제하시겠습니까?")){
      axios.delete(`https://insamansa2.weareinbody.com/api/attendance/awaytime/${id}`).then((res)=>{
        if(res.data){
          AlertModal("success", "성공", "이석시간이 정상적으로 삭제되었습니다.");
        }else{
          AlertModal("success", "성공", "이석시간 삭제를 실패했습니다.");
        }
        handleAlarmClose();
      }).catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
    }
  }

  // 시간 표시 템플릿
  const template = (props: any): any => {
    // 구분 색 표시용 템플릿
    if(props.column.field === "mode"){
      if(props.mode === "이석"){
        return <div style={{backgroundColor: "gold"}}>{props.mode}</div>
      }else if(props.mode === "출근"){
        return <div style={{backgroundColor: "#CEE4FE"}}>{props.mode}</div>
      }else if(props.mode === "퇴근"){
        return <div style={{backgroundColor: "#F6DBDD"}}>{props.mode}</div>
      }else{
        return <div style={{backgroundColor: "#E4E6E7"}}>{props.mode}</div>
      }
    }
    // 이석 시간 시작~종료 표시용 템플릿
    else if(props.column.field === "started"){
      if (props.mode === "이석") {
        return (
          <div>{`${formatDate(props.started)} ~ ${formatDate(props.ended)}`}</div>
        );
      } else {
        return <div>{formatDate(props.started)}</div>;
      }
    }
    // 삭제 버튼 템플릿
    else if(props.column.field === "delete"){
      if(props.id !== 0){
        return <CustomButtonRed onClick={()=>handleDelete(props.id)} sx={{height: "20px", margin: "0px"}}>삭제</CustomButtonRed>
      }else{
        return <div></div>
      }
    }
  };

  const formatDate = (dateString) => {
    if (dateString === null) {
      return "";
    } else {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}`;
    }
  };

  return (
    <Box mt={"30px"}>
      <GridComponent
        dataSource={data}
        allowPaging={true}
        pageSettings={{ pageSize: 10 }}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="id"
            headerText="PK"
            width="0"
          />
          <ColumnDirective
            field="mode"
            headerText="구분"
            width="30"
            textAlign="Center"
            template={template}
          />
          <ColumnDirective
            field="remark"
            headerText="비고"
            width="20"
            textAlign="Center"
          />
          <ColumnDirective
            field="started"
            headerText="시간"
            width="40"
            textAlign="Center"
            template={template}
          />
          <ColumnDirective
            field="delete"
            headerText="삭제"
            width="20"
            textAlign="Center"
            template={template}
          />
        </ColumnsDirective>
        <Inject services={[Page]} />
      </GridComponent>
    </Box>
  );
}
