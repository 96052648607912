import { Fragment, useEffect, useState } from "react";
import {
  DisabledText,
  ForecastImg,
  ForecastWeathERImage,
  MainContainer,
  MainProfileBox,
  MainText,
  MaximumTemperature,
  MinimumTemperature,
  PhotoBox,
  PortletContent,
  ProfileBox,
  ProfileTextBox,
  SettingText,
  SubText,
  SubTextBox,
  TemperatureContent,
  TemperatureTitle,
  TmrForecast,
  TmrForecastContent,
  WeatherBox,
  WeatherImage,
} from "../../styles/Components/Main/MainPortal";
import axios from "axios";
import {
  Avatar,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { useUserState } from "../../system/context/UserContext";
import { WeatherSite } from "../../system/types/interface";
import { ErrorHandler, WeatherSiteApi } from "../../system/ApiService";
import TitleBox from "../Common/TitleBox";
import {
  CustomButtonBlackSpace,
  CustomButtonGrey,
} from "../../styles/Common/CommonComponent";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { TextBoxAlignCenter } from "../../styles/theme";
import AlertModal from "../Common/AlertModal";
import { useMsal } from "@azure/msal-react";
import Swal from "sweetalert2";
import { GREENCONTENTS } from "../../styles/Color";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 500,
  width: 800,
  bgcolor: "background.paper",
  p: 4,
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 600,
    fontSize: theme.typography.pxToRem(12),
  },
}));

function MainProfile() {
  const user = useUserState();
  const { instance } = useMsal();
  // photo
  const [photoData, setPhotoData]: any = useState("");

  useEffect(() => {
    let token = sessionStorage.getItem("token") || "";
    axios
      .get<Blob>("https://graph.microsoft.com/v1.0/me/photo/$value", {
        headers: { Authorization: token },
        responseType: "blob",
      })
      .then((res) => {
        const myFile = new File([res.data], "imageName");
        const reader = new FileReader();
        reader.onload = (ev) => {
          const previewImage = String(ev.target?.result);
          setPhotoData(previewImage);
        };
        reader.readAsDataURL(myFile);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          Swal.fire({
            title: "세션이 만료되었습니다.",
            text: "재로그인 부탁드립니다.",
            icon: "warning",
            confirmButtonText: "재로그인",
            confirmButtonColor: GREENCONTENTS,
          }).then((result) => {
            if (result.isConfirmed) {
              instance.logout();
              window.sessionStorage.clear();
            }
          });
        } else if (err.response.status !== 404) {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        }
      });
  }, []);

  // 날씨

  // router 이동시 메모리 lack 에러
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    return () => setLoading(false);
  }, []);

  // 위치 설정 모달
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [siteX, setSiteX] = useState(61);
  const [siteY, setSiteY] = useState(126);
  const [siteAddress, setSiteAddress] = useState("논현동");
  const [siteData, setSiteData] = useState<WeatherSite[]>([]);
  const [selectAddress, setSelectAddress] = useState("");
  const onChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAddress((event.target as HTMLInputElement).value);
  };
  const onSaveAddress = () => {
    var x = 0;
    var y = 0;
    if (selectAddress === "천안시 서북구 입장면") {
      x = 64;
      y = 113;
    } else if (selectAddress === "논현동") {
      x = 61;
      y = 126;
    } else {
      x = 61;
      y = 125;
    }
    setSiteAddress(siteAddress);
    if (siteData) {
      const newData: WeatherSite = {
        employeeId: user.employeeId,
        siteX: x,
        siteY: y,
        siteAddress: selectAddress,
      };
      WeatherSiteApi.UpdateWeatherSite(newData)
        .then(() => {
          refreshWeatherSite();
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    } else {
      const newData: WeatherSite = {
        employeeId: user.employeeId,
        siteX: x,
        siteY: y,
        siteAddress: selectAddress,
      };
      WeatherSiteApi.InsertWeatherSite(newData)
        .then(() => {
          refreshWeatherSite();
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
    setOpen(false);
  };

  function refreshWeatherSite() {
    WeatherSiteApi.GetWeatherSite(user.employeeId)
      .then((res) => {
        setSiteData(res.data);
        setSiteX(res.data.siteX);
        setSiteY(res.data.siteY);
        setSiteAddress(res.data.siteAddress);
        setSelectAddress(res.data.siteAddress);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
  }

  useEffect(() => {
    refreshWeatherSite();
  }, []);

  // 기상청
  const [forecastData, setForecastData] = useState([]);
  const data_api_key =
    "9R103KDTnKu2apR3vh4Bx0X9kCN%2FI%2BI0eFs8pvVZAOKfwuYm9g5IxjeLNqFjSV5vA0UtfGJj5V%2Fgh3RS2DluAA%3D%3D";
  const api_go =
    "http://apis.data.go.kr/1360000/VilageFcstInfoService_2.0/getVilageFcst";
  const [temp_max, setTemp_max] = useState(0);
  const [temp_min, setTemp_min] = useState(0);
  const [temp_max_tmr, setTemp_max_tmr] = useState(0);
  const [temp_min_tmr, setTemp_min_tmr] = useState(0);
  const [temp_max_dat, setTemp_max_dat] = useState(0);
  const [temp_min_dat, setTemp_min_dat] = useState(0);
  const [skyCondition, setSkyCondition] = useState(0);
  const [rainCondition, setRainCondition] = useState<number | null>(null);
  const [temp_now, setTemp_now] = useState(0);
  const [rain, setRain] = useState("강수없음");

  // 오늘 날짜
  let today = new Date();
  let year = today.getFullYear();
  let tempMonth = today.getMonth() + 1;
  let month = tempMonth < 10 ? "0" + tempMonth : tempMonth;
  let tempDate = today.getDate();
  let date = tempDate < 10 ? "0" + tempDate : tempDate;
  let time = today.getHours();

  let base_today = year + "" + month + "" + date;
  let base_time = time + "00";

  // 어제 날짜
  let nowDate = new Date();
  let yesterDate = nowDate.getTime() - 1 * 24 * 60 * 60 * 1000;
  nowDate.setTime(yesterDate);

  let yesterYear = nowDate.getFullYear();
  let tempYesterMonth = nowDate.getMonth() + 1;
  let tempYesterDay = nowDate.getDate();
  let yesterMonth =
    tempYesterMonth < 10 ? "0" + tempYesterMonth : tempYesterMonth;
  let yesterDay = tempYesterDay < 10 ? "0" + tempYesterDay : tempYesterDay;

  // 내일 날짜
  let nowDate2 = new Date();
  let tmrDate = nowDate2.getTime() + 1 * 24 * 60 * 60 * 1000;
  nowDate2.setTime(tmrDate);

  let tmrYear = nowDate2.getFullYear();
  let tempTmrMonth = nowDate2.getMonth() + 1;
  let tempTmrDay = nowDate2.getDate();
  let tmrMonth = tempTmrMonth < 10 ? "0" + tempTmrMonth : tempTmrMonth;
  let tmrDay = tempTmrDay < 10 ? "0" + tempTmrDay : tempTmrDay;

  // 내일모레 날짜
  let nowDate3 = new Date();
  let twoafterDate = nowDate3.getTime() + 2 * 24 * 60 * 60 * 1000;
  nowDate3.setTime(twoafterDate);

  let twoafterYear = nowDate3.getFullYear();
  let tempTwoAfterMonth = nowDate3.getMonth() + 1;
  let tempTwoAfterDay = nowDate3.getDate();
  let twoafterMonth =
    tempTwoAfterMonth < 10 ? "0" + tempTwoAfterMonth : tempTwoAfterMonth;
  let twoafterDay =
    tempTwoAfterDay < 10 ? "0" + tempTwoAfterDay : tempTwoAfterDay;

  // 날
  let base_yesterday = yesterYear + "" + yesterMonth + "" + yesterDay;
  let base_tmrday = tmrYear + "" + tmrMonth + "" + tmrDay;

  // 요일(내일, 내일모레)
  var week = new Array(
    "일요일",
    "월요일",
    "화요일",
    "수요일",
    "목요일",
    "금요일",
    "토요일"
  );
  var dayOftheWeek = twoafterYear + "-" + twoafterMonth + "-" + twoafterDay;
  var twoafterDOW = new Date(`${dayOftheWeek}`).getDay();
  var DOWdayOftheWeek = week[twoafterDOW];

  // 오늘 날짜
  let dayOfWeek = week[new Date().getDay()];

  // 초단기예보 (현재 온도)

  let temp_before_time = time - 1;
  let before_time =
    temp_before_time < 10
      ? "0" + temp_before_time + "00"
      : temp_before_time + "00";

  let temp_after_time = time + 1;
  let after_time =
    temp_after_time < 10
      ? "0" + temp_after_time + "00"
      : temp_after_time + "00";
  const api_nowtemp =
    "http://apis.data.go.kr/1360000/VilageFcstInfoService_2.0/getUltraSrtFcst";

  useEffect(() => {
    let mounted = true;
    if (time > 0) {
      axios
        .get(
          `${api_nowtemp}?serviceKey=${data_api_key}&numOfRows=60&pageNo=1&dataType=JSON&base_date=${base_today}&base_time=${before_time}&nx=${siteX}&ny=${siteY}`
        )
        .then((res) => {
          let JsonNow =
            JSON.stringify(res.data.response?.body.items.item) || "";
          if (JsonNow) {
            const Jsonnowtmp = JSON.parse(JsonNow).filter(function (
              element: any
            ) {
              return (
                element.fcstTime === `${after_time}` &&
                element.category === "T1H"
              );
            });
            setTemp_now(Jsonnowtmp[0].fcstValue);
            const Jsonnowrain = JSON.parse(JsonNow).filter(function (
              element: any
            ) {
              return (
                element.fcstTime === `${after_time}` &&
                element.category === "RN1"
              );
            });
            setRain(Jsonnowrain[0].fcstValue);
            const Jsonnowsky = JSON.parse(JsonNow).filter(function (
              element: any
            ) {
              return (
                element.fcstTime === `${after_time}` &&
                element.category === "SKY"
              );
            });
            setSkyCondition(Jsonnowsky[0].fcstValue);
            const Jsonnowraincondition = JSON.parse(JsonNow).filter(function (
              element: any
            ) {
              return (
                element.fcstTime === `${after_time}` &&
                element.category === "PTY"
              );
            });
            setRainCondition(Jsonnowraincondition[0].fcstValue);
          }
        });
    } else {
      axios
        .get(
          `${api_nowtemp}?serviceKey=${data_api_key}&numOfRows=60&pageNo=1&dataType=JSON&base_date=${base_yesterday}&base_time=2300&nx=${siteX}&ny=${siteY}`
        )
        .then((res) => {
          let JsonNow = JSON.stringify(res.data.response.body.items.item);
          const Jsonnowtmp = JSON.parse(JsonNow).filter(function (
            element: any
          ) {
            return element.fcstTime === "0000" && element.category === "T1H";
          });
          setTemp_now(Jsonnowtmp[0].fcstValue);
          const Jsonnowrain = JSON.parse(JsonNow).filter(function (
            element: any
          ) {
            return element.fcstTime === "0000" && element.category === "RN1";
          });
          setRain(Jsonnowrain[0].fcstValue);
          const Jsonnowsky = JSON.parse(JsonNow).filter(function (
            element: any
          ) {
            return element.fcstTime === "0000" && element.category === "SKY";
          });
          setSkyCondition(Jsonnowsky[0].fcstValue);
          const Jsonnowraincondition = JSON.parse(JsonNow).filter(function (
            element: any
          ) {
            return element.fcstTime === "0000" && element.category === "PTY";
          });
          setRainCondition(Jsonnowraincondition[0].fcstValue);
        });
    }
    return () => {
      mounted = false;
    };
  }, [siteAddress]);

  // 내일, 모레 데이터
  const [poptmrAM, setPoptmrAM] = useState(0);
  const [poptmrPM, setPoptmrPM] = useState(0);
  const [poptdaAM, setPoptdaAM] = useState(0);
  const [poptdaPM, setPoptdaPM] = useState(0);

  const [skytmrAM, setSkytmrAM] = useState(0);
  const [skytmrPM, setSkytmrPM] = useState(0);
  const [skytdaAM, setSkytdaAM] = useState(0);
  const [skytdaPM, setSkytdaPM] = useState(0);

  const [ptytmrAM, setPtytmrAM] = useState(0);
  const [ptytmrPM, setPtytmrPM] = useState(0);
  const [ptytdaAM, setPtytdaAM] = useState(0);
  const [ptytdaPM, setPtytdaPM] = useState(0);

  // 단기 예보(오늘 최저, 최고 & 내일, 모레 날씨 정보)
  useEffect(() => {
    let mounted = true;
    if (time > 2) {
      axios
        .get(
          `${api_go}?serviceKey=${data_api_key}&pageNo=1&numOfRows=846&dataType=JSON&base_date=${base_today}&base_time=0200&nx=${siteX}&ny=${siteY}`
        )
        .then((res) => {
          setForecastData(res.data.response?.body.items.item);
          let JsonMaxMin =
            JSON.stringify(res.data.response?.body.items.item) || "";
          if (JsonMaxMin) {
            const Json_tempMin = JSON.parse(JsonMaxMin).filter(function (
              element: any
            ) {
              return element.category === "TMN";
            });
            const Json_tempMax = JSON.parse(JsonMaxMin).filter(function (
              element: any
            ) {
              return element.category === "TMX";
            });
            const Json_pop = JSON.parse(JsonMaxMin).filter(function (
              element: any
            ) {
              return element.category === "POP";
            });
            const poptmrAM = Json_pop.slice(21, 33).reduce(function (
              prev: any,
              current: any
            ) {
              return prev.fcstValue > current.fcstValue ? prev : current;
            });
            const poptmrPM = Json_pop.slice(33, 45).reduce(function (
              prev: any,
              current: any
            ) {
              return prev.fcstValue > current.fcstValue ? prev : current;
            });
            const poptdaAM = Json_pop.slice(45, 57).reduce(function (
              prev: any,
              current: any
            ) {
              return prev.fcstValue > current.fcstValue ? prev : current;
            });
            const poptdaPM = Json_pop.slice(57, 70).reduce(function (
              prev: any,
              current: any
            ) {
              return prev.fcstValue > current.fcstValue ? prev : current;
            });
            const Json_skyAM = JSON.parse(JsonMaxMin).filter(function (
              element: any
            ) {
              return element.category === "SKY" && element.fcstTime === "0700";
            });
            const Json_skyPM = JSON.parse(JsonMaxMin).filter(function (
              element: any
            ) {
              return element.category === "SKY" && element.fcstTime === "1800";
            });
            const Json_ptyAM = JSON.parse(JsonMaxMin).filter(function (
              element: any
            ) {
              return element.category === "PTY" && element.fcstTime === "0700";
            });
            const Json_ptyPM = JSON.parse(JsonMaxMin).filter(function (
              element: any
            ) {
              return element.category === "PTY" && element.fcstTime === "1800";
            });
            setSkytmrAM(Json_skyAM[1].fcstValue);
            setSkytdaAM(Json_skyAM[2].fcstValue);
            setSkytmrPM(Json_skyPM[1].fcstValue);
            setSkytdaPM(Json_skyPM[2].fcstValue);
            setPtytmrAM(Json_ptyAM[1].fcstValue);
            setPtytdaAM(Json_ptyAM[2].fcstValue);
            setPtytmrPM(Json_ptyPM[1].fcstValue);
            setPtytdaPM(Json_ptyPM[2].fcstValue);
            setPoptmrAM(poptmrAM.fcstValue);
            setPoptmrPM(poptmrPM.fcstValue);
            setPoptdaAM(poptdaAM.fcstValue);
            setPoptdaPM(poptdaPM.fcstValue);
            setTemp_min(Json_tempMin[0].fcstValue);
            setTemp_max(Json_tempMax[0].fcstValue);
            setTemp_min_tmr(Json_tempMin[1].fcstValue);
            setTemp_max_tmr(Json_tempMax[1].fcstValue);
            setTemp_min_dat(Json_tempMin[2].fcstValue);
            setTemp_max_dat(Json_tempMax[2].fcstValue);
          }
        });
    } else {
      axios
        .get(
          `${api_go}?serviceKey=${data_api_key}&pageNo=1&numOfRows=737&dataType=JSON&base_date=${base_yesterday}&base_time=2300&nx=${siteX}&ny=${siteY}`
        )
        .then((res) => {
          let JsonMaxMin = JSON.stringify(res.data.response.body.items.item);
          const Json_tempMin = JSON.parse(JsonMaxMin).filter(function (
            element: any
          ) {
            return element.category === "TMN";
          });
          const Json_tempMax = JSON.parse(JsonMaxMin).filter(function (
            element: any
          ) {
            return element.category === "TMX";
          });
          const Json_pop = JSON.parse(JsonMaxMin).filter(function (
            element: any
          ) {
            return element.category === "POP";
          });
          const poptmrAM = Json_pop.slice(24, 36).reduce(function (
            prev: any,
            current: any
          ) {
            return prev.fcstValue > current.fcstValue ? prev : current;
          });
          const poptmrPM = Json_pop.slice(36, 48).reduce(function (
            prev: any,
            current: any
          ) {
            return prev.fcstValue > current.fcstValue ? prev : current;
          });
          const poptdaAM = Json_pop.slice(48, 60).reduce(function (
            prev: any,
            current: any
          ) {
            return prev.fcstValue > current.fcstValue ? prev : current;
          });
          const poptdaPM = Json_pop.slice(63, 73).reduce(function (
            prev: any,
            current: any
          ) {
            return prev.fcstValue > current.fcstValue ? prev : current;
          });
          const Json_skyAM = JSON.parse(JsonMaxMin).filter(function (
            element: any
          ) {
            return element.category === "SKY" && element.fcstTime === "0700";
          });
          const Json_skyPM = JSON.parse(JsonMaxMin).filter(function (
            element: any
          ) {
            return element.category === "SKY" && element.fcstTime === "1800";
          });
          const Json_ptyAM = JSON.parse(JsonMaxMin).filter(function (
            element: any
          ) {
            return element.category === "PTY" && element.fcstTime === "0700";
          });
          const Json_ptyPM = JSON.parse(JsonMaxMin).filter(function (
            element: any
          ) {
            return element.category === "PTY" && element.fcstTime === "1800";
          });
          setSkytmrAM(Json_skyAM[1].fcstValue);
          setSkytdaAM(Json_skyAM[2].fcstValue);
          setSkytmrPM(Json_skyPM[1].fcstValue);
          setSkytdaPM(Json_skyPM[2].fcstValue);
          setPtytmrAM(Json_ptyAM[1].fcstValue);
          setPtytdaAM(Json_ptyAM[2].fcstValue);
          setPtytmrPM(Json_ptyPM[1].fcstValue);
          setPtytdaPM(Json_ptyPM[2].fcstValue);
          setPoptmrAM(poptmrAM.fcstValue);
          setPoptmrPM(poptmrPM.fcstValue);
          setPoptdaAM(poptdaAM.fcstValue);
          setPoptdaPM(poptdaPM.fcstValue);
          setTemp_min(Json_tempMin[0].fcstValue);
          setTemp_max(Json_tempMax[0].fcstValue);
          setTemp_min_tmr(Json_tempMin[1].fcstValue);
          setTemp_max_tmr(Json_tempMax[1].fcstValue);
          setTemp_min_dat(Json_tempMin[2].fcstValue);
          setTemp_max_dat(Json_tempMax[2].fcstValue);
        });
    }
    return () => {
      mounted = false;
    };
  }, [siteAddress]);

  // 날씨 아이콘 & description
  const [icons, setIcons] = useState("");
  const [description, setDescription] = useState("");
  const [tmrAMIcons, setTmrAMIcons] = useState("");
  const [tmrPMIcons, setTmrPMIcons] = useState("");
  const [tdaAMIcons, setTdaAMIcons] = useState("");
  const [tdaPMIcons, setTdaPMIcons] = useState("");
  useEffect(() => {
    let mounted = true;
    if (rainCondition) {
      if (Number(rainCondition) == 3) {
        setIcons("/images/snowy.png");
      } else if (Number(rainCondition) === 7) {
        setIcons("/images/snowy.png");
      } else if (Number(rainCondition) === 2) {
        setIcons("/images/snowing.png");
      } else if (Number(rainCondition) === 6) {
        setIcons("/images/snowing.png");
      } else if (Number(rainCondition) === 5) {
        setIcons("/images/shower.png");
      } else if (Number(rainCondition) === 1) {
        setIcons("/images/rainy.png");
      } else {
        if (Number(skyCondition) === 1) {
          setIcons("/images/sunny.png");
        } else if (Number(skyCondition) === 3) {
          setIcons("/images/clouds.png");
        } else {
          setIcons("/images/cloudy.png");
        }
      }
    }
    if (rainCondition) {
      if (Number(rainCondition) === 1) {
        setDescription("비");
      } else if (Number(rainCondition) === 2) {
        setDescription("비/눈");
      } else if (Number(rainCondition) === 3) {
        setDescription("눈");
      } else if (Number(rainCondition) === 5) {
        setDescription("빗방울");
      } else if (Number(rainCondition) === 6) {
        setDescription("빗방울눈날림");
      } else if (Number(rainCondition) === 7) {
        setDescription("눈날림");
      } else {
        if (Number(skyCondition) === 1) {
          setDescription("맑음");
        } else if (Number(skyCondition) === 3) {
          setDescription("구름많음");
        } else {
          setDescription("흐림");
        }
      }
    }
    if (ptytmrAM) {
      if (Number(ptytmrAM) === 3) {
        setTmrAMIcons("/images/snowy.png");
      } else if (Number(ptytmrAM) === 2) {
        setTmrAMIcons("/images/snowing.png");
      } else if (Number(ptytmrAM) === 1) {
        setTmrAMIcons("/images/rainy.png");
      } else if (Number(ptytmrAM) === 4) {
        setTmrAMIcons("/images/shower.png");
      } else {
        if (Number(skytmrAM) === 1) {
          setTmrAMIcons("/images/sunny.png");
        } else if (Number(skytmrAM) === 3) {
          setTmrAMIcons("/images/clouds.png");
        } else {
          setTmrAMIcons("/images/cloudy.png");
        }
      }
    }
    if (ptytmrPM) {
      if (Number(ptytmrPM) === 3) {
        setTmrPMIcons("/images/snowy.png");
      } else if (Number(ptytmrPM) === 2) {
        setTmrPMIcons("/images/snowing.png");
      } else if (Number(ptytmrPM) === 1) {
        setTmrPMIcons("/images/rainy.png");
      } else if (Number(ptytmrPM) === 4) {
        setTmrPMIcons("/images/shower.png");
      } else {
        if (Number(skytmrPM) === 1) {
          setTmrPMIcons("/images/sunny.png");
        } else if (Number(skytmrPM) === 3) {
          setTmrPMIcons("/images/clouds.png");
        } else {
          setTmrPMIcons("/images/cloudy.png");
        }
      }
    }
    if (ptytdaAM) {
      if (Number(ptytdaAM) === 3) {
        setTdaAMIcons("/images/snowy.png");
      } else if (Number(ptytdaAM) === 2) {
        setTdaAMIcons("/images/snowing.png");
      } else if (Number(ptytdaAM) === 1) {
        setTdaAMIcons("/images/rainy.png");
      } else if (Number(ptytdaAM) === 4) {
        setTdaAMIcons("/images/shower.png");
      } else {
        if (Number(skytdaAM) === 1) {
          setTdaAMIcons("/images/sunny.png");
        } else if (Number(skytdaAM) === 3) {
          setTdaAMIcons("/images/clouds.png");
        } else {
          setTdaAMIcons("/images/cloudy.png");
        }
      }
    }
    if (ptytdaPM) {
      if (Number(ptytdaPM) === 3) {
        setTdaPMIcons("/images/snowy.png");
      } else if (Number(ptytdaPM) === 2) {
        setTdaPMIcons("/images/snowing.png");
      } else if (Number(ptytdaPM) === 1) {
        setTdaPMIcons("/images/rainy.png");
      } else if (Number(ptytdaPM) === 4) {
        setTdaPMIcons("/images/shower.png");
      } else {
        if (Number(skytdaPM) === 1) {
          setTdaPMIcons("/images/sunny.png");
        } else if (Number(skytdaPM) === 3) {
          setTdaPMIcons("/images/clouds.png");
        } else {
          setTdaPMIcons("/images/cloudy.png");
        }
      }
    }
    return () => {
      mounted = false;
    };
  }, [rainCondition, ptytmrAM, ptytdaAM, ptytdaAM, ptytdaPM]);

  return (
    <MainProfileBox>
      <MainContainer>
        <ProfileBox>
          {photoData ? (
            <PhotoBox>
              <img src={photoData} alt="프로필 사진" className="profilephoto" />
            </PhotoBox>
          ) : (
            <Avatar alt={photoData} sx={{ width: 80, height: 80 }}></Avatar>
          )}
          <ProfileTextBox>
            <DisabledText>안녕하세요</DisabledText>
            <MainText>
              <Typography variant="h5">{user.name} 님</Typography>
            </MainText>
          </ProfileTextBox>
          <SubTextBox>
            <SubText>{user?.group} </SubText>
          </SubTextBox>
          <SubTextBox>
            <SubText>{" " + user?.parentTeam} </SubText>
          </SubTextBox>
          <SubTextBox>
            <SubText>{" " + user?.teamName}</SubText>
          </SubTextBox>
          <SubTextBox>
            <SubText>
              {user.jobPosition} / {user.jobTitle}
            </SubText>
          </SubTextBox>
        </ProfileBox>
        <WeatherBox>
          <SubText>
            {year}/{month}/{date} {dayOfWeek}
            {icons && <WeatherImage src={icons} alt="weather"></WeatherImage>}
            {temp_now ? temp_now + "°C" : " - °C"}
            <HtmlTooltip
              placement="right"
              title={
                <Fragment>
                  <Typography color="inherit">날씨 예보 정보</Typography>
                  {
                    "날씨 예보는 대한민국 기상청에서 국내 날씨 예보 정보를 받아 제공됩니다."
                  }
                  <br />
                  <br />
                  <b>{"포털 제공 내용"}</b>
                  <br />
                  {
                    " 현재 이후 시간 중 가장 가까운 시간대의 날씨, 기온, 하루 중 최고, 최저 기온"
                  }
                  <br />
                  <b>{"하단부 제공 내용"}</b>
                  <br />
                  {
                    " 오전, 오후 출퇴근 시간대의 날씨(아이콘)와 강수량(%) / 하루 중 최고, 최저 기온"
                  }
                  <br />
                  <PortletContent>
                    <TextBoxAlignCenter>
                      <TmrForecast>
                        <TmrForecastContent>
                          <Typography
                            variant="subtitle2"
                            component="div"
                            align="center"
                          >
                            내일
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="div"
                            align="center"
                          >
                            {tmrMonth}.{tmrDay}.
                          </Typography>
                        </TmrForecastContent>
                        <TmrForecastContent>
                          <Typography
                            variant="subtitle2"
                            component="div"
                            align="center"
                          >
                            오전
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="div"
                            align="center"
                          >
                            {poptmrAM}%
                          </Typography>
                        </TmrForecastContent>
                        {tmrAMIcons ? (
                          <ForecastImg>
                            <ForecastWeathERImage
                              src={tmrAMIcons}
                              alt="내일 오전 날씨"
                            ></ForecastWeathERImage>
                          </ForecastImg>
                        ) : (
                          ""
                        )}

                        <TmrForecastContent>
                          <Typography
                            variant="subtitle2"
                            component="div"
                            align="center"
                          >
                            오후
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="div"
                            align="center"
                          >
                            {poptmrPM}%
                          </Typography>
                        </TmrForecastContent>
                        {tmrPMIcons ? (
                          <ForecastImg>
                            <ForecastWeathERImage
                              src={tmrPMIcons}
                              alt="내일 오후 날씨"
                            ></ForecastWeathERImage>
                          </ForecastImg>
                        ) : (
                          ""
                        )}

                        <TmrForecastContent>
                          <MaximumTemperature
                            variant="subtitle2"
                            align="center"
                          >
                            {" "}
                            {Math.floor(temp_max_tmr)}°C
                          </MaximumTemperature>
                          <MinimumTemperature
                            variant="subtitle2"
                            align="center"
                          >
                            {Math.floor(temp_min_tmr)}°C
                          </MinimumTemperature>
                        </TmrForecastContent>
                      </TmrForecast>

                      <TmrForecast>
                        <TmrForecastContent>
                          <Typography
                            variant="subtitle2"
                            component="div"
                            align="center"
                          >
                            {DOWdayOftheWeek}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="div"
                            align="center"
                          >
                            {twoafterMonth}.{twoafterDay}.
                          </Typography>
                        </TmrForecastContent>
                        <TmrForecastContent>
                          <Typography
                            variant="subtitle2"
                            component="div"
                            align="center"
                          >
                            오전
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="div"
                            align="center"
                          >
                            {poptdaAM}%
                          </Typography>
                        </TmrForecastContent>
                        {tdaAMIcons ? (
                          <ForecastImg>
                            <ForecastWeathERImage
                              src={tdaAMIcons}
                              alt="모레 오전 날씨"
                            ></ForecastWeathERImage>
                          </ForecastImg>
                        ) : (
                          ""
                        )}

                        <TmrForecastContent>
                          <Typography
                            variant="subtitle2"
                            component="div"
                            align="center"
                          >
                            오후
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="div"
                            align="center"
                          >
                            {poptdaPM}%
                          </Typography>
                        </TmrForecastContent>
                        {tdaPMIcons ? (
                          <ForecastImg>
                            <ForecastWeathERImage
                              src={tdaPMIcons}
                              alt="모레 오후 날씨"
                            ></ForecastWeathERImage>
                          </ForecastImg>
                        ) : (
                          ""
                        )}

                        <TmrForecastContent>
                          <MaximumTemperature
                            variant="subtitle2"
                            align="center"
                          >
                            {" "}
                            {Math.floor(temp_max_dat)}°C
                          </MaximumTemperature>
                          <MinimumTemperature
                            variant="subtitle2"
                            align="center"
                          >
                            {Math.floor(temp_min_dat)}°C
                          </MinimumTemperature>
                        </TmrForecastContent>
                      </TmrForecast>
                    </TextBoxAlignCenter>
                  </PortletContent>
                </Fragment>
              }
            >
              <InfoIcon color="disabled" />
            </HtmlTooltip>
          </SubText>
          <div>
            <TemperatureTitle>최고기온</TemperatureTitle>
            <TemperatureContent>
              {" "}
              {temp_max ? temp_max : "-"}°C
            </TemperatureContent>
            <TemperatureTitle>최저기온</TemperatureTitle>
            <TemperatureContent>
              {" "}
              {temp_min ? temp_min : "-"}°C
            </TemperatureContent>
            <TemperatureTitle>위치</TemperatureTitle>
            <TemperatureContent>
              {" "}
              {siteAddress ? siteAddress : "논현동"}
            </TemperatureContent>

            <Tooltip title="위치 설정" placement="right">
              <SettingText onClick={handleOpen}>설정</SettingText>
            </Tooltip>
            <Modal open={open} onClose={handleClose}>
              <Box sx={style}>
                <div className="modalTitle">
                  <TitleBox title="날씨 예보 위치 설정" />
                  <CloseIcon
                    onClick={handleClose}
                    style={{ cursor: "pointer" }}
                    sx={{ fontSize: 40 }}
                  />
                </div>
                <div style={{ padding: "30px 10px 30px 10px" }}>
                  <FormControl>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      style={{ marginBottom: "10px", color: "#81848B" }}
                    >
                      회사 위치
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue={siteAddress}
                      name="radio-buttons-group"
                      onChange={onChangeAddress}
                    >
                      <FormControlLabel
                        value="논현동"
                        control={<Radio />}
                        label="(주)인바디 본사 | 서울시 강남구 언주로 625 인바디빌딩"
                        style={{ margin: "10px 0px" }}
                      />
                      <FormControlLabel
                        value="개포동"
                        control={<Radio />}
                        label="(주)인바디 벤처센터 | 서울시 강남구 논현로 2길 54 인바디벤처센터"
                        style={{ margin: "10px 0px" }}
                      />
                      <FormControlLabel
                        value="양재2동"
                        control={<Radio />}
                        label="(주)인바디 양재센터 | 서울특별시 서초구 동산로 1 5층 인바디양재센터"
                        style={{ margin: "10px 0px" }}
                      />
                      <FormControlLabel
                        value="천안시 서북구 입장면"
                        control={<Radio />}
                        label="㈜인바디 공장 | 충청남도 천안시 서북구 입장면"
                        style={{ margin: "10px 0px" }}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="tab_bt_wrap">
                  <CustomButtonGrey onClick={handleClose}>
                    취소
                  </CustomButtonGrey>
                  <CustomButtonBlackSpace onClick={onSaveAddress}>
                    저장
                  </CustomButtonBlackSpace>
                </div>
              </Box>
            </Modal>
          </div>
        </WeatherBox>
      </MainContainer>
    </MainProfileBox>
  );
}

export default MainProfile;
