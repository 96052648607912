export enum CommonRoutes {
  root = "/",
  logout = "/logout",
  login = "/login",
  menuAdmin = "/admin/Menu",
  yeonsuwonAdmin = "/admin/Yeonsuwon",

  noticeRoot = "/notice",
  notice = "/notice/board/:page",

  noticedetail = "/notice/detail/:noticeId",
  noticeedit = "/notice/edit/:noticeId?",
  departmentnoticeRoot = "/departmentnotice",
  departmentnotice = "/departmentnotice/board/:page",
  departmentnoticedetail = "/departmentnotice/detail/:departmentNoticeId",
  departmentnoticeedit = "/departmentnotice/edit/:departmentNoticeId?",

  qnaRoot = "/qna",
  qna = "/qna/board/:page",
  qnawrite = "/qna/qnawrite",
  qnadetail = "/qna/detail/:qnaId",
  qnaedit = "/qna/edit/:qnaId?",
  error = "/*",

  breakfasttable = "/breakfastmenu",
  cheonanMenu = "/cheonanMenu",
  mypage = "/mypage",

  yoensuwonReservation = "/yoensuwon/reservation",
  yoensuwonStatus = "/yoensuwon/reservationStatus",
  yoensuwonMyReservation = "/yoensuwon/myReservation",

  ceoschedule = "/ceoscheduler",
}
