import React, { useCallback, useEffect, useState } from "react";
import TitleBox from "../../components/Common/TitleBox";
import { DepartmentNotice } from "../../system/types/interface";
import { DepartmentNoticeApi, ErrorHandler } from "../../system/ApiService";
import DepartmentNoticeDetailBoard from "../../components/DepartmentNotice/DepartmentNoticeDetailBoard";
import { useHistory } from "react-router-dom";
import { useUserState } from "../../system/context/UserContext";
import AlertModal from "../../components/Common/AlertModal";

interface DepartmentNoticeProps {
  location: any;
}

function DepartmentNoticeDetail({ location }: DepartmentNoticeProps) {
  const [id, setId] = useState(0);
  const [data, setData]: any = useState<DepartmentNotice[]>([]);
  let history = useHistory();
  const user = useUserState();

  useEffect(() => {
    if (location.state.displays) {
      setId(Number(location.state.displays));
      const id = Number(location.state.displays);
      DepartmentNoticeApi.GetDepartmentNotice(id)
        .then((res) => {
          setData(res.data);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    } else {
      history.goBack();
    }
  }, [id]);

  // 데이터 받을 때마다 조회수 증가
  useEffect(() => {
    const newData: DepartmentNotice = {
      departmentNoticeTitle: data.departmentNoticeTitle,
      departmentNoticeContent: data.departmentNoticeContent,
      departmentNoticeModifiedAt: data.departmentNoticeModifiedAt,
      departmentNoticeWriter: data.departmentNoticeWriter,
      departmentNoticeId: data.departmentNoticeId,
      departmentNoticeMain: data.departmentNoticeMain,
      departmentNoticeCreatedAt: data.departmentNoticeCreatedAt,
      departmentNoticeViews: data.departmentNoticeViews + 1,
      departmentNoticeSendMail: data.departmentNoticeSendMail,
      teamName: data.teamName,
      type: data.type,
      fileuploadFolder: data.fileuploadFolder,
      departmentBoardId: data.departmentBoardId,
      employeeId: data.employeeId,
    };
    if (data.departmentNoticeTitle) {
      DepartmentNoticeApi.UpdateDepartmentNotice(newData)
        .then(() => {})
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  }, [data]);

  return (
    <div>
      <TitleBox title={`${user.parentTeam} 공지`} />
      <DepartmentNoticeDetailBoard data={data} />
    </div>
  );
}

export default DepartmentNoticeDetail;
