import { Typography } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import "../../styles/Components/Write/WriteBoard.css";
import { CheonanMenuApi, ErrorHandler } from "../../system/ApiService";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { CheonanJsonData, ChoenanWrite } from "../../system/types/interface";
import CheonanMenuTable from "../InbodyMenu/CheonanMenuTable";
import CheonanMenuPreview from "../InbodyMenu/CheonanMenuPreview";
import AlertModal from "../Common/AlertModal";

interface CheonanMenuWriteProps {
  parent: ChoenanWrite;
}

function CheonanMenuWrite({ parent }: CheonanMenuWriteProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState("");
  const [nameList, setNameList] = useState<string[]>([]);
  const [thisWeekJson, setThisWeekJson] = useState("");
  const [cheonanMenuData, setCheonanMenuData] = useState("");
  const [Jsondata, setJsonData] = useState<CheonanJsonData[]>([]);
  const [data, setData] = useState<CheonanJsonData[]>([]);
  const [viewData, setViewData] = useState(false);
  const OnChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setViewData(false);
    if (!event.target.files) return;
    setFileName(event.target.files[0].name);
    const formData = new FormData();
    formData.append("file", event.target.files[0]);

    var menucheckDate =
      String(parent.menuDate).substring(0, 4) +
      "-" +
      String(parent.menuDate).substring(4, 6) +
      "-" +
      String(parent.menuDate).substring(6, 8) +
      "T00:00:00";

    CheonanMenuApi.InsertCheonanMenuJson(formData)
      .then((res) => {
        setJsonData(res.data);

        if (res.data[0].date === menucheckDate) {
          setViewData(true);
        } else {
          AlertModal("check", "해당 날짜에 맞는 파일을 업로드해주세요.");
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 500) {
            AlertModal("check", "제공되는 형식에 맞는 파일을 업로드해주세요.");
          }
        }
      });
  };
  const weekName = ["이번 주", "다음 주", "다음다음 주"];

  function onSave(args: any) {
    if (
      String(parent.menuDate).substring(6, 8) !=
      Jsondata[0].date.substring(8, 10)
    ) {
      AlertModal("check", "제공되는 형식에 맞는 파일을 업로드해주세요.");
      return;
    }
    const savedata = JSON.stringify(Jsondata);
    if (parent.menuId != 0) {
      CheonanMenuApi.UpdateCheonanMenu({
        ...args.data,
        menuId: parent.menuId,
        menuDate: Number(parent.menuDate),
        menuDataList: savedata,
      })
        .then(() => {
          AlertModal(
            "success",
            `${weekName[parent.activeNum]} 주간 메뉴표가 저장되었습니다.`
          );
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    } else {
      CheonanMenuApi.InsertCheonanMenu({
        ...args.data,
        menuDate: Number(parent.menuDate),
        menuDataList: savedata,
      })
        .then(() => {
          AlertModal(
            "success",
            `${weekName[parent.activeNum]} 주간 메뉴표가 저장되었습니다.`
          );
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  }

  // css
  const uploadTitle = {
    marginTop: "10px",
  };

  const menubutton = {
    marginLeft: "20px",
  };

  const smallTable = {
    width: 1200,
  };

  return (
    <>
      <Typography variant="h6" style={uploadTitle}>
        메뉴 파일 업로드
      </Typography>
      <div className="filebox">
        <input
          className="upload-name"
          placeholder="엑셀 파일만 업로드 가능합니다."
        />
        <label htmlFor="file">파일찾기</label>
        <input
          type="file"
          id="file"
          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ref={inputRef}
          onChange={OnChangeFile}
        />
      </div>
      <>
        {fileName.length > 0 ? (
          <>
            <div>
              {fileName}
              <ButtonComponent style={menubutton} onClick={onSave}>
                주간메뉴표 저장
              </ButtonComponent>
            </div>

            <Typography variant="h6" style={uploadTitle}>
              메뉴 미리 보기
            </Typography>
            <CheonanMenuTable
              parent={{
                data: Jsondata,
                activeNum: parent.activeNum,
                viewData: viewData,
              }}
            />
          </>
        ) : (
          ""
        )}
      </>
      {parent.data.length > 0 ? (
        <>
          <Typography variant="h6" style={uploadTitle}>
            현재 등록된 주간 메뉴표
          </Typography>
          <CheonanMenuPreview
            parent={{
              data: parent.data,
              activeNum: parent.activeNum,
              viewData: true,
            }}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default CheonanMenuWrite;
