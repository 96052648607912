import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";
import { loginRequest } from "./authConfig";

function LoginRedirect() {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const inProgress = window.sessionStorage.getItem("msal_progress");

  useEffect(() => {
    // https://www.weareinbody.com 등 타 도메인으로 접속 시 redirect 중복 실행하는 이슈로 인해 미리 weareinbody.com 리다이렉트
    // if (!window.location.href.includes("https://weareinbody.com")) {
    //   window.location.replace("https://weareinbody.com");
    //   return;
    // }

    if (!isAuthenticated) {
      window.sessionStorage.setItem("msal_progress", "true");
      instance
        .loginRedirect(loginRequest)
        .then((res: any) => {
          console.log(res);
        })
        .catch((error: any) => {
          console.error(error);
          window.sessionStorage.removeItem("msal_progress");
          //alert("로그인 중 에러가 발생하였습니다.");
          //window.location.replace("http://localhost:3000");
        });
    }
  }, [inProgress, isAuthenticated, instance]);

  return <div>loginRedirect</div>;
}

export default LoginRedirect;
