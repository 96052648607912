import { styled } from "@mui/material/styles";
import React, { ReactNode } from "react";

interface ContentProps {
  children: ReactNode;
  open: boolean;
}

const drawerWidth = 235;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `0px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 140,
  }),
}));

function Content({ children, open }: ContentProps) {
  return (
    <>
      <div style={{ marginTop: "100px" }}>
        <Main open={open}>
          <div>{children}</div>
        </Main>
      </div>
    </>
  );
}

export default Content;
