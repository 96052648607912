import TitleBox from "../../components/Common/TitleBox";
import CheonanMenu from "../../components/InbodyMenu/CheonanMenu";

function Cheonan() {
  return (
    <>
      <TitleBox title="주간 메뉴표 (천안)" />
      <CheonanMenu />
    </>
  );
}

export default Cheonan;
