import React, { useCallback, useEffect, useState } from "react";
import { useHistory, Router } from "react-router-dom";
import TitleBox from "../../components/Common/TitleBox";
import { Qna } from "../../system/types/interface";
import { QnaApi, ErrorHandler } from "../../system/ApiService";
import QnaDetailBoard from "../../components/Qna/QnaDetailBoard";
import { useUserState } from "../../system/context/UserContext";
import AlertModal from "../../components/Common/AlertModal";

interface QnaProps {
  location: any;
}

function QnaDetail({ location }: QnaProps) {
  const user = useUserState();
  let history = useHistory();
  const id = Number(location.state.displays);
  const [data, setData]: any = useState<Qna[]>([]);

  useEffect(() => {
    QnaApi.GetQna(id)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
  }, [id]);

  //(data.qnaInCharge === user.teamName || data.employeeId === user.employeeId)

  // 데이터 받을 때마다 조회수 증가
  useEffect(() => {
    const newData: Qna = {
      qnaTitle: data.qnaTitle,
      qnaContent: data.qnaContent,
      qnaModifiedAt: data.qnaModifiedAt,
      qnaWriter: data.qnaWriter,
      qnaId: data.qnaId,
      qnaCreatedAt: data.qnaCreatedAt,
      qnaViews: data.qnaViews + 1,
      qnaSecret: data.qnaSecret,
      qnaInCharge: data.qnaInCharge,
      qnaPassword: data.qnaPassword,
      teamName: data.teamName,
      employeeId: data.employeeId,
      fileuploadFolder: data.fileuploadFolder,
      numberOfComments: data.numberOfComments,
    };
    if (data.qnaTitle) {
      if (data.qnaSecret === false) {
        QnaApi.UpdateQna(newData)
          .then(() => {})
          .catch((error) => {
            let msg = ErrorHandler(error);
            AlertModal("msg", msg);
          });
      } else if (
        data.qnaInCharge === user.teamName ||
        data.employeeId === user.employeeId
      ) {
        QnaApi.UpdateQna(newData)
          .then(() => {})
          .catch((error) => {
            let msg = ErrorHandler(error);
            AlertModal("msg", msg);
          });
      } else {
      }
    }
  }, [data]);

  return (
    <div>
      <TitleBox title="질의응답" />

      <QnaDetailBoard data={data} />
    </div>
  );
}

export default QnaDetail;
