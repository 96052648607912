import { isoDateFormatter } from "../../components/Common/IsoDateFormatter";
import { CeoScheduleInfo } from "./ceoschedule";
import {
  AttendanceInfo,
  AuthorityUser,
  AwayTime,
  BreakfastRanking,
  DailyWorkReport,
  DepartmentNotice,
  GetDocumentListDto,
  GetPaymentProgressStatus,
  MenuList,
  Notice,
  Qna,
} from "./interface";

export const InitUser: AuthorityUser = {
  employeeId: "",
  employeeNo: "",
  name: "",
  gwName: "",
  gwMail: "",
  jobTitle: "",
  jobPosition: "",
  workplace: "",
  teamId: "",
  teamName: "",
  parentTeamId: "",
  parentTeam: "",
  role: "",
  positionCode: "",
  authority: [],
};

export const InitMenuList: MenuList = {
  menuId: 0,
  menuDate: 0,
  mainMon: "",
  mainTue: "",
  mainWed: "",
  mainThu: "",
  mainFri: "",
  detailMon: "",
  detailTue: "",
  detailWed: "",
  detailThu: "",
  detailFri: "",
  absenteesMon: 0,
  absenteesTue: 0,
  absenteesWed: 0,
  absenteesThu: 0,
  absenteesFri: 0,
  total: 0
};

export const InitBreakfastRanking: BreakfastRanking = {
  ratingId: 0,
  employeeId: "",
  employeeName: "",
  menuDate: 0,
  mainMenu: "",
  selectMenu: "",
  starRating: 5,
  review: "",
  anonymity: false,
};

export const InitAttendanceInfo: AttendanceInfo = {
  absent: 0,
  annualLeave: 0,
  late: 0,
  lateExp: 0,
  monthlyLate: 0,
  monthlyLeave: 0,
  monthlyNightShift: 0,
  nightShiftSecom: 0,
  nightShiftV4: 0,
  totalLeave: 0,
  usedAnnualLeave: 0,
  yearOfService: 0,
  yearlyLate: 0,
  yearlyLateExp: 0,
};

export const InitNotice: Notice = {
  noticeId: 0,
  noticeTitle: "",
  noticeContent: "",
  noticeMain: false,
  noticeCreatedAt: "",
  noticeModifiedAt: "",
  noticeViews: 0,
  noticeSendMail: false,
  noticeWriter: "",
  teamName: "",
  type: "",
  fileuploadFolder: "",
  employeeId: "",
  setInfo: false,
};

export const InitDepartmentNotice: DepartmentNotice = {
  departmentNoticeId: 0,
  departmentNoticeTitle: "",
  departmentNoticeContent: "",
  departmentNoticeMain: false,
  departmentNoticeCreatedAt: "",
  departmentNoticeModifiedAt: "",
  departmentNoticeViews: 0,
  departmentNoticeSendMail: false,
  departmentNoticeWriter: "",
  teamName: "",
  type: "",
  departmentBoardId: 0,
  fileuploadFolder: "",
  employeeId: "",
  setInfo: false,
};

export const InitQna: Qna = {
  qnaId: 0,
  qnaTitle: "",
  qnaContent: "",
  qnaSecret: false,
  qnaPassword: "",
  qnaCreatedAt: "",
  qnaModifiedAt: "",
  qnaViews: 0,
  qnaInCharge: "",
  qnaWriter: "",
  employeeId: "",
  teamName: "",
  fileuploadFolder: "",
  numberOfComments: 0,
  setInfo: false,
};

const today = new Date();
export const InitCeoScheduleInfo: CeoScheduleInfo = {
  scheduleId: 0,
  scheduleSubject: "",
  scheduleContents: "",
  scheduleOwner: "차기철",
  startTime: isoDateFormatter(new Date()) + "T09:00:00",
  endTime: isoDateFormatter(new Date()) + "T12:00:00",
  isAllDay: false,
  categoryColor: "",
  scheduleCreatedAt: "",
  scheduleModifiedAt: "",
  teamName: "",
  scheduleWriter: "",
};

export const InitGetDocumentListDto: GetDocumentListDto = {
  id: 0,
  documentFormId: 0,
  title: "",
  status: 0,
  statusValue: "",
  employeeId: "",
  v4Id: 0,
  documentNo: "",
  parentTeam: "",
  team: "",
  jobPosition: "",
  jobTitle: "",
  name: "",
  created: "",
  updated: "",
  approved: "",
  relationType: "",
  formName: "",
  group: "",
  documentRead: 0,
  checked: false,
};

export const InitGetPaymentProgressStatus: GetPaymentProgressStatus = {
  inProgress: 0,
  awaitingApproval: 0,
  upcomingApproval: 0,
  unreadReference: 0,
};

export const InitDailyWorkReport: DailyWorkReport = {
  workDate: "",
  workStatus: "",
  workplace: "",
  group: "",
  parentTeam: "",
  team: "",
  jobPosition: "",
  jobTitle: "",
  name: "",
  gwMail: "",
  workGroupName: "",
  secomStartTime: "",
  secomEndTime: "",
  normalWorkTime: 0,
  secomOwTime: 0,
  document: "",
  gwStartTime: "",
  gwEndTime: "",
  gwOwTime: 0,
  weeklyOwTime: 0,
  remainWeeklyOwTime: 0,
  awayTime: 0,
  weeklyAwayTime: 0
}

export const InitAwayTime: AwayTime = {
  id: 0,
  kind: "",
  employeeNo: "",
  startedDate: "",
  stratedTime: "",
  endedTime: "",
}