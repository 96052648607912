import { Grid } from "@syncfusion/ej2-react-grids";
import { ScheduleComponent, Month, Inject, ViewsDirective, ViewDirective } from "@syncfusion/ej2-react-schedule";
import { useEffect, useState } from "react";
import { BreakfastRatingApi } from "../../system/ApiService";
import { BreakfastRanking } from "../../system/types/interface";
import "../../styles/Components/Admin/HeadOfficeRank.css";
import Rating from "@mui/material/Rating";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Typography from "@mui/material/Typography";

function HeadOfficeRank(this: any) {
  let scheduleObj: ScheduleComponent | null;

  // 오늘 날짜
  var currentDay = new Date();
  var theYear = currentDay.getFullYear();
  var theMonth = currentDay.getMonth() + 1;
  var theDate = currentDay.getDate();

  const [pickYear, setPickYear] = useState(theYear);
  const [pickMonth, setPickMonth] = useState(theMonth);
  const [pickDay, setPickDay] = useState(theDate);
  var menuMonth = pickMonth < 10 ? "0" + pickMonth : pickMonth;
  var menuDay = pickDay < 10 ? "0" + pickDay : pickDay;
  var menuDate = pickYear + "" + menuMonth + "" + menuDay;

  function checkData(args: any) {
    setPage(1);
    if (args.target.nodeName === "DIV" && args.target.ariaLabel) {
      var pickLabel = args.target.ariaLabel;
      var pickDate = new Date(pickLabel);
      // console.log(args.target.ariaLabel);
      setPickYear(pickDate.getFullYear());
      setPickMonth(pickDate.getMonth() + 1);
      setPickDay(pickDate.getDate());
    } else if (args.target.nodeName === "TD") {
      let elements = args.target.getElementsByClassName("e-navigate");
      var pickLabel = elements[0].ariaLabel;
      var pickDate = new Date(pickLabel);
      setPickYear(pickDate.getFullYear());
      setPickMonth(pickDate.getMonth() + 1);
      setPickDay(pickDate.getDate());
    }
  }

  // 페이징 처리
  const [totalData, setTotalData] = useState<BreakfastRanking[]>([]);
  const [reviewData, setReviewData] = useState<BreakfastRanking[]>([]);
  const [page, setPage] = useState(1);
  const [reviewdataLength, setReviewdataLength] = useState(Number(reviewData.length));
  const [dataLength, setDataLength] = useState(Number(totalData.length));
  const [pageRange, setPageRange] = useState(10);
  const lastPage = Math.ceil(dataLength / pageRange);
  const [checkDelete, setCheckDelete] = useState(0);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    BreakfastRatingApi.GetReviewPageBreakfastRating(page, 10, Number(menuDate)).then((res) => {
      setReviewData(res.data);
      setReviewdataLength(res.data.length);
    });
  }, [page, menuDate]);

  useEffect(() => {
    BreakfastRatingApi.GetTotalBreakfastRating(Number(menuDate)).then((res) => {
      setTotalData(res.data);
      setDataLength(res.data.length);
    });
  }, [menuDate]);

  // console.log(new Date("Wednesday, March 8, 2023"));
  const [checkContent, setCheckContent] = useState(0);
  function onChangeCheck(id: number) {
    if (id === checkContent) {
      setCheckContent(0);
    } else {
      setCheckContent(id);
    }
  }

  // 데이터
  let scheduleObject: ScheduleComponent;
  function onEventRendered(args: {
    data: { CategoryColor: string };
    element: { firstChild: HTMLElement; style: { backgroundColor: string } };
  }): void {
    let categoryColor: string = args.data.CategoryColor as string;
    if (!args.element || !categoryColor) {
      return;
    }
    if (scheduleObject.currentView === "Agenda") {
      (args.element.firstChild as HTMLElement).style.borderLeftColor = categoryColor;
    } else {
      args.element.style.backgroundColor = categoryColor;
    }
  }

  const data = [
    {
      Id: 1,
      Subject: "도시락",
      StartTime: new Date(2023, 2, 28, 8, 30),
      EndTime: new Date(2023, 2, 28, 8, 30),
      CategoryColor: "#971b2f",
    },
  ];

  function eventTemplate(props: any) {
    return (
      <div>
        <div style={{ background: props.CategoryColor }}>{props.Subject}</div>
      </div>
    );
  }

  return (
    <>
      <ScheduleComponent
        editorTemplate="test"
        ref={(t) => (scheduleObj = t)}
        onClick={checkData}
        dateFormat="yyyy년 MM월"
        eventSettings={{ dataSource: data }}
        eventRendered={onEventRendered}
      >
        <ViewsDirective>
          <ViewDirective option="Month" readonly={true} isSelected={true} eventTemplate={eventTemplate.bind(this)} />
        </ViewsDirective>
        <Inject services={[Month]} />
      </ScheduleComponent>
      <div className="reviewTable">
        <Typography variant="h5">
          {pickYear !== 0 ? (
            <>
              {pickYear}년 {pickMonth}월 {pickDay}일
            </>
          ) : (
            ""
          )}
        </Typography>
        <div>
          <div className="board_list">
            <div className="board_top">
              <div className="board_num">번호</div>
              <div className="board_selectmenu">선택한 메뉴</div>
              <div className="board_rating">별점</div>
              <div className="board_review">리뷰</div>
            </div>
            {reviewData
              ? reviewData.map((board, idx) => {
                  return (
                    <div className="board_top_board" key={board.ratingId}>
                      <div className="board_num">
                        {page === 1 ? idx + 1 : ""}
                        {page > 1 && idx < 9 ? page - 1 + "" + (idx + 1) : ""}
                        {page > 1 && idx === 9 ? page + "0" : ""}
                      </div>
                      <div className="board_selectmenu">{board.selectMenu}</div>
                      <div className="board_rating">
                        <Rating name="starRating" value={board.starRating} readOnly sx={{ fontSize: "22px" }} />
                      </div>
                      <div
                        className="board_review"
                        onClick={() => {
                          onChangeCheck(board.ratingId);
                        }}
                      >
                        <span
                          style={{
                            maxWidth: "95%",
                            minWidth: "95%",
                            float: "left",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "left",
                          }}
                        >
                          {" "}
                          {board.review}
                        </span>
                        <span style={{ float: "right" }}>
                          {" "}
                          {checkContent === board.ratingId ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </span>
                      </div>
                      {checkContent === board.ratingId ? (
                        <>
                          <pre className="reviewContent">{board.review}</pre>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })
              : ""}
            {reviewdataLength == 0 ? (
              <div className="board_emptyBox">
                <div className="board_emptynotice">작성된 주간 아침 메뉴(본사) 리뷰가 없습니다.</div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {dataLength > 0 ? (
          <div className="mypage_Pagination">
            <Stack spacing={2} alignItems="center">
              <Pagination
                count={lastPage}
                defaultPage={1}
                page={page}
                siblingCount={2}
                boundaryCount={2}
                onChange={handleChange}
              />
            </Stack>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default HeadOfficeRank;
