import { Box, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { CustomText, CustomTextProps } from "./CustomText";

interface CustomTextTooltipProps extends CustomTextProps {
  arrow?: boolean;
  placement?:
    | "top"
    | "right"
    | "bottom"
    | "left"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | undefined;
}

export default function CustomTextTooltip({
  arrow,
  placement = "bottom",
  children,
  ...props
}: CustomTextTooltipProps) {
  const textElement = useRef<HTMLDivElement | null>(null);
  const [overflow, setOverflow] = useState(false);

  useEffect(() => {
    const element = textElement.current;
    if (element) {
      setOverflow(element.scrollWidth > element.clientWidth);
    }
  }, [children]);

  return (
    <Tooltip
      placement={placement}
      arrow={arrow}
      title={
        overflow ? (
          <CustomText
            sx={{
              overflowWrap: "break-word",
              fontSize: "14px",
            }}
          >
            {children}
          </CustomText>
        ) : (
          ""
        )
      }
    >
      <Box ref={textElement}>
        {overflow ? (
          <CustomText {...props} overflowTooltip>
            {children}
          </CustomText>
        ) : (
          <CustomText {...props}>{children}</CustomText>
        )}
      </Box>
    </Tooltip>
  );
}
