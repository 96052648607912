import NoticeBoard from "../../components/Notice/NoticeBoard";
import TitleBox from "../../components/Common/TitleBox";

function NoticeMain() {
  return (
    <div>
      <TitleBox title="전사 공지 " />
      <NoticeBoard />
    </div>
  );
}

export default NoticeMain;
