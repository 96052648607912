import Typography from "@mui/material/Typography";
import "../../../../src/styles/Components/Main/Portlet.css";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { MenuList } from "../../../system/types/interface";
import { InitMenuList } from "../../../system/types/initObject";
import { ErrorHandler, MenuApi } from "../../../system/ApiService";
import AlertModal from "../../Common/AlertModal";

function BreakfastPortlet() {
  const [data, setData] = useState<MenuList>(InitMenuList);
  let history = useHistory();

  var currentDay = new Date();
  var theYear = currentDay.getFullYear();
  var theMonth = currentDay.getMonth();
  var theDate = currentDay.getDate();
  var theDayOfWeek = currentDay.getDay();

  var thisWeek: any = [];
  var weekDate: any = [];
  var dayoftheweek = ["일", "월", "화", "수", "목", "금", "토"];

  var pretodaymonth = theMonth + 1;
  var todaymonth =
    String(pretodaymonth).length === 1
      ? "0" + `${pretodaymonth}`
      : `${pretodaymonth}`;
  var todayDay =
    String(theDate).length === 1 ? "0" + `${theDate}` : `${theDate}`;
  var today =
    todaymonth + "월 " + todayDay + "일 " + `(${dayoftheweek[theDayOfWeek]})`;

  for (var i = 1; i < 6; i++) {
    var resultDay = new Date(theYear, theMonth, theDate + (i - theDayOfWeek));
    var yyyy = resultDay.getFullYear();
    var premm = Number(resultDay.getMonth()) + 1;
    var predd = resultDay.getDate();

    var mm = String(premm).length === 1 ? "0" + `${premm}` : `${premm}`;
    var dd = String(predd).length === 1 ? "0" + `${predd}` : `${predd}`;

    thisWeek[i - 1] = mm + "월 " + dd + "일 " + `(${dayoftheweek[i]})`;
    weekDate[i - 1] = yyyy + mm + dd;
  }
  var menuDate = Number(weekDate[0]);

  useEffect(() => {
    MenuApi.GetMenuList(menuDate)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
  }, []);

  const mainmenu = [
    data.mainMon,
    data.mainTue,
    data.mainWed,
    data.mainThu,
    data.mainFri,
  ];

  return (
    <div className="PortletLayout">
      <div className="PortletTitle">
        <Typography
          variant="h5"
          component="div"
          style={{ padding: "5px 0px 5px 5px" }}
        >
          주간 아침 메뉴 (본사)
          <Typography
            variant="subtitle2"
            component="span"
            onClick={() => {
              history.push("/breakfastmenu");
            }}
            style={{
              cursor: "pointer",
              paddingTop: "10px",
              paddingRight: "5px",
              color: "#81848B",
              float: "right",
              display: "inline-block",
            }}
          >
            [전체보기]
          </Typography>
        </Typography>
      </div>
      <div className="menuportlet_content">
        {thisWeek.map((day: any, index: number) => {
          return (
            <div key={index} className="menu_content">
              <div className={day === today ? "todaymenuDay" : "menuDay"}>
                {" "}
                {day}
              </div>
              <div className={day === today ? "todaymenuInfo" : "menuInfo"}>
                {" "}
                {mainmenu[index] ? mainmenu[index] : "추후 업데이트 예정"}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BreakfastPortlet;
