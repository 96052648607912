import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, MsalConsumer } from "@azure/msal-react";
import { msalConfig } from "./azure/authConfig";
import { registerLicense } from "@syncfusion/ej2-base";

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById("root");
registerLicense(
  "Mgo+DSMBMAY9C3t2VVhkQlFacl9JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRjWn1ddHRQTmZfU0E="
);

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>,
  container
);

<script src="https://unpkg.com/react-router-dom/umd/react-router-dom.min.js"></script>;
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
