import React, { useRef, useEffect, useState, createRef } from "react";
import "../../../src/styles/Components/Write/WriteBoard.css";
import { enableRipple } from "@syncfusion/ej2-base";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DepartmentNotice } from "../../system/types/interface";
import {
  DepartmentNoticeApi,
  ErrorHandler,
  NoticeAttachmentApi,
  FileUploadApi,
  FileDeleteApi,
} from "../../system/ApiService";
import { useHistory, useLocation } from "react-router-dom";
import { useUserState } from "../../system/context/UserContext";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";

import {
  CustomButtonBlackSpace,
  CustomButtonGrey,
} from "../../styles/Common/CommonComponent";
import { InitDepartmentNotice } from "../../system/types/initObject";
import CrossEditor from "../Common/Editor/CrossEditor";
import AlertModal from "../Common/AlertModal";
import { Grid } from "@mui/material";
import { CustomText } from "../../styles/CustomText";
function DepartmentNoticeEditBoard() {
  const user = useUserState();
  const [data, setData] = useState<DepartmentNotice>(InitDepartmentNotice);
  const [noticesendmail, setNoticesendmail] = useState(false);
  const [deleteFileName, setDeleteFileName]: any = useState([]);
  const [deleteFileId, setDeleteFileId]: any = useState([]);
  const refContent = createRef<CrossEditor>();

  const noticewriter = user.parentTeam + " " + user.name;
  const [teamName, setTeamName] = useState(user.parentTeam);
  const [started, setStarted] = useState<boolean>(false);
  const params = {
    Width: "100%",
    Height: 700,
  };
  const onLoaded = (_: any, editor: { GetBodyValue: () => any }) => {
    setStarted(true);
  };
  // Id 값 가져오기
  let history = useHistory();
  const location = useLocation();
  const id = Number(location.state);

  // 날짜(수정날짜만 변경)
  const today = new Date();
  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth() + 1;
  const todayDate = today.getDate();
  const todayHour = today.getHours();
  const todayMinute = today.getMinutes();
  const todaySecond = today.getSeconds();

  const noticecreatedat =
    todayYear + "년 " + todayMonth + "월 " + todayDate + "일";
  const noticemodifiedat =
    todayYear + "년 " + todayMonth + "월 " + todayDate + "일";

  const [Fileuploadfolder, setFileuploadfolder] = useState(
    String(todayYear) +
      String(todayMonth) +
      String(todayDate) +
      String(todayHour) +
      String(todayMinute) +
      String(todaySecond) +
      "_" +
      user.employeeId
  );

  // 작성 정보 받아오기
  useEffect(() => {
    if (id) {
      DepartmentNoticeApi.GetDepartmentNotice(id)
        .then((res) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(res.data.content, "text/html");
          const tableElement = doc.querySelector(
            'table[name="namo-editor-table-class"]'
          );
          if (tableElement) {
            setData({ ...res.data, setInfo: true });
            setFileuploadfolder(res.data.fileuploadFolder);
          } else {
            setData({
              ...res.data,
              setInfo: true,
              departmentNoticeContent: res.data.departmentNoticeContent,
            });
            setFileuploadfolder(res.data.fileuploadFolder);
          }
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    } else {
      setData({ ...data, setInfo: true });
    }
  }, [id]);

  // 버튼
  enableRipple(true);

  // 글 수정
  const handleOnChange = (args: any) => {
    if (args.target.name === "departmentNoticeMain") {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: !data.departmentNoticeMain,
      });
    } else {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    }
  };

  // 글 수정 전송
  var submitcheck = false;

  const EditSubmit = () => {
    var checksubmit = 0;
    if (data.departmentNoticeTitle.length == 0) {
      AlertModal("check", "제목을 작성해주세요");

      return;
    }
    if (data.type.length == 0) {
      AlertModal("check", "유형을 선택해주세요");
      return;
    }

    if (id && checksubmit === 0) {
      for (var i = 0; i < deleteFileName.length; i++) {
        FileDeleteApi.DeleteFile(
          "Notice",
          Fileuploadfolder,
          deleteFileName[i]
        ).then(() => {
          checksubmit = 1;
        });
      }

      for (var i = 0; i < deleteFileId.length; i++) {
        NoticeAttachmentApi.DeleteNoticeAttachment(deleteFileId[i])
          .then(() => {
            console.log("업로드 파일 삭제");
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            AlertModal("msg", msg);
          });
      }
    }

    if (submitcheck === false && refContent.current) {
      const editor = refContent.current.editorRef;
      submitcheck = true;

      const newData: DepartmentNotice = {
        ...data,
        departmentNoticeContent: editor.GetBodyValue() || "",
        departmentNoticeModifiedAt: noticemodifiedat,
        departmentNoticeSendMail: noticesendmail,
        fileuploadFolder: Fileuploadfolder,
      };

      if (id) {
        DepartmentNoticeApi.UpdateDepartmentNotice(newData)
          .then(() => {
            AlertModal("success", "공지사항이 수정되었습니다.");
            history.push("/departmentnotice/board/1");
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            AlertModal("msg", msg);
          });
      } else {
        DepartmentNoticeApi.InsertDepartmentNotice({
          ...data,
          departmentNoticeContent: editor.GetBodyValue() || "",
          departmentNoticeCreatedAt: noticecreatedat,
          departmentNoticeModifiedAt: noticemodifiedat,
          departmentNoticeSendMail: noticesendmail,
          departmentNoticeWriter: noticewriter,
          teamName: user.parentTeam,
          fileuploadFolder: Fileuploadfolder,
          employeeId: user.employeeId,
        })
          .then(() => {
            AlertModal("success", "공지사항이 작성되었습니다.");
            history.push("/departmentnotice/board/1");
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            AlertModal("msg", msg);
          });
      }
    }

    const NoticeUploadfile = new FormData();
    Object.values(files).forEach((files) =>
      NoticeUploadfile.append("files", files)
    );

    FileUploadApi.InsertFileUpload(NoticeUploadfile, "Notice", Fileuploadfolder)
      .then(() => {})
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });

    Object.values(files).forEach((files) =>
      NoticeAttachmentApi.InsertNoticeAttachment({
        fileuploadFolder: Fileuploadfolder,
        noticeAttachmentFileName: files.name,
      })
    );
  };

  // 파일 업로드
  const inputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [existingFiles, setExistingFiles]: any = useState([]);

  const saveFileSelected = (e: any) => {
    const nowSelectList: any = Array.from(e.target.files || []);
    const nowList = [...files];

    var checkFile = 1;
    for (var i = 0; i < nowSelectList.length; i++) {
      for (var j = 0; j < nowList.length; j++) {
        if (nowSelectList[i].name === nowList[j].name) {
          checkFile = 2;
        }
      }
    }
    for (var i = 0; i < nowSelectList.length; i++) {
      for (var j = 0; j < existingFiles.length; j++) {
        if (
          nowSelectList[i].name === existingFiles[j].noticeAttachmentFileName
        ) {
          checkFile = 2;
        }
      }
    }

    if (checkFile === 2) {
      AlertModal(
        "check",
        "기존 업로드 파일 중 중복된 이름을 가진 파일이 있습니다. \n업로드 하실 파일의 이름을 수정해주세요."
      );
    } else {
      const nowList = [...files];
      nowList.push(...nowSelectList);
      setFiles(nowList);
    }
  };

  const handleExistingFileDelete = (item: any, index: any) => {
    deleteFileName.push(existingFiles[index].noticeAttachmentFileName);
    const newFiles = [
      ...existingFiles.slice(0, index),
      ...existingFiles.slice(index + 1),
    ];
    setExistingFiles(newFiles);
    deleteFileId.push(item.noticeAttachmentId);
  };

  const handleDelete = (index: any) => {
    const newFiles = [...files.slice(0, index), ...files.slice(index + 1)];
    const store = new DataTransfer();
    newFiles.forEach((file) => store.items.add(file));

    if (inputRef.current) {
      inputRef.current.files = store.files;
    }
    setFiles(newFiles);
  };

  // 첨부 파일 정보
  useEffect(() => {
    if (data.fileuploadFolder) {
      NoticeAttachmentApi.GetNoticeAttachmentList(data.fileuploadFolder)
        .then((res) => {
          setExistingFiles(res.data);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  }, [data]);
  // 유형
  const [type, setType] = useState("");
  const TypeData: string[] = [
    "공지",
    "안내",
    "요청",
    "인사",
    "주의",
    "교육",
    "경조사",
    "기타",
  ];

  return (
    <div className="board">
      <div className="board_write">
        <div className="title">
          <Grid container alignItems="center" mb="10px">
            <Grid item style={{ width: "85px" }}>
              <CustomText type="title">제목</CustomText>
            </Grid>
            <Grid item style={{ width: "calc(100% - 85px)" }}>
              <input
                name="departmentNoticeTitle"
                value={data.departmentNoticeTitle || ""}
                type="text"
                placeholder="제목 입력"
                onChange={handleOnChange}
              />
            </Grid>
          </Grid>
        </div>
        <div className="info_dashed">
          <Grid container alignItems="center" mb="10px">
            <Grid item mr="45px">
              <CustomText type="title">주요 공지로 등록</CustomText>
            </Grid>

            <Grid>
              <input
                type="checkbox"
                name="departmentNoticeMain"
                onChange={handleOnChange}
                checked={data.departmentNoticeMain}
              />
            </Grid>
          </Grid>
        </div>

        <Grid container alignItems="center" margin="10px 0px">
          <Grid container item xs={12} sm={6} alignItems="center">
            <Grid item mr="25px">
              <CustomText type="title">작성자</CustomText>
            </Grid>

            <Grid item>
              <CustomText type="subtitle2">
                {data.departmentNoticeWriter
                  ? data.departmentNoticeWriter
                  : noticewriter}
              </CustomText>
            </Grid>
          </Grid>

          <Grid container item xs={12} sm={6} alignItems="center">
            <Grid item mr="25px">
              <CustomText type="title">유형</CustomText>
            </Grid>
            <Grid item>
              <CustomText type="subtitle2">
                <DropDownListComponent
                  id="ddlelement"
                  name="type"
                  value={data.type}
                  dataSource={TypeData}
                  placeholder="공지 유형을 선택해주세요."
                  onChange={handleOnChange}
                  width="80%"
                />
              </CustomText>
            </Grid>
          </Grid>
        </Grid>

        <form id="myForm" className="form-vertical">
          <div className="form-group">
            {data.setInfo && (
              <CrossEditor
                ref={refContent}
                name="inbodyportal"
                params={params}
                value={data.departmentNoticeContent}
                onLoaded={onLoaded}
              />
            )}
          </div>
        </form>

        <div className="info">
          <div className="fileuploadbox">첨부파일</div>
          <div className="filebox">
            <input className="upload-name" placeholder="첨부파일" />
            <label htmlFor="file">파일찾기</label>
            <input
              ref={inputRef}
              type="file"
              id="file"
              onChange={saveFileSelected}
              multiple
            />
            <ul style={{ margin: "5px" }}>
              {existingFiles.map((item: any, index: number) => (
                <li
                  key={`existingfile_${index}_${item.noticeAttachmentId}_index`}
                >
                  {item.noticeAttachmentFileName}
                  <ButtonComponent
                    iconCss="e-icons e-close"
                    className="fileuploadButton"
                    onClick={() => handleExistingFileDelete(item, index)}
                  >
                    삭제
                  </ButtonComponent>
                </li>
              ))}
              {files.map((item: any, index) => (
                <li key={`uplaodfile_${index}_${item.noticeAttachmentId}`}>
                  {item.name}
                  <ButtonComponent
                    iconCss="e-icons e-close"
                    className="fileuploadButton"
                    onClick={() => handleDelete(index)}
                  >
                    삭제
                  </ButtonComponent>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="bt_wrap">
        <CustomButtonGrey
          onClick={() => {
            history.goBack();
          }}
        >
          취소
        </CustomButtonGrey>
        {id ? (
          <CustomButtonBlackSpace
            id="validateSubmit"
            className="sample-btn e-control e-btn"
            type="submit"
            data-ripple="true"
            onClick={EditSubmit}
          >
            수정
          </CustomButtonBlackSpace>
        ) : (
          <CustomButtonBlackSpace
            id="validateSubmit"
            className="sample-btn e-control e-btn"
            type="submit"
            data-ripple="true"
            onClick={EditSubmit}
          >
            등록
          </CustomButtonBlackSpace>
        )}
      </div>
    </div>
  );
}
export default DepartmentNoticeEditBoard;
