import { styled } from "@mui/material/styles";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

export const CustomButtonBlackSpace = styled(ButtonComponent)({
  backgroundColor: "#313131",
  color: "#FFFFFF",
  fontWeight: "500",
  boxShadow: "none",
  padding: "10px",
  borderRadius: "8px",
  width: "110px",
  "&:hover": { backgroundColor: "#000000", color: "#FFFFFF" },
  marginLeft: "10px",
  textAlign: "center",
});

export const CustomButtonBlack = styled(ButtonComponent)({
  backgroundColor: "#313131",
  color: "#FFFFFF",
  fontWeight: "500",
  boxShadow: "none",
  padding: "10px",
  borderRadius: "8px",
  width: "110px",
  textAlign: "center",
  "&:hover": { backgroundColor: "#000000", color: "#FFFFFF" },
});

export const CustomButtonBlue = styled(ButtonComponent)({
  backgroundColor: "#CEE4FE",
  color: "#3883D8",
  fontWeight: "500",
  boxShadow: "none",
  padding: "10px",
  borderRadius: "8px",
  width: "110px",
  textAlign: "center",
  "&:hover": { backgroundColor: "#3883D8", color: "#FFFFFF" },
});

export const CustomButtonGreen = styled(ButtonComponent)({
  backgroundColor: "#CAEDE2",
  color: "#0E8A65",
  fontWeight: "500",
  boxShadow: "none",
  padding: "10px",
  borderRadius: "8px",
  width: "110px",
  textAlign: "center",
  "&:hover": { backgroundColor: "#0E8A65", color: "#FFFFFF" },
});

export const CustomButtonRed = styled(ButtonComponent)({
  backgroundColor: "#F6DBDD",
  color: "#CA4545",
  fontWeight: "500",
  boxShadow: "none",
  padding: "10px",
  borderRadius: "8px",
  width: "110px",
  textAlign: "center",
  "&:hover": { backgroundColor: "#C53636", color: "#FFFFFF" },
});

export const CustomButtonGrey = styled(ButtonComponent)({
  backgroundColor: "#E4E6E7",
  color: "#878787",
  fontWeight: "500",
  boxShadow: "none",
  padding: "10px",
  borderRadius: "8px",
  width: "110px",
  textAlign: "center",
});

export const CustomButtonWhite = styled(ButtonComponent)({
  backgroundColor: "#FFFFFF",
  color: "#9B9B9B",
  fontWeight: "500",
  border: "1px #D9D9D9 solid",
  boxShadow: "none",
  padding: "10px",
  borderRadius: "8px",
  width: "110px",
  "&:hover": {
    backgroundColor: "#FFFFFF",
    color: "#9B9B9B",
  },
  marginBottom: "5px",
  textAlign: "center",
});

// Notice Button
export const NoticeButtonBlue = styled("div")({
  backgroundColor: "#CEE4FE",
  color: "#3883D8",
  fontWeight: "600",
  boxShadow: "none",
  padding: "5px",
  borderRadius: "8px",
  width: "90px",
  margin: "0 auto",
  textAlign: "center",
});

export const NoticeButtonGrey = styled("div")({
  backgroundColor: "#E4E6E7",
  color: "#878787",
  fontWeight: "600",
  boxShadow: "none",
  padding: "5px",
  borderRadius: "8px",
  width: "90px",
  margin: "0 auto",
  textAlign: "center",
});

export const NoticeButtonRed = styled("div")({
  backgroundColor: "#F6DBDD",
  color: "#CA4545",
  fontWeight: "600",
  boxShadow: "none",
  padding: "5px",
  borderRadius: "8px",
  width: "90px",
  margin: "0 auto",
  textAlign: "center",
});

export const NoticeButtonYellow = styled("div")({
  backgroundColor: "#FFE88E",
  color: "#F59210",
  fontWeight: "600",
  boxShadow: "none",
  padding: "5px",
  borderRadius: "8px",
  width: "90px",
  margin: "0 auto",
  textAlign: "center",
});

export const NoticeButtonGreen = styled("div")({
  backgroundColor: "#CAEDE2",
  color: "#0E8A65",
  fontWeight: "600",
  boxShadow: "none",
  padding: "5px",
  borderRadius: "8px",
  width: "90px",
  margin: "0 auto",
  textAlign: "center",
});

export const NoticeButtonMint = styled("div")({
  backgroundColor: "rgba(41,226,229,0.3)",
  color: "#05C3C6",
  fontWeight: "600",
  boxShadow: "none",
  padding: "5px",
  borderRadius: "8px",
  width: "90px",
  margin: "0 auto",
  textAlign: "center",
});

export const NoticeButtonPink = styled("div")({
  backgroundColor: "rgba(205,47,188,0.3)",
  color: "#CD2FBC",
  fontWeight: "600",
  boxShadow: "none",
  padding: "5px",
  borderRadius: "8px",
  width: "90px",
  margin: "0 auto",
  textAlign: "center",
});

export const NoticeButtonOrange = styled("div")({
  backgroundColor: "#FFBD67",
  color: "#B2711B",
  fontWeight: "600",
  boxShadow: "none",
  padding: "5px",
  borderRadius: "8px",
  width: "90px",
  margin: "0 auto",
  textAlign: "center",
});

export const NoticeButtonBrown = styled("div")({
  backgroundColor: "#d7ccc8",
  color: "#4e342e",
  fontWeight: "600",
  boxShadow: "none",
  padding: "5px",
  borderRadius: "8px",
  width: "90px",
  margin: "0 auto",
  textAlign: "center",
});
