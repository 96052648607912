import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { CheonanMenuApi, ErrorHandler } from "../../../system/ApiService";
import { CheonanJsonData } from "../../../system/types/interface";
import {
  CheonanPortletEmptyBox,
  CheonanPortletItem,
  CheonanPortletMenuInfo,
  MenuPortletDay,
  MenuPortletThisWeekend,
  NoticeBoxLayout,
  NoticeBoxTitle,
  NoticeViewAll,
} from "../../../styles/Components/Main/MainPortal";
import AlertModal from "../../Common/AlertModal";

export interface Menu {
  조식: string[];
  중식: string[];
  석식: string[];
}

function LongCheonanMenuPortlet() {
  let history = useHistory();
  const [data, setData] = useState<CheonanJsonData[]>([]);
  const [dataView, setDataView] = useState(false);

  var currentDay = new Date();
  var theDayOfWeek = currentDay.getDay();
  var theYear = currentDay.getFullYear();
  var theMonth = currentDay.getMonth();
  var theDate = currentDay.getDate();
  var getDay = currentDay.getDay();

  var pretodaymonth = theMonth + 1;
  var todaymonth =
    String(pretodaymonth).length === 1
      ? "0" + `${pretodaymonth}`
      : `${pretodaymonth}`;
  var todayDay =
    String(theDate).length === 1 ? "0" + `${theDate}` : `${theDate}`;
  var today: any = theYear + "" + todaymonth + "" + todayDay;

  var resultDay = new Date(theYear, theMonth, theDate + (1 - theDayOfWeek));
  var yyyy = resultDay.getFullYear();
  var premm = Number(resultDay.getMonth()) + 1;
  var predd = resultDay.getDate();

  var mm = String(premm).length === 1 ? "0" + `${premm}` : `${premm}`;
  var dd = String(predd).length === 1 ? "0" + `${predd}` : `${predd}`;

  var weekDateGetData = yyyy + "" + mm + "" + dd;

  let offset = currentDay.getTimezoneOffset() * 60000;
  let dateOffset = new Date(currentDay.getTime() - offset);
  let todayDate = dateOffset.toISOString();

  useEffect(() => {
    if (weekDateGetData.length > 0) {
      CheonanMenuApi.GetCheonanMenu(Number(weekDateGetData))
        .then((res) => {
          if (res.data) {
            setData(JSON.parse(res.data.menuDataList));
            setDataView(true);
          }
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  }, []);

  const todaymenuList = data.filter((data) => {
    return data.date === todayDate.split("T")[0] + "T00:00:00";
  });

  function moveToView() {
    history.push("/cheonanMenu");
  }
  return (
    <>
      <NoticeBoxLayout>
        <NoticeBoxTitle variant="h6">오늘의 메뉴 (천안)</NoticeBoxTitle>
        <NoticeViewAll onClick={moveToView}>전체보기</NoticeViewAll>
      </NoticeBoxLayout>
      <MenuPortletThisWeekend>
        {dataView && getDay != 0 && getDay != 6 ? (
          <>
            <CheonanPortletMenuInfo>
              <MenuPortletDay>조식 </MenuPortletDay>

              {todaymenuList[0]["breakfast"][0] != null ? (
                <>
                  {todaymenuList[0]["breakfast"].map(
                    (menu: any, index: number) => {
                      return (
                        <CheonanPortletItem key={index + menu}>
                          {menu}
                        </CheonanPortletItem>
                      );
                    }
                  )}
                </>
              ) : (
                <CheonanPortletEmptyBox>미운영</CheonanPortletEmptyBox>
              )}
            </CheonanPortletMenuInfo>

            <CheonanPortletMenuInfo>
              <MenuPortletDay>중식</MenuPortletDay>

              {todaymenuList[0]["lunch"][0] != null ? (
                <>
                  {todaymenuList[0]["lunch"].map((menu: any, index: number) => {
                    return (
                      <CheonanPortletItem key={index + menu}>
                        {menu}
                      </CheonanPortletItem>
                    );
                  })}
                </>
              ) : (
                <CheonanPortletEmptyBox>미운영</CheonanPortletEmptyBox>
              )}
            </CheonanPortletMenuInfo>
            <CheonanPortletMenuInfo>
              <MenuPortletDay>석식</MenuPortletDay>

              {todaymenuList[0]["dinner"][0] != null ? (
                <>
                  {todaymenuList[0]["dinner"].map(
                    (menu: any, index: number) => {
                      return (
                        <CheonanPortletItem key={index + menu}>
                          {menu}
                        </CheonanPortletItem>
                      );
                    }
                  )}
                </>
              ) : (
                <CheonanPortletEmptyBox>미운영</CheonanPortletEmptyBox>
              )}
            </CheonanPortletMenuInfo>
          </>
        ) : (
          <>
            <CheonanPortletMenuInfo>
              <MenuPortletDay>조식 </MenuPortletDay>
              <CheonanPortletEmptyBox>
                추후 업데이트 예정
              </CheonanPortletEmptyBox>
            </CheonanPortletMenuInfo>
            <CheonanPortletMenuInfo>
              <MenuPortletDay>중식 </MenuPortletDay>
              <CheonanPortletEmptyBox>
                추후 업데이트 예정
              </CheonanPortletEmptyBox>
            </CheonanPortletMenuInfo>
            <CheonanPortletMenuInfo>
              <MenuPortletDay>석식 </MenuPortletDay>
              <CheonanPortletEmptyBox>
                추후 업데이트 예정
              </CheonanPortletEmptyBox>
            </CheonanPortletMenuInfo>
          </>
        )}
      </MenuPortletThisWeekend>
    </>
  );
}

export default LongCheonanMenuPortlet;
