import React, { useCallback, useEffect, useState } from "react";
import "../../styles/Mypage/Board.css";
import { Qna } from "../../system/types/interface";
import { QnaApi, ErrorHandler } from "../../system/ApiService";
import { useHistory } from "react-router-dom";
import { useUserState } from "../../system/context/UserContext";

// syncfusion
import { enableRipple } from "@syncfusion/ej2-base";

// material ui
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import LockIcon from "@mui/icons-material/Lock";
import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  StyledBody,
  StyledBody2,
  StyledBodyTitle,
  StyledHead,
  StyledHead2,
  StyledHeadTitle,
} from "../../styles/theme";
import AlertModal from "../Common/AlertModal";

function MypageQna() {
  const user = useUserState();
  const [data, setData] = useState<Qna[]>([]);
  let history = useHistory();
  // 버튼
  enableRipple(true);
  // 페이징 처리
  const [totalData, setTotalData] = useState<Qna[]>([]);
  const [page, setPage] = useState(1);
  const [dataLength, setDataLength] = useState(1);
  const pageRange = 10; // 목록에 나타날 게시글 수
  const lastPage = Math.ceil(dataLength / pageRange);

  // 게시판 정보 불러오기

  useEffect(() => {
    QnaApi.GetMypageQnaList(page, 10, user.employeeId)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });

    QnaApi.GetQnaTotalList()
      .then((res) => {
        setTotalData(res.data);
        var datacnt = 0;
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].employeeId === user.employeeId) {
            datacnt += 1;
          }
        }
        setDataLength(datacnt);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
  }, [page]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  return (
    <Grid item xs={12} style={{ marginTop: "10px" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledHead align="center">번호</StyledHead>
              <StyledHead2 align="center">담당 부서</StyledHead2>
              <StyledHeadTitle align="center">제목</StyledHeadTitle>
              <StyledHead2 align="center">글쓴이</StyledHead2>
              <StyledHead2 align="center">작성일</StyledHead2>
              <StyledHead align="center">조회수</StyledHead>
            </TableRow>
          </TableHead>

          {data
            ? data.map((board: any) => {
                return (
                  <TableBody key={board.qnaId} id={board.qnaId}>
                    <TableRow>
                      <StyledBody align="center">{board.qnaId}</StyledBody>
                      <StyledBody2 align="center">
                        {board.qnaInCharge}
                      </StyledBody2>
                      {board.qnaSecret === false ? (
                        <StyledBodyTitle
                          onClick={() => {
                            history.push({
                              pathname: `/qna/detail/${board.qnaId}`,
                              state: { displays: board.qnaId },
                            });
                          }}
                        >
                          <span
                            style={{
                              maxWidth: "88%",
                              float: "left",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {board.qnaTitle}
                          </span>{" "}
                          <span
                            style={{
                              color: "#971B2F",
                              float: "left",
                              width: "10%",
                              marginLeft: "5px",
                            }}
                          >
                            {" "}
                            {board.numberOfComments
                              ? `[${board.numberOfComments}]`
                              : ""}{" "}
                          </span>{" "}
                        </StyledBodyTitle>
                      ) : (
                        <StyledBodyTitle
                          className="mypage_title"
                          onClick={() => {
                            history.push({
                              pathname: `/qna/detail/${board.qnaId}`,
                              state: { displays: board.qnaId },
                            });
                          }}
                        >
                          <span
                            style={{
                              maxWidth: "88%",
                              float: "left",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {board.qnaTitle}{" "}
                          </span>{" "}
                          <span style={{ float: "left" }}>
                            <LockIcon />{" "}
                          </span>{" "}
                          <span
                            style={{
                              color: "#971B2F",
                              float: "left",
                              width: "5%",
                              marginLeft: "5px",
                            }}
                          >
                            {" "}
                            {board.numberOfComments
                              ? `[${board.numberOfComments}]`
                              : ""}{" "}
                          </span>
                        </StyledBodyTitle>
                      )}
                      <StyledBody2 align="center">
                        {board.qnaWriter}{" "}
                      </StyledBody2>
                      <StyledBody2 align="center">
                        {board.qnaCreatedAt}
                      </StyledBody2>
                      <StyledBody align="center">{board.qnaViews}</StyledBody>
                    </TableRow>
                  </TableBody>
                );
              })
            : ""}
        </Table>
      </TableContainer>
      {dataLength == 0 ? (
        <div className="mypage_emptyBox">
          <div className="mypage_emptynotice">작성된 질의응답이 없습니다.</div>
        </div>
      ) : (
        ""
      )}

      {dataLength > 0 ? (
        <div className="mypage_Pagination">
          <Stack spacing={2} alignItems="center">
            <Pagination
              count={lastPage}
              defaultPage={1}
              page={page}
              siblingCount={2}
              boundaryCount={2}
              onChange={handleChange}
            />
          </Stack>
        </div>
      ) : (
        ""
      )}
    </Grid>
  );
}

export default MypageQna;
