import QnaBoard from "../../components/Qna/QnaBoard";
import TitleBox from "../../components/Common/TitleBox";
import React, { useEffect } from "react";

function QnaMain() {

    return (
        <div >
            <TitleBox title="질의응답" />
            <QnaBoard />
        </div>
    );
}

export default QnaMain;