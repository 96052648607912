import { Typography } from "@mui/material";
import { useEffect } from "react";
import { BasicTable, BasicTh, BasicTr, KindTh, MainBox, MainTh } from "../../styles/theme";
import { CheonanTable } from "../../system/types/interface";

interface CheonanMenuTableProps {
  parent: CheonanTable;
}

function CheonanMenuTable({ parent }: CheonanMenuTableProps) {
  var currentDay = new Date();
  var theYear = currentDay.getFullYear();
  var theMonth = currentDay.getMonth();
  var theDate = currentDay.getDate();
  var theDayOfWeek = currentDay.getDay();

  var thisWeek: any = [];
  var nextWeek = [];
  var thisWeekData: any = [];
  var nextWeekDate = [];
  var thisWeekMenuList: any = [];

  var dayoftheweek = ["일", "월", "화", "수", "목", "금", "토"];

  var pretodaymonth = theMonth + 1;
  var todaymonth = String(pretodaymonth).length === 1 ? "0" + `${pretodaymonth}` : `${pretodaymonth}`;
  var todayDay = String(theDate).length === 1 ? "0" + `${theDate}` : `${theDate}`;
  var today: any = theYear + "" + todaymonth + "" + todayDay;

  let offset = currentDay.getTimezoneOffset() * 60000;
  let dateOffset = new Date(currentDay.getTime() - offset);
  let todayDate = dateOffset.toISOString();

  if (parent.data[0]) {
    for (var i = 1 + 7 * parent.activeNum; i < 6 + 7 * parent.activeNum; i++) {
      var resultDay = new Date(theYear, theMonth, theDate + (i - theDayOfWeek));
      var yyyy = resultDay.getFullYear();
      var premm = Number(resultDay.getMonth()) + 1;
      var predd = resultDay.getDate();

      var mm = String(premm).length === 1 ? "0" + `${premm}` : `${premm}`;
      var dd = String(predd).length === 1 ? "0" + `${predd}` : `${predd}`;

      thisWeek[i - (1 + 7 * parent.activeNum)] =
        mm + "월 " + dd + "일 " + `(${dayoftheweek[i - 7 * parent.activeNum]})`;

      thisWeekData[i - (1 + 7 * parent.activeNum)] = yyyy + "-" + mm + "-" + dd + "T00:00:00";

      thisWeekMenuList[i - (1 + 7 * parent.activeNum)] = parent.data.filter((dt) => {
        return dt.date === yyyy + "-" + mm + "-" + dd + "T00:00:00";
      });
    }
  }

  useEffect(() => {
    thisWeek = [];
    thisWeekData = [];
    thisWeekMenuList = [];
  }, [parent.activeNum, parent.viewData]);

  // css
  const emptyTitle = {
    padding: "300px 650px",
  };

  return (
    <div>
      {parent.viewData ? (
        <>
          {parent.viewData ? (
            <BasicTable>
              <thead>
                <BasicTr>
                  <MainTh>구분</MainTh>
                  <MainTh>{thisWeek[0]}</MainTh>
                  <MainTh>{thisWeek[1]}</MainTh>
                  <MainTh>{thisWeek[2]}</MainTh>
                  <MainTh>{thisWeek[3]}</MainTh>
                  <MainTh>{thisWeek[4]}</MainTh>
                </BasicTr>
              </thead>
              <tbody>
                <BasicTr>
                  <KindTh>정성이 가득한 조식</KindTh>

                  {thisWeekMenuList.length > 0
                    ? thisWeekMenuList.map((dt: any, index) => {
                        return (
                          <BasicTh key={"breakfast" + index}>
                            {dt[0].breakfast.map((bmenu: string, idx: number) => {
                              return <div key={bmenu + idx}>{bmenu}</div>;
                            })}
                          </BasicTh>
                        );
                      })
                    : null}
                </BasicTr>
                <BasicTr>
                  <KindTh>빵</KindTh>
                  {thisWeekMenuList.length > 0
                    ? thisWeekMenuList.map((dt: any, index) => {
                        return (
                          <BasicTh key={"bread" + index}>
                            {dt[0].bread.map((bread: string, idx: number) => {
                              return <div key={bread + idx}>{bread}</div>;
                            })}
                          </BasicTh>
                        );
                      })
                    : null}
                </BasicTr>
                <BasicTr>
                  <KindTh>셀프존</KindTh>
                  {thisWeekMenuList.length > 0
                    ? thisWeekMenuList.map((dt: any, index) => {
                        return (
                          <BasicTh key={"selfzone" + index}>
                            {dt[0].selfzone.map((sz: string, idx: number) => {
                              return <div key={sz + idx}>{sz}</div>;
                            })}
                          </BasicTh>
                        );
                      })
                    : null}
                </BasicTr>
                <BasicTr>
                  <KindTh>건강을 생각한 중식</KindTh>
                  {thisWeekMenuList.length > 0
                    ? thisWeekMenuList.map((dt: any, index) => {
                        return (
                          <BasicTh key={"lunch" + index}>
                            {dt[0].lunch.map((lunch: string, idx: number) => {
                              return <div key={lunch + idx}>{lunch}</div>;
                            })}
                          </BasicTh>
                        );
                      })
                    : null}
                </BasicTr>

                <BasicTr>
                  <KindTh>플러스</KindTh>
                  {thisWeekMenuList.length > 0
                    ? thisWeekMenuList.map((dt: any, index) => {
                        return (
                          <BasicTh key={"plus" + index}>
                            {dt[0].plusmenu.map((pmenu: string, idx: number) => {
                              return <div key={pmenu + idx}>{pmenu}</div>;
                            })}
                          </BasicTh>
                        );
                      })
                    : null}
                </BasicTr>
                <BasicTr>
                  <KindTh>샐러드</KindTh>
                  {thisWeekMenuList.length > 0
                    ? thisWeekMenuList.map((dt: any, index) => {
                        return (
                          <BasicTh key={"salad" + index}>
                            {dt[0].salad.map((salad: string, idx: number) => {
                              return <div key={salad + idx}>{salad}</div>;
                            })}
                          </BasicTh>
                        );
                      })
                    : null}
                </BasicTr>
                <BasicTr>
                  <KindTh>추가찬</KindTh>
                  {thisWeekMenuList.length > 0
                    ? thisWeekMenuList.map((dt: any, index) => {
                        return (
                          <BasicTh key={"plussidemenu" + index}>
                            {dt[0].plussidemenu.map((psmenu: string, idx: number) => {
                              return <div key={psmenu + idx}>{psmenu}</div>;
                            })}
                          </BasicTh>
                        );
                      })
                    : null}
                </BasicTr>
                <BasicTr>
                  <KindTh>행복을 주는 석식</KindTh>
                  {thisWeekMenuList.length > 0
                    ? thisWeekMenuList.map((dt: any, index) => {
                        return (
                          <BasicTh key={"dinner" + index}>
                            {dt[0].dinner.map((dinner: string, idx: number) => {
                              return <div key={dinner + idx}>{dinner}</div>;
                            })}
                          </BasicTh>
                        );
                      })
                    : null}
                </BasicTr>
              </tbody>
            </BasicTable>
          ) : (
            ""
          )}
        </>
      ) : (
        <div style={emptyTitle}>
          <Typography variant="h6">추후 업데이트 될 예정입니다.</Typography>
        </div>
      )}
    </div>
  );
}

export default CheonanMenuTable;
