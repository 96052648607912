import CeoScheduler from "../../components/CeoSchedule/CeoScheduler";
import TitleBox from "../../components/Common/TitleBox";
function CeoScheduleMain() {
  return (
    <>
      <TitleBox title="대표이사 일정표" />
      <CeoScheduler />
    </>
  );
}

export default CeoScheduleMain;
