import React, { useEffect, useState } from "react";
import { useUserState } from "../../system/context/UserContext";
import "../../styles/Mypage/Board.css";
import { BreakfastRanking } from "../../system/types/interface";
import { BreakfastRatingApi, ErrorHandler } from "../../system/ApiService";

// syncfusion
import { enableRipple } from "@syncfusion/ej2-base";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

// material ui
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import Rating from "@mui/material/Rating";
import AlertModal from "../Common/AlertModal";

function MypageBreakfastReview() {
  const user = useUserState();
  // 버튼
  enableRipple(true);

  // 페이징 처리
  const [reviewData, setReviewData] = useState<BreakfastRanking[]>([]);
  const [page, setPage] = useState(1);
  const [dataLength, setDataLength] = useState(1);
  const [pageRange, setPageRange] = useState(10);
  const lastPage = Math.ceil(dataLength / pageRange);
  const [checkDelete, setCheckDelete] = useState(0);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  useEffect(() => {
    BreakfastRatingApi.GetMypageBreakfastRating(page, 10, user.employeeId).then(
      (res) => {
        setReviewData(res.data);
      }
    );

    BreakfastRatingApi.GetMypageAllBreakfastRating(user.employeeId).then(
      (res) => {
        setDataLength(res.data.length);
      }
    );
  }, [page, checkDelete]);

  const [checkContent, setCheckContent] = useState(0);

  function onChangeCheck(id: number) {
    if (id === checkContent) {
      setCheckContent(0);
    } else {
      setCheckContent(id);
    }
  }

  function onDelete(id: number) {
    if (window.confirm("댓글을 정말 삭제하시겠습니까?")) {
      BreakfastRatingApi.DeleteBreakfast(id)
        .then(() => {
          console.log("리뷰 삭제 완료");
          setCheckDelete(id);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  }

  return (
    <>
      <div>
        <div className="mypage_board_list">
          <div className="mypage_top">
            <div className="mypage_date">날짜</div>
            <div className="mypage_mainmenu">메인 메뉴</div>
            <div className="mypage_selectmenu">선택한 메뉴</div>
            <div className="mypage_rating">별점</div>
            <div className="mypage_review">리뷰</div>
          </div>

          {reviewData
            ? reviewData.map((board) => {
                return (
                  <div className="mypage_top_board" key={board.ratingId}>
                    <div className="mypage_date">
                      {String(board.menuDate).slice(0, 4)}년{" "}
                      {String(board.menuDate).slice(4, 6)}월{" "}
                      {String(board.menuDate).slice(6, 8)}일
                    </div>
                    <div className="mypage_mainmenu">{board.mainMenu}</div>
                    <div className="mypage_selectmenu">{board.selectMenu}</div>
                    <div className="mypage_rating">
                      {" "}
                      <Rating
                        name="starRating"
                        value={board.starRating}
                        readOnly
                        sx={{ fontSize: "22px" }}
                      />
                    </div>
                    <div
                      className="mypage_review"
                      onClick={() => {
                        onChangeCheck(board.ratingId);
                      }}
                    >
                      <span
                        style={{
                          maxWidth: "95%",
                          minWidth: "95%",
                          float: "left",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        {board.review}
                      </span>
                      <span style={{ float: "right" }}>
                        {" "}
                        {checkContent === board.ratingId ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )}
                      </span>
                    </div>

                    {checkContent === board.ratingId ? (
                      <>
                        <pre className="reviewContent">{board.review}</pre>
                        <ButtonComponent
                          iconCss="e-icons e-delete-2"
                          style={{ marginBottom: "20px", marginLeft: "88px" }}
                          onClick={() => {
                            onDelete(board.ratingId);
                          }}
                        >
                          삭제
                        </ButtonComponent>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })
            : ""}
          {dataLength == 0 ? (
            <div className="mypage_emptyBox">
              <div className="mypage_emptynotice">
                작성된 주간 아침 메뉴(본사) 리뷰가 없습니다.
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {dataLength > 0 ? (
        <div className="mypage_Pagination">
          <Stack spacing={2} alignItems="center">
            <Pagination
              count={lastPage}
              defaultPage={1}
              page={page}
              siblingCount={2}
              boundaryCount={2}
              onChange={handleChange}
            />
          </Stack>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default MypageBreakfastReview;
