import React, { useEffect, useState } from "react";
import "../../../src/styles/Components/Write/WriteBoard.css";
import { enableRipple } from "@syncfusion/ej2-base";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { Qna } from "../../system/types/interface";
import {
  QnaApi,
  ErrorHandler,
  QnaAttachmentApi,
} from "../../system/ApiService";
import { Link, useHistory } from "react-router-dom";
import { useUserState } from "../../system/context/UserContext";
import QnaCommentBoard from "./QnaComment";
import {
  CustomButtonGreen,
  CustomButtonGrey,
  CustomButtonRed,
} from "../../styles/Common/CommonComponent";
import AlertModal from "../Common/AlertModal";
import { Grid } from "@mui/material";
import { CustomText } from "../../styles/CustomText";
import HtmlContent from "../Common/HtmlContext";

interface Commentdata {
  data: Qna;
}

function QnaDetailBoard({ data }: Commentdata) {
  const user = useUserState();
  let history = useHistory();
  const id = data.qnaId;
  const employeeId = data.employeeId;
  const [detailData, setDetailData]: any = useState<Qna[]>([]);
  // 버튼
  enableRipple(true);
  useEffect(() => {
    if (id) {
      if (data.qnaSecret === true) {
        if (data.employeeId === user.employeeId) {
          QnaApi.GetQna(id)
            .then((res) => {
              setDetailData(res.data);
            })
            .catch((error) => {
              let msg = ErrorHandler(error);
              AlertModal("msg", msg);
            });
        } else if (data.qnaInCharge === user.teamName) {
          QnaApi.GetQna(id)
            .then((res) => {
              setDetailData(res.data);
            })
            .catch((error) => {
              let msg = ErrorHandler(error);
              AlertModal("msg", msg);
            });
        } else {
          AlertModal("check", "작성자와 담당자만 확인할 수 있는 페이지입니다.");
          history.goBack();
        }
      } else {
        QnaApi.GetQna(id)
          .then((res) => {
            setDetailData(res.data);
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            AlertModal("msg", msg);
          });
      }
    }
  }, [data]);

  const [uploadData, setUploadData] = useState([]);
  // 첨부 파일 정보
  useEffect(() => {
    if (data.fileuploadFolder) {
      QnaAttachmentApi.GetQnaAttachmentList(data.fileuploadFolder)
        .then((res) => {
          setUploadData(res.data);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  }, [data]);

  function downloadfile(item: any) {
    window.location.assign(
      `https://inbodyinfra.blob.core.windows.net/inbodyportal/Qna/${item.fileuploadFolder}/${item.qnaAttachmentFileName}`
    );
  }

  //// 글 삭제
  const QnaDelete = () => {
    if (window.confirm("질의응답을 삭제하시겠습니까?")) {
      QnaApi.DeleteQna(data.qnaId)
        .then(() => {
          AlertModal("success", "질의응답이 삭제되었습니다.");
          history.push("/qna/board/1");
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  };

  return (
    <>
      {detailData ? (
        <div className="board">
          <div className="board_write">
            <div className="title">
              <Grid container alignItems="center" mb="10px">
                <Grid item mr="45px">
                  <CustomText type="title">제목</CustomText>
                </Grid>
                <Grid>
                  <CustomText type="subtitle2">
                    {detailData.qnaTitle}
                  </CustomText>
                </Grid>
              </Grid>
            </div>

            <div className="notice_info">
              <Grid container alignItems="center" mb="10px">
                <Grid container item xs={12} sm={6} alignItems="center">
                  <Grid item mr="25px">
                    <CustomText type="title">작성자</CustomText>
                  </Grid>
                  <Grid item>
                    <CustomText type="subtitle2">
                      {detailData.qnaWriter}
                    </CustomText>
                  </Grid>
                </Grid>

                <Grid container item xs={12} sm={6} alignItems="center">
                  <Grid item mr="25px">
                    <CustomText type="title">담당부서</CustomText>
                  </Grid>
                  <Grid item>
                    <CustomText type="subtitle2">
                      {detailData.qnaInCharge}
                    </CustomText>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div className="notice_info">
              {detailData.qnaCreatedAt === detailData.qnaModifiedAt ? (
                <Grid container alignItems="center" mb="10px">
                  <Grid item mr="25px">
                    <CustomText type="title">작성일</CustomText>
                  </Grid>
                  <Grid>
                    <CustomText type="subtitle2">
                      {detailData.qnaCreatedAt}
                    </CustomText>
                  </Grid>
                </Grid>
              ) : (
                <Grid container alignItems="center" mb="10px">
                  <Grid item mr="25px">
                    <CustomText type="title">수정일</CustomText>
                  </Grid>
                  <Grid>
                    <CustomText type="subtitle2">
                      {detailData.qnaModifiedAt}
                    </CustomText>
                  </Grid>
                </Grid>
              )}
            </div>
            <div className="cont">
              <Grid container alignItems="center" mb="15px">
                <Grid item xs={12}>
                  <CustomText type="title">내용</CustomText>
                </Grid>
                <Grid item xs={12} mt="15px">
                  <HtmlContent
                    data={
                      detailData.qnaContent
                        ? detailData.qnaContent
                        : "작성된 내용이 없습니다."
                    }
                  ></HtmlContent>
                </Grid>
              </Grid>
            </div>
            <div className="info">
              <div className="fileuploadbox">첨부파일</div>
              <div className="filebox">
                {uploadData.map((item: any) => (
                  <li key={`${item.qnaAttachmentFileName}`}>
                    {item.qnaAttachmentFileName}
                    <ButtonComponent
                      iconCss="e-icons e-download"
                      className="fileuploadButton"
                      onClick={() => downloadfile(item)}
                    >
                      다운로드
                    </ButtonComponent>
                  </li>
                ))}
              </div>
            </div>
          </div>

          {user.employeeId === data.employeeId ? (
            <div className="bt_wrap">
              <CustomButtonGreen
                style={{ marginRight: "10px" }}
                onClick={() => {
                  history.push({ pathname: `/qna/edit/${id}`, state: id });
                }}
              >
                수정
              </CustomButtonGreen>
              <CustomButtonRed
                iconCss="e-icons e-delete-2"
                style={{ marginRight: "10px" }}
                onClick={QnaDelete}
              >
                삭제
              </CustomButtonRed>
              <CustomButtonGrey
                onClick={() => {
                  history.push({ pathname: "/qna/board/1" });
                }}
              >
                목록
              </CustomButtonGrey>
            </div>
          ) : (
            <div className="bt_wrap">
              <Link
                to="/qna/board/1"
                style={{ marginLeft: "10px", paddingTop: "5px" }}
              >
                <CustomButtonGrey>목록</CustomButtonGrey>
              </Link>
            </div>
          )}

          <div>
            <QnaCommentBoard
              id={id}
              employeeId={employeeId}
              Qnadata={detailData}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default QnaDetailBoard;
