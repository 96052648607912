import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useUserState } from "../../system/context/UserContext";

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function Yeonsuwon() {
  const user = useUserState();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  // css
  const regionBlock = {
    margin: "10px 10px",
    padding: "10px 10px",
  };

  return (
    <>
      <div>
        <Tabs value={value} onChange={handleChange} textColor="primary">
          <Tab label="신청 현황" {...a11yProps(0)}></Tab>
          <Tab label="사용 완료" {...a11yProps(1)}></Tab>
        </Tabs>
        {value === 0 ? <div style={regionBlock}></div> : ""}
        {value === 1 ? <div style={regionBlock}> </div> : ""}
      </div>
    </>
  );
}

export default Yeonsuwon;
