import { grey } from "@mui/material/colors";
import {
  AirplaneInFlight,
  AmazonLogo,
  AppleLogo,
  Bus,
  Car,
  CloudArrowUp,
  Clipboard,
  CodepenLogo,
  CodesandboxLogo,
  Desktop,
  DiscordLogo,
  DribbbleLogo,
  FacebookLogo,
  FigmaLogo,
  ForkKnife,
  GithubLogo,
  GitlabLogo,
  GlobeSimple,
  GoogleChromeLogo,
  GoogleDriveLogo,
  GoogleLogo,
  GooglePhotosLogo,
  InstagramLogo,
  LinkedinLogo,
  LinuxLogo,
  MagnifyingGlass,
  MessengerLogo,
  MetaLogo,
  NotionLogo,
  PaypalLogo,
  PersonSimpleRun,
  PinterestLogo,
  Receipt,
  RedditLogo,
  TiktokLogo,
  Train,
  TwitterLogo,
  YoutubeLogo,
  PencilLine,
  Chats,
  EnvelopeSimple,
  PhoneCall,
  AddressBook,
  DeviceMobile,
  Timer,
  IdentificationCard,
  CalendarBlank,
  CalendarCheck,
  Calendar,
  PlusSquare,
  CheckSquare,
  Book,
  Playlist,
  Bookmark,
  ListBullets,
  ListChecks,
  House,
  Factory,
  Storefront,
  Park,
  SuitcaseRolling,
  Airplane,
  CreditCard,
  MusicNote,
  Gift,
  Coffee,
  FilmStrip,
  FirstAid,
  ShoppingBagOpen,
  ShoppingCart,
  Package,
  Heart,
  Star,
  SunDim,
  Moon,
  Smiley,
  Shapes,
  Bank,
  Barbell,
  CoatHanger,
  FinnTheHuman,
  AndroidLogo,
  Camera,
  BowlFood,
  PawPrint,
} from "@phosphor-icons/react";

function IconList({ item }: any) {
  return (
    <>
      {item.siteIcon === "ContentPasteIcon" ? <Clipboard size={20} /> : ""}
      {item.siteIcon === "CreateIcon" ? <PencilLine size={20} /> : ""}
      {item.siteIcon === "ForumIcon" ? <Chats size={20} /> : ""}
      {item.siteIcon === "EmailIcon" ? <EnvelopeSimple size={20} /> : ""}
      {item.siteIcon === "LocalPhoneIcon" ? <PhoneCall size={20} /> : ""}
      {item.siteIcon === "addressbook" ? <AddressBook size={20} /> : ""}
      {item.siteIcon === "ReceiptLongIcon" ? <Receipt size={20} /> : ""}
      {item.siteIcon === "TabletAndroidIcon" ? <DeviceMobile size={20} /> : ""}
      {item.siteIcon === "TimerIcon" ? <Timer size={20} /> : ""}
      {item.siteIcon === "BadgeIcon" ? <IdentificationCard size={20} /> : ""}

      {item.siteIcon === "LibraryBooksIcon" ? <Book size={20} /> : ""}
      {item.siteIcon === "camera" ? <Camera size={20} /> : ""}
      {item.siteIcon === "EventIcon" ? <CalendarBlank size={20} /> : ""}
      {item.siteIcon === "EventAvailableIcon" ? <CalendarCheck size={20} /> : ""}
      {item.siteIcon === "EventNoteIcon" ? <Calendar size={20} /> : ""}
      {item.siteIcon === "LibraryAddIcon" ? <PlusSquare size={20} /> : ""}
      {item.siteIcon === "LibraryAddCheckIcon" ? <CheckSquare size={20} /> : ""}
      {item.siteIcon === "playlist" ? <Playlist size={20} /> : ""}
      {item.siteIcon === "bookmark" ? <Bookmark size={20} /> : ""}
      {item.siteIcon === "ListAltIcon" ? <ListBullets size={20} /> : ""}
      {item.siteIcon === "RecentActorsIcon" ? <ListChecks size={20} /> : ""}

      {item.siteIcon === "HomeIcon" ? <House size={20} /> : ""}
      {item.siteIcon === "factory" ? <Factory size={20} /> : ""}
      {item.siteIcon === "store" ? <Storefront size={20} /> : ""}
      {item.siteIcon === "park" ? <Park size={20} /> : ""}
      {item.siteIcon === "suitcase" ? <SuitcaseRolling size={20} /> : ""}
      {item.siteIcon === "DirectionsRunIcon" ? <PersonSimpleRun size={20} /> : ""}
      {item.siteIcon === "DirectionsBusIcon" ? <Bus size={20} /> : ""}
      {item.siteIcon === "DirectionsRailwayIcon" ? <Train size={20} /> : ""}
      {item.siteIcon === "DirectionsCarIcon" ? <Car size={20} /> : ""}
      {item.siteIcon === "FlightIcon" ? <Airplane size={20} /> : ""}
      {item.siteIcon === "CreditCardIcon" ? <CreditCard size={20} /> : ""}
      {item.siteIcon === "CardGiftcardIcon" ? <Gift size={20} /> : ""}
      {item.siteIcon === "food" ? <BowlFood size={20} /> : ""}
      {item.siteIcon === "DiningIcon" ? <ForkKnife size={20} /> : ""}
      {item.siteIcon === "coffee" ? <Coffee size={20} /> : ""}
      {item.siteIcon === "AudiotrackIcon" ? <MusicNote size={20} /> : ""}
      {item.siteIcon === "LocalMoviesIcon" ? <FilmStrip size={20} /> : ""}
      {item.siteIcon === "LocalHospitalIcon" ? <FirstAid size={20} /> : ""}
      {item.siteIcon === "LocalMallIcon" ? <ShoppingBagOpen size={20} /> : ""}
      {item.siteIcon === "LocalGroceryStoreIcon" ? <ShoppingCart size={20} /> : ""}
      {item.siteIcon === "package" ? <Package size={20} /> : ""}

      {item.siteIcon === "FavoriteIcon" ? <Heart size={20} /> : ""}
      {item.siteIcon === "star" ? <Star size={20} /> : ""}
      {item.siteIcon === "gym" ? <Barbell size={20} /> : ""}
      {item.siteIcon === "clothes" ? <CoatHanger size={20} /> : ""}
      {item.siteIcon === "InterestsIcon" ? <Shapes size={20} /> : ""}
      {item.siteIcon === "SavingsIcon" ? <Bank size={20} /> : ""}
      {item.siteIcon === "FinnTheHuman" ? <FinnTheHuman size={20} /> : ""}
      {item.siteIcon === "SentimentVerySatisfiedIcon" ? <Smiley size={20} /> : ""}
      {item.siteIcon === "LightModeIcon" ? <SunDim size={20} /> : ""}
      {item.siteIcon === "DarkModeIcon" ? <Moon size={20} /> : ""}

      {item.siteIcon === "YouTubeIcon" ? <YoutubeLogo size={20} /> : ""}
      {item.siteIcon === "InstagramIcon" ? <InstagramLogo size={20} /> : ""}
      {item.siteIcon === "messenger" ? <MessengerLogo size={20} /> : ""}
      {item.siteIcon === "TwitterIcon" ? <TwitterLogo size={20} /> : ""}
      {item.siteIcon === "FacebookIcon" ? <FacebookLogo size={20} /> : ""}
      {item.siteIcon === "GoogleIcon" ? <GoogleLogo size={20} /> : ""}
      {item.siteIcon === "LanguageIcon" ? <GlobeSimple size={20} /> : ""}
      {item.siteIcon === "CloudUploadIcon" ? <CloudArrowUp size={20} /> : ""}
      {item.siteIcon === "ComputerIcon" ? <Desktop size={20} /> : ""}
      {item.siteIcon === "SearchIcon" ? <MagnifyingGlass size={20} /> : ""}

      {item.siteIcon === "googledrive" ? <GoogleDriveLogo size={20} /> : ""}
      {item.siteIcon === "googlechrome" ? <GoogleChromeLogo size={20} /> : ""}
      {item.siteIcon === "googlephotos" ? <GooglePhotosLogo size={20} /> : ""}
      {item.siteIcon === "android" ? <AndroidLogo size={20} /> : ""}
      {item.siteIcon === "apple" ? <AppleLogo size={20} /> : ""}
      {item.siteIcon === "meta" ? <MetaLogo size={20} /> : ""}
      {item.siteIcon === "amazon" ? <AmazonLogo size={20} /> : ""}
      {item.siteIcon === "notion" ? <NotionLogo size={20} /> : ""}
      {item.siteIcon === "paypal" ? <PaypalLogo size={20} /> : ""}
      {item.siteIcon === "reddit" ? <RedditLogo size={20} /> : ""}
      {item.siteIcon === "tiktok" ? <TiktokLogo size={20} /> : ""}

      {item.siteIcon === "github" ? <GithubLogo size={20} /> : ""}
      {item.siteIcon === "gitlab" ? <GitlabLogo size={20} /> : ""}
      {item.siteIcon === "linkedin" ? <LinkedinLogo size={20} /> : ""}
      {item.siteIcon === "figma" ? <FigmaLogo size={20} /> : ""}
      {item.siteIcon === "pinterest" ? <PinterestLogo size={20} /> : ""}
      {item.siteIcon === "dribble" ? <DribbbleLogo size={20} /> : ""}
      {item.siteIcon === "codesandbox" ? <CodesandboxLogo size={20} /> : ""}
      {item.siteIcon === "linux" ? <LinuxLogo size={20} /> : ""}
      {item.siteIcon === "codepen" ? <CodepenLogo size={20} /> : ""}
      {item.siteIcon === "discord" ? <DiscordLogo size={20} /> : ""}
      {item.siteIcon === "pet" ? <PawPrint size={20} /> : ""}
    </>
  );
}

export default IconList;
