import React, { useCallback, useEffect, useState } from "react";
import TitleBox from "../../components/Common/TitleBox";
import { Notice } from "../../system/types/interface";
import { NoticeApi, ErrorHandler } from "../../system/ApiService";
import NoticeDetailBoard from "../../components/Notice/NoticeDetailBoard";
import { useHistory } from "react-router-dom";
import AlertModal from "../../components/Common/AlertModal";

interface NoticeProps {
  location: any;
}

function NoticeDetail({ location }: NoticeProps) {
  const [id, setId] = useState(0);
  const [data, setData]: any = useState<Notice[]>([]);
  let history = useHistory();

  useEffect(() => {
    if (location.state.displays) {
      setId(Number(location.state.displays));
      const id = Number(location.state.displays);
      NoticeApi.GetNotice(id)
        .then((res) => {
          setData(res.data);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    } else {
      history.goBack();
    }
  }, [id]);

  // 데이터 받을 때마다 조회수 증가
  useEffect(() => {
    const newData: Notice = {
      noticeTitle: data.noticeTitle,
      noticeContent: data.noticeContent,
      noticeModifiedAt: data.noticeModifiedAt,
      noticeWriter: data.noticeWriter,
      noticeId: data.noticeId,
      noticeMain: data.noticeMain,
      noticeCreatedAt: data.noticeCreatedAt,
      noticeViews: data.noticeViews + 1,
      noticeSendMail: data.noticeSendMail,
      teamName: data.teamName,
      type: data.type,
      fileuploadFolder: data.fileuploadFolder,
      employeeId: data.employeeId,
    };
    if (data.noticeTitle) {
      NoticeApi.UpdateNotice(newData)
        .then(() => {})
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  }, [data]);

  return (
    <div>
      <TitleBox title="전사 공지" />
      <NoticeDetailBoard data={data} />
    </div>
  );
}

export default NoticeDetail;
