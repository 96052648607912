import React, { useEffect, useState } from "react";
import QnaEditBoard from "../../components/Qna/QnaEditBoard";
import TitleBox from "../../components/Common/TitleBox";
import { enableRipple } from "@syncfusion/ej2-base";
import {
  DropDownButtonComponent,
  ItemModel,
} from "@syncfusion/ej2-react-splitbuttons";

function QnaEdit() {
  return (
    <div>
      <TitleBox title="질의응답" />
      <QnaEditBoard />
    </div>
  );
}

export default QnaEdit;
