import React, { useEffect, useState } from "react";
import "../../../src/styles/Components/Notice/NoticeBoard.css";
import { enableRipple } from "@syncfusion/ej2-base";
import { Notice } from "../../system/types/interface";
import { NoticeApi, ErrorHandler } from "../../system/ApiService";
import { Link, useHistory } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  CustomButtonBlack,
  CustomButtonWhite,
} from "../../styles/Common/CommonComponent";
import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  PaginationMargin,
  StyledBody,
  StyledBody2,
  StyledBodyTitle,
  StyledEmptyBody,
  StyledHead,
  StyledHead2,
  StyledHeadTitle,
} from "../../styles/theme";
import NoticeComponent from "../Common/NoticeComponent";
import AlertModal from "../Common/AlertModal";

function NoticeBoard() {
  const [data, setData] = useState<Notice[]>([]); // NoticeMain = false 인 data 값
  let history = useHistory();
  // 버튼
  enableRipple(true);

  // 페이징 처리
  const [totalData, setTotalData] = useState<Notice[]>([]);
  const [page, setPage] = useState(1);
  const dataLength = Number(totalData.length);
  const [pageRange, setPageRange] = useState(10);
  const lastPage = Math.ceil(dataLength / pageRange);

  // 게시판 정보 불러오기

  useEffect(() => {
    NoticeApi.GetNoticeTotalList()
      .then((res) => {
        setTotalData(res.data);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });

    NoticeApi.GetNoticeList(page, 10)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
  }, [page]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // 검색창
  const [search, setSearch] = useState("");
  const [searchOpen, setSearchOpen] = useState(false);

  function startSearch() {
    setSearchOpen(true);
  }

  function stopSearch() {
    setSearchOpen(false);
    setNoticeType("");
  }

  const onChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setSearch(e.target.value);
  };
  const [type, setType] = React.useState("");
  const [NoticeType, setNoticeType] = React.useState("");
  const filterTotalData = totalData.filter((p) => {
    if (type === "searchType") {
      return p.type === NoticeType;
    } else if (type === "searchContent") {
      return p.noticeContent
        .replace(" ", "")
        .toLocaleLowerCase()
        .includes(search.toLocaleLowerCase().replace(" ", ""));
    } else if (type === "searchWriter") {
      return p.noticeWriter
        .replace(" ", "")
        .toLocaleLowerCase()
        .includes(search.toLocaleLowerCase().replace(" ", ""));
    } else {
      return p.noticeTitle
        .replace(" ", "")
        .toLocaleLowerCase()
        .includes(search.toLocaleLowerCase().replace(" ", ""));
    }
  });
  const handleChangeType = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  const handleChangeNoticeType = (event: SelectChangeEvent) => {
    setNoticeType(event.target.value as string);
    setSearchOpen(true);
  };

  return (
    <div className="noticeBoard">
      <div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
            }}
          >
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">검색</InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  label="type"
                  onChange={handleChangeType}
                >
                  <MenuItem value={"searchType"}>유형</MenuItem>
                  <MenuItem value={"searchTitle"}>제목</MenuItem>
                  <MenuItem value={"searchContent"}>내용</MenuItem>
                  <MenuItem value={"searchWriter"}>글쓴이</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {type === "searchType" ? (
              <Box sx={{ mx: "10px", width: 310 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    유형
                    {searchOpen ? (
                      <IconButton
                        type="button"
                        aria-label="search"
                        onClick={stopSearch}
                      >
                        <CloseIcon />
                      </IconButton>
                    ) : null}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={NoticeType}
                    label="NoticeType"
                    onChange={handleChangeNoticeType}
                  >
                    <MenuItem value={"공지"}>공지</MenuItem>
                    <MenuItem value={"안내"}>안내</MenuItem>
                    <MenuItem value={"요청"}>요청</MenuItem>
                    <MenuItem value={"인사"}>인사</MenuItem>
                    <MenuItem value={"주의"}>주의</MenuItem>
                    <MenuItem value={"교육"}>교육</MenuItem>
                    <MenuItem value={"경조사"}>경조사</MenuItem>
                    <MenuItem value={"우편&등기"}>우편&등기</MenuItem>
                    <MenuItem value={"기타"}>기타</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            ) : (
              <Paper
                component="form"
                sx={{
                  mx: "10px",
                  display: "flex",
                  alignItems: "center",
                  width: 310,
                  height: 40,
                }}
              >
                <input style={{ display: "none" }} />
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="검색하실 단어를 입력해주세요."
                  value={search}
                  onChange={onChange}
                />
                {searchOpen ? (
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={stopSearch}
                  >
                    <CloseIcon />
                  </IconButton>
                ) : null}
              </Paper>
            )}
            <CustomButtonBlack onClick={startSearch}>검색</CustomButtonBlack>
          </div>

          {searchOpen ? (
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledHead align="center">번호</StyledHead>
                      <StyledHead2 align="center"></StyledHead2>
                      <StyledHeadTitle align="center">제목</StyledHeadTitle>
                      <StyledHead2 align="center">글쓴이</StyledHead2>
                      <StyledHead2 align="center">작성일</StyledHead2>
                      <StyledHead align="center">조회수</StyledHead>
                    </TableRow>
                  </TableHead>

                  {filterTotalData
                    ? filterTotalData.map((board: any) => {
                        return (
                          <TableBody key={board.noticeId} id={board.noticeId}>
                            <TableRow>
                              {board.noticeMain ? (
                                <StyledBody align="center">
                                  주요 공지
                                </StyledBody>
                              ) : (
                                <StyledBody align="center">
                                  {board.noticeId}
                                </StyledBody>
                              )}

                              <StyledBody2 align="center">
                                <NoticeComponent type={board.type} />
                              </StyledBody2>
                              <StyledBodyTitle
                                onClick={() => {
                                  history.push({
                                    pathname: `/notice/detail/${board.noticeId}`,
                                    state: { displays: board.noticeId },
                                  });
                                }}
                              >
                                {board.noticeTitle}
                              </StyledBodyTitle>
                              <StyledBody2 align="center">
                                {board.noticeWriter}
                              </StyledBody2>
                              <StyledBody2 align="center">
                                {board.noticeCreatedAt}
                              </StyledBody2>
                              <StyledBody align="center">
                                {board.noticeViews}
                              </StyledBody>
                            </TableRow>
                          </TableBody>
                        );
                      })
                    : null}
                </Table>
              </TableContainer>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledHead align="center">번호</StyledHead>
                      <StyledHead2 align="center"></StyledHead2>
                      <StyledHeadTitle align="center">제목</StyledHeadTitle>
                      <StyledHead2 align="center">글쓴이</StyledHead2>
                      <StyledHead2 align="center">작성일</StyledHead2>
                      <StyledHead align="center">조회수</StyledHead>
                    </TableRow>
                  </TableHead>

                  {totalData
                    ? totalData.map((board: any) => {
                        return (
                          <TableBody key={board.noticeId} id={board.noticeId}>
                            {board.noticeMain === true ? (
                              <TableRow>
                                <StyledBody align="center">
                                  주요 공지
                                </StyledBody>
                                <StyledBody2 align="center">
                                  <NoticeComponent type={board.type} />
                                </StyledBody2>
                                <StyledBodyTitle
                                  onClick={() => {
                                    history.push({
                                      pathname: `/notice/detail/${board.noticeId}`,
                                      state: { displays: board.noticeId },
                                    });
                                  }}
                                >
                                  {board.noticeTitle}
                                </StyledBodyTitle>
                                <StyledBody2 align="center">
                                  {board.noticeWriter}
                                </StyledBody2>
                                <StyledBody2 align="center">
                                  {board.noticeCreatedAt}
                                </StyledBody2>
                                <StyledBody align="center">
                                  {board.noticeViews}
                                </StyledBody>
                              </TableRow>
                            ) : null}
                          </TableBody>
                        );
                      })
                    : ""}
                  {data
                    ? data.map((board: any) => {
                        return (
                          <TableBody key={board.noticeId} id={board.noticeId}>
                            <TableRow>
                              <StyledBody align="center">
                                {board.noticeId}
                              </StyledBody>
                              <StyledBody2 align="center">
                                <NoticeComponent type={board.type} />
                              </StyledBody2>
                              <StyledBodyTitle
                                onClick={() => {
                                  history.push({
                                    pathname: `/notice/detail/${board.noticeId}`,
                                    state: { displays: board.noticeId },
                                  });
                                }}
                              >
                                {board.noticeTitle}
                              </StyledBodyTitle>
                              <StyledBody2 align="center">
                                {board.noticeWriter}
                              </StyledBody2>
                              <StyledBody2 align="center">
                                {board.noticeCreatedAt}
                              </StyledBody2>
                              <StyledBody align="center">
                                {board.noticeViews}
                              </StyledBody>
                            </TableRow>
                          </TableBody>
                        );
                      })
                    : null}
                  {dataLength === 0 ? (
                    <TableBody>
                      <TableRow>
                        <StyledEmptyBody colSpan={6}>
                          작성된 공지사항이 없습니다.
                        </StyledEmptyBody>
                      </TableRow>
                    </TableBody>
                  ) : null}
                </Table>
              </TableContainer>
            </Grid>
          )}
        </div>
        <div style={{ textAlign: "right" }}>
          <Link to="/notice/edit">
            <CustomButtonWhite style={{ marginTop: "10px" }}>
              글쓰기
            </CustomButtonWhite>
          </Link>
        </div>
        {searchOpen ? (
          ""
        ) : (
          <PaginationMargin>
            {dataLength === 0 ? (
              ""
            ) : (
              <Stack spacing={2} alignItems="center">
                <Pagination
                  count={lastPage}
                  defaultPage={1}
                  page={page}
                  siblingCount={2}
                  boundaryCount={2}
                  onChange={handleChange}
                />
              </Stack>
            )}
          </PaginationMargin>
        )}
      </div>
    </div>
  );
}

export default NoticeBoard;
