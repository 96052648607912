import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import "../../../src/styles/Components/Qna/QnaComment.css";
import { useUserState } from "../../system/context/UserContext";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { QnaComment, Qna } from "../../system/types/interface";
import { QnaCommentApi, ErrorHandler, QnaApi } from "../../system/ApiService";
import {
  CustomButtonBlack,
  CustomButtonRed,
  CustomButtonWhite,
} from "../../styles/Common/CommonComponent";
import AlertModal from "../Common/AlertModal";

interface CommentProps {
  id: number;
  employeeId: string;
  Qnadata: Qna;
}

function QnaCommentBoard({ id, employeeId, Qnadata }: CommentProps) {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    return () => setLoading(false);
  }, []);
  const user = useUserState();
  const [comment, setComment] = useState("");
  const [qnaCommentWriter, setQnaCommentWriter] = useState(
    user.teamName + " " + user.name
  );
  const [data, setData] = useState<QnaComment[]>([]);
  var numberofdata = data.length;
  // 날짜
  const today = new Date();
  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth() + 1;
  const todayDate = today.getDate();

  const qnaCommentCreatedAt =
    todayYear + "년 " + todayMonth + "월 " + todayDate + "일";
  const qnaCommentModifiedAt =
    todayYear + "년 " + todayMonth + "월 " + todayDate + "일";

  function qnaCommentWrite(e: {
    target: { value: React.SetStateAction<string> };
  }) {
    setComment(e.target.value);
  }

  // 답변 작성
  const commentSubmit = (args: any) => {
    var pluscomment = numberofdata + 1;
    if (comment.length === 0) {
      AlertModal("check", "답변을 작성해주세요");
      return;
    }

    QnaCommentApi.InsertQnaComment({
      ...args.data,
      QnaId: id,
      QnaCommentContent: comment,
      QnaCommentCreatedAt: qnaCommentCreatedAt,
      QnaCommentModifiedAt: qnaCommentModifiedAt,
      QnaCommentWriter: qnaCommentWriter,
      EmployeeId: user.employeeId,
      NumberOfComments: pluscomment,
    })
      .then(() => {
        refreshComment();
        setComment("");
        AlertModal("success", "답변이 작성되었습니다.");
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
    const newData: Qna = {
      qnaTitle: Qnadata.qnaTitle,
      qnaContent: Qnadata.qnaContent,
      qnaModifiedAt: Qnadata.qnaModifiedAt,
      qnaWriter: Qnadata.qnaWriter,
      qnaId: Qnadata.qnaId,
      qnaCreatedAt: Qnadata.qnaCreatedAt,
      qnaViews: Qnadata.qnaViews,
      qnaSecret: Qnadata.qnaSecret,
      qnaInCharge: Qnadata.qnaInCharge,
      qnaPassword: Qnadata.qnaPassword,
      teamName: Qnadata.teamName,
      employeeId: Qnadata.employeeId,
      fileuploadFolder: Qnadata.fileuploadFolder,
      numberOfComments: pluscomment,
    };
    QnaApi.UpdateQna(newData)
      .then(() => {})
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
  };

  function refreshComment() {
    if (id) {
      QnaCommentApi.GetQnaCommentList(id)
        .then((res) => {
          setData(res.data);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  }

  useEffect(() => {
    if (id) {
      QnaCommentApi.GetQnaCommentList(id)
        .then((res) => {
          setData(res.data);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  }, [id]);

  // 답변 삭제
  const CommentDelete = (board: { qnaCommentId: number }) => {
    if (window.confirm("답변을 삭제하시겠습니까?")) {
      var minuscomment = numberofdata - 1;
      QnaCommentApi.DeleteQnaComment(board.qnaCommentId)
        .then(() => {
          refreshComment();
          AlertModal("success", "답변이 삭제되었습니다.");
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });

      const newData: Qna = {
        qnaTitle: Qnadata.qnaTitle,
        qnaContent: Qnadata.qnaContent,
        qnaModifiedAt: Qnadata.qnaModifiedAt,
        qnaWriter: Qnadata.qnaWriter,
        qnaId: Qnadata.qnaId,
        qnaCreatedAt: Qnadata.qnaCreatedAt,
        qnaViews: Qnadata.qnaViews,
        qnaSecret: Qnadata.qnaSecret,
        qnaInCharge: Qnadata.qnaInCharge,
        qnaPassword: Qnadata.qnaPassword,
        teamName: Qnadata.teamName,
        employeeId: Qnadata.employeeId,
        fileuploadFolder: Qnadata.fileuploadFolder,
        numberOfComments: minuscomment,
      };
      QnaApi.UpdateQna(newData)
        .then(() => {})
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  };

  return (
    <>
      <Typography variant="h6" component="h6" style={{ marginTop: "20px" }}>
        답변 {data.length}개
      </Typography>
      <div className="commentBoard_wrap">
        <div className="commentWrite">
          <dl>
            <dd>
              {user.teamName} {user.name}
            </dd>
            <dt>
              <CustomButtonBlack onClick={commentSubmit}>
                등록
              </CustomButtonBlack>
            </dt>
            <div>
              <textarea
                value={comment}
                placeholder="답변을 작성해주세요"
                onChange={qnaCommentWrite}
              />
            </div>
          </dl>
        </div>
        <div className="comments">
          {data
            ? data.map((board: any) => {
                return (
                  <div key={board.qnaCommentId}>
                    <div className="commentBoard">
                      <div>
                        <div className="commentWriter">
                          {board.qnaCommentWriter}{" "}
                        </div>
                        <div className="commentcontent">
                          {board.qnaCommentContent}
                        </div>
                      </div>
                      <div className="comment_button">
                        <dl>
                          <dd>
                            <div className="commentWriteDay">
                              {board.qnaCommentModifiedAt}
                            </div>
                          </dd>
                          {user.employeeId === board.employeeId ? (
                            <dt>
                              <CustomButtonRed
                                onClick={() => {
                                  CommentDelete(board);
                                }}
                              >
                                삭제
                              </CustomButtonRed>
                            </dt>
                          ) : (
                            ""
                          )}
                        </dl>
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </>
  );
}

export default QnaCommentBoard;
