import { useHistory } from "react-router-dom";
import {
  BoxTitle,
  MenuPortletDay,
  MenuPortletMainMenu,
  MenuPortletMenuBox,
  MenuPortletMenuInfo,
  MenuPortletSubMenu,
  MenuPortletThisWeekend,
  NoticeBoxLayout,
  NoticeBoxTitle,
  NoticeSelectTitle,
  NoticeViewAll,
  PortletMainTitle,
} from "../../../styles/Components/Main/MainPortal";
import { useEffect, useState } from "react";
import { MenuList } from "../../../system/types/interface";
import { InitMenuList } from "../../../system/types/initObject";
import { ErrorHandler, MenuApi } from "../../../system/ApiService";
import AlertModal from "../../Common/AlertModal";

function LongBreakfastPortlet() {
  const history = useHistory();
  const [data, setData] = useState<MenuList>(InitMenuList);
  const [todayData, setTodayData] = useState("");
  // 데이터
  var currentDay = new Date();
  var theYear = currentDay.getFullYear();
  var theMonth = currentDay.getMonth();
  var theDate = currentDay.getDate();
  var theDayOfWeek = currentDay.getDay();
  var thisWeek: any = [];
  var weekDate: any = [];
  var dayoftheweek = ["일", "월", "화", "수", "목", "금", "토"];
  for (var i = 1; i < 6; i++) {
    var resultDay = new Date(theYear, theMonth, theDate + (i - theDayOfWeek));
    var yyyy = resultDay.getFullYear();
    var premm = Number(resultDay.getMonth()) + 1;
    var predd = resultDay.getDate();

    var mm = String(premm).length === 1 ? "0" + `${premm}` : `${premm}`;
    var dd = String(predd).length === 1 ? "0" + `${predd}` : `${predd}`;

    thisWeek[i - 1] = mm + "월 " + dd + "일 " + `(${dayoftheweek[i]})`;
    weekDate[i - 1] = yyyy + mm + dd;
  }
  var menuDate = Number(weekDate[0]);
  const mainmenu = [
    data.mainMon,
    data.mainTue,
    data.mainWed,
    data.mainThu,
    data.mainFri,
  ];
  const detailmenu = [
    data.detailMon,
    data.detailTue,
    data.detailWed,
    data.detailThu,
    data.detailFri,
  ];
  useEffect(() => {
    if (menuDate) {
      MenuApi.GetMenuList(menuDate)
        .then((res: any) => {
          setData(res.data);
          if (res.data.menuId !== 0) {
            if (theDayOfWeek === 1) {
              setTodayData(res.data.detailMon);
            } else if (theDayOfWeek === 2) {
              setTodayData(res.data.detailTue);
            } else if (theDayOfWeek === 3) {
              setTodayData(res.data.detailWed);
            } else if (theDayOfWeek === 4) {
              setTodayData(res.data.detailThu);
            } else if (theDayOfWeek === 5) {
              setTodayData(res.data.detailFri);
            }
          }
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  }, []);

  function moveToView() {
    history.push("/breakfastmenu");
  }
  return (
    <>
      <NoticeBoxLayout>
        <NoticeBoxTitle variant="h6">주간 아침 메뉴 (본사)</NoticeBoxTitle>
        <NoticeViewAll onClick={moveToView}>전체보기</NoticeViewAll>
      </NoticeBoxLayout>
      <MenuPortletThisWeekend>
        {thisWeek.map((day: any, index: number) => {
          return (
            <MenuPortletMenuInfo key={"thisWeek" + index}>
              <MenuPortletDay>{day}</MenuPortletDay>
              <MenuPortletMenuBox>
                <MenuPortletMainMenu>
                  {mainmenu[index] ? mainmenu[index] : "업데이트 예정"}
                </MenuPortletMainMenu>
                {detailmenu[index] ? (
                  detailmenu[index].split(",").map((menuinfo: any) => {
                    return (
                      <MenuPortletSubMenu key={menuinfo}>
                        {menuinfo}
                      </MenuPortletSubMenu>
                    );
                  })
                ) : (
                  <div key={"thisWeekmenuempty" + index}></div>
                )}
              </MenuPortletMenuBox>
            </MenuPortletMenuInfo>
          );
        })}
      </MenuPortletThisWeekend>
    </>
  );
}

export default LongBreakfastPortlet;
