import React, { useEffect, useState } from "react";
import "../../../styles/pages/Layout/AddSiteList.css";
import { AddSite } from "../../../system/types/interface";
import { SiteApi, ErrorHandler } from "../../../system/ApiService";
import { useUserState } from "../../../system/context/UserContext";
import TitleBox from "../../../components/Common/TitleBox";
import IconList from "./IconList";
import IconRadio from "./IconRadio";

// syncfusion
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Modal,
  styled,
  Tab,
  Tabs,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import { Star, X } from "@phosphor-icons/react";
import { CloseButton, ModalTitle } from "../../../styles/theme";
import {
  CustomButtonBlackSpace,
  CustomButtonGrey,
  CustomButtonRed,
} from "../../../styles/Common/CommonComponent";
import AlertModal from "../../../components/Common/AlertModal";

interface HeaderProps {
  open: boolean;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 13,
  },
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 700,
  bgcolor: "#ffffff",
  border: "1px solid #E0E0E0",
  boxShadow: 24,
  px: 4,
};

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const _initGrabData = {
  target: null,
  position: null,
  move_up: [],
  move_down: [],
  updateList: [],
};

function AddSiteList({ open }: HeaderProps) {
  const user = useUserState();
  const [siteData, setSiteData] = useState<AddSite[]>([]);
  const [value, setValue] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [siteTitle, setSiteTitle] = useState("");
  const [siteAddress, setSiteAddress] = useState("");
  const [siteIcon, setSiteIcon] = React.useState("ContentPasteIcon");
  const [siteOrder, setSiteOrder] = useState(0);

  const onChangeSiteTitle = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSiteTitle(e.target.value);
  };

  const onChangeSiteAddress = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSiteAddress(e.target.value);
  };

  const onChangeIcon = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSiteIcon((event.target as HTMLInputElement).value);
  };

  function closeModal() {
    setOpenModal(false);
  }

  const handleDelete = (siteid: any) => {
    if (window.confirm("즐겨찾기 사이트를 삭제하시겠습니까?")) {
      SiteApi.DeleteSite(siteid)
        .then(() => {
          refreshSite();
          AlertModal("success", "사이트가 삭제되었습니다.");
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  };
  useEffect(() => {
    SiteApi.GetSiteList(user.employeeId)
      .then((res) => {
        setSiteData(res.data);
        if (res.data.length > 0) {
          setSiteOrder(res.data.length);
        }
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
  }, []);

  const refreshSite = () => {
    SiteApi.GetSiteList(user.employeeId)
      .then((res) => {
        setSiteData(res.data);
        if (res.data.length > 0) {
          setSiteOrder(res.data.length);
        }
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
  };

  const siteSubmit = (args: any) => {
    if (siteTitle.length === 0) {
      AlertModal("check", "사이트 이름을 작성해주세요");

      return;
    }
    if (siteAddress.length === 0) {
      AlertModal("check", "사이트 주소를 작성해주세요.");

      return;
    }
    if (siteIcon.length === 0) {
      AlertModal("check", "사이트 아이콘을 선택해주세요.");
      return;
    }

    SiteApi.InsertSite({
      ...args.data,
      EmployeeId: user.employeeId,
      SiteTitle: siteTitle,
      SiteAddress: siteAddress,
      SiteIcon: siteIcon,
      SiteType: "site",
      SiteOrder: siteOrder,
    })
      .then(() => {
        refreshSite();
        AlertModal("success", "사이트가 추가되었습니다.");
        setSiteTitle("");
        setSiteAddress("");
        setSiteIcon("ContentPasteIcon");
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
  };

  // 즐겨찾기 순서
  const [grab, setGrab]: any = useState(_initGrabData);
  const [isDrag, setIsDrag] = useState(false);

  React.useEffect(() => {}, [grab, siteData]);
  const _onDragOver = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  const _onDragStart = (e: any) => {
    setIsDrag(true);
    setGrab({
      ...grab,
      target: e.target,
      position: Number(e.target.dataset.position),
      updateList: [...siteData],
    });

    e.target.classList.add("grabbing");
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target);

    e.target.classList.add("grabbing");
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target);
  };

  const _onDragEnd = (e: any) => {
    setIsDrag(false);
    e.target.classList.remove("grabbing");
    e.dataTransfer.dropEffect = "move";
    setSiteData([...grab.updateList]);
    setGrab({
      target: null,
      move_up: [],
      move_down: [],
      updateList: [],
    });
    e.target.style.visibility = "visible";
  };

  const _onDragEnter = (e: any) => {
    let grabPosition = Number(grab.target.dataset.position);
    let listPosition = grab.position;
    let targetPosition = Number(e.target.dataset.position);

    let move_up = [...grab.move_up];
    let move_down = [...grab.move_down];

    let data = [...grab.updateList];
    data[listPosition] = data.splice(targetPosition, 1, data[listPosition])[0];

    if (grabPosition > targetPosition) {
      move_down.includes(targetPosition)
        ? move_down.pop()
        : move_down.push(targetPosition);
    } else if (grabPosition < targetPosition) {
      move_up.includes(targetPosition)
        ? move_up.pop()
        : move_up.push(targetPosition);
    } else {
      move_down = [];
      move_up = [];
    }

    setGrab({
      ...grab,
      move_up,
      move_down,
      updateList: data,
      position: targetPosition,
    });
  };

  const _onDragLeave = (e: any) => {
    if (e.target === grab.target) {
      e.target.style.visibility = "hidden";
    }
  };

  const saveSiteOrder = (args: any) => {
    for (let i = 0; i < siteData.length; i++) {
      const newData = {
        ...args.data,
        EmployeeId: siteData[i].employeeId,
        SiteAddress: siteData[i].siteAddress,
        SiteIcon: siteData[i].siteIcon,
        SiteId: siteData[i].siteId,
        SiteOrder: i,
        SiteTitle: siteData[i].siteTitle,
        SiteType: "site",
      };
      SiteApi.UpdateSite(newData)
        .then(() => {
          console.log("사이트 순서 변경 완료");
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  };

  function closeAddSite() {
    setOpenModal(false);
  }

  return (
    <>
      {open ? (
        <>
          {siteData
            ? siteData.map((item: any) => {
                return (
                  <div
                    key={item.siteId + item.siteTitle}
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      onClick={() => {
                        window.open(`${item.siteAddress}`);
                      }}
                      key={item.siteId}
                    >
                      <ListItemButton>
                        <IconList item={item} />
                        <ListItemText primary={item.siteTitle} sx={{ pl: 2 }} />
                      </ListItemButton>
                    </div>
                  </div>
                );
              })
            : ""}
        </>
      ) : (
        ""
      )}
      {siteData.length === 0 ? (
        <div></div>
      ) : (
        <Divider style={{ borderBottom: "dashed 1px gray" }} />
      )}
      {open ? (
        <div onClick={handleOpenModal} style={{ cursor: "pointer" }}>
          <List>
            <ListItemButton onClick={handleOpenModal}>
              <Star size={20} />
              <ListItemText primary="즐겨찾기 관리" sx={{ pl: 2 }} />
            </ListItemButton>
          </List>
        </div>
      ) : (
        <></>
      )}

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseButton>
            <X onClick={closeModal} size={40} />
          </CloseButton>
          <ModalTitle>
            <TitleBox title="즐겨찾기 관리" />
          </ModalTitle>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="inherit"
                aria-label="inherit tabs example"
              >
                <Tab
                  className="tabtitle"
                  label="즐겨찾기 추가"
                  {...a11yProps(0)}
                />
                <Tab
                  className="tabtitle"
                  label="즐겨찾기 삭제"
                  {...a11yProps(1)}
                />
                <Tab
                  className="tabtitle"
                  label="즐겨찾기 순서"
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
            <div className="tabcomponent">
              {value === 0 ? (
                <div>
                  <div className="tabinput">
                    <Typography variant="subtitle1" component="div">
                      사이트 이름
                    </Typography>
                    <input
                      value={siteTitle}
                      type="text"
                      placeholder="아이콘 위로 마우스를 올릴 시 사이트 이름이 나타납니다."
                      onChange={onChangeSiteTitle}
                    />
                  </div>

                  <div className="tabinput">
                    <Typography variant="subtitle1" component="div">
                      사이트 주소
                    </Typography>
                    <input
                      value={siteAddress}
                      type="text"
                      placeholder="아이콘 클릭 시 사이트 주소로 이동합니다."
                      onChange={onChangeSiteAddress}
                    />
                    <div className="tabinput">
                      <Typography variant="subtitle1" component="div">
                        사이트 아이콘
                      </Typography>
                      <IconRadio
                        siteIcon={siteIcon}
                        onChangeIcon={onChangeIcon}
                      />
                    </div>
                  </div>
                  <div className="tab_bt_wrap ">
                    <CustomButtonGrey
                      className="sample-btn e-control e-btn"
                      type="submit"
                      onClick={closeAddSite}
                    >
                      취소
                    </CustomButtonGrey>
                    <CustomButtonBlackSpace
                      id="validateSubmit"
                      className="sample-btn e-control e-btn"
                      type="submit"
                      data-ripple="true"
                      onClick={siteSubmit}
                    >
                      추가
                    </CustomButtonBlackSpace>
                  </div>
                </div>
              ) : (
                ""
              )}
              {value === 1 ? (
                <div>
                  <Typography variant="subtitle1" component="div">
                    현재 추가된 사이트 목록
                  </Typography>
                  <div className="addsitelist">
                    <ul>
                      {siteData
                        ? siteData.map((item: any) => (
                            <li key={item.siteId} className="addsitelistitem">
                              <IconList
                                className="addsitelistitemcontent"
                                item={item}
                              />{" "}
                              {item.siteTitle} - {item.siteAddress}
                              <CustomButtonRed
                                iconCss="e-icons e-trash"
                                className="siteDeleteButton"
                                onClick={() => handleDelete(item.siteId)}
                              >
                                삭제
                              </CustomButtonRed>
                            </li>
                          ))
                        : " "}
                    </ul>
                  </div>
                  <div className="tab_delete_bt_wrap ">
                    <CustomButtonGrey
                      className="sample-btn e-control e-btn"
                      type="submit"
                      onClick={closeAddSite}
                    >
                      취소
                    </CustomButtonGrey>
                  </div>
                </div>
              ) : (
                ""
              )}
              {value === 2 ? (
                <div>
                  <Typography variant="subtitle1" component="div">
                    즐겨찾기 순서 변경
                  </Typography>
                  <div className="draglistbox" onDragOver={_onDragOver}>
                    {siteData
                      ? siteData.map((item: any, index) => (
                          <li
                            key={index}
                            className="draglist"
                            draggable={true}
                            data-position={index}
                            onDragStart={_onDragStart}
                            onDragEnd={_onDragEnd}
                            onDragEnter={_onDragEnter}
                            onDragLeave={_onDragLeave}
                          >
                            <IconList item={item} /> {item.siteTitle} -{" "}
                            {item.siteAddress}
                          </li>
                        ))
                      : " "}
                  </div>
                  <div className="tab_bt_wrap_drag">
                    <CustomButtonGrey
                      className="sample-btn e-control e-btn"
                      type="submit"
                      onClick={closeAddSite}
                    >
                      취소
                    </CustomButtonGrey>
                    <CustomButtonBlackSpace
                      className="sample-btn e-control e-btn"
                      type="submit"
                      onClick={saveSiteOrder}
                    >
                      저장
                    </CustomButtonBlackSpace>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default AddSiteList;
