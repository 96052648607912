import React, { useEffect, useState } from "react";

import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import {
  AirplaneInFlight,
  AmazonLogo,
  AppleLogo,
  Bus,
  Car,
  CloudArrowUp,
  Clipboard,
  CodepenLogo,
  CodesandboxLogo,
  Desktop,
  DiscordLogo,
  DribbbleLogo,
  FacebookLogo,
  FigmaLogo,
  ForkKnife,
  GithubLogo,
  GitlabLogo,
  GlobeSimple,
  GoogleChromeLogo,
  GoogleDriveLogo,
  GoogleLogo,
  GooglePhotosLogo,
  InstagramLogo,
  LinkedinLogo,
  LinuxLogo,
  MagnifyingGlass,
  MessengerLogo,
  MetaLogo,
  NotionLogo,
  PaypalLogo,
  PersonSimpleRun,
  PinterestLogo,
  Receipt,
  RedditLogo,
  TiktokLogo,
  Train,
  TwitterLogo,
  YoutubeLogo,
  PencilLine,
  Chats,
  EnvelopeSimple,
  PhoneCall,
  AddressBook,
  DeviceMobile,
  Timer,
  IdentificationCard,
  CalendarBlank,
  CalendarCheck,
  Calendar,
  PlusSquare,
  CheckSquare,
  Book,
  Playlist,
  Bookmark,
  ListBullets,
  ListChecks,
  House,
  Factory,
  Storefront,
  Park,
  SuitcaseRolling,
  Airplane,
  CreditCard,
  MusicNote,
  Gift,
  Coffee,
  FilmStrip,
  FirstAid,
  ShoppingBagOpen,
  ShoppingCart,
  Package,
  Heart,
  Star,
  SunDim,
  Moon,
  Smiley,
  Shapes,
  Bank,
  Barbell,
  CoatHanger,
  FinnTheHuman,
  AndroidLogo,
  Camera,
  BowlFood,
  PawPrint,
} from "@phosphor-icons/react";

function IconRadio({ siteIcon, onChangeIcon }: any) {
  return (
    <>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          style={{ width: 870 }}
          value={siteIcon}
          onChange={onChangeIcon}
        >
          <FormControlLabel value="ContentPasteIcon" control={<Radio />} label={<Clipboard size={25} />} />
          <FormControlLabel value="CreateIcon" control={<Radio />} label={<PencilLine size={25} />} />
          <FormControlLabel value="ForumIcon" control={<Radio />} label={<Chats size={25} />} />
          <FormControlLabel value="EmailIcon" control={<Radio />} label={<EnvelopeSimple size={25} />} />
          <FormControlLabel value="LocalPhoneIcon" control={<Radio />} label={<PhoneCall size={25} />} />
          <FormControlLabel value="addressbook" control={<Radio />} label={<AddressBook size={25} />} />
          <FormControlLabel value="ReceiptLongIcon" control={<Radio />} label={<Receipt size={25} />} />
          <FormControlLabel value="TabletAndroidIcon" control={<Radio />} label={<DeviceMobile size={25} />} />
          <FormControlLabel value="TimerIcon" control={<Radio />} label={<Timer size={25} />} />
          <FormControlLabel value="BadgeIcon" control={<Radio />} label={<IdentificationCard size={25} />} />

          <FormControlLabel value="LibraryBooksIcon" control={<Radio />} label={<Book size={25} />} />
          <FormControlLabel value="camera" control={<Radio />} label={<Camera size={25} />} />
          <FormControlLabel value="EventIcon" control={<Radio />} label={<CalendarBlank size={25} />} />
          <FormControlLabel value="EventAvailableIcon" control={<Radio />} label={<CalendarCheck size={25} />} />
          <FormControlLabel value="EventNoteIcon" control={<Radio />} label={<Calendar size={25} />} />
          <FormControlLabel value="LibraryAddIcon" control={<Radio />} label={<PlusSquare size={25} />} />
          <FormControlLabel value="LibraryAddCheckIcon" control={<Radio />} label={<CheckSquare size={25} />} />
          <FormControlLabel value="playlist" control={<Radio />} label={<Playlist size={25} />} />
          <FormControlLabel value="bookmark" control={<Radio />} label={<Bookmark size={25} />} />
          <FormControlLabel value="ListAltIcon" control={<Radio />} label={<ListBullets size={25} />} />
          <FormControlLabel value="RecentActorsIcon" control={<Radio />} label={<ListChecks size={25} />} />

          <FormControlLabel value="HomeIcon" control={<Radio />} label={<House size={25} />} />
          <FormControlLabel value="factory" control={<Radio />} label={<Factory size={25} />} />
          <FormControlLabel value="HomeWorkIcon" control={<Radio />} label={<Storefront size={25} />} />
          <FormControlLabel value="LocationCityIcon" control={<Radio />} label={<Park size={25} />} />
          <FormControlLabel value="suticase" control={<Radio />} label={<SuitcaseRolling size={25} />} />
          <FormControlLabel value="DirectionsRunIcon" control={<Radio />} label={<PersonSimpleRun size={25} />} />
          <FormControlLabel value="DirectionsBusIcon" control={<Radio />} label={<Bus size={25} />} />
          <FormControlLabel value="DirectionsRailwayIcon" control={<Radio />} label={<Train size={25} />} />
          <FormControlLabel value="DirectionsCarIcon" control={<Radio />} label={<Car size={25} />} />
          <FormControlLabel value="FlightIcon" control={<Radio />} label={<Airplane size={25} />} />
          <FormControlLabel value="CreditCardIcon" control={<Radio />} label={<CreditCard size={25} />} />
          <FormControlLabel value="CardGiftcardIcon" control={<Radio />} label={<Gift size={25} />} />
          <FormControlLabel value="food" control={<Radio />} label={<BowlFood size={25} />} />
          <FormControlLabel value="DiningIcon" control={<Radio />} label={<ForkKnife size={25} />} />
          <FormControlLabel value="coffee" control={<Radio />} label={<Coffee size={25} />} />
          <FormControlLabel value="AudiotrackIcon" control={<Radio />} label={<MusicNote size={25} />} />
          <FormControlLabel value="LocalMoviesIcon" control={<Radio />} label={<FilmStrip size={25} />} />
          <FormControlLabel value="LocalHospitalIcon" control={<Radio />} label={<FirstAid size={25} />} />
          <FormControlLabel value="LocalMallIcon" control={<Radio />} label={<ShoppingBagOpen size={25} />} />
          <FormControlLabel value="LocalGroceryStoreIcon" control={<Radio />} label={<ShoppingCart size={25} />} />
          <FormControlLabel value="package" control={<Radio />} label={<Package size={25} />} />

          <FormControlLabel value="FavoriteIcon" control={<Radio />} label={<Heart size={25} />} />
          <FormControlLabel value="star" control={<Radio />} label={<Star size={25} />} />
          <FormControlLabel value="gym" control={<Radio />} label={<Barbell size={25} />} />
          <FormControlLabel value="clothes" control={<Radio />} label={<CoatHanger size={25} />} />
          <FormControlLabel value="InterestsIcon" control={<Radio />} label={<Shapes size={25} />} />
          <FormControlLabel value="SavingsIcon" control={<Radio />} label={<Bank size={25} />} />
          <FormControlLabel value="SentimentSatisfiedAltIcon" control={<Radio />} label={<FinnTheHuman size={25} />} />
          <FormControlLabel value="SentimentVerySatisfiedIcon" control={<Radio />} label={<Smiley size={25} />} />
          <FormControlLabel value="LightModeIcon" control={<Radio />} label={<SunDim size={25} />} />
          <FormControlLabel value="DarkModeIcon" control={<Radio />} label={<Moon size={25} />} />

          <FormControlLabel value="YouTubeIcon" control={<Radio />} label={<YoutubeLogo size={25} />} />
          <FormControlLabel value="InstagramIcon" control={<Radio />} label={<InstagramLogo size={25} />} />
          <FormControlLabel value="messenger" control={<Radio />} label={<MessengerLogo size={25} />} />
          <FormControlLabel value="TwitterIcon" control={<Radio />} label={<TwitterLogo size={25} />} />
          <FormControlLabel value="FacebookIcon" control={<Radio />} label={<FacebookLogo size={25} />} />
          <FormControlLabel value="GoogleIcon" control={<Radio />} label={<GoogleLogo size={25} />} />
          <FormControlLabel value="LanguageIcon" control={<Radio />} label={<GlobeSimple size={25} />} />
          <FormControlLabel value="CloudUploadIcon" control={<Radio />} label={<CloudArrowUp size={25} />} />
          <FormControlLabel value="ComputerIcon" control={<Radio />} label={<Desktop size={25} />} />
          <FormControlLabel value="SearchIcon" control={<Radio />} label={<MagnifyingGlass size={25} />} />

          <FormControlLabel value="googledrive" control={<Radio />} label={<GoogleDriveLogo size={25} />} />
          <FormControlLabel value="googlechrome" control={<Radio />} label={<GoogleChromeLogo size={25} />} />
          <FormControlLabel value="googlephotos" control={<Radio />} label={<GooglePhotosLogo size={25} />} />
          <FormControlLabel value="android" control={<Radio />} label={<AndroidLogo size={25} />} />
          <FormControlLabel value="apple" control={<Radio />} label={<AppleLogo size={25} />} />
          <FormControlLabel value="meta" control={<Radio />} label={<MetaLogo size={25} />} />
          <FormControlLabel value="amazon" control={<Radio />} label={<AmazonLogo size={25} />} />
          <FormControlLabel value="notion" control={<Radio />} label={<NotionLogo size={25} />} />
          <FormControlLabel value="paypal" control={<Radio />} label={<PaypalLogo size={25} />} />
          <FormControlLabel value="reddit" control={<Radio />} label={<RedditLogo size={25} />} />
          <FormControlLabel value="tiktok" control={<Radio />} label={<TiktokLogo size={25} />} />

          <FormControlLabel value="github" control={<Radio />} label={<GithubLogo size={25} />} />
          <FormControlLabel value="gitlab" control={<Radio />} label={<GitlabLogo size={25} />} />
          <FormControlLabel value="linkedin" control={<Radio />} label={<LinkedinLogo size={25} />} />
          <FormControlLabel value="figma" control={<Radio />} label={<FigmaLogo size={25} />} />
          <FormControlLabel value="pinterest" control={<Radio />} label={<PinterestLogo size={25} />} />
          <FormControlLabel value="dribble" control={<Radio />} label={<DribbbleLogo size={25} />} />
          <FormControlLabel value="codesandbox" control={<Radio />} label={<CodesandboxLogo size={25} />} />
          <FormControlLabel value="linux" control={<Radio />} label={<LinuxLogo size={25} />} />
          <FormControlLabel value="codepen" control={<Radio />} label={<CodepenLogo size={25} />} />
          <FormControlLabel value="discord" control={<Radio />} label={<DiscordLogo size={25} />} />
          <FormControlLabel value="pet" control={<Radio />} label={<PawPrint size={25} />} />
        </RadioGroup>
      </FormControl>
    </>
  );
}

export default IconRadio;
