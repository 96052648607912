import MypageMain from "../../components/Mypage/MypageMain";
import TitleBox from "../../components/Common/TitleBox";

function Mypage() { 


    return (
        <>
        <TitleBox title="내가 작성한 글" />
        <MypageMain/>
        </>
    )
}

export default Mypage;