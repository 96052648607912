import Portlet from "../../components/Main/Portlet";
import Profile from "../../components/Main/Profile";
import "../../styles/pages/Layout/Main.css";
import MainProfile from "../../components/Main/MainProfile";
import FixedPortlet from "../../components/Main/FixedPortlet";
import {
  FixedPortletBox,
  MovePortletBox,
} from "../../styles/Components/Main/MainPortal";
import { Pc, Mobile } from "./ResponsiveScreen";
import MovedPortlet from "../../components/Main/MovedPortlet";

function LandingMain() {
  return (
    <div>
      <Pc>
        <MainProfile />
        <FixedPortletBox>
          <FixedPortlet />
        </FixedPortletBox>
        <MovePortletBox>
          <MovedPortlet />
        </MovePortletBox>
      </Pc>
      <Mobile>
        <div className="mainbox">
          <div className="profilebox">
            <Profile />
          </div>
          <div className="portletbox">
            <Portlet />
          </div>
        </div>
      </Mobile>
    </div>
  );
}

export default LandingMain;
