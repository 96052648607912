import Typography from "@mui/material/Typography";
import "../../../../src/styles/Components/Main/Portlet.css";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { CheonanMenuApi, ErrorHandler } from "../../../system/ApiService";
import { CheonanJsonData } from "../../../system/types/interface";
import AlertModal from "../../Common/AlertModal";

export interface Menu {
  조식: string[];
  중식: string[];
  석식: string[];
}

function CheonanMenuPortlet() {
  let history = useHistory();
  const [data, setData] = useState<CheonanJsonData[]>([]);
  const [dataView, setDataView] = useState(false);

  var currentDay = new Date();
  var theDayOfWeek = currentDay.getDay();
  var theYear = currentDay.getFullYear();
  var theMonth = currentDay.getMonth();
  var theDate = currentDay.getDate();
  var getDay = currentDay.getDay();

  var pretodaymonth = theMonth + 1;
  var todaymonth =
    String(pretodaymonth).length === 1
      ? "0" + `${pretodaymonth}`
      : `${pretodaymonth}`;
  var todayDay =
    String(theDate).length === 1 ? "0" + `${theDate}` : `${theDate}`;
  var today: any = theYear + "" + todaymonth + "" + todayDay;

  var resultDay = new Date(theYear, theMonth, theDate + (1 - theDayOfWeek));
  var yyyy = resultDay.getFullYear();
  var premm = Number(resultDay.getMonth()) + 1;
  var predd = resultDay.getDate();

  var mm = String(premm).length === 1 ? "0" + `${premm}` : `${premm}`;
  var dd = String(predd).length === 1 ? "0" + `${predd}` : `${predd}`;

  var weekDateGetData = yyyy + "" + mm + "" + dd;

  let offset = currentDay.getTimezoneOffset() * 60000;
  let dateOffset = new Date(currentDay.getTime() - offset);
  let todayDate = dateOffset.toISOString();

  useEffect(() => {
    if (weekDateGetData.length > 0) {
      CheonanMenuApi.GetCheonanMenu(Number(weekDateGetData))
        .then((res) => {
          if (res.data) {
            setData(JSON.parse(res.data.menuDataList));
            setDataView(true);
          }
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  }, []);

  const todaymenuList = data.filter((data) => {
    return data.date === todayDate.split("T")[0] + "T00:00:00";
  });

  // css
  const portletSize = {
    height: "120px",
    marginTop: "70px",
  };

  return (
    <div className="PortletLayout">
      <Typography
        variant="h5"
        component="div"
        style={{ padding: "5px 0px 5px 5px" }}
      >
        오늘의 메뉴 (천안)
        <Typography
          variant="subtitle2"
          component="span"
          onClick={() => {
            history.push("/cheonanMenu");
          }}
          style={{
            cursor: "pointer",
            paddingTop: "10px",
            paddingRight: "5px",
            color: "#81848B",
            float: "right",
            display: "inline-block",
          }}
        >
          [전체보기]
        </Typography>
      </Typography>
      <div className="menuportlet_content">
        <div className="cheonanMenuTable">
          {dataView && getDay != 0 && getDay != 6 ? (
            <>
              <div className="cheonanMenu">
                <div className="cheonanMenuTitle">조식</div>
                {todaymenuList[0]["breakfast"][0] != null ? (
                  <>
                    {todaymenuList[0]["breakfast"].map(
                      (menu: any, index: number) => {
                        return (
                          <div
                            className="cheonanMenuContent"
                            key={index + menu}
                          >
                            {menu}
                          </div>
                        );
                      }
                    )}
                  </>
                ) : (
                  <div style={portletSize}>미운영</div>
                )}
              </div>

              <div className="cheonanMenu">
                <div className="cheonanMenuTitle">중식</div>

                {todaymenuList[0]["lunch"][0] != null ? (
                  <>
                    {todaymenuList[0]["lunch"].map(
                      (menu: any, index: number) => {
                        return (
                          <div
                            className="cheonanMenuContent"
                            key={index + menu}
                          >
                            {menu}
                          </div>
                        );
                      }
                    )}
                  </>
                ) : (
                  <div style={portletSize}>미운영</div>
                )}
              </div>
              <div className="cheonanMenu">
                <div className="cheonanMenuTitle">석식</div>

                {todaymenuList[0]["dinner"][0] != null ? (
                  <>
                    {todaymenuList[0]["dinner"].map(
                      (menu: any, index: number) => {
                        return (
                          <div
                            className="cheonanMenuContent"
                            key={index + menu}
                          >
                            {menu}
                          </div>
                        );
                      }
                    )}
                  </>
                ) : (
                  <div style={portletSize}>미운영</div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="cheonanMenu">
                <div className="cheonanMenuTitle">조식</div>
                <div style={portletSize}>추후 업데이트 예정</div>
              </div>
              <div className="cheonanMenu">
                <div className="cheonanMenuTitle">조식</div>
                <div style={portletSize}>추후 업데이트 예정</div>
              </div>
              <div className="cheonanMenu">
                <div className="cheonanMenuTitle">조식</div>
                <div style={portletSize}>추후 업데이트 예정</div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default CheonanMenuPortlet;
