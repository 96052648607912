import React, { useEffect, useState } from "react";
import "../../../src/styles/Components/BreakfastTable/BreakfastTable.css";
import { useUserState } from "../../system/context/UserContext";

// material
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Grid } from "@mui/material";

// syncfusion
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import TitleBox from "../Common/TitleBox";
import { MessageComponent } from "@syncfusion/ej2-react-notifications";
import BreakfastTable from "../InbodyMenu/BreakfastTable";
import { ErrorHandler, MenuApi } from "../../system/ApiService";
import { MenuList } from "../../system/types/interface";
import { InitMenuList } from "../../system/types/initObject";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ButtonAlign } from "../../styles/theme";
import {
  CustomButtonBlack,
  CustomButtonBlackSpace,
  CustomButtonGrey,
} from "../../styles/Common/CommonComponent";
import AlertModal from "../Common/AlertModal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 830,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function HeadOffice() {
  const [data, setData] = useState<MenuList>(InitMenuList);
  const [nextdata, setNextData] = useState<MenuList>(InitMenuList);
  const [nnextdata, setNnextData] = useState<MenuList>(InitMenuList);
  const [value, setValue] = useState(0);
  const user = useUserState();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [refreshData, setRefreshData] = useState(0);

  // 날짜
  var currentDay = new Date();
  var theYear = currentDay.getFullYear();
  var theMonth = currentDay.getMonth();
  var theDate = currentDay.getDate();
  var theDayOfWeek = currentDay.getDay();

  const dayOfWeekNames = ["일", "월", "화", "수", "목", "금", "토"];

function formatDate(date: Date): string {
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  const dayName = dayOfWeekNames[date.getDay()];
  return `${mm}월 ${dd}일 (${dayName})`;
}

function formatYyyymmdd(date: Date): string {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  return `${yyyy}${mm}${dd}`;
}

const thisWeek: string[] = [];
const nextWeek: string[] = [];
const nnextWeek: string[] = [];
const thisDate: string[] = [];
const nextDate: string[] = [];
const nnextDate: string[] = [];

for (let i = 0; i < 3; i++) {
  for (let j = 1; j < 6; j++) {
    const resultDay = new Date(theYear, theMonth, theDate + j + 7 * i - theDayOfWeek);
    const formattedDate = formatDate(resultDay);
    const yyyymmdd = formatYyyymmdd(resultDay);

    if (i === 0) {
      thisWeek.push(formattedDate);
      thisDate.push(yyyymmdd);
    } else if (i === 1) {
      nextWeek.push(formattedDate);
      nextDate.push(yyyymmdd);
    } else {
      nnextWeek.push(formattedDate);
      nnextDate.push(yyyymmdd);
    }
  }
}

  var menuDate = Number(thisDate[0]);
  var menuNextDate = Number(nextDate[0]);
  var menuNnextDate = Number(nnextDate[0]);

  useEffect(() => {
    if (menuDate) {
      MenuApi.GetMenuList(menuDate)
        .then((res) => {
          setData(res.data);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
    if (menuNextDate) {
      MenuApi.GetMenuList(menuNextDate)
        .then((res) => {
          setNextData(res.data);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
    if (menuNnextDate) {
      MenuApi.GetMenuList(menuNnextDate)
        .then((res) => {
          setNnextData(res.data);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  }, []);

  // 모달
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  function handleClose() {
    setOpen(false);
  }
  const week = ["Mon", "Tue", "Wed", "Thu", "Fri"];

  const thismainmenu = [
    data.mainMon,
    data.mainTue,
    data.mainWed,
    data.mainThu,
    data.mainFri,
  ];
  const nextmainmenu = [
    nextdata.mainMon,
    nextdata.mainTue,
    nextdata.mainWed,
    nextdata.mainThu,
    nextdata.mainFri,
  ];
  const nnextmainmenu = [
    nnextdata.mainMon,
    nnextdata.mainTue,
    nnextdata.mainWed,
    nnextdata.mainThu,
    nnextdata.mainFri,
  ];
  const thisdetailmenu = [
    data.detailMon,
    data.detailTue,
    data.detailWed,
    data.detailThu,
    data.detailFri,
  ];
  const nextdetailmenu = [
    nextdata.detailMon,
    nextdata.detailTue,
    nextdata.detailWed,
    nextdata.detailThu,
    nextdata.detailFri,
  ];
  const nnextdetailmenu = [
    nnextdata.detailMon,
    nnextdata.detailTue,
    nnextdata.detailWed,
    nnextdata.detailThu,
    nnextdata.detailFri,
  ];
  const thisabsentees = [
    data.absenteesMon,
    data.absenteesTue,
    data.absenteesWed,
    data.absenteesThu,
    data.absenteesFri,
  ];
  const nextabsentees = [
    nextdata.absenteesMon,
    nextdata.absenteesTue,
    nextdata.absenteesWed,
    nextdata.absenteesThu,
    nextdata.absenteesFri,
  ];
  const nnextabsentees = [
    nnextdata.absenteesMon,
    nnextdata.absenteesTue,
    nnextdata.absenteesWed,
    nnextdata.absenteesThu,
    nnextdata.absenteesFri,
  ];

  const onChange = (args: any) => {
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]: args.target.value,
      menuDate: menuDate,
    });
  };
  const onNextChange = (args: any) => {
    setNextData({
      ...nextdata,
      [(args.target as HTMLInputElement).name]: args.target.value,
      menuDate: menuNextDate,
    });
  };
  const onNnextChange = (args: any) => {
    setNnextData({
      ...nnextdata,
      [(args.target as HTMLInputElement).name]: args.target.value,
      menuDate: menuNnextDate,
    });
  };

  function closeModal() {
    setOpen(false);
  }

  const onSave = () => {
    let promise: Promise<any>;
    if (data.menuId) {
      promise = MenuApi.UpdateMenu({ ...data });
    } else {
      promise = MenuApi.InsertMenu({ ...data });
    }

    if (nextdata.menuId) {
      promise = MenuApi.UpdateMenu({ ...nextdata });
    } else {
      promise = MenuApi.InsertMenu({ ...nextdata });
    }

    if (nnextdata.menuId) {
      promise = MenuApi.UpdateMenu({ ...nnextdata });
    } else {
      promise = MenuApi.InsertMenu({ ...nnextdata });
    }
    promise
      .then(() => {
        AlertModal("success", "정상적으로 등록되었습니다.");
        var numRefreshData = refreshData + 1;
        setRefreshData(numRefreshData);
        setOpen(false);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
  };

  // css
  const titleBox = {
    textAlign: "center" as const,
    margin: "40px",
  };

  return (
    <>
      <div className="breakfastmain">
        {/* 사번으로 권한 체크 */}
        {["20220906", "20220914", "20190907"].includes(user.employeeId) ? (
          <ButtonAlign>
            <CustomButtonBlack onClick={handleOpen}>
              메뉴 입력
            </CustomButtonBlack>
          </ButtonAlign>
        ) : (
          ""
        )}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="modalTitle">
              <TitleBox title="주간 아침 메뉴 (본사) 입력" />
              <CloseIcon
                onClick={handleClose}
                style={{ cursor: "pointer" }}
                sx={{ fontSize: 40 }}
              />
            </div>
            <MessageComponent
              style={{ marginTop: "20px", whiteSpace: "pre-line" }}
              id="msg_default"
              content={`각 날짜의 메인메뉴에는 '주간 아침 메뉴 (본사)' 포틀릿에 적힐 이름을 적어주세요.\r\n※ 본사 총 인원: ${data.total}명 (${menuDate} 기준)`}
            ></MessageComponent>

            <Tabs value={value} onChange={handleChange} textColor="primary">
              <Tab label="이번 주" {...a11yProps(0)}></Tab>
              <Tab label="다음 주" {...a11yProps(1)}></Tab>
              <Tab label="다음다음 주" {...a11yProps(2)}></Tab>
            </Tabs>
            {value === 0 ? (
              <div className="regionBlock">
                {thisWeek.map((day: string, index: number) => {
                  return (
                    <div key={"thisWeek" + index} className="weekTable">
                      <Grid container spacing={1} mt={"10px"}>
                        <Grid item xs={2} textAlign={"right"}>
                          {day}
                        </Grid>
                        <Grid item xs={1} textAlign={"right"}>
                          메인
                        </Grid>
                        <Grid item xs={9} style={{paddingTop:"0px"}}>
                          <TextBoxComponent
                            placeholder="메인 메뉴를 입력해 주세요."
                            cssClass="e-outline"
                            type="text"
                            name={"main" + week[index]}
                            onChange={onChange}
                            value={thismainmenu[index]}
                          />
                        </Grid>
                        <Grid item xs={2} textAlign={"right"} style={{color:"Blue"}}>
                          {`${data.total - thisabsentees[index]}명`}</Grid>
                        <Grid item xs={1} textAlign={"right"}>
                          종류
                        </Grid>
                        <Grid item xs={9} style={{paddingTop:"0px"}}>
                          <TextBoxComponent
                            placeholder="세부 메뉴를 쉼표(,) 처리하여 입력해 주세요.         ex. 참치 김밥, 소고기 김밥"
                            cssClass="e-outline"
                            type="text"
                            name={"detail" + week[index]}
                            onChange={onChange}
                            value={thisdetailmenu[index]}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
            {value === 1 ? (
              <div className="regionBlock">
                {nextWeek.map((day: string, index: number) => {
                  return (
                    <div key={"thisWeek" + index} className="weekTable">
                      <Grid container spacing={1} mt={"10px"}>
                        <Grid item xs={2} textAlign={"right"}>
                          {day}
                        </Grid>
                        <Grid item xs={1} textAlign={"right"}>
                          메인
                        </Grid>
                        <Grid item xs={9} style={{paddingTop:"0px"}}>
                          <TextBoxComponent
                            placeholder="메인 메뉴를 입력해 주세요."
                            cssClass="e-outline"
                            type="text"
                            name={"main" + week[index]}
                            onChange={onNextChange}
                            value={nextmainmenu[index]}
                          />
                        </Grid>
                        <Grid item xs={2} textAlign={"right"} style={{color:"Blue"}}>
                          {`${data.total - nextabsentees[index]}명`}</Grid>
                        <Grid item xs={1} textAlign={"right"}>
                          종류
                        </Grid>
                        <Grid item xs={9} style={{paddingTop:"0px"}}>
                          <TextBoxComponent
                            placeholder="세부 메뉴를 쉼표(,) 처리하여 입력해 주세요.         ex. 참치 김밥, 소고기 김밥"
                            cssClass="e-outline"
                            type="text"
                            name={"detail" + week[index]}
                            onChange={onNextChange}
                            value={nextdetailmenu[index]}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
            {value === 2 ? (
              <div className="regionBlock">
                {nnextWeek.map((day: string, index: number) => {
                  return (
                    <div key={"thisWeek" + index} className="weekTable">
                      <Grid container spacing={1} mt={"10px"}>
                        <Grid item xs={2} textAlign={"right"}>
                          {day}
                        </Grid>
                        <Grid item xs={1} textAlign={"right"}>
                          메인
                        </Grid>
                        <Grid item xs={9} style={{paddingTop:"0px"}}>
                          <TextBoxComponent
                            placeholder="메인 메뉴를 입력해 주세요."
                            cssClass="e-outline"
                            type="text"
                            name={"main" + week[index]}
                            onChange={onNnextChange}
                            value={nnextmainmenu[index]}
                          />
                        </Grid>
                        <Grid item xs={2} textAlign={"right"} style={{color:"Blue"}}>
                          {`${data.total - nnextabsentees[index]}명`}</Grid>
                        <Grid item xs={1} textAlign={"right"}>
                          종류
                        </Grid>
                        <Grid item xs={9} style={{paddingTop:"0px"}}>
                          <TextBoxComponent
                            placeholder="세부 메뉴를 쉼표(,) 처리하여 입력해 주세요.         ex. 참치 김밥, 소고기 김밥"
                            cssClass="e-outline"
                            type="text"
                            name={"detail" + week[index]}
                            onChange={onNnextChange}
                            value={nnextdetailmenu[index]}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
            <div className="tab_bt_wrap ">
              <CustomButtonGrey
                className="sample-btn e-control e-btn"
                type="submit"
                style={{ marginLeft: "10px" }}
                onClick={closeModal}
              >
                취소
              </CustomButtonGrey>
              <CustomButtonBlackSpace
                className="sample-btn e-control e-btn"
                type="submit"
                onClick={onSave}
              >
                저장
              </CustomButtonBlackSpace>
            </div>
          </Box>
        </Modal>

        <BreakfastTable refreshData={refreshData} />

        <div className="thisweekend">
          <div style={titleBox}>
            <Typography variant="h6" style={{ fontWeight: "700" }}>
              다음다음 주 아침 메뉴
            </Typography>
          </div>
          {nnextWeek.map((day: any, index: number) => {
            return (
              <div key={"nnextWeek" + index} className="menuinfo">
                <div className="day">{day}</div>
                <div className="menubox">
                  <div className="mainMenu">
                    {nnextmainmenu[index]
                      ? nnextmainmenu[index]
                      : "추후 업데이트 예정"}
                  </div>
                  {nnextdetailmenu[index]
                    ? nnextdetailmenu[index]
                        .split(",")
                        .map((menuinfo: any, i: number) => {
                          return (
                            <div key={"menu" + i} className="subMenu">
                              {menuinfo}
                            </div>
                          );
                        })
                    : ""}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default HeadOffice;
