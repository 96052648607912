import {
  CustomButtonRed,
  CustomButtonWhite,
  InputBox,
  ModalContentBox,
  ModalTitleBox,
  ModalTitleIcon,
  ModalTitleText,
  TextareaBox,
} from "../../styles/theme";
import { Plus, X } from "@phosphor-icons/react";
import { CeoScheduleInfo } from "../../system/types/ceoschedule";
import { useEffect, useState } from "react";
import { InitCeoScheduleInfo } from "../../system/types/initObject";
import {
  ColorExample,
  CeoScheduleModalContent,
  CeoScheduleModalContentBox,
  CeoScheduleModalContentFlexBox,
  CeoScheduleModalContentTitle,
  CeoScheduleModalInput,
  CeoScheduleModalLabel,
  ColorBigBox,
  ColorBox,
  InbodySwitch,
  OpenColorBoxText,
  CeoScheduleButtonBox,
  ColorExample2,
  CeoSchedulerInnerCircle,
} from "../../styles/CeoScheduleStyle";

// material
import { Grid, Switch } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

//syncfusion
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { loadCldr, L10n } from "@syncfusion/ej2-base";
import * as gregorian from "../../../node_modules/cldr-data/main/ko/ca-gregorian.json";
import * as numbers from "../../../node_modules/cldr-data/main/ko/numbers.json";
import * as timeZoneNames from "../../../node_modules/cldr-data/main/ko/timeZoneNames.json";
import * as NumberingSystems from "../../../node_modules/cldr-data/supplemental/numberingSystems.json";
import * as weekData from "../../../node_modules/cldr-data/supplemental/weekData.json";
import { isoDateFormatter, isoHourFormatter } from "../Common/IsoDateFormatter";
import { ColorPalette } from "../../system/Constants";
import { HexColorPicker } from "react-colorful";
import { CeoScheduleApi, ErrorHandler } from "../../system/ApiService";
import { useUserState } from "../../system/context/UserContext";
import AlertModal from "../Common/AlertModal";
import { useRecoilValue } from "recoil";
import { CeoAtom } from "../../system/atom";

loadCldr(NumberingSystems, gregorian, numbers, timeZoneNames, weekData);
L10n.load({
  ko: {
    datetimepicker: {
      today: "오늘",
    },
  },
});

function validation(data: CeoScheduleInfo) {
  if (data.scheduleSubject === "") {
    AlertModal("check", "제목을 작성해주세요.");
    return false;
  } else if (data.startTime === "") {
    AlertModal("check", "일정 시작 시간을 입력해주세요.");

    return false;
  } else if (data.startTime && data.isAllDay === false && data.endTime === "") {
    AlertModal("check", "일정 종료 시간을 입력해주세요.");
    return false;
  } else if (data.categoryColor === "") {
    AlertModal("check", "일정이 보여질 색상을 선택해주세요.");
    return false;
  }
  return true;
}

interface CeoScheduleEditModalProps {
  handleClose: () => void;
  detailedData: CeoScheduleInfo;
  refreshData: () => void;
  selectedDate: Date | null;
}

function CeoScheduleEditModal({
  handleClose,
  detailedData,
  refreshData,
  selectedDate,
}: CeoScheduleEditModalProps) {
  const [data, setData] = useState<CeoScheduleInfo>(
    detailedData ? detailedData : InitCeoScheduleInfo
  );

  const [colorBoxOpen, setColorBoxOpen] = useState(false);
  const [color, setColor] = useState(
    detailedData ? detailedData.categoryColor : ""
  );
  const [scheduleOwner, setScheduleOwner] = useState(
    detailedData.scheduleOwner === "이라미" ? "사장님" : "회장님"
  );
  const ceoSchedulerOwner = useRecoilValue(CeoAtom);
  // 날짜(수정날짜만 변경)
  const today = new Date();
  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth() + 1;
  const todayDate = today.getDate();
  const schedulerCreatedat =
    todayYear + "년 " + todayMonth + "월 " + todayDate + "일";
  var submitcheck = false;

  const user = useUserState();
  useEffect(() => {
    if (selectedDate) {
      setData({
        ...data,
        scheduleOwner: ceoSchedulerOwner === "회장님" ? "차기철" : "이라미",
        categoryColor: color,
        startTime: isoDateFormatter(selectedDate) + "T09:00:00",
        endTime: isoDateFormatter(selectedDate) + "T12:00:00",
      });
    } else {
      setData({
        ...data,
        scheduleOwner: ceoSchedulerOwner === "회장님" ? "차기철" : "이라미",
        categoryColor: color,
      });
    }
    setScheduleOwner(ceoSchedulerOwner);
  }, [color]);

  const onChange = (args: any) => {
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "scheduleOwner") {
      if ((event.target as HTMLInputElement).value === "사장님") {
        setScheduleOwner("사장님");
        setData({
          ...data,
          [event.target.name]: "이라미",
        });
      } else {
        setScheduleOwner("회장님");
        setData({
          ...data,
          [event.target.name]: "차기철",
        });
      }
    } else if (event.target.name === "isAllDay") {
      setData({
        ...data,
        [event.target.name]: event.target.checked,
      });
    }
  };

  const onChangeStartDate = (args: any) => {
    setData({
      ...data,
      [(args.target as HTMLInputElement).name]:
        isoDateFormatter(args.target.value) +
        " " +
        isoHourFormatter(args.target.value),
    });
  };

  const changeColor = (item: string) => {
    setData({
      ...data,
      categoryColor: item,
    });
  };

  const onChangeColorBoxOpen = () => {
    setColorBoxOpen(!colorBoxOpen);
  };

  function onDelete() {
    var result = window.confirm("해당 일정을 정말 삭제하시겠습니까?");
    if (result) {
      CeoScheduleApi.DeleteCeoSchedule(detailedData.scheduleId)
        .then(() => {
          AlertModal("success", "일정이 삭제되었습니다.");
          handleClose();
          refreshData();
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  }

  function onSave() {
    submitcheck = true;
    if (validation(data)) {
      if (data.scheduleId) {
        CeoScheduleApi.UpdateCeoSchedule({
          ...data,
          scheduleModifiedAt: schedulerCreatedat,
          teamName: user.teamName,
          scheduleWriter: user.name,
        })
          .then(() => {
            AlertModal("success", "일정이 수정되었습니다.");
            handleClose();
            refreshData();
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            AlertModal("msg", msg);
            submitcheck = false;
          });
      } else {
        CeoScheduleApi.InsertCeoSchedule({
          ...data,
          scheduleCreatedAt: schedulerCreatedat,
          scheduleModifiedAt: schedulerCreatedat,
          teamName: user.teamName,
          scheduleWriter: user.name,
        })
          .then(() => {
            AlertModal("success", "일정이 생성되었습니다.");

            handleClose();
            refreshData();
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            AlertModal("msg", msg);
            submitcheck = false;
          });
      }
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ModalTitleBox>
            {detailedData.scheduleId === 0 ? (
              <ModalTitleText variant="h5">대표이사 일정 생성</ModalTitleText>
            ) : (
              <ModalTitleText variant="h5">대표이사 일정 수정</ModalTitleText>
            )}

            <ModalTitleIcon onClick={handleClose}>
              <X size={32} />
            </ModalTitleIcon>
          </ModalTitleBox>
          <ModalContentBox>
            <CeoScheduleModalContentBox>
              <CeoScheduleModalContentTitle>
                대표이사 선택
              </CeoScheduleModalContentTitle>
              <div>
                <FormControl>
                  <RadioGroup
                    name="scheduleOwner"
                    value={scheduleOwner}
                    onChange={handleChange}
                    row
                  >
                    <FormControlLabel
                      value="회장님"
                      control={<Radio />}
                      label="회장님"
                    />
                    <FormControlLabel
                      value="사장님"
                      control={<Radio />}
                      label="사장님"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </CeoScheduleModalContentBox>
            <CeoScheduleModalContentFlexBox>
              <CeoScheduleModalContentTitle>
                일정 시작 시간
              </CeoScheduleModalContentTitle>
              <CeoScheduleModalContent>
                <DateTimePickerComponent
                  name="startTime"
                  id="datetimepicker"
                  format="yyyy-MM-dd aa hh:mm"
                  locale="ko"
                  onChange={onChangeStartDate}
                  value={new Date(data.startTime)}
                  showClearButton={false}
                />
              </CeoScheduleModalContent>
              <CeoScheduleModalContent>
                <InbodySwitch
                  name="isAllDay"
                  checked={data.isAllDay}
                  onChange={handleChange}
                />
              </CeoScheduleModalContent>
              <CeoScheduleModalLabel>하루 종일</CeoScheduleModalLabel>
            </CeoScheduleModalContentFlexBox>
            <CeoScheduleModalContentBox>
              <CeoScheduleModalContentFlexBox>
                <CeoScheduleModalContentTitle>
                  일정 종료 시간
                </CeoScheduleModalContentTitle>
                <CeoScheduleModalContent>
                  <DateTimePickerComponent
                    name="endTime"
                    id="datetimepicker"
                    format="yyyy-MM-dd aa hh:mm"
                    locale="ko"
                    onChange={onChangeStartDate}
                    value={new Date(data.endTime)}
                    min={new Date(data.startTime)}
                    showClearButton={false}
                  />
                </CeoScheduleModalContent>
              </CeoScheduleModalContentFlexBox>
            </CeoScheduleModalContentBox>
            <CeoScheduleModalContentFlexBox>
              <CeoScheduleModalContentTitle>제목</CeoScheduleModalContentTitle>
              <CeoScheduleModalInput>
                <InputBox
                  type="text"
                  name="scheduleSubject"
                  value={data.scheduleSubject}
                  onChange={onChange}
                  placeholder="제목 추가"
                />
              </CeoScheduleModalInput>
            </CeoScheduleModalContentFlexBox>
            <CeoScheduleModalContentFlexBox>
              <CeoScheduleModalContentTitle>내용</CeoScheduleModalContentTitle>
              <CeoScheduleModalInput>
                <TextareaBox
                  onChange={onChange}
                  name="scheduleContents"
                  placeholder="해당 일정에 대한 설명을 적어주세요."
                  value={data.scheduleContents}
                ></TextareaBox>
              </CeoScheduleModalInput>
            </CeoScheduleModalContentFlexBox>
            <CeoScheduleModalContentFlexBox>
              <CeoScheduleModalContentTitle>색상</CeoScheduleModalContentTitle>
              <CeoScheduleModalLabel>
                {" "}
                {data.categoryColor ? (
                  <>
                    {data.isAllDay ? (
                      <ColorExample color={data.categoryColor}>
                        일정
                      </ColorExample>
                    ) : (
                      <>
                        <ColorExample2 color={data.categoryColor}>
                          <CeoSchedulerInnerCircle color={data.categoryColor} />
                          <div>일정</div>
                        </ColorExample2>
                      </>
                    )}
                  </>
                ) : null}
              </CeoScheduleModalLabel>
            </CeoScheduleModalContentFlexBox>
            <ColorBigBox>
              {ColorPalette.map((item, index) => {
                return (
                  <ColorBox
                    onClick={() => {
                      changeColor(item.color);
                    }}
                    key={index}
                    style={{ backgroundColor: `${item.color}` }}
                  ></ColorBox>
                );
              })}
            </ColorBigBox>

            <OpenColorBoxText onClick={onChangeColorBoxOpen}>
              {colorBoxOpen ? (
                <>
                  <X size={18} weight="bold" /> 닫기
                </>
              ) : (
                <>
                  <Plus weight="bold" size={18} /> 색상 더 보기
                </>
              )}
            </OpenColorBoxText>

            <CeoScheduleModalContentFlexBox>
              {colorBoxOpen && (
                <ColorBigBox>
                  <HexColorPicker color={color} onChange={setColor} />
                </ColorBigBox>
              )}
            </CeoScheduleModalContentFlexBox>
          </ModalContentBox>
          <CeoScheduleButtonBox>
            {detailedData.scheduleId ? (
              <CustomButtonRed onClick={onDelete}>삭제</CustomButtonRed>
            ) : null}

            <CustomButtonWhite onClick={onSave}>저장</CustomButtonWhite>
          </CeoScheduleButtonBox>
        </Grid>
      </Grid>
    </>
  );
}

export default CeoScheduleEditModal;
