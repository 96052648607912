import {
  NoticeButtonBrown,
  NoticeButtonBlue,
  NoticeButtonGreen,
  NoticeButtonGrey,
  NoticeButtonMint,
  NoticeButtonOrange,
  NoticeButtonPink,
  NoticeButtonRed,
  NoticeButtonYellow,
} from "../../styles/Common/CommonComponent";

interface NoticeComponentProps {
  type: string;
}

function NoticeComponent({ type }: NoticeComponentProps) {
  return (
    <>
      {type === "공지" ? <NoticeButtonRed>공지</NoticeButtonRed> : null}
      {type === "안내" ? <NoticeButtonYellow>안내</NoticeButtonYellow> : null}
      {type === "요청" ? <NoticeButtonMint>요청</NoticeButtonMint> : null}
      {type === "인사" ? <NoticeButtonGreen>인사</NoticeButtonGreen> : null}
      {type === "주의" ? <NoticeButtonOrange>주의</NoticeButtonOrange> : null}
      {type === "교육" ? <NoticeButtonPink>교육</NoticeButtonPink> : null}
      {type === "경조사" ? <NoticeButtonGrey>경조사</NoticeButtonGrey> : null}
      {type === "우편&등기" ? (
        <NoticeButtonBlue>우편&등기</NoticeButtonBlue>
      ) : null}
      {type === "기타" ? <NoticeButtonBrown>기타</NoticeButtonBrown> : null}
    </>
  );
}

export default NoticeComponent;
