import Swal from "sweetalert2";
import { GREENCONTENTS, REDTITLE } from "../../styles/Color";

function AlertModal(type: string, message?: string, content?: string) {
  return (
    <>
      {type === "예약완료" &&
        Swal.fire({
          title: "예약이 완료됐습니다.",
          icon: "success",
          confirmButtonText: "확인",

          confirmButtonColor: GREENCONTENTS,
        })}
      {type === "success" &&
        Swal.fire({
          title: message,
          icon: "success",
          confirmButtonText: "확인",

          confirmButtonColor: GREENCONTENTS,
        })}
      {type === "msg" &&
        Swal.fire({
          title: message,
          icon: "error",
          confirmButtonText: "확인",
          confirmButtonColor: GREENCONTENTS,
        })}
      {type === "err" &&
        Swal.fire({
          title: message,
          icon: "error",
          text: content,
          confirmButtonText: "확인",
          confirmButtonColor: GREENCONTENTS,
        })}
      {type === "check" &&
        Swal.fire({
          title: message,
          icon: "warning",
          confirmButtonText: "확인",
          confirmButtonColor: GREENCONTENTS,
        })}
      {type === "question" &&
        Swal.fire({
          title: message,
          icon: "question",
          confirmButtonText: "예",
          cancelButtonText: "아니오",
          showCancelButton: true,
          confirmButtonColor: GREENCONTENTS,
          cancelButtonColor: REDTITLE,
        })}
      {type === "memo" &&
        Swal.fire({
          title: message,
          text: content,
          icon: "success",
          confirmButtonText: "확인",
          confirmButtonColor: GREENCONTENTS,
        })}
    </>
  );
}

export default AlertModal;
