import React, { useEffect, useState } from "react";
import NoticeEditBoard from "../../components/Notice/NoticeEditBoard";
import TitleBox from "../../components/Common/TitleBox";
import { enableRipple } from "@syncfusion/ej2-base";
import {
  DropDownButtonComponent,
  ItemModel,
} from "@syncfusion/ej2-react-splitbuttons";

function NoticeEdit() {
  return (
    <div>
      <TitleBox title="전사 공지" />
      <NoticeEditBoard />
    </div>
  );
}

export default NoticeEdit;
