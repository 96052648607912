import { useState } from "react";
import TitleBox from "../../components/Common/TitleBox";
import { useUserState } from "../../system/context/UserContext";
import Yeonsuwon from "../../components/admin/Yeonsuwon";
import { MainBox } from "../../styles/theme";

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function YoensuwonAdmin() {
  const user = useUserState();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  // css
  const regionBlock = {
    margin: "10px 10px",
    padding: "10px 10px",
  };

  return (
    <>
      <div>
        <TitleBox title="연수원 예약 - 관리자 페이지" />
        <MainBox>
          {" "}
          <Yeonsuwon />
        </MainBox>
      </div>
    </>
  );
}

export default YoensuwonAdmin;
