import React, { useCallback, useEffect, useState } from "react";
import { Notice, DepartmentNotice } from "../../../system/types/interface";
import {
  NoticeApi,
  DepartmentNoticeApi,
  ErrorHandler,
} from "../../../system/ApiService";
import { Link, useHistory } from "react-router-dom";
import "../../../../src/styles/Components/Portlet/BoardPortlet.css";
import Typography from "@mui/material/Typography";
import { useUserState } from "../../../system/context/UserContext";
import NoticeComponent from "../../Common/NoticeComponent";
import {
  DeptPortletTitle,
  DeptWriter,
  DisabledNotice,
  EmptyBox,
  EmptyNotice,
  NoticeContent,
  NoticeDate,
  NoticeSelectTitle,
  NoticeWriter,
  PortletLayout,
  PortletMainTitle,
  PortletTitle,
  PortletType,
  SelectedNotice,
} from "../../../styles/Components/Main/MainPortal";
import AlertModal from "../../Common/AlertModal";

interface LongNoticePortletProps {
  noticeClass: boolean;
  setNoticeClass: (status: boolean) => void;
}

function LongNoticePortlet({
  noticeClass,
  setNoticeClass,
}: LongNoticePortletProps) {
  const [data, setData] = useState<Notice[]>([]);
  const [departmentData, setDepartmentData] = useState<DepartmentNotice[]>([]);
  let history = useHistory();
  const user = useUserState();

  useEffect(() => {
    let isComponentMoutned = true;

    NoticeApi.GetNoticeTotalList()
      .then((res) => {
        if (res.data) {
          if (isComponentMoutned) {
            setData(res.data.slice(0, 12));
          }
        }
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
    return () => {
      isComponentMoutned = false;
    };
  }, []);

  useEffect(() => {
    let isComponentMoutned = true;
    DepartmentNoticeApi.GetDepartmentNoticeTotalList(user.parentTeam)
      .then((res) => {
        if (isComponentMoutned) {
          setDepartmentData(res.data.slice(0, 12));
        }
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
    return () => {
      isComponentMoutned = false;
    };
  }, []);

  function toNotice() {
    setNoticeClass(true);
  }

  function toDepartmentNotice() {
    setNoticeClass(false);
  }

  return (
    <>
      {noticeClass === true ? (
        <PortletLayout>
          <PortletMainTitle>
            <NoticeSelectTitle>
              <SelectedNotice>전사</SelectedNotice>
              {user.parentTeam === "공통파트" || !user.parentTeam ? (
                ""
              ) : (
                <DisabledNotice onClick={toDepartmentNotice}>
                  {user.parentTeam}
                </DisabledNotice>
              )}
            </NoticeSelectTitle>
          </PortletMainTitle>
          {data
            ? data.map((board: any) => {
                return (
                  <NoticeContent key={board.noticeId} id={board.noticeId}>
                    <PortletType>
                      <NoticeComponent type={board.type} />{" "}
                    </PortletType>
                    <PortletTitle
                      onClick={() => {
                        history.push({
                          pathname: `/notice/detail/${board.noticeId}`,
                          state: { displays: board.noticeId },
                        });
                      }}
                    >
                      {board.noticeTitle}
                    </PortletTitle>
                    <NoticeWriter>{board.noticeWriter}</NoticeWriter>
                    {board.noticeCreatedAt.split(" ")[1].split("월")[0]
                      .length === 1 &&
                    board.noticeCreatedAt.split(" ")[2].split("일")[0]
                      .length === 2 ? (
                      <NoticeDate>
                        {board.noticeCreatedAt.split("년")[0] +
                          ".0" +
                          board.noticeCreatedAt.split(" ")[1].split("월")[0] +
                          "." +
                          board.noticeCreatedAt.split(" ")[2].split("일")[0]}
                      </NoticeDate>
                    ) : (
                      ""
                    )}
                    {board.noticeCreatedAt.split(" ")[1].split("월")[0]
                      .length === 2 &&
                    board.noticeCreatedAt.split(" ")[2].split("일")[0]
                      .length === 1 ? (
                      <NoticeDate>
                        {board.noticeCreatedAt.split("년")[0] +
                          "." +
                          board.noticeCreatedAt.split(" ")[1].split("월")[0] +
                          ".0" +
                          board.noticeCreatedAt.split(" ")[2].split("일")[0]}
                      </NoticeDate>
                    ) : (
                      ""
                    )}
                    {board.noticeCreatedAt.split(" ")[2].split("일")[0]
                      .length === 1 &&
                    board.noticeCreatedAt.split(" ")[1].split("월")[0]
                      .length === 1 ? (
                      <NoticeDate>
                        {board.noticeCreatedAt.split("년")[0] +
                          ".0" +
                          board.noticeCreatedAt.split(" ")[1].split("월")[0] +
                          ".0" +
                          board.noticeCreatedAt.split(" ")[2].split("일")[0]}
                      </NoticeDate>
                    ) : (
                      ""
                    )}
                    {board.noticeCreatedAt.split(" ")[2].split("일")[0]
                      .length === 2 &&
                    board.noticeCreatedAt.split(" ")[1].split("월")[0]
                      .length === 2 ? (
                      <NoticeDate>
                        {board.noticeCreatedAt.split("년")[0] +
                          "." +
                          board.noticeCreatedAt.split(" ")[1].split("월")[0] +
                          "." +
                          board.noticeCreatedAt.split(" ")[2].split("일")[0]}
                      </NoticeDate>
                    ) : (
                      ""
                    )}
                  </NoticeContent>
                );
              })
            : ""}
          {data.length > 0 ? (
            ""
          ) : (
            <EmptyBox>
              <EmptyNotice>작성된 공지사항이 없습니다.</EmptyNotice>
            </EmptyBox>
          )}
        </PortletLayout>
      ) : (
        <PortletLayout>
          <PortletMainTitle>
            <NoticeSelectTitle>
              <DisabledNotice onClick={toNotice}>전사</DisabledNotice>
              <SelectedNotice>{user.parentTeam}</SelectedNotice>
            </NoticeSelectTitle>
          </PortletMainTitle>

          {departmentData
            ? departmentData.map((board: any) => {
                return (
                  <NoticeContent
                    key={board.departmentNoticeId}
                    id={board.departmentNoticeId}
                  >
                    <PortletType>
                      <NoticeComponent type={board.type} />{" "}
                    </PortletType>
                    <DeptPortletTitle
                      onClick={() => {
                        history.push({
                          pathname: `/departmentnotice/detail/${board.departmentNoticeId}`,
                          state: { displays: board.departmentNoticeId },
                        });
                      }}
                    >
                      {board.departmentNoticeTitle}
                    </DeptPortletTitle>
                    <DeptWriter>
                      {board.departmentNoticeWriter.split(" ")[1]}
                    </DeptWriter>

                    {board.departmentNoticeCreatedAt
                      .split(" ")[1]
                      .split("월")[0].length === 1 &&
                    board.departmentNoticeCreatedAt.split(" ")[2].split("일")[0]
                      .length === 2 ? (
                      <NoticeDate>
                        {board.departmentNoticeCreatedAt.split("년")[0] +
                          ".0" +
                          board.departmentNoticeCreatedAt
                            .split(" ")[1]
                            .split("월")[0] +
                          "." +
                          board.departmentNoticeCreatedAt
                            .split(" ")[2]
                            .split("일")[0]}
                      </NoticeDate>
                    ) : (
                      ""
                    )}
                    {board.departmentNoticeCreatedAt
                      .split(" ")[1]
                      .split("월")[0].length === 2 &&
                    board.departmentNoticeCreatedAt.split(" ")[2].split("일")[0]
                      .length === 1 ? (
                      <NoticeDate>
                        {board.departmentNoticeCreatedAt.split("년")[0] +
                          "." +
                          board.departmentNoticeCreatedAt
                            .split(" ")[1]
                            .split("월")[0] +
                          ".0" +
                          board.departmentNoticeCreatedAt
                            .split(" ")[2]
                            .split("일")[0]}
                      </NoticeDate>
                    ) : (
                      ""
                    )}
                    {board.departmentNoticeCreatedAt
                      .split(" ")[2]
                      .split("일")[0].length === 1 &&
                    board.departmentNoticeCreatedAt.split(" ")[1].split("월")[0]
                      .length === 1 ? (
                      <NoticeDate>
                        {board.departmentNoticeCreatedAt.split("년")[0] +
                          ".0" +
                          board.departmentNoticeCreatedAt
                            .split(" ")[1]
                            .split("월")[0] +
                          ".0" +
                          board.departmentNoticeCreatedAt
                            .split(" ")[2]
                            .split("일")[0]}
                      </NoticeDate>
                    ) : (
                      ""
                    )}
                    {board.departmentNoticeCreatedAt
                      .split(" ")[2]
                      .split("일")[0].length === 2 &&
                    board.departmentNoticeCreatedAt.split(" ")[1].split("월")[0]
                      .length === 2 ? (
                      <NoticeDate>
                        {board.departmentNoticeCreatedAt.split("년")[0] +
                          "." +
                          board.departmentNoticeCreatedAt
                            .split(" ")[1]
                            .split("월")[0] +
                          "." +
                          board.departmentNoticeCreatedAt
                            .split(" ")[2]
                            .split("일")[0]}
                      </NoticeDate>
                    ) : (
                      ""
                    )}
                  </NoticeContent>
                );
              })
            : ""}
          {departmentData.length > 0 ? (
            ""
          ) : (
            <EmptyBox>
              <EmptyNotice>작성된 공지사항이 없습니다.</EmptyNotice>
            </EmptyBox>
          )}
        </PortletLayout>
      )}
    </>
  );
}

export default LongNoticePortlet;
