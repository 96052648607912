export const ColorPalette = [
  { id: 1, color: "#f44336" },
  { id: 2, color: "#e91e63" },
  { id: 3, color: "#9c27b0" },
  { id: 4, color: "#673ab7" },
  { id: 5, color: "#3f51b5" },
  { id: 6, color: "#2196f3" },
  { id: 7, color: "#03a9f4" },
  { id: 8, color: "#00bcd4" },
  { id: 9, color: "#009688" },
  { id: 10, color: "#4caf50" },
  { id: 11, color: "#8bc34a" },
  { id: 12, color: "#cddc39" },
  { id: 13, color: "#ffc107" },
  { id: 14, color: "#ff9800" },
  { id: 15, color: "#ff5722" },
  { id: 16, color: "#795548" },
  { id: 17, color: "#9e9e9e" },
  { id: 18, color: "#607d8b" },
];
