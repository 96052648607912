import { AxiosError } from "axios";
import HttpClient from "./http-client";
import {
  Notice,
  PortletLocation,
  Qna,
  PortletSetting,
  QnaComment,
  NoticeAttachment,
  QnaAttachment,
  FileUpload,
  DepartmentNotice,
  WeatherSite,
  MenuList,
  MenuData,
  BreakfastRanking,
} from "./types/interface";
import { CeoScheduleInfo } from "./types/ceoschedule";

//const url = `https://weareinbody.com/api`;
const url = `${window.location.origin}/api`;
const client = new HttpClient(url);

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

// Api Class

class _UserApi extends _BasicApi {
  Login(userId: string, userPassword: string) {
    return this.client.post("login", {
      Id: userId,
      Password: userPassword,
    });
  }
  Logout() {
    client.disableToken();
    return this.client.get("login/logout");
  }
  SetToken(token: string) {
    client.setToken(token);
  }
  InitUserInfo() {
    return this.client.post("login/renewal");
  }

  SSOLogin(token: string | null, gwMail: string | undefined) {
    console.log("ssologin function : " + gwMail);
    if (!token) {
      console.log("no token");
      throw new Error("No token for sso-login");
    }
    this.SetToken(token);
    const form = new FormData();
    form.append("gwMail", gwMail || "");
    //  return this.client.post("login/ssologin", form);
    //임시 SSOlogin 호출 URL
    return new HttpClient("https://insamansa2.weareinbody.com/api").post(
      `login/ssologin/v2`,
      form
    );
    // return new HttpClient("https://insamansa2.weareinbody.com/api/login/ssologin").post(`login/ssologin`, form);
  }
}

class _EmployeeApi {
  GetEmployeeList() {
    return new HttpClient(
      "https://insamansa2.weareinbody.com/api/hremployee/list"
    ).get("");
  }
}

class _NoticeApi extends _BasicApi {
  GetNotice(id: number) {
    return this.client.get(`/notice/${id}`);
  }
  GetNoticeList(pageNo: number, pageSize: number) {
    return this.client.get(`/notice/${pageNo}/${pageSize}`);
  }
  GetMypageNoticeList(pageNo: number, pageSize: number, EmployeeId: string) {
    return this.client.get(
      `/notice/mypage/${EmployeeId}/${pageNo}/${pageSize}`
    );
  }
  GetNoticeTotalList() {
    return this.client.get(`/notice`);
  }
  InsertNotice(notice: Notice) {
    return this.client.post(`/notice`, notice);
  }
  UpdateNotice(notice: Notice) {
    return this.client.put(`/notice`, notice);
  }
  DeleteNotice(id: number) {
    return this.client.delete(`/notice/${id}`);
  }
}

class _DepartmentNoticeApi extends _BasicApi {
  GetDepartmentNotice(id: number) {
    return this.client.get(`/departmentnotice/${id}`);
  }
  GetDepartmentNoticeList(pageNo: number, pageSize: number, TeamName: string) {
    return this.client.get(
      `/departmentnotice/${TeamName}/${pageNo}/${pageSize}`
    );
  }
  GetMypageDeptNoticeList(
    pageNo: number,
    pageSize: number,
    EmployeeId: string,
    TeamName: string
  ) {
    return this.client.get(
      `/departmentnotice/mypage/${TeamName}/${EmployeeId}/${pageNo}/${pageSize}`
    );
  }
  GetDepartmentNoticeTotalList(TeamName: string) {
    return this.client.get(`/departmentnotice/TeamName/${TeamName}`);
  }
  InsertDepartmentNotice(departmentnotice: DepartmentNotice) {
    return this.client.post(`/departmentnotice`, departmentnotice);
  }
  UpdateDepartmentNotice(departmentnotice: DepartmentNotice) {
    return this.client.put(`/departmentnotice`, departmentnotice);
  }
  DeleteDepartmentNotice(id: number) {
    return this.client.delete(`/departmentnotice/${id}`);
  }
}
class _QnaApi extends _BasicApi {
  GetQna(id: number) {
    return this.client.get(`/qna/${id}`);
  }
  GetQnaList(pageNo: number, pageSize: number) {
    return this.client.get(`/qna/${pageNo}/${pageSize}`);
  }
  GetMypageQnaList(pageNo: number, pageSize: number, EmployeeId: string) {
    return this.client.get(`/qna/mypage/${EmployeeId}/${pageNo}/${pageSize}`);
  }
  GetQnaTotalList() {
    return this.client.get(`/qna/`);
  }
  InsertQna(qna: Qna) {
    return this.client.post(`/qna`, qna);
  }
  UpdateQna(qna: Qna) {
    return this.client.put(`/qna`, qna);
  }
  DeleteQna(id: number) {
    return this.client.delete(`/qna/${id}`);
  }
}

class _SiteApi extends _BasicApi {
  GetSite(id: number) {
    return this.client.get(`/addsite/${id}`);
  }
  GetSiteList(EmployeeId: string) {
    return this.client.get(`/addsite/site/${EmployeeId}`);
  }
  GetTotalSiteList() {
    return this.client.get(`/addsite/`);
  }
  InsertSite(qna: Qna) {
    return this.client.post(`/addsite`, qna);
  }
  UpdateSite(qna: Qna) {
    return this.client.put(`/addsite`, qna);
  }
  DeleteSite(id: number) {
    return this.client.delete(`/addsite/${id}`);
  }
}

class _QnaCommentApi extends _BasicApi {
  GetQnaCommentList(QnaId: number) {
    return this.client.get(`/qnacomment/comments/${QnaId}`);
  }
  GetQnaComment(id: number) {
    return this.client.get(`/qnacomment/${id}`);
  }
  InsertQnaComment(qnacomment: QnaComment) {
    return this.client.post(`/qnacomment`, qnacomment);
  }
  UpdateQnaComment(qnacomment: QnaComment) {
    return this.client.put(`/qnacomment`, qnacomment);
  }
  DeleteQnaComment(id: number) {
    return this.client.delete(`/qnacomment/${id}`);
  }
}

class _PortletLocationApi extends _BasicApi {
  GetPortletLocation(id: string) {
    return this.client.get(`/PortletLocation/${id}`);
  }
  InsertPortletLocation(portletlocation: PortletLocation) {
    return this.client.post(`/PortletLocation`, portletlocation);
  }
  UpdatePortletLocation(portletlocation: PortletLocation) {
    return this.client.put(`/PortletLocation`, portletlocation);
  }
}

class _PortletSettingApi extends _BasicApi {
  GetPortletSetting(id: string) {
    return this.client.get(`/PortletSetting/${id}`);
  }
  InsertPortletSetting(portletsetting: PortletSetting) {
    return this.client.post(`/PortletSetting`, portletsetting);
  }
  UpdatePortletSetting(portletsetting: PortletSetting) {
    return this.client.put(`/PortletSetting`, portletsetting);
  }
}

class _WeatherSiteApi extends _BasicApi {
  GetWeatherSite(id: string) {
    return this.client.get(`/WeatherSite/${id}`);
  }
  InsertWeatherSite(weathersite: WeatherSite) {
    return this.client.post(`/WeatherSite`, weathersite);
  }
  UpdateWeatherSite(weathersite: WeatherSite) {
    return this.client.put(`/WeatherSite`, weathersite);
  }
}

class _NoticeAttachmentApi extends _BasicApi {
  GetNoticeAttachmentList(FileuploadFolder: string) {
    return this.client.get(`/NoticeAttachment/Notice/${FileuploadFolder}`);
  }
  GetNoticeAttachment(id: number) {
    return this.client.get(`/NoticeAttachment/${id}`);
  }
  InsertNoticeAttachment(noticeattachment: NoticeAttachment) {
    return this.client.post(`/NoticeAttachment`, noticeattachment);
  }
  DeleteNoticeAttachment(id: number) {
    return this.client.delete(`/NoticeAttachment/${id}`);
  }
}

class _QnaAttachmentApi extends _BasicApi {
  GetQnaAttachmentList(FileuploadFolder: string) {
    return this.client.get(`/QnaAttachment/Qna/${FileuploadFolder}`);
  }
  GetQnaAttachment(id: number) {
    return this.client.get(`/QnaAttachment/${id}`);
  }
  InsertQnaAttachment(qnaattachment: QnaAttachment) {
    return this.client.post(`/QnaAttachment`, qnaattachment);
  }
  DeleteQnaAttachment(id: number) {
    return this.client.delete(`/QnaAttachment/${id}`);
  }
}

class _FileUploadApi extends _BasicApi {
  InsertFileUpload(
    fileupload: FileUpload,
    BoardType: string,
    FileuploadFolder: string
  ) {
    return this.client.post(
      `/FileUpload/UploadFilesToStorage/${BoardType}/${FileuploadFolder}`,
      fileupload
    );
  }
}

class _FileDownloadApi extends _BasicApi {
  GetFileDownload(
    BoardType: string,
    FileuploadFolder: string,
    fileName: string
  ) {
    return this.client.get(
      `/FileDownload/DownloadFile/${BoardType}/${FileuploadFolder}/${fileName}`
    );
  }
}

class _FileDeleteApi extends _BasicApi {
  DeleteFile(BoardType: string, FileuploadFolder: string, fileName: string) {
    return this.client.delete(
      `/FileDelete/DeleteFile/${BoardType}/${FileuploadFolder}/${fileName}`
    );
  }
}

class _MenuApi extends _BasicApi {
  GetTotalMenuList() {
    return this.client.get(`/MenuList/`);
  }
  GetMenuList(menudate: number) {
    return this.client.get(`/MenuList/${menudate}`);
  }
  InsertMenu(menulist: MenuList) {
    return this.client.post(`/MenuList`, menulist);
  }
  UpdateMenu(menulist: MenuList) {
    return this.client.put(`/MenuList`, menulist);
  }
}

class _CheonanMenuApi extends _BasicApi {
  GetTotalCheonanMenu() {
    return this.client.get(`/CheonanMenu/`);
  }
  InsertCheonanMenuJson(file: FormData) {
    return this.client.post(`/CheonanMenu/`, file);
  }
  GetCheonanMenu(menudate: number) {
    return this.client.get(`/CheonanMenu/${menudate}`);
  }
  InsertCheonanMenu(menuData: MenuData) {
    return this.client.post(`/CheonanMenu/Cheonandata`, menuData);
  }
  UpdateCheonanMenu(menuData: MenuData) {
    return this.client.put(`/CheonanMenu/Cheonandata`, menuData);
  }
}

class _BreakfastRatingApi extends _BasicApi {
  GetTotalBreakfastRating(menudate: number) {
    return this.client.get(`/BreakfastRating/${menudate}`);
  }
  GetBreakfastRating(ratingId: string) {
    return this.client.get(`/BreakfastRating/${ratingId}`);
  }
  GetMypageAllBreakfastRating(EmployeeId: string) {
    return this.client.get(
      `/BreakfastRating/mypage/BreakfastReview/${EmployeeId}`
    );
  }
  GetMypageBreakfastRating(
    pageNo: number,
    pageSize: number,
    EmployeeId: string
  ) {
    return this.client.get(
      `/BreakfastRating/mypage/${EmployeeId}/${pageNo}/${pageSize}`
    );
  }
  GetReviewPageBreakfastRating(
    pageNo: number,
    pageSize: number,
    MenuDate: number
  ) {
    return this.client.get(
      `/BreakfastRating/review/${MenuDate}/${pageNo}/${pageSize}`
    );
  }
  InsertBreakfastRating(ratingData: BreakfastRanking) {
    return this.client.post(`/BreakfastRating`, ratingData);
  }
  UpdateBreakfast(ratingData: BreakfastRanking) {
    return this.client.put(`/BreakfastRating`, ratingData);
  }
  DeleteBreakfast(ratingId: number) {
    return this.client.delete(`/BreakfastRating/${ratingId}`);
  }
}

class _CeoScheduleApi extends _BasicApi {
  GetCeoSchedule(id: number) {
    return this.client.get(`/CEOSchedule/${id}`);
  }
  GetCeoScheduleList() {
    return this.client.get(`/CEOSchedule`);
  }
  InsertCeoSchedule(ceoscheduledata: CeoScheduleInfo) {
    return this.client.post(`/CEOSchedule`, ceoscheduledata);
  }
  UpdateCeoSchedule(ceoscheduledata: CeoScheduleInfo) {
    return this.client.put(`/CEOSchedule`, ceoscheduledata);
  }
  DeleteCeoSchedule(id: number) {
    return this.client.delete(`/CEOSchedule/${id}`);
  }
}

// Api class Export
export const UserApi = new _UserApi(client);
export const NoticeApi = new _NoticeApi(client);
export const DepartmentNoticeApi = new _DepartmentNoticeApi(client);
export const QnaApi = new _QnaApi(client);
export const EmployeeApi = new _EmployeeApi();
export const PortletLocationApi = new _PortletLocationApi(client);
export const PortletSettingApi = new _PortletSettingApi(client);
export const QnaCommentApi = new _QnaCommentApi(client);
export const SiteApi = new _SiteApi(client);
export const NoticeAttachmentApi = new _NoticeAttachmentApi(client);
export const QnaAttachmentApi = new _QnaAttachmentApi(client);
export const FileUploadApi = new _FileUploadApi(client);
export const FileDownloadApi = new _FileDownloadApi(client);
export const FileDeleteApi = new _FileDeleteApi(client);
export const WeatherSiteApi = new _WeatherSiteApi(client);
export const MenuApi = new _MenuApi(client);
export const CheonanMenuApi = new _CheonanMenuApi(client);
export const BreakfastRatingApi = new _BreakfastRatingApi(client);
export const CeoScheduleApi = new _CeoScheduleApi(client);

function isErrorWithMessage(error: unknown): string {
  if (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  ) {
    return (error as Record<string, unknown>).message as string;
  } else {
    return "";
  }
}

// Error Handler
export function ErrorHandler(error: AxiosError): string {
  let msg = "";
  if (error.response) {
    msg = isErrorWithMessage(error.response.data);
    if (msg === "" || msg === "[object Object]") {
      //여전히 빈 상태라면...
      switch (error.response.status) {
        case 401:
          msg = "세션이 만료되었습니다. 다시 로그인해주세요.";
          break;
        case 400: //Bad Request
          msg = "데이터 입력이 잘못되었습니다.";
          break;
        case 403: //Forbidden
          msg = "접근할 수 없습니다.";
          break;
        case 404: //NotFound
          msg = "표시할 데이터가 없습니다.";
          break;
        case 405: //method not allowd
          msg = "405: 접근할 수 없습니다.";
          break;
        case 500:
          msg = "서버 에러가 발생하였습니다.";
          break;
        default:
          msg = "알 수 없는 오류입니다. 관리자에게 문의해주세요";
          break;
      }
    }
  } else {
    msg += "서버에서 데이터를 가져올 수 없습니다.";
  }
  return msg;
}
