import React, { ReactNode } from "react";
import { useMediaQuery } from "react-responsive";

interface ContentProps {
  children: ReactNode;
}

export const Mobile = ({ children }: ContentProps) => {
  const isMobile = useMediaQuery({
    query: "(max-width:1200px)",
  });
  return <>{isMobile && children}</>;
};

export const Pc = ({ children }: ContentProps) => {
  const isPc = useMediaQuery({
    query: "(min-width:1201px)",
  });
  return <>{isPc && children}</>;
};

export const HeaderSearchComponent = ({ children }: any) => {
  const isSearchComponent = useMediaQuery({
    query: "(min-width:651px)",
  });
  return <>{isSearchComponent && children}</>;
};

export const BodySearchComponent = ({ children }: any) => {
  const isSearchComponent = useMediaQuery({
    query: "(max-width:650px)",
  });
  return <>{isSearchComponent && children}</>;
};
