import { atom } from "recoil";
export const CeoAtom = atom({
  key: "CeoAtom",
  default: "회장님",
});

export const EmployeeSearchModalAtom = atom({
  key: "EmployeeSearchModalAtom",
  default: false,
});

export const EmployeeListDataAtom = atom({
  key: "EmployeeListDataAtom",
  default: [],
});
