import React, { useEffect, useState } from "react";
import "../../../src/styles/Components/Write/WriteBoard.css";
import {
  DepartmentNoticeApi,
  NoticeAttachmentApi,
  ErrorHandler,
} from "../../system/ApiService";
import { Link, useHistory } from "react-router-dom";
import { useUserState } from "../../system/context/UserContext";
import axios from "axios";

// syncfusion
import { enableRipple } from "@syncfusion/ej2-base";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";

// material ui
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  CustomButtonBlackSpace,
  CustomButtonBlue,
  CustomButtonGreen,
  CustomButtonGrey,
  CustomButtonRed,
} from "../../styles/Common/CommonComponent";
import NoticeComponent from "../Common/NoticeComponent";
import {
  NoticeDetailTitle,
  NoticeDetailTitleBox,
  NoticeDetailTitleContent,
  NoticeDetailTitleType,
} from "../../styles/Components/Notice/NoticeStyle";
import AlertModal from "../Common/AlertModal";
import { Grid } from "@mui/material";
import { CustomText } from "../../styles/CustomText";
import HtmlContent from "../Common/HtmlContext";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function DepartmentNoticeDetailBoard(props: any) {
  let history = useHistory();
  const id = props.data.departmentNoticeId;
  const user = useUserState();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    return () => setLoading(false);
  }, []);

  // 버튼
  enableRipple(true);

  //// 글 삭제
  const NoticeDelete = () => {
    if (window.confirm("공지사항을 삭제하시겠습니까?")) {
      DepartmentNoticeApi.DeleteDepartmentNotice(props.data.departmentNoticeId)
        .then(() => {
          AlertModal("success", "공지사항이 삭제되었습니다.");
          history.push("/departmentnotice/board/1");
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  };
  const [uploadData, setUploadData] = useState([]);
  // 첨부 파일 정보
  useEffect(() => {
    if (props.data.fileuploadFolder) {
      NoticeAttachmentApi.GetNoticeAttachmentList(props.data.fileuploadFolder)
        .then((res) => {
          setUploadData(res.data);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  }, [props]);

  function downloadfile(item: any) {
    window.location.assign(
      `https://inbodyinfra.blob.core.windows.net/inbodyportal/Notice/${item.fileuploadFolder}/${item.noticeAttachmentFileName}`
    );
  }

  // 메일 보내기
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const sendNotice = () => {
    if (mailsendList.length === 0) {
      AlertModal("check", "메일 받을 대상을 선택해주세요.");

      return;
    }

    let sendDepartment = [{ emailAddress: { address: `${mailsendList[0]}` } }];

    for (let i = 1; i < mailsendList.length; i++) {
      sendDepartment.push({ emailAddress: { address: `${mailsendList[i]}` } });
    }
    let token = sessionStorage.getItem("token") || "";

    fetch("https://graph.microsoft.com/v1.0/me/sendMail", {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        message: {
          subject: props.data.departmentNoticeTitle,
          body: {
            contentType: "HTML",
            content: props.data.departmentNoticeContent,
          },
          toRecipients: sendDepartment,
        },
      }),
    }).then(() => setOpen(false));
  };

  // multiSelect
  const [multiSelectData, setMultiSelectData]: any = useState([]);
  const fields = { value: "email", text: "name" };
  const [mailsendList, setMailSendList] = useState([]);

  useEffect(() => {
    axios
      .get("https://360degree.weareinbody.com/api/employee/email")
      .then((res) => {
        setMultiSelectData(res.data);
      });
  }, []);

  function itemTemplate(data: any): JSX.Element {
    return (
      <span>
        <span>{data.name}</span>
        <span style={{ right: "15px", position: "absolute" }}>
          {data.email}
        </span>
      </span>
    );
  }

  function multiselctemployee(args: any) {
    setMailSendList(args.nativeEvent.text);
  }

  return (
    <>
      {props.data ? (
        <div
          className={
            user.parentTeam === props.data.teamName ? "boardTeam" : "board"
          }
        >
          <div className="board_write">
            <div className="title">
              <Grid container alignItems="center" mb="10px">
                <Grid item mr="45px">
                  <CustomText type="title">제목</CustomText>
                </Grid>
                <Grid item mr="10px">
                  <NoticeComponent type={props.data.type} />
                </Grid>
                <Grid>
                  <CustomText type="subtitle2">
                    {props.data.departmentNoticeTitle}
                  </CustomText>
                </Grid>
              </Grid>
            </div>
            <div className="notice_info">
              <Grid container alignItems="center" mb="10px">
                <Grid container item xs={12} sm={6} alignItems="center">
                  <Grid item mr="25px">
                    <CustomText type="title">작성자</CustomText>
                  </Grid>
                  <Grid item>
                    <CustomText type="subtitle2">
                      {props.data.departmentNoticeWriter}
                    </CustomText>
                  </Grid>
                </Grid>

                {props.data.departmentNoticeCreatedAt ===
                props.data.departmentNoticeModifiedAt ? (
                  <Grid container item xs={12} sm={6} alignItems="center">
                    <Grid item mr="25px">
                      <CustomText type="title">작성일</CustomText>
                    </Grid>
                    <Grid item>
                      <CustomText type="subtitle2">
                        {props.data.departmentNoticeCreatedAt}
                      </CustomText>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container item xs={12} sm={6} alignItems="center">
                    <Grid item mr="25px">
                      <CustomText type="title">수정일</CustomText>
                    </Grid>
                    <Grid>
                      <CustomText type="subtitle2">
                        {props.data.departmentNoticeModifiedAt}
                      </CustomText>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </div>
            <div className="cont">
              <Grid container alignItems="center" mb="15px">
                <Grid item xs={12}>
                  <CustomText type="title">내용</CustomText>
                </Grid>
                <Grid item xs={12} mt="15px">
                  <HtmlContent
                    data={
                      props.data.departmentNoticeContent
                        ? props.data.departmentNoticeContent
                        : "작성된 내용이 없습니다."
                    }
                  ></HtmlContent>
                </Grid>
              </Grid>
            </div>
            <div className="info">
              <div className="fileuploadbox">첨부파일</div>
              <div className="filebox">
                {uploadData.map((item: any) => (
                  <li key={`${item.noticeAttachmentFileName}`}>
                    {item.noticeAttachmentFileName}
                    <ButtonComponent
                      iconCss="e-icons e-download"
                      className="fileuploadButton"
                      onClick={() => downloadfile(item)}
                    >
                      다운로드
                    </ButtonComponent>
                  </li>
                ))}
              </div>
            </div>
          </div>

          {user.parentTeam === props.data.teamName ? (
            <div className="bt_wrap">
              <span>
                <CustomButtonBlue
                  onClick={handleOpen}
                  style={{ marginRight: "10px" }}
                >
                  메일 보내기
                </CustomButtonBlue>
              </span>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableScrollLock
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    style={{ marginBottom: "30px" }}
                  >
                    {user.parentTeam} 공지 메일 발송
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    style={{ marginBottom: "10px" }}
                  >
                    메일 받는 사람을 선택해주세요.
                  </Typography>

                  <MultiSelectComponent
                    id="sendemail"
                    dataSource={multiSelectData}
                    fields={fields}
                    mode="Box"
                    placeholder="ex. 본사_2022    "
                    itemTemplate={itemTemplate}
                    onChange={multiselctemployee}
                  />

                  <div style={{ marginTop: "30px", textAlign: "right" }}>
                    <CustomButtonGrey onClick={handleClose}>
                      취소
                    </CustomButtonGrey>
                    <CustomButtonBlackSpace onClick={sendNotice}>
                      메일 보내기
                    </CustomButtonBlackSpace>
                  </div>
                </Box>
              </Modal>

              <span>
                <CustomButtonGreen
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    history.push({
                      pathname: `/departmentnotice/edit/${id}`,
                      state: id,
                    });
                  }}
                >
                  수정
                </CustomButtonGreen>
              </span>
              <span>
                <CustomButtonRed onClick={NoticeDelete}>삭제</CustomButtonRed>
              </span>
              <span>
                <Link
                  to="/departmentnotice/board/1"
                  style={{ marginLeft: "10px" }}
                >
                  <CustomButtonGrey>목록</CustomButtonGrey>
                </Link>
              </span>
            </div>
          ) : (
            <div className="bt_wrap">
              <span>
                <Link
                  to="/departmentnotice/board/1"
                  style={{ marginLeft: "10px" }}
                >
                  <CustomButtonGrey iconCss="e-icons e-menu">
                    목록
                  </CustomButtonGrey>
                </Link>
              </span>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default DepartmentNoticeDetailBoard;
