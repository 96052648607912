import React, { useRef, useEffect, useState, createRef } from "react";
import "../../../src/styles/Components/Write/WriteBoard.css";
import { enableRipple } from "@syncfusion/ej2-base";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { Qna } from "../../system/types/interface";
import {
  QnaApi,
  ErrorHandler,
  QnaAttachmentApi,
  FileUploadApi,
  FileDeleteApi,
} from "../../system/ApiService";
import { useHistory, useLocation } from "react-router-dom";
import { useUserState } from "../../system/context/UserContext";

import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  CustomButtonBlackSpace,
  CustomButtonGrey,
} from "../../styles/Common/CommonComponent";
import { InitQna } from "../../system/types/initObject";
import CrossEditor from "../Common/Editor/CrossEditor";
import AlertModal from "../Common/AlertModal";
import { Grid } from "@mui/material";
import { CustomText } from "../../styles/CustomText";
function QnaEditBoard() {
  const user = useUserState();
  const [data, setData] = useState<Qna>(InitQna);
  const QnaData: string[] = [
    "인사파트",
    "총무팀",
    "회계팀",
    "영업관리팀",
    "홍보팀",
    "인프라개발파트",
    "기타",
  ];
  const [deleteFileName, setDeleteFileName]: any = useState([]);
  const [deleteFileId, setDeleteFileId]: any = useState([]);
  const refContent = createRef<CrossEditor>();
  const [started, setStarted] = useState<boolean>(false);
  const affiliationName = user?.teamName
    ? user?.teamName
    : user?.parentTeam
    ? user?.parentTeam
    : user?.group;
  const params = {
    Width: "100%",
    Height: 700,
  };
  const onLoaded = (_: any, editor: { GetBodyValue: () => any }) => {
    setStarted(true);
  };
  // Id 값 가져오기

  let history = useHistory();
  const location = useLocation();
  const id = Number(location.state);

  // 날짜(수정날짜만 변경)
  const today = new Date();
  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth() + 1;
  const todayDate = today.getDate();
  const todayHour = today.getHours();
  const todayMinute = today.getMinutes();
  const todaySecond = today.getSeconds();

  const Value_qnaModifiedAt =
    todayYear + "년 " + todayMonth + "월 " + todayDate + "일";
  const Value_qnaWriter = affiliationName + " " + user.name;

  const [Fileuploadfolder, setFileuploadfolder] = useState(
    String(todayYear) +
      String(todayMonth) +
      String(todayDate) +
      String(todayHour) +
      String(todayMinute) +
      String(todaySecond) +
      "_" +
      user.employeeId
  );

  useEffect(() => {
    if (data.qnaSecret === true) {
      if (data.employeeId === user.employeeId) {
      } else {
        AlertModal("check", "작성자만 확인할 수 있는 페이지입니다.");
        history.goBack();
      }
    } else {
    }
  }, []);

  // 메일 전송
  const [sendDepartment, setSendDepartment] = useState("");
  useEffect(() => {
    if (data.qnaInCharge === "인사파트") {
      setSendDepartment("Common_HR_2023@inbody.com");
    } else if (data.qnaInCharge === "총무팀") {
      setSendDepartment("Support_manager_2022@inbody.com");
    } else if (data.qnaInCharge === "회계팀") {
      setSendDepartment("Support_accounting_2022@inbody.com");
    } else if (data.qnaInCharge === "영업관리팀") {
      setSendDepartment("Support_sales_2022@inbody.com");
    } else if (data.qnaInCharge === "홍보팀") {
      setSendDepartment("Research_PR_2023@inbody.com");
    } else if (data.qnaInCharge === "인프라개발파트") {
      setSendDepartment("InBody_Infra_2022@inbody.com");
    } else {
      setSendDepartment("jiaiha@inbody.com");
    }
  });

  // 작성 정보 받아오기
  useEffect(() => {
    if (id) {
      QnaApi.GetQna(id)
        .then((res) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(res.data.content, "text/html");
          const tableElement = doc.querySelector(
            'table[name="namo-editor-table-class"]'
          );
          if (tableElement) {
            setData({ ...res.data, setInfo: true });

            setFileuploadfolder(res.data.fileuploadFolder);
          } else {
            setData({
              ...res.data,
              setInfo: true,
              qnaContent: res.data.qnaContent,
            });

            setFileuploadfolder(res.data.fileuploadFolder);
          }
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    } else {
      setData({ ...data, setInfo: true });
    }
  }, [id]);

  // 버튼
  enableRipple(true);

  // 글 수정
  const handleOnChange = (args: any) => {
    if (args.target.name === "qnaSecret") {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: !data.qnaSecret,
      });
    } else {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    }
  };
  // 글 수정 전송
  var submitcheck = false;

  const EditSubmit = () => {
    if (!started) {
      AlertModal(
        "check",
        "에디터가 로드되지 않았습니다. 잠시 후 다시 시도해 주시기 바랍니다."
      );

      return;
    }

    var checksubmit = 0;
    if (data.qnaTitle.length == 0) {
      AlertModal("check", "제목을 작성해주세요");
      return;
    }

    if (data.qnaInCharge.length == 0) {
      AlertModal("check", "담당 부서를 선택해주세요");
      return;
    }

    if (id && checksubmit === 0) {
      for (var i = 0; i < deleteFileName.length; i++) {
        FileDeleteApi.DeleteFile(
          "Qna",
          Fileuploadfolder,
          deleteFileName[i]
        ).then(() => {
          checksubmit = 1;
        });
      }
      for (var i = 0; i < deleteFileId.length; i++) {
        QnaAttachmentApi.DeleteQnaAttachment(deleteFileId[i])
          .then(() => {
            console.log("업로드 파일 삭제");
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            AlertModal("msg", msg);
          });
      }
    }

    if (submitcheck === false && refContent.current) {
      const editor = refContent.current.editorRef;
      submitcheck = true;
      if (id) {
        QnaApi.UpdateQna({
          ...data,
          qnaContent: editor.GetBodyValue() || "",
          qnaModifiedAt: Value_qnaModifiedAt,
          qnaWriter: Value_qnaWriter,
          teamName: affiliationName,
          employeeId: user.employeeId,
          fileuploadFolder: Fileuploadfolder,
        })
          .then(() => {
            AlertModal("success", "Qna가 수정되었습니다.");

            history.push("/qna/board/1");
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            AlertModal("msg", msg);
          });
      } else {
        QnaApi.InsertQna({
          ...data,
          qnaContent: editor.GetBodyValue() || "",
          qnaCreatedAt: Value_qnaModifiedAt,
          qnaModifiedAt: Value_qnaModifiedAt,
          qnaWriter: Value_qnaWriter,
          employeeId: user.employeeId,
          teamName: affiliationName,
          fileuploadFolder: Fileuploadfolder,
        })
          .then(() => {
            AlertModal("success", "Qna가 작성되었습니다.");

            history.push("/qna/board/1");
          })
          .catch((error) => {
            let msg = ErrorHandler(error);
            AlertModal("msg", msg);
          });
      }
      if (data.qnaInCharge !== "전체") {
        let token = sessionStorage.getItem("token") || "";
        fetch("https://graph.microsoft.com/v1.0/me/sendMail", {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            message: {
              subject: `[요청] 질의응답 페이지에 답변부탁드립니다.`,
              body: {
                contentType: "HTML",
                content: `질의응답 페이지에 ${data.qnaInCharge} 앞으로 제목 '${data.qnaTitle}' 게시글이 작성되었습니다. <br/> 답변 부탁드립니다. <br/> 질의응답 페이지로 이동하기 : <a href="https://weareinbody.com/qna/board/1" title="질의응답 페이지로 이동하기" target="_blank"> https://weareinbody.com/qna/board/1</a> `,
              },
              toRecipients: [
                {
                  emailAddress: {
                    address: sendDepartment,
                  },
                },
              ],
            },
          }),
        });
      }
    }

    const QnaUploadfile = new FormData();
    Object.values(files).forEach((files) =>
      QnaUploadfile.append("files", files)
    );

    FileUploadApi.InsertFileUpload(QnaUploadfile, "Qna", Fileuploadfolder)
      .then(() => {
        console.log("업로드 완료");
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });

    Object.values(files).forEach((files) =>
      QnaAttachmentApi.InsertQnaAttachment({
        fileuploadFolder: Fileuploadfolder,
        qnaAttachmentFileName: files.name,
      })
    );
  };

  // 파일 업로드
  const inputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [existingFiles, setExistingFiles]: any = useState([]);

  const saveFileSelected = (e: any) => {
    const nowSelectList: any = Array.from(e.target.files || []);
    const nowList = [...files];
    var checkFile = 1;
    for (var i = 0; i < nowSelectList.length; i++) {
      for (var j = 0; j < nowList.length; j++) {
        if (nowSelectList[i].name === nowList[j].name) {
          checkFile = 2;
        }
      }
    }
    for (var i = 0; i < nowSelectList.length; i++) {
      for (var j = 0; j < existingFiles.length; j++) {
        if (nowSelectList[i].name === existingFiles[j].qnaAttachmentFileName) {
          checkFile = 2;
        }
      }
    }

    if (checkFile === 2) {
      AlertModal(
        "check",
        "기존 업로드 파일 중 중복된 이름을 가진 파일이 있습니다. \n업로드 하실 파일의 이름을 수정해주세요."
      );
    } else {
      const nowList = [...files];
      nowList.push(...nowSelectList);
      setFiles(nowList);
    }
  };

  const handleExistingFileDelete = (item: any, index: any) => {
    deleteFileName.push(existingFiles[index].qnaAttachmentFileName);

    const newFiles = [
      ...existingFiles.slice(0, index),
      ...existingFiles.slice(index + 1),
    ];
    setExistingFiles(newFiles);
    deleteFileId.push(item.qnaAttachmentId);
  };

  const handleDelete = (index: any) => {
    const newFiles = [...files.slice(0, index), ...files.slice(index + 1)];

    const store = new DataTransfer();
    newFiles.forEach((file) => store.items.add(file));

    if (inputRef.current) {
      inputRef.current.files = store.files;
    }
    setFiles(newFiles);
  };

  // 첨부 파일 정보
  useEffect(() => {
    if (data.fileuploadFolder) {
      QnaAttachmentApi.GetQnaAttachmentList(data.fileuploadFolder)
        .then((res) => {
          setExistingFiles(res.data);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  }, [data]);

  return (
    <div className="board">
      <div className="board_write">
        <div className="title">
          <Grid container alignItems="center" mb="10px">
            <Grid item style={{ width: "85px" }}>
              <CustomText type="title">제목</CustomText>
            </Grid>
            <Grid item style={{ width: "calc(100% - 85px)" }}>
              <input
                name="qnaTitle"
                value={data.qnaTitle || ""}
                type="text"
                placeholder="제목 입력"
                onChange={handleOnChange}
              />
            </Grid>
          </Grid>
        </div>

        {id ? (
          <div className="info_editdashed"></div>
        ) : (
          <div className="info_dashed">
            <Grid container alignItems="center" mb="10px">
              <Grid container item xs={12} sm={6} alignItems="center">
                <Grid item mr="25px">
                  <CustomText type="title">비밀글 설정</CustomText>
                </Grid>

                <Grid item>
                  <input
                    name="qnaSecret"
                    type="checkbox"
                    onChange={handleOnChange}
                    checked={data.qnaSecret}
                  />
                </Grid>
              </Grid>
              {data.qnaSecret === true && (
                <>
                  <Grid container item xs={12} sm={6} alignItems="center">
                    <Grid item mr="25px">
                      <CustomText type="title">비밀번호</CustomText>
                    </Grid>
                    <Grid item>
                      <input
                        name="qnaPassword"
                        value={data.qnaPassword}
                        type="password"
                        placeholder="비밀번호 입력"
                        onChange={handleOnChange}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        )}
        <Grid container alignItems="center" margin="10px 0px">
          <Grid container item xs={12} sm={6} alignItems="center">
            <Grid item mr="25px">
              <CustomText type="title">작성자</CustomText>
            </Grid>

            <Grid item>
              <CustomText type="subtitle2">
                {data.qnaWriter
                  ? data.qnaWriter
                  : affiliationName + " " + user.name}
              </CustomText>
            </Grid>
          </Grid>

          <Grid container item xs={12} sm={6} alignItems="center">
            <Grid item mr="25px">
              <CustomText type="title">담당부서</CustomText>
            </Grid>
            <Grid item>
              <CustomText type="subtitle2">
                <DropDownListComponent
                  id="ddlelement"
                  name="qnaInCharge"
                  dataSource={QnaData}
                  value={data.qnaInCharge}
                  onChange={handleOnChange}
                  placeholder="담당 부서를 선택해주세요."
                  width="100%"
                  style={{ minWidth: "200px" }}
                />
              </CustomText>
            </Grid>
          </Grid>
        </Grid>
        <form id="myForm" className="form-vertical">
          <div className="form-group">
            {data.setInfo && (
              <CrossEditor
                ref={refContent}
                name="inbodyportal"
                params={params}
                value={data.qnaContent}
                onLoaded={onLoaded}
              />
            )}
          </div>
        </form>
        <div className="info">
          <div className="fileuploadbox">첨부파일</div>
          <div className="filebox">
            <input className="upload-name" placeholder="첨부파일" />
            <label htmlFor="file">파일찾기</label>
            <input
              ref={inputRef}
              type="file"
              id="file"
              onChange={saveFileSelected}
              multiple
            />
            <ul style={{ margin: "5px" }}>
              {existingFiles.map((item: any, index: number) => (
                <li key={`existingfile_${index}_${item.qnaAttachmentId}_index`}>
                  {item.qnaAttachmentFileName}
                  <ButtonComponent
                    iconCss="e-icons e-close"
                    className="fileuploadButton"
                    onClick={() => handleExistingFileDelete(item, index)}
                  >
                    삭제
                  </ButtonComponent>
                </li>
              ))}
              {files.map((item: any, index) => (
                <li key={`uplaodfile_${index}_${item.qnaAttachmentId}`}>
                  {item.name}
                  <ButtonComponent
                    iconCss="e-icons e-close"
                    className="fileuploadButton"
                    onClick={() => handleDelete(index)}
                  >
                    삭제
                  </ButtonComponent>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="bt_wrap">
        <CustomButtonGrey
          style={{ marginLeft: "10px" }}
          onClick={() => {
            history.goBack();
          }}
        >
          취소
        </CustomButtonGrey>

        <CustomButtonBlackSpace
          id="validateSubmit"
          className="sample-btn e-control e-btn"
          type="submit"
          data-ripple="true"
          onClick={EditSubmit}
        >
          {id ? "수정" : "등록"}
        </CustomButtonBlackSpace>
      </div>
    </div>
  );
}
export default QnaEditBoard;
