import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { AwayTime } from "../../../system/types/interface";
import {
  DatePickerComponent,
  TimePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import { InitAwayTime } from "../../../system/types/initObject";
import { CustomButtonBlue } from "../../../styles/Common/CommonComponent";
import { useUserState } from "../../../system/context/UserContext";
import AlertModal from "../../Common/AlertModal";
import axios from "axios";
import { ErrorHandler } from "../../../system/ApiService";
interface AwayTimeInputFormProps {
  calendarDay: string;
  handleAwayClose: () => void;
}

export default function AwayTimeInputForm({
  calendarDay,
  handleAwayClose,
}: AwayTimeInputFormProps) {
  const user = useUserState();
  const [awayTime, setAwayTime] = useState<AwayTime>({
    ...InitAwayTime,
    startedDate: calendarDay,
    employeeNo: user.employeeNo,
  });

  useEffect(() => {
    console.log(awayTime);
  }, [awayTime]);

  //#region event handler
  const handleChangeStartedDate = (props) => {
    // 선택된 날짜 문자열을 Date 객체로 변환
    const selectedDate = new Date(props.target.value);
    // Date 객체를 yyyy-MM-dd 형식으로 변환
    const formattedDate = selectedDate.toISOString().split("T")[0]; // "yyyy-MM-dd" 형식
    if(formattedDate){
      setAwayTime({
        ...awayTime,
        startedDate: formattedDate,
      });
    }
  };
  const handleChangeStartedTime = (props) => {
    if (props.target.prevValue !== undefined) {
      setAwayTime({ ...awayTime, stratedTime: props.target.prevValue });
    }
  };
  const handleChangeEndedTime = (props) => {
    if (props.target.prevValue !== undefined) {
      setAwayTime({ ...awayTime, endedTime: props.target.prevValue });
    }
  };
  const handleChangeKind = (props: SelectChangeEvent) => {
    if (props.target !== null) {
      setAwayTime({ ...awayTime, kind: props.target.value });
    }
  };
  const handleSubmit = () => {
    if (awayTime.startedDate === "") {
      AlertModal("err", "이석시간 입력 실패", "해당 일자를 입력해 주세요.");
      return;
    } else if (awayTime.stratedTime === "") {
      AlertModal(
        "err",
        "이석시간 입력 실패",
        "이석 시작 시각 입력해주세요."
      );
      return;
    } else if (awayTime.endedTime === "") {
      AlertModal(
        "err",
        "이석시간 입력 실패",
        "이석 종료 시각을 입력해 주세요."
      );
      return;
    } else if(awayTime.stratedTime >= awayTime.endedTime){
      AlertModal(
        "err",
        "이석시간 입력 실패",
        "이석 종료 시각이 시작 시각보다 빠릅니다.\r\n다시 입력해 주세요."
      );
      return;
    } else if (awayTime.kind === "") {
      AlertModal("err", "이석시간 입력 실패", "이석 사유를 선택해 주세요.");
      return;
    }

    if (window.confirm("이석시간을 입력하시겠습니까?")) {
      console.log({
        kind: awayTime.kind,
        employeeNo: awayTime.employeeNo,
        started: `${awayTime.startedDate} ${awayTime.stratedTime}`,
        ended: `${awayTime.startedDate} ${awayTime.endedTime}`,
      });
      axios
        .post(`https://insamansa2.weareinbody.com/api/attendance/awaytime`, {
          kind: awayTime.kind,
          employeeNo: awayTime.employeeNo,
          started: `${awayTime.startedDate} ${awayTime.stratedTime}`,
          ended: `${awayTime.startedDate} ${awayTime.endedTime}`,
        })
        .then((res) => {
          AlertModal(
            "success",
            "성공",
            "이석시간이 정상적으로 입력되었습니다."
          );
          handleAwayClose();
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  };
  //#endregion

  const GridItemMarginTop = "10px";
  const leftGridPadding = "6px";

  return (
    <Box mt={"30px"}>
      <Grid container>
        <Grid item xs={12} mb={"10px"}>
          <Typography variant="body2">{"※ 점심시간(1시간), 업무관련 사항(사내교육/회의/출장/외근 등)은 입력 불필요"}</Typography>
        </Grid>
        <Grid item xs={4} mt={GridItemMarginTop} p={leftGridPadding}>
          해당 일자
        </Grid>
        <Grid item xs={8} mt={GridItemMarginTop} textAlign={"center"}>
          <DatePickerComponent
            id="datepicker"
            value={new Date(awayTime.startedDate)}
            onChange={handleChangeStartedDate}
            format={"yyyy-MM-dd"}
            style={{ textAlign: "center" }}
          />
        </Grid>
        <Grid item xs={4} mt={GridItemMarginTop} p={leftGridPadding}>
          시작 시각
        </Grid>
        <Grid item xs={8} mt={GridItemMarginTop} textAlign={"center"}>
          <TimePickerComponent
            id="time"
            value={new Date(awayTime.stratedTime)}
            onChange={handleChangeStartedTime}
            format={"HH:mm"}
            style={{ textAlign: "center" }}
          />
        </Grid>
        <Grid item xs={4} mt={GridItemMarginTop} p={leftGridPadding}>
          종료 시각
        </Grid>
        <Grid item xs={8} mt={GridItemMarginTop} textAlign={"center"}>
          <TimePickerComponent
            id="time"
            value={new Date(awayTime.stratedTime)}
            onChange={handleChangeEndedTime}
            format={"HH:mm"}
            style={{ textAlign: "center" }}
          />
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={8} mb={"10px"}>
          <Typography variant="body2">{"※ 시간을 분 단위로 입력하려면 직접 입력해 주세요."}</Typography>
        </Grid>
        <Grid item xs={4} mt={GridItemMarginTop} p={leftGridPadding}>
          이석 사유
        </Grid>
        <Grid item xs={8} mt={GridItemMarginTop} textAlign={"center"}>
          <FormControl fullWidth size="small">
            <InputLabel>사유 선택</InputLabel>
            <Select value={awayTime.kind} onChange={handleChangeKind}>
              <MenuItem value={"외출"}>외출</MenuItem>
              <MenuItem value={"식사"}>식사</MenuItem>
              <MenuItem value={"병원"}>병원</MenuItem>
              <MenuItem value={"휴게"}>휴게</MenuItem>
              <MenuItem value={"어학"}>어학</MenuItem>
              <MenuItem value={"기타"}>기타</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} mt={GridItemMarginTop} textAlign={"left"}></Grid>
        <Grid item xs={6} mt={GridItemMarginTop} textAlign={"right"}>
          <CustomButtonBlue onClick={handleSubmit}>저장</CustomButtonBlue>
        </Grid>
      </Grid>
    </Box>
  );
}
