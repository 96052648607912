import "../../styles/Components/Admin/Cheonan.css";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material";
import { StepIconProps } from "@mui/material/StepIcon";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { MessageComponent } from "@syncfusion/ej2-react-notifications";
import { useEffect, useState } from "react";
import CheonanMenuWrite from "./CheonanMenuWrite";
import { CheonanJsonData } from "../../system/types/interface";
import { CheonanMenuApi, ErrorHandler } from "../../system/ApiService";
import AlertModal from "../Common/AlertModal";

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  cursor: "pointer",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(204, 31, 59) 0%, rgb(151, 27, 47) 50%, rgb(79, 12, 23) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <CalendarTodayIcon />,
    2: <DateRangeIcon />,
    3: <CalendarMonthIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },

  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

function Cheonan() {
  const [activeNum, setActiveNum] = useState(0);
  const [menuId, setMenuId] = useState(0);
  const [data, setData] = useState<CheonanJsonData[]>([]);

  function ChangeActive(index: number) {
    setActiveNum(index);
    setMenuDate(weekDateGetData[DateOrder[index]]);
  }

  const DateOrder = [0, 2, 4];

  // 날짜
  var currentDay = new Date();
  var theDayOfWeek = currentDay.getDay();
  var theYear = currentDay.getFullYear();
  var theMonth = currentDay.getMonth();
  var theDate = currentDay.getDate();

  const dateGap = [1, 5, 8, 12, 15, 19];
  var weekDate: any = [];
  var weekDateDisplay: any = [];
  var weekDateGetData: any = [];

  for (var i = 0; i < 6; i++) {
    var resultDay = new Date(
      theYear,
      theMonth,
      theDate + (dateGap[i] - theDayOfWeek)
    );
    var yyyy = resultDay.getFullYear();
    var premm = Number(resultDay.getMonth()) + 1;
    var predd = resultDay.getDate();

    var mm = String(premm).length === 1 ? "0" + `${premm}` : `${premm}`;
    var dd = String(predd).length === 1 ? "0" + `${predd}` : `${predd}`;

    weekDate[i] = yyyy + mm + dd;
    weekDateDisplay[i] = yyyy + "." + mm + "." + dd;
    weekDateGetData[i] = yyyy + "" + mm + "" + dd;
  }
  const [menuDate, setMenuDate] = useState(weekDateGetData[DateOrder[0]]);
  useEffect(() => {
    setMenuId(0);
    setData([]);
    if (weekDateGetData.length > 0) {
      CheonanMenuApi.GetCheonanMenu(
        Number(weekDateGetData[DateOrder[activeNum]])
      )
        .then((res) => {
          if (res.data) {
            setData(JSON.parse(res.data.menuDataList));
            setMenuId(res.data.menuId);
          }
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  }, [activeNum]);

  const steps = [
    `이번 주 (${weekDateDisplay[0]} ~ ${weekDateDisplay[1]})`,
    `다음 주 (${weekDateDisplay[2]} ~ ${weekDateDisplay[3]})`,
    `다음다음 주 (${weekDateDisplay[4]} ~ ${weekDateDisplay[5]})`,
  ];

  return (
    <div>
      <Stepper
        alternativeLabel
        activeStep={activeNum}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              onClick={() => {
                ChangeActive(index);
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="cheonanmenubox">
        <MessageComponent
          style={{ marginBottom: "13px" }}
          id="msg_default"
          content="파일찾기에서 파일은 선택하신 후 메뉴 미리보기에서 확인해주세요. 그 후, '주간메뉴표 저장' 버튼을 눌러야 저장됩니다. "
        ></MessageComponent>
        {activeNum === 0 ? (
          <>
            <CheonanMenuWrite
              parent={{
                menuId: menuId,
                menuDate: menuDate,
                data: data,
                setData: setData,
                activeNum: activeNum,
              }}
            />
          </>
        ) : (
          ""
        )}
        {activeNum === 1 ? (
          <CheonanMenuWrite
            parent={{
              menuId: menuId,
              menuDate: menuDate,
              data: data,
              setData: setData,
              activeNum: activeNum,
            }}
          />
        ) : (
          ""
        )}
        {activeNum === 2 ? (
          <CheonanMenuWrite
            parent={{
              menuId: menuId,
              menuDate: menuDate,
              data: data,
              setData: setData,
              activeNum: activeNum,
            }}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Cheonan;
