import "../../../src/styles/Components/Main/Profile.css";
import { useState, useEffect, Fragment } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useUserState } from "../../system/context/UserContext";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import { Calendar, CalendarComponent } from "@syncfusion/ej2-react-calendars";
import {
  IconButton,
  InputBase,
  Modal,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { BodySearchComponent } from "../../pages/Layout/ResponsiveScreen";
import { useRecoilValue } from "recoil";
import { EmployeeListDataAtom } from "../../system/atom";
import { Employee } from "../../system/types/interface";
import {
  EmployeeModalstyle,
  callContant,
  contactContant,
  employeeTable,
  emptyEmployeeTable,
  modalTitle,
  searchBoxProfile,
  tableBox,
  tableContent,
  tableTitle,
} from "../../styles/Components/Main/MainPortal";
import { Copy, Info, List, X } from "@phosphor-icons/react";
import { CloseButton } from "../../styles/theme";
import TitleBox from "../Common/TitleBox";
import React from "react";
import AlertModal from "../Common/AlertModal";
import { ErrorHandler } from "../../system/ApiService";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 600,
    padding: "20px",
    backgroundColor: "rgba(18, 18, 18, 0.8)",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: "22px",
  },
}));

function Profile() {
  const user = useUserState();
  const employeeData = useRecoilValue<Employee[]>(EmployeeListDataAtom);
  const [, setCommuteData] = useState({});

  // 날짜
  const today = new Date();

  const todayYear = today.getFullYear();
  const tempTodayMonth = today.getMonth() + 1;
  const todayMonth =
    tempTodayMonth < 10 ? "0" + tempTodayMonth : tempTodayMonth;

  const tempTodayDate = today.getDate();
  const todayDate = tempTodayDate < 10 ? "0" + tempTodayDate : tempTodayDate;

  const [calendarDay, setCalendarDay] = useState(
    `${todayYear}-${todayMonth}-${todayDate}`
  );
  let calendarInstance: Calendar;

  function selectedCalendar() {
    if (calendarInstance.value) {
      setCalendarDay(
        calendarInstance.value.getFullYear() +
          "-" +
          (calendarInstance.value.getMonth() + 1 < 10
            ? "0" + (calendarInstance.value.getMonth() + 1)
            : calendarInstance.value.getMonth() + 1) +
          "-" +
          (calendarInstance.value.getDate() < 10
            ? "0" + calendarInstance.value.getDate()
            : calendarInstance.value.getDate())
      );
    }
  }

  // 출퇴근시간

  let workdate = todayYear + "" + todayMonth + "" + todayDate;
  let [gotowork, setGoToWork] = useState("");
  let [leavework, setLeaveWork] = useState("");

  useEffect(() => {
    axios
      .get(
        `https://insamansa2.weareinbody.com/api/attendance/workdate/${user.employeeId}/${workdate}`
      )
      .then((res) => {
        setCommuteData(res.data);
        if (res.data.wsTime) {
          setGoToWork(
            res.data.wsTime.slice(8, 10) +
              "시 " +
              res.data.wsTime.slice(10, 12) +
              "분"
          );
        }
        if (res.data.wcTime) {
          setLeaveWork(
            res.data.wcTime.slice(8, 10) +
              "시 " +
              res.data.wcTime.slice(10, 12) +
              "분"
          );
        }
      });
  }, []);

  // todo
  const [todoData, setTodoData]: any = useState([]);

  useEffect(() => {
    let token = sessionStorage.getItem("token") || "";

    fetch(
      `https://graph.microsoft.com/v1.0/me/calendarview?$top=1000&$skip=0&startdatetime=${calendarDay}T00:00:00.000Z&enddatetime=${calendarDay}T23:59:59.999Z`,
      {
        headers: {
          Authorization: token,
          Prefer: 'outlook.timezone = "Korea Standard Time"',
        },
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        let preData = res.value.slice(0).sort(function (a: any, b: any) {
          return (
            new Date(a.start.dateTime).getTime() -
            new Date(b.start.dateTime).getTime()
          );
        });
        setTodoData(preData);
      });
  }, [calendarDay]);

  // photo
  const [photoData, setPhotoData]: any = useState("");

  useEffect(() => {
    let token = sessionStorage.getItem("token") || "";

    axios
      .get<Blob>("https://graph.microsoft.com/v1.0/me/photo/$value", {
        headers: { Authorization: token },
        responseType: "blob",
      })
      .then((res) => {
        const myFile = new File([res.data], "imageName");
        const reader = new FileReader();
        reader.onload = (ev) => {
          const previewImage = String(ev.target?.result);
          setPhotoData(previewImage);
        };
        reader.readAsDataURL(myFile);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);
  // 검색창
  const [search, setSearch] = useState("");
  const [searchOpen, setSearchOpen] = useState(false);

  function stopSearch() {
    setSearchOpen(false);
    setSearch("");
  }
  function searchEmployee(e: {
    target: { value: React.SetStateAction<string> };
  }) {
    setSearch(e.target.value);
    setSearchOpen(true);
  }
  // 임직원 검색 모달
  const [selectedEmployeeData, setSelectedEmployeeData] = useState<Employee>();
  const [ModalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  function OpenEmployeeModal(selectData: Employee) {
    setSelectedEmployeeData(selectData);
    handleModalOpen();
  }
  function handleModalClose() {
    setModalOpen(false);
    setSearch("");
  }

  const handleCopyClipBoard = (text: any) => {
    try {
      navigator.clipboard.writeText(text);
      AlertModal("success", "클립보드에 복사되었습니다.");
    } catch (error) {
      AlertModal("msg", "클립보드 복사에 실패하였습니다.");
    }
  };
  return (
    <div className="profile">
      <BodySearchComponent>
        <div className="searchCard">
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>

          <input style={{ display: "none" }} />
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            className="inputField"
            placeholder="임직원 검색"
            value={search}
            onChange={searchEmployee}
          />
          {searchOpen === false || search.length === 0 ? (
            <></>
          ) : (
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={stopSearch}
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
      </BodySearchComponent>

      {search.length === 0 || searchOpen === false ? (
        ""
      ) : (
        <Box sx={searchBoxProfile}>
          {employeeData.map((item: Employee) =>
            item.name.includes(search) ||
            item.gwName.includes(search) ||
            item.email.includes(search) ||
            item.phone.includes(search) ? (
              <div
                className="mainBox"
                key={item.name}
                onClick={() => OpenEmployeeModal(item)}
              >
                <div
                  style={{
                    display: "border-box",
                    width: "30%",
                    color: "#000000",
                  }}
                >
                  {item.name}
                </div>
                <div style={{ display: "border-box" }}>
                  <div className="employeeInfoColor">{item.team}</div>
                  <div className="employeeInfoColor">
                    {item.jobPosition} / {item.jobTitle}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )
          )}
        </Box>
      )}

      <Modal
        open={ModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={EmployeeModalstyle}>
          <CloseButton>
            <X size={40} onClick={handleModalClose} />
          </CloseButton>
          <div style={modalTitle}>
            <TitleBox title="임직원 검색" />
            <HtmlTooltip
              placement="right-start"
              title={
                <Fragment>
                  <Typography variant="subtitle1">도움말</Typography>
                  {
                    "본사 내선 번호는 앞자리에 따라 구분 : 02) 300-2***, 02) 2182-8***, 1***, 4***"
                  }
                  <br />
                  {"공장 내선 국번 : 041) 589-내선번호"}
                  <br />
                  {"양재 내선 국번 : 02) 6952-내선번호"}
                  <br />
                  <br />
                  {"벤처센터 대표 국번 : 02) 6952-8981"}
                  <br />
                  {"벤처센터 내선 국번 : 070) 4016-6***"}
                  <br />
                  {"벤처센터 6F FAX : 02-6952-8982 / 7F FAX : 02-6952-8987"}
                  <br />
                  {"LookinBody 내선 국번 : 02) 2098-내선번호"}
                </Fragment>
              }
            >
              <Info size={25} color="#6D6D6D" />
            </HtmlTooltip>
          </div>
          {selectedEmployeeData ? (
            <div style={tableBox}>
              <div style={employeeTable}>
                <div style={tableTitle}>이름</div>
                <div style={tableContent}>
                  {" "}
                  {selectedEmployeeData.name}
                  {selectedEmployeeData.name === selectedEmployeeData.gwName ? (
                    ""
                  ) : (
                    <span style={{ marginLeft: "10px" }}>
                      / {selectedEmployeeData.gwName}
                    </span>
                  )}
                </div>
              </div>
              <div style={employeeTable}>
                <div style={tableTitle}>소속</div>
                <div style={tableContent}>
                  {selectedEmployeeData?.group}
                  {selectedEmployeeData?.department
                    ? " " + selectedEmployeeData?.department
                    : ""}
                  {selectedEmployeeData?.team
                    ? " " + selectedEmployeeData?.team
                    : ""}
                </div>
              </div>
              <div style={employeeTable}>
                <div style={tableTitle}>직책</div>
                <div style={tableContent}>
                  {" "}
                  {selectedEmployeeData.jobPosition} /{" "}
                  {selectedEmployeeData.jobTitle}
                </div>
              </div>
              <div style={employeeTable}>
                <div style={tableTitle}>근무 위치</div>
                <div style={tableContent}>
                  {" "}
                  {selectedEmployeeData.workplace}
                </div>
              </div>
              <div style={employeeTable}>
                <div style={tableTitle}>연락처</div>
                <div style={tableContent}>
                  <span style={contactContant}> 휴대폰</span>
                  {selectedEmployeeData.phone}{" "}
                  <Tooltip
                    title="휴대전화 복사"
                    placement="right"
                    onClick={() => {
                      handleCopyClipBoard(`${selectedEmployeeData.phone}`);
                    }}
                  >
                    <IconButton>
                      <Copy size={25} color="#6D6D6D" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <div style={emptyEmployeeTable}>
                <div style={tableTitle}></div>

                {selectedEmployeeData.tel ? (
                  <div style={tableContent}>
                    <span style={callContant}> 내선번호</span>
                    {selectedEmployeeData.tel}{" "}
                    <Tooltip
                      title="내선번호 복사"
                      placement="right"
                      onClick={() => {
                        handleCopyClipBoard(`${selectedEmployeeData.tel}`);
                      }}
                    >
                      <IconButton>
                        <Copy size={25} color="#6D6D6D" />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : (
                  <div style={tableContent}></div>
                )}
              </div>
              <div style={emptyEmployeeTable}>
                <div style={tableTitle}></div>
                <div style={tableContent}>
                  <span style={contactContant}> 이메일</span>
                  {selectedEmployeeData?.email}
                  <Tooltip
                    title="이메일 복사"
                    placement="right"
                    onClick={() => {
                      handleCopyClipBoard(`${selectedEmployeeData.email}`);
                    }}
                  >
                    <IconButton>
                      <Copy size={25} color="#6D6D6D" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </Box>
      </Modal>

      <div className="profilecard">
        <div className="MyProfileTitle">
          <Typography variant="h5" component="div">
            My Profile
          </Typography>
        </div>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={5} container justifyContent="flex-end">
              {photoData ? (
                <div className="photobox">
                  <img
                    src={photoData}
                    alt="프로필 사진"
                    className="profilephoto"
                  />
                </div>
              ) : (
                <Avatar
                  alt={photoData}
                  sx={{ width: 100, height: 100 }}
                ></Avatar>
              )}
            </Grid>
            <Grid item xs={7}>
              <div className="myprofileUser">
                {user.parentTeam === user.teamName ? (
                  <div style={{ marginTop: "10px" }}></div>
                ) : (
                  ""
                )}
                <Typography
                  variant="h6"
                  style={{ fontWeight: "500", marginBottom: "3px" }}
                >
                  {user.name}{" "}
                  <Typography
                    variant="subtitle2"
                    component="span"
                    style={{ color: "#81848B" }}
                  >
                    님
                  </Typography>
                </Typography>
                {user.parentTeam === user.teamName ? (
                  ""
                ) : (
                  <div style={{ color: "#4B4F5A", fontSize: "15px" }}>
                    {user.parentTeam}{" "}
                  </div>
                )}
                <div style={{ color: "#4B4F5A", fontSize: "15px" }}>
                  {user.teamName}
                </div>
                <div style={{ color: "#4B4F5A", fontSize: "15px" }}>
                  {user.jobPosition} / {user.jobTitle}
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
        <div
          className="officetimealign"
          onClick={() => {
            window.open("https://insamansa2.weareinbody.com/");
          }}
        >
          <Typography variant="h6" style={{ color: "#4B4F5A" }}>
            오늘 출퇴근 시간
          </Typography>
          {window.innerWidth < 440 ? (
            <div className="officetime">
              <Typography variant="h6" component="div" className="">
                {gotowork ? gotowork : "출근 시간"} ~
              </Typography>
              <Typography variant="h6" component="div" className="">
                {leavework ? leavework : "퇴근 시간"}
              </Typography>
            </div>
          ) : (
            <div className="officetime">
              <Typography variant="h5" component="div" className="">
                {gotowork ? gotowork : "출근 시간"} ~{" "}
                {leavework ? leavework : "퇴근 시간"}
              </Typography>
            </div>
          )}
        </div>
      </div>

      <div className="calendarcard">
        <div className="calendar">
          <CalendarComponent
            id="calendar"
            onClick={selectedCalendar}
            ref={(scope) => {
              (calendarInstance as Calendar | null) = scope;
            }}
          />
        </div>
        <div>
          {todoData
            ? todoData.map((todo: any) => {
                return (
                  <div key={todo.id}>
                    {todo.start.dateTime.slice(0, 10) === calendarDay ? (
                      <div>
                        {window.innerWidth < 440 ? (
                          <>
                            {" "}
                            <Typography
                              align="center"
                              variant="subtitle1"
                              style={{
                                marginTop: "10px",
                                paddingTop: "10px",
                                borderTop: "1px solid lightGray",
                                color: "#4B4F5A",
                              }}
                            >
                              {todo.start.dateTime.slice(0, 10)}{" "}
                              {todo.start.dateTime.slice(11, 16)} ~{" "}
                            </Typography>
                            <Typography
                              align="center"
                              variant="subtitle1"
                              style={{ color: "#4B4F5A" }}
                            >
                              {" "}
                              {todo.end.dateTime.slice(0, 10)}{" "}
                              {todo.end.dateTime.slice(11, 16)}
                            </Typography>
                          </>
                        ) : (
                          <Typography
                            align="center"
                            variant="subtitle1"
                            style={{
                              marginTop: "10px",
                              paddingTop: "10px",
                              borderTop: "1px solid lightGray",
                              color: "#4B4F5A",
                            }}
                          >
                            {todo.start.dateTime.slice(0, 10)}{" "}
                            {todo.start.dateTime.slice(11, 16)} ~{" "}
                            {todo.end.dateTime.slice(0, 10)}{" "}
                            {todo.end.dateTime.slice(11, 16)}
                          </Typography>
                        )}

                        <Typography
                          align="center"
                          variant="h6"
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            overflow: "hidden",
                          }}
                        >
                          {" "}
                          {todo.subject}
                        </Typography>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </div>
  );
}

export default Profile;
