import React, { useCallback, useEffect, useState } from "react";
import { Qna } from "../../../system/types/interface";
import { QnaApi, ErrorHandler } from "../../../system/ApiService";
import { useHistory } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import Typography from "@mui/material/Typography";
import "../../../../src/styles/Components/Portlet/BoardPortlet.css";
import AlertModal from "../../Common/AlertModal";

function QnaPortlet() {
  const [data, setData] = useState<Qna[]>([]);
  let history = useHistory();
  useEffect(() => {
    let isComponentMounted = true;
    QnaApi.GetQnaTotalList()
      .then((res) => {
        if (res.data) {
          if (isComponentMounted) {
            setData(res.data.slice(0, 5));
          }
        }
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
    return () => {
      isComponentMounted = false;
    };
  }, []);

  return (
    <div className="PortletLayout">
      <div className="portlet_maintitle">
        <Typography
          variant="h5"
          component="div"
          style={{ paddingTop: "5px", paddingLeft: "5px" }}
        >
          질의응답
          <Typography
            onClick={() => {
              history.push("/qna/board/1");
            }}
            variant="subtitle2"
            component="span"
            style={{
              cursor: "pointer",
              paddingTop: "10px",
              paddingRight: "5px",
              color: "#81848B",
              float: "right",
              display: "inline-block",
            }}
          >
            [전체보기]
          </Typography>
        </Typography>
      </div>
      {data
        ? data.map((board: any) => {
            return (
              <div className="QnaContent" key={board.qnaId} id={board.qnaId}>
                {board.qnaSecret ? (
                  <div
                    className="portlet_qnatitle"
                    onClick={() => {
                      history.push({
                        pathname: `/qna/detail/${board.qnaId}`,
                        state: { displays: board.qnaId },
                      });
                    }}
                  >
                    {" "}
                    <span
                      style={{
                        maxWidth: "85%",
                        float: "left",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      [{board.qnaInCharge}] {board.qnaTitle}
                    </span>
                    <span style={{ float: "left" }}>
                      <LockIcon />{" "}
                    </span>{" "}
                    <span
                      style={{
                        color: "#971B2F",
                        float: "left",
                        width: "5%",
                        marginLeft: "1px",
                      }}
                    >
                      {" "}
                      {board.numberOfComments
                        ? `[${board.numberOfComments}]`
                        : ""}{" "}
                    </span>
                  </div>
                ) : (
                  <div
                    className="portlet_qnatitle"
                    onClick={() => {
                      history.push({
                        pathname: `/qna/detail/${board.qnaId}`,
                        state: { displays: board.qnaId },
                      });
                    }}
                  >
                    <span
                      style={{
                        maxWidth: "90%",
                        float: "left",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      [{board.qnaInCharge}] {board.qnaTitle}
                    </span>{" "}
                    <span
                      style={{
                        color: "#971B2F",
                        float: "left",
                        width: "5%",
                        marginLeft: "5px",
                      }}
                    >
                      {" "}
                      {board.numberOfComments
                        ? `[${board.numberOfComments}]`
                        : ""}{" "}
                    </span>
                  </div>
                )}
                <div className="writer">{board.qnaWriter}</div>
                {board.qnaCreatedAt.split(" ")[1].split("월")[0].length === 1 &&
                board.qnaCreatedAt.split(" ")[2].split("일")[0].length === 2 ? (
                  <div className="date">
                    {board.qnaCreatedAt.split("년")[0] +
                      ".0" +
                      board.qnaCreatedAt.split(" ")[1].split("월")[0] +
                      "." +
                      board.qnaCreatedAt.split(" ")[2].split("일")[0]}
                  </div>
                ) : (
                  ""
                )}
                {board.qnaCreatedAt.split(" ")[1].split("월")[0].length === 2 &&
                board.qnaCreatedAt.split(" ")[2].split("일")[0].length === 1 ? (
                  <div className="date">
                    {board.qnaCreatedAt.split("년")[0] +
                      "." +
                      board.qnaCreatedAt.split(" ")[1].split("월")[0] +
                      ".0" +
                      board.qnaCreatedAt.split(" ")[2].split("일")[0]}
                  </div>
                ) : (
                  ""
                )}
                {board.qnaCreatedAt.split(" ")[2].split("일")[0].length === 1 &&
                board.qnaCreatedAt.split(" ")[1].split("월")[0].length === 1 ? (
                  <div className="date">
                    {board.qnaCreatedAt.split("년")[0] +
                      ".0" +
                      board.qnaCreatedAt.split(" ")[1].split("월")[0] +
                      ".0" +
                      board.qnaCreatedAt.split(" ")[2].split("일")[0]}
                  </div>
                ) : (
                  ""
                )}
                {board.qnaCreatedAt.split(" ")[2].split("일")[0].length === 2 &&
                board.qnaCreatedAt.split(" ")[1].split("월")[0].length === 2 ? (
                  <div className="date">
                    {board.qnaCreatedAt.split("년")[0] +
                      "." +
                      board.qnaCreatedAt.split(" ")[1].split("월")[0] +
                      "." +
                      board.qnaCreatedAt.split(" ")[2].split("일")[0]}
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })
        : ""}
      {data.length > 0 ? (
        ""
      ) : (
        <div className="emptyBox">
          <div className="emptynotice">작성된 질의응답이 없습니다.</div>
        </div>
      )}
    </div>
  );
}

export default QnaPortlet;
