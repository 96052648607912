import React, { useCallback, useEffect, useState } from "react";
import { Qna } from "../../system/types/interface";
import { QnaApi, ErrorHandler } from "../../system/ApiService";
import { Link, useHistory } from "react-router-dom";
import { useUserState } from "../../system/context/UserContext";

// syncfusion
import { enableRipple } from "@syncfusion/ej2-base";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

// material ui
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import LockIcon from "@mui/icons-material/Lock";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  CustomButtonBlack,
  CustomButtonBlackSpace,
  CustomButtonGrey,
  CustomButtonWhite,
} from "../../styles/Common/CommonComponent";
import {
  PaginationMargin,
  StyledBody,
  StyledBody2,
  StyledBodyTitle,
  StyledEmptyBody,
  StyledHead,
  StyledHead2,
  StyledHeadTitle,
} from "../../styles/theme";
import AlertModal from "../Common/AlertModal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function QnaBoard() {
  const user = useUserState();
  const [data, setData] = useState<Qna[]>([]);
  let history = useHistory();
  // 버튼
  enableRipple(true);

  // 페이징 처리
  const [totalData, setTotalData] = useState<Qna[]>([]);
  const [page, setPage] = useState(1);
  const dataLength = Number(totalData.length);
  const pageRange = 10; // 목록에 나타날 게시글 수
  const lastPage = Math.ceil(dataLength / pageRange);

  // 게시판 정보 불러오기

  useEffect(() => {
    QnaApi.GetQnaList(page, 10)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
  }, [page]);

  useEffect(() => {
    QnaApi.GetQnaTotalList()
      .then((res) => {
        setTotalData(res.data);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
  }, [page]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // 모달
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [password, setPassword] = useState("");
  function qnaPasswordWrite(e: {
    target: { value: React.SetStateAction<string> };
  }) {
    setPassword(e.target.value);
  }
  function passwordSubmit() {
    AlertModal("check", "비밀번호가 일치하지 않습니다");
    return;
  }

  // 검색창
  const [search, setSearch] = useState("");
  const [searchOpen, setSearchOpen] = useState(false);

  function startSearch() {
    setSearchOpen(true);
  }

  function stopSearch() {
    setSearchOpen(false);
    setDeptType("");
  }

  const onChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setSearch(e.target.value);
  };
  const [type, setType] = React.useState("");
  const [DeptType, setDeptType] = React.useState("");
  const filterTotalData = totalData.filter((p) => {
    if (type === "searchType") {
      return p.qnaInCharge === DeptType;
    } else if (type === "searchContent") {
      return (
        p.qnaContent
          .replace(" ", "")
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase().replace(" ", "")) &&
        p.qnaSecret === false
      );
    } else if (type === "searchWriter") {
      return p.qnaWriter
        .replace(" ", "")
        .toLocaleLowerCase()
        .includes(search.toLocaleLowerCase().replace(" ", ""));
    } else {
      return p.qnaTitle
        .replace(" ", "")
        .toLocaleLowerCase()
        .includes(search.toLocaleLowerCase().replace(" ", ""));
    }
  });
  const handleChangeType = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  const handleChangeNoticeType = (event: SelectChangeEvent) => {
    setDeptType(event.target.value as string);
    setSearchOpen(true);
  };

  return (
    <div style={{ marginTop: "22px", width: "1620px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">검색</InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={type}
              label="type"
              onChange={handleChangeType}
              sx={{ maxHeight: "40px" }}
            >
              <MenuItem value={"searchType"}>부서</MenuItem>
              <MenuItem value={"searchTitle"}>제목</MenuItem>
              <MenuItem value={"searchContent"}>
                내용
                <Tooltip
                  title="내용 검색의 경우 검색 결과에서 비밀글은 제외됩니다."
                  placement="right"
                >
                  <IconButton>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </MenuItem>
              <MenuItem value={"searchWriter"}>글쓴이</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {type === "searchType" ? (
          <Box sx={{ mx: "10px", width: 310 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                부서
                {searchOpen ? (
                  <IconButton
                    type="button"
                    aria-label="search"
                    onClick={stopSearch}
                  >
                    <CloseIcon />
                  </IconButton>
                ) : null}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={DeptType}
                label="DeptType"
                onChange={handleChangeNoticeType}
              >
                <MenuItem value={"인사파트"}>인사파트</MenuItem>
                <MenuItem value={"총무팀"}>총무팀</MenuItem>
                <MenuItem value={"회계팀"}>회계팀</MenuItem>
                <MenuItem value={"영업관리팀"}>영업관리팀</MenuItem>
                <MenuItem value={"홍보팀"}>홍보팀</MenuItem>
                <MenuItem value={"인프라개발파트"}>인프라개발파트</MenuItem>
                <MenuItem value={"기타"}>기타</MenuItem>
              </Select>
            </FormControl>
          </Box>
        ) : (
          <Paper
            component="form"
            sx={{
              mx: "10px",
              display: "flex",
              alignItems: "center",
              width: 310,
              height: 40,
            }}
          >
            <input style={{ display: "none" }} />
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="검색하실 단어를 입력해주세요."
              value={search}
              onChange={onChange}
            />
            {searchOpen ? (
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={stopSearch}
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <></>
            )}
          </Paper>
        )}
        <CustomButtonBlack onClick={startSearch}>검색</CustomButtonBlack>
      </div>
      {searchOpen ? (
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledHead align="center">번호</StyledHead>
                  <StyledHead2 align="center">담당 부서</StyledHead2>
                  <StyledHeadTitle align="center">제목</StyledHeadTitle>
                  <StyledHead2 align="center">글쓴이</StyledHead2>
                  <StyledHead2 align="center">작성일</StyledHead2>
                  <StyledHead align="center">조회수</StyledHead>
                </TableRow>
              </TableHead>

              {filterTotalData
                ? filterTotalData.map((board: any) => {
                    return (
                      <TableBody key={board.qnaId} id={board.qnaId}>
                        <TableRow>
                          <StyledBody align="center">{board.qnaId}</StyledBody>

                          <StyledBody2 align="center">
                            {board.qnaInCharge}
                          </StyledBody2>

                          {board.qnaSecret === true &&
                          board.employeeId !== user.employeeId &&
                          board.qnaInCharge !== user.parentTeam ? (
                            <>
                              <StyledBodyTitle onClick={handleOpen}>
                                {board.qnaTitle}{" "}
                                <span style={{ color: "#971B2F" }}>
                                  {" "}
                                  {board.numberOfComments
                                    ? `[${board.numberOfComments}]`
                                    : null}{" "}
                                </span>{" "}
                                <LockIcon />
                              </StyledBodyTitle>
                              <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box sx={style}>
                                  <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                    align="center"
                                    style={{ marginTop: "8%" }}
                                  >
                                    비밀글 기능으로 보호된 글입니다.
                                  </Typography>
                                  <Typography
                                    id="modal-modal-description"
                                    sx={{ mt: 2 }}
                                    align="center"
                                  >
                                    작성자와 담당자만 열람하실 수 있습니다.
                                    <br />
                                    본인이라면 비밀번호를 입력하세요.
                                  </Typography>
                                  <div
                                    style={{
                                      margin: "30px 0px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <input
                                      value={password}
                                      type="password"
                                      placeholder="비밀번호 입력"
                                      onChange={qnaPasswordWrite}
                                      style={{ width: "300px", height: "40px" }}
                                    />
                                    <br />
                                  </div>
                                  <div style={{ textAlign: "center" }}>
                                    <CustomButtonGrey
                                      style={{ marginTop: "10px" }}
                                      onClick={handleClose}
                                    >
                                      취소
                                    </CustomButtonGrey>
                                    <CustomButtonBlackSpace
                                      style={{ marginTop: "10px" }}
                                      onClick={
                                        board.qnaPassword === password
                                          ? () => {
                                              history.push({
                                                pathname: `/qna/detail/${board.qnaId}`,
                                                state: {
                                                  displays: board.qnaId,
                                                },
                                              });
                                            }
                                          : passwordSubmit
                                      }
                                    >
                                      확인
                                    </CustomButtonBlackSpace>
                                  </div>
                                </Box>
                              </Modal>
                            </>
                          ) : null}

                          {board.qnaSecret === true &&
                          (board.employeeId === user.employeeId ||
                            board.qnaInCharge === user.parentTeam) ? (
                            <StyledBodyTitle
                              onClick={() => {
                                history.push({
                                  pathname: `/qna/detail/${board.qnaId}`,
                                  state: { displays: board.qnaId },
                                });
                              }}
                            >
                              <span>{board.qnaTitle}</span>
                              <span>
                                <LockIcon />{" "}
                              </span>
                              <span style={{ color: "#971B2F" }}>
                                {" "}
                                {board.numberOfComments
                                  ? `[${board.numberOfComments}]`
                                  : null}{" "}
                              </span>{" "}
                            </StyledBodyTitle>
                          ) : null}
                          {board.qnaSecret === false ? (
                            <StyledBodyTitle
                              onClick={() => {
                                history.push({
                                  pathname: `/qna/detail/${board.qnaId}`,
                                  state: { displays: board.qnaId },
                                });
                              }}
                            >
                              <span>
                                [{board.qnaInCharge}] {board.qnaTitle}
                              </span>{" "}
                              <span style={{ color: "#971B2F" }}>
                                {" "}
                                {board.numberOfComments
                                  ? `[${board.numberOfComments}]`
                                  : null}{" "}
                              </span>{" "}
                            </StyledBodyTitle>
                          ) : null}
                          <StyledBody2 align="center">
                            {board.qnaWriter}
                          </StyledBody2>
                          <StyledBody2 align="center">
                            {board.qnaCreatedAt}
                          </StyledBody2>
                          <StyledBody align="center">
                            {board.qnaViews}
                          </StyledBody>
                        </TableRow>
                      </TableBody>
                    );
                  })
                : null}
            </Table>
          </TableContainer>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledHead align="center">번호</StyledHead>
                  <StyledHead2 align="center">담당 부서</StyledHead2>
                  <StyledHeadTitle align="center">제목</StyledHeadTitle>
                  <StyledHead2 align="center">글쓴이</StyledHead2>
                  <StyledHead2 align="center">작성일</StyledHead2>
                  <StyledHead align="center">조회수</StyledHead>
                </TableRow>
              </TableHead>

              {data
                ? data.map((board: any) => {
                    return (
                      <TableBody key={board.qnaId} id={board.qnaId}>
                        <TableRow>
                          <StyledBody align="center">{board.qnaId}</StyledBody>
                          <StyledBody2 align="center">
                            {board.qnaInCharge}
                          </StyledBody2>

                          {board.qnaSecret === true &&
                          board.employeeId !== user.employeeId &&
                          board.qnaInCharge !== user.teamName ? (
                            <>
                              <StyledBodyTitle onClick={handleOpen}>
                                {board.qnaTitle}{" "}
                                <span style={{ color: "#971B2F" }}>
                                  {" "}
                                  {board.numberOfComments
                                    ? `[${board.numberOfComments}]`
                                    : null}{" "}
                                </span>{" "}
                                <LockIcon />
                              </StyledBodyTitle>
                              <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box sx={style}>
                                  <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                    align="center"
                                    style={{ marginTop: "8%" }}
                                  >
                                    비밀글 기능으로 보호된 글입니다.
                                  </Typography>
                                  <Typography
                                    id="modal-modal-description"
                                    sx={{ mt: 2 }}
                                    align="center"
                                  >
                                    작성자와 담당자만 열람하실 수 있습니다.
                                    <br />
                                    본인이라면 비밀번호를 입력하세요.
                                  </Typography>
                                  <div
                                    style={{
                                      margin: "30px 0px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <input
                                      value={password}
                                      type="password"
                                      placeholder="비밀번호 입력"
                                      onChange={qnaPasswordWrite}
                                      style={{ width: "300px", height: "40px" }}
                                    />
                                    <br />
                                  </div>
                                  <div style={{ textAlign: "center" }}>
                                    <CustomButtonGrey
                                      style={{ marginTop: "10px" }}
                                      onClick={handleClose}
                                    >
                                      취소
                                    </CustomButtonGrey>
                                    <CustomButtonBlackSpace
                                      style={{ marginTop: "10px" }}
                                      onClick={
                                        board.qnaPassword === password
                                          ? () => {
                                              history.push({
                                                pathname: `/qna/detail/${board.qnaId}`,
                                                state: {
                                                  displays: board.qnaId,
                                                },
                                              });
                                            }
                                          : passwordSubmit
                                      }
                                    >
                                      확인
                                    </CustomButtonBlackSpace>
                                  </div>
                                </Box>
                              </Modal>
                            </>
                          ) : null}
                          {board.qnaSecret === true &&
                          (board.employeeId === user.employeeId ||
                            board.qnaInCharge === user.teamName) ? (
                            <StyledBodyTitle
                              onClick={() => {
                                history.push({
                                  pathname: `/qna/detail/${board.qnaId}`,
                                  state: { displays: board.qnaId },
                                });
                              }}
                            >
                              <span>{board.qnaTitle}</span>
                              <span>
                                <LockIcon />{" "}
                              </span>
                              <span style={{ color: "#971B2F" }}>
                                {" "}
                                {board.numberOfComments
                                  ? `[${board.numberOfComments}]`
                                  : null}{" "}
                              </span>{" "}
                            </StyledBodyTitle>
                          ) : null}

                          {board.qnaSecret === false ? (
                            <StyledBodyTitle
                              onClick={() => {
                                history.push({
                                  pathname: `/qna/detail/${board.qnaId}`,
                                  state: { displays: board.qnaId },
                                });
                              }}
                            >
                              <span>{board.qnaTitle}</span>{" "}
                              <span style={{ color: "#971B2F" }}>
                                {" "}
                                {board.numberOfComments
                                  ? `[${board.numberOfComments}]`
                                  : null}{" "}
                              </span>{" "}
                            </StyledBodyTitle>
                          ) : null}
                          <StyledBody2 align="center">
                            {board.qnaWriter}
                          </StyledBody2>
                          <StyledBody2 align="center">
                            {board.qnaCreatedAt}
                          </StyledBody2>
                          <StyledBody align="center">
                            {board.qnaViews}
                          </StyledBody>
                        </TableRow>
                      </TableBody>
                    );
                  })
                : null}
              {dataLength === 0 ? (
                <TableBody>
                  <TableRow>
                    <StyledEmptyBody colSpan={6}>
                      작성된 질의응답이 없습니다.
                    </StyledEmptyBody>
                  </TableRow>
                </TableBody>
              ) : null}
            </Table>
          </TableContainer>
        </Grid>
      )}

      <div style={{ textAlign: "right" }}>
        <Link to="/qna/edit">
          <CustomButtonWhite style={{ marginTop: "10px" }}>
            글쓰기
          </CustomButtonWhite>
        </Link>
      </div>
      {searchOpen ? null : (
        <PaginationMargin>
          {dataLength === 0 ? null : (
            <Stack spacing={2} alignItems="center">
              <Pagination
                count={lastPage}
                defaultPage={1}
                page={page}
                siblingCount={2}
                boundaryCount={2}
                onChange={handleChange}
              />
            </Stack>
          )}
        </PaginationMargin>
      )}
    </div>
  );
}

export default QnaBoard;
