import { SECONDARY } from "../../styles/theme";

import CopyrightIcon from "@mui/icons-material/Copyright";
import { Divider, Grid } from "@mui/material";

function Footer() {
  return (
    <div>
      <Divider></Divider>
      <Grid
        container
        justifyContent="center"
        alignItems="cetner"
        style={{
          fontSize: "10pt",
          color: SECONDARY,
          marginTop: "15px",
        }}
      >
        Copyright <CopyrightIcon /> {new Date().getFullYear()} by InBody Co.,
        Ltd. All rights reserved.
      </Grid>
    </div>
  );
}

export default Footer;
