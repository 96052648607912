import React, { ReactElement } from "react";
import {  Redirect, Route, RouteComponentProps } from "react-router-dom";
import { CommonRoutes } from "../system/types/type";

interface UserRouteProps {
  Component: React.FC<RouteComponentProps>;
  path?: string;
  exact?: boolean;
}

export default function UserRoute({ Component, path, exact = false }: UserRouteProps): ReactElement {
  const isUser = true;

  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) =>
        isUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: CommonRoutes.login,
              state: {
                requestedPath: path,
              },
            }}
          />
        )
      }
    ></Route>
  );
}
