export const isoDateFormatter = (date: Date) => {
  if (!date) return undefined;
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${month >= 10 ? month : "0" + month}-${
    day >= 10 ? day : "0" + day
  }`;
};

export const isoDateKoFormatter = (date: Date) => {
  if (!date) return undefined;
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();

  return `${year}년 ${month >= 10 ? month : "0" + month}월 ${
    day >= 10 ? day : "0" + day
  }일 ${hour >= 10 ? hour : "0" + hour}시 ${minute === 30 ? "30" : "00"}분`;
};

export const isoHourFormatter = (date: Date) => {
  if (!date) return undefined;
  const hour = date.getHours();
  const minute = date.getMinutes();
  return `${hour >= 10 ? hour : "0" + hour}:${
    minute >= 10 ? minute : "0" + minute
  }:00`;
};

export const isoTimeFormatter = (date: Date) => {
  if (!date) return undefined;
  const hour = date.getHours();
  const minute = date.getMinutes();
  return `${hour >= 10 ? hour : "0" + hour}:${
    minute >= 10 ? minute : "0" + minute
  }`;
};
