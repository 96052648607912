import "../../styles/Mypage/Mypage.css";
import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useUserState } from "../../system/context/UserContext";

import EventNoteIcon from "@mui/icons-material/EventNote";
import DateRangeIcon from "@mui/icons-material/DateRange";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import MypageNotice from "./MypageNotice";
import MypageDeptNotice from "./MypageDeptNotice";
import MypageQna from "./MypageQna";
import MypageBreakfastReview from "./MypageBreakfastReview";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function MypageMain() {
  const user = useUserState();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div className="Mypageboard">
      <Box sx={{ width: "100%" }}>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} textColor="inherit" onChange={handleChange} aria-label="basic tabs example">
              <Tab label="전사 공지" {...a11yProps(0)} />
              <Tab label={user.parentTeam + " 공지"} {...a11yProps(1)} />
              <Tab label="질의응답" {...a11yProps(2)} />
              <Tab label="주간 아침 메뉴(본사) 리뷰" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <div>
            {value === 0 ? <MypageNotice /> : ""}
            {value === 1 ? <MypageDeptNotice /> : ""}
            {value === 2 ? <MypageQna /> : ""}
            {user.workplace === "본사" ? <>{value === 3 ? <MypageBreakfastReview /> : ""}</> : ""}
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default MypageMain;
