import React, { useEffect, useState } from "react";
import { useUserState } from "../../../system/context/UserContext";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import "../../../../src/styles/Components/Main/Portlet.css";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 600,
  },
}));

function OfficeAttendancePortlet() {
  const user = useUserState();
  const [commuteData, setCommuteData] = useState({});
  const [loading, setLoading] = useState(false);
  const [totalLeave, setTotalLeave] = useState(0);
  const [usedAnnualLeave, setUsedAnnualLeave] = useState(0);
  const [yearlyLate, setYearlyLate] = useState(0);
  const [yearlyLateExp, setYearlyLateExp] = useState(0);
  const [monthlyNightShift, setMonthlyNightShift] = useState(0);

  // 날짜
  const today = new Date();
  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth() + 1;

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://insamansa2.weareinbody.com/api/attendance/monthly/${user.employeeId}/${todayYear}/${todayMonth}`
      )
      .then((res) => {
        setCommuteData(res.data);
        setTotalLeave(res.data.totalLeave);
        setUsedAnnualLeave(res.data.usedAnnualLeave);
        setYearlyLate(res.data.yearlyLate);
        setMonthlyNightShift(res.data.monthlyNightShift);
        setYearlyLateExp(res.data.yearlyLateExp);
      });
  }, []);

  useEffect(() => {
    return () => setLoading(false); // cleanup function을 이용
  }, []);
  return (
    <div className="PortletLayout">
      <Typography
        variant="h5"
        component="div"
        style={{ padding: "5px 0px 5px 5px" }}
      >
        근태정보
      </Typography>
      <div className="portlet_content">
        <Typography
          variant="h5"
          component="div"
          align="center"
          style={{ marginTop: "10%" }}
        >
          <div style={{ display: "inline-block", width: "20%" }}>
            <Typography
              variant="h6"
              style={{ marginBottom: "35px", color: "#4B4F5A" }}
            >
              총 연차
              <HtmlTooltip
                placement="right"
                title={
                  <React.Fragment>
                    <Typography color="inherit">총 연차 기준</Typography>

                    <b>{" 총 연차 = 연차 + 월차"}</b>
                    <br />

                    {
                      " ※ 입사 2년차까지의 연차 계산은 입사일부터 현재까지를 기준으로 합니다."
                    }
                    <br />
                  </React.Fragment>
                }
              >
                <InfoIcon color="disabled" fontSize="small" />
              </HtmlTooltip>
            </Typography>
            <Typography variant="h4" style={{ marginTop: "20px" }}>
              {totalLeave ? totalLeave : "- "}
              <Typography
                variant="subtitle2"
                component="span"
                style={{ color: "#81848B" }}
              >
                일
              </Typography>
            </Typography>
          </div>
          <div style={{ display: "inline-block", width: "33%" }}>
            <Typography
              variant="h6"
              component="span"
              style={{ color: "#4B4F5A" }}
            >
              잔여/사용
            </Typography>
            <Typography
              variant="subtitle2"
              component="span"
              style={{ color: "#81848B" }}
            >
              {" "}
              연차
            </Typography>
            <Typography variant="h4" style={{ marginTop: "36px" }}>
              {totalLeave ? totalLeave - usedAnnualLeave : "- "}
              <Typography
                variant="subtitle2"
                component="span"
                style={{ color: "#81848B" }}
              >
                일
                <Typography
                  variant="h6"
                  component="span"
                  style={{ color: "#81848B" }}
                >
                  {" "}
                  /
                </Typography>
              </Typography>{" "}
              {usedAnnualLeave ? usedAnnualLeave : "- "}
              <Typography
                variant="subtitle2"
                component="span"
                style={{ color: "#81848B" }}
              >
                일
              </Typography>
            </Typography>
          </div>

          <div
            style={{
              display: "inline-block",
              width: "26%",
              borderLeft: "1px solid #CFD0D1 ",
            }}
          >
            <Typography
              variant="h6"
              style={{ marginBottom: "35px", color: "#4B4F5A" }}
            >
              연간 지각
            </Typography>
            <Typography variant="h4" style={{ marginTop: "20px" }}>
              {yearlyLate - yearlyLateExp || yearlyLate - yearlyLateExp > 0
                ? yearlyLate - yearlyLateExp
                : "- "}
              <Typography
                variant="subtitle2"
                component="span"
                style={{ color: "#81848B" }}
              >
                일
              </Typography>
            </Typography>
          </div>
          <div style={{ display: "inline-block", width: "21%" }}>
            <Typography
              variant="h6"
              style={{ marginBottom: "35px", color: "#4B4F5A" }}
            >
              월간 야근
            </Typography>
            <Typography variant="h4" style={{ marginTop: "20px" }}>
              {monthlyNightShift ? monthlyNightShift : "- "}
              <Typography
                variant="subtitle2"
                component="span"
                style={{ color: "#81848B" }}
              >
                일
              </Typography>
            </Typography>
          </div>
        </Typography>
      </div>
    </div>
  );
}

export default OfficeAttendancePortlet;
