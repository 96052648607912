import React, { useEffect, useState } from "react";
import { useUserState } from "../../system/context/UserContext";
import { NavLink } from "react-router-dom";
import ListSite from "./HeaderItem/ListSite";
import { EmployeeApi } from "../../system/ApiService";
import { useMsal } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
import TitleBox from "../../components/Common/TitleBox";
import "../../styles/pages/Layout/Header.css";

// material
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import InputBase from "@mui/material/InputBase";
import MuiDrawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Employee } from "../../system/types/interface";

import {
  Box,
  createTheme,
  CSSObject,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  styled,
  Theme,
  ThemeProvider,
  Toolbar,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Copy, DotsThreeVertical, Info, List, X } from "@phosphor-icons/react";
import { CloseButton } from "../../styles/theme";
import { HeaderSearchComponent } from "./ResponsiveScreen";
import AlertModal from "../../components/Common/AlertModal";
import { useRecoilState } from "recoil";
import { EmployeeListDataAtom } from "../../system/atom";
import {
  callContant,
  contactContant,
  EmployeeModalstyle,
  employeeTable,
  emptyEmployeeTable,
  modalTitle,
  tableBox,
  tableContent,
  tableTitle,
} from "../../styles/Components/Main/MainPortal";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 600,
    padding: "20px",
    backgroundColor: "rgba(18, 18, 18, 0.8)",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: "22px",
  },
}));

const drawerWidth = 235; // 60

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(0)})`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(0)})`,
  },
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface HeaderProps {
  open: boolean;
  setOpen: (status: boolean) => void;
}

function signOutClickHandler(instance: any, mail: string) {
  instance.logout();
  window.sessionStorage.clear();
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 13,
  },
}));

const searchBox = {
  position: "absolute" as "absolute",
  top: "250%",
  right: "-95px",
  transform: "translate(-50%, -50%)",
  height: 200,
  width: 300,
  bgcolor: "#ffffff",
  border: "1px solid #E0E0E0",
  borderRadius: "8px",
  boxShadow: 24,
  py: 1,
  overflow: "scroll",
};

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function Header({ open, setOpen }: HeaderProps) {
  const [data, setData] = useRecoilState(EmployeeListDataAtom);
  const theme = useTheme();
  const history = useHistory();
  const { instance } = useMsal();
  const user = useUserState();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openProfile = Boolean(anchorEl);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [employeeData, setEmployeeData]: any = useState<Employee[]>([]);

  const fields = { value: "name", text: "email" };
  useEffect(() => {
    EmployeeApi.GetEmployeeList().then((res) => {
      setData(res.data);
    });
  }, []);

  function searchEmployee(e: {
    target: { value: React.SetStateAction<string> };
  }) {
    setSearch(e.target.value);
    setSearchOpen(true);
  }

  function OpenEmployeeModal(selectData: Employee) {
    setEmployeeData(selectData);
    handleModalOpen();
  }

  // 임직원 검색 모달
  const [ModalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  function handleModalClose() {
    setModalOpen(false);
    setSearch("");
  }

  const handleCopyClipBoard = (text: any) => {
    try {
      navigator.clipboard.writeText(text);
      AlertModal("success", "클립보드에 복사되었습니다.");
    } catch (error) {
      AlertModal("msg", "클립보드 복사에 실패하였습니다.");
    }
  };

  // 검색창
  const [search, setSearch] = useState("");
  const [searchOpen, setSearchOpen] = useState(false);

  function stopSearch() {
    setSearchOpen(false);
    setSearch("");
  }

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <ThemeProvider theme={darkTheme}>
          <AppBar color="inherit" position="fixed" open={open}>
            <Toolbar>
              {/* 메뉴 아이콘 */}
              <IconButton
                edge="start"
                onClick={handleDrawerOpen}
                color="inherit"
                aria-label="open drawer"
                sx={{ marginRight: 1, ...(open && { display: "none" }) }}
              >
                <List />
              </IconButton>
              <IconButton
                edge="start"
                onClick={handleDrawerClose}
                color="inherit"
                aria-label="open drawer"
                sx={{ marginRight: 1, ...(!open && { display: "none" }) }}
              >
                <List />
              </IconButton>
              {/* 로고 - 홈버튼 */}
              <NavLink to="/">
                <img
                  src="/images/inbody_logo.png"
                  width="100px"
                  alt="title"
                ></img>
              </NavLink>
              {/* 사이트 이름 */}
              <Typography
                variant="h5"
                sx={{ flexGrow: 1 }}
                style={{
                  marginLeft: "10px",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
                onClick={() => {
                  history.push({ pathname: "/" });
                }}
              >
                업무 포털
              </Typography>

              <HeaderSearchComponent>
                <div className="searchEmployee">
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>

                  <input style={{ display: "none" }} />
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    className="inputField"
                    placeholder="임직원 검색"
                    value={search}
                    onChange={searchEmployee}
                  />
                  {searchOpen === false || search.length === 0 ? (
                    <></>
                  ) : (
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                      onClick={stopSearch}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </div>
              </HeaderSearchComponent>

              {search.length === 0 || searchOpen === false ? (
                ""
              ) : (
                <Box sx={searchBox}>
                  {data.map((item: Employee) =>
                    item.name.includes(search) ||
                    item.gwName.includes(search) ||
                    item.email.includes(search) ||
                    item.phone.includes(search) ? (
                      <div
                        className="mainBox"
                        key={item.name}
                        onClick={() => OpenEmployeeModal(item)}
                      >
                        <div
                          style={{
                            display: "border-box",
                            width: "30%",
                            color: "#000000",
                          }}
                        >
                          {item.name}
                        </div>
                        <div style={{ display: "border-box" }}>
                          <div className="employeeInfoColor">{item.team}</div>
                          <div className="employeeInfoColor">
                            {item.jobPosition} / {item.jobTitle}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  )}
                </Box>
              )}

              <Modal
                open={ModalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={EmployeeModalstyle}>
                  <CloseButton>
                    <X size={40} onClick={handleModalClose} />
                  </CloseButton>
                  <div style={modalTitle}>
                    <TitleBox title="임직원 검색" />
                    <HtmlTooltip
                      placement="right-start"
                      title={
                        <React.Fragment>
                          <Typography variant="subtitle1">도움말</Typography>
                          {
                            "본사 내선 번호는 앞자리에 따라 구분 : 02) 300-2***, 02) 2182-8***, 1***, 4***"
                          }
                          <br />
                          {"공장 내선 국번 : 041) 589-내선번호"}
                          <br />
                          {"양재 내선 국번 : 02) 6952-내선번호"}
                          <br />
                          <br />
                          {"벤처센터 대표 국번 : 02) 6952-8981"}
                          <br />
                          {"벤처센터 내선 국번 : 070) 4016-6***"}
                          <br />
                          {
                            "벤처센터 6F FAX : 02-6952-8982 / 7F FAX : 02-6952-8987"
                          }
                          <br />
                          {"LookinBody 내선 국번 : 02) 2098-내선번호"}
                        </React.Fragment>
                      }
                    >
                      <Info size={25} color="#6D6D6D" />
                    </HtmlTooltip>
                  </div>
                  {employeeData ? (
                    <div style={tableBox}>
                      <div style={employeeTable}>
                        <div style={tableTitle}>이름</div>
                        <div style={tableContent}>
                          {" "}
                          {employeeData.name}
                          {employeeData.name === employeeData.gwName ? (
                            ""
                          ) : (
                            <span style={{ marginLeft: "10px" }}>
                              / {employeeData.gwName}
                            </span>
                          )}
                        </div>
                      </div>
                      <div style={employeeTable}>
                        <div style={tableTitle}>소속</div>
                        <div style={tableContent}>
                          {employeeData?.group}
                          {employeeData?.department
                            ? " " + employeeData?.department
                            : ""}
                          {employeeData?.team ? " " + employeeData?.team : ""}
                        </div>
                      </div>
                      <div style={employeeTable}>
                        <div style={tableTitle}>직책</div>
                        <div style={tableContent}>
                          {" "}
                          {employeeData.jobPosition} / {employeeData.jobTitle}
                        </div>
                      </div>
                      <div style={employeeTable}>
                        <div style={tableTitle}>근무 위치</div>
                        <div style={tableContent}>
                          {" "}
                          {employeeData.workplace}
                        </div>
                      </div>
                      <div style={employeeTable}>
                        <div style={tableTitle}>연락처</div>
                        <div style={tableContent}>
                          <span style={contactContant}> 휴대폰</span>
                          {employeeData.phone}{" "}
                          <Tooltip
                            title="휴대전화 복사"
                            placement="right"
                            onClick={() => {
                              handleCopyClipBoard(`${employeeData.phone}`);
                            }}
                          >
                            <IconButton>
                              <Copy size={25} color="#6D6D6D" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                      <div style={emptyEmployeeTable}>
                        <div style={tableTitle}></div>

                        {employeeData.tel ? (
                          <div style={tableContent}>
                            <span style={callContant}> 내선번호</span>
                            {employeeData.tel}{" "}
                            <Tooltip
                              title="내선번호 복사"
                              placement="right"
                              onClick={() => {
                                handleCopyClipBoard(`${employeeData.tel}`);
                              }}
                            >
                              <IconButton>
                                <Copy size={25} color="#6D6D6D" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        ) : (
                          <div style={tableContent}></div>
                        )}
                      </div>
                      <div style={emptyEmployeeTable}>
                        <div style={tableTitle}></div>
                        <div style={tableContent}>
                          <span style={contactContant}> 이메일</span>
                          {employeeData.email}
                          <Tooltip
                            title="이메일 복사"
                            placement="right"
                            onClick={() => {
                              handleCopyClipBoard(`${employeeData.email}`);
                            }}
                          >
                            <IconButton>
                              <Copy size={25} color="#6D6D6D" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </Box>
              </Modal>
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <DotsThreeVertical />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={openProfile}
                  onClose={handleClose}
                  PaperProps={{ sx: { backgroundColor: "#ffffff" } }}
                >
                  <MenuItem onClick={handleClose} style={{ cursor: "default" }}>
                    {/* 사용자 프로필 */}
                    <div>
                      <Typography
                        variant="subtitle1"
                        align="left"
                        sx={{ color: "#121212" }}
                      >{` ${user.name}`}</Typography>
                      <Typography
                        variant="subtitle2"
                        align="left"
                        sx={{ color: "#121212" }}
                      >
                        {user.gwMail}
                      </Typography>
                    </div>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      history.push({ pathname: "/mypage" });
                    }}
                    style={{ color: "#121212" }}
                  >
                    내가 작성한 글
                  </MenuItem>
                  <MenuItem
                    onClick={() => signOutClickHandler(instance, user.gwMail)}
                    style={{ color: "#CC1F3B" }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>
        </ThemeProvider>
        <Drawer
          variant="permanent"
          open={open}
          sx={{
            "& .MuiDrawer-paper": {
              backgroundColor: "#ffffff",
            },
          }}
        >
          <DrawerHeader>
            <LightTooltip title="메뉴 숨기기" placement="left">
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon sx={{ color: grey[700] }} />
                ) : (
                  <ChevronLeftIcon sx={{ color: grey[700] }} />
                )}
              </IconButton>
            </LightTooltip>
          </DrawerHeader>
          <Divider style={{ borderBottom: "dashed 1px white" }} />
          <ListSite open={open} setOpen={setOpen} />
        </Drawer>
      </Box>
    </div>
  );
}

export default Header;
