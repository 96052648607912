import { useEffect, useState } from "react";
import {
  CustomButtonBlackSpace,
  CustomButtonGrey,
  CustomButtonWhite,
} from "../../styles/Common/CommonComponent";
import {
  EmptyPortletBox,
  FixedBox,
  FixedPortletMainBox,
  MoveMenuPortlet,
} from "../../styles/Components/Main/MainPortal";

import {
  PortletLocation,
  PortletLocationArray,
  PortletSetting,
} from "../../system/types/interface";
import { useUserState } from "../../system/context/UserContext";
import {
  ErrorHandler,
  PortletLocationApi,
  PortletSettingApi,
} from "../../system/ApiService";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { X } from "@phosphor-icons/react";
import { CloseButton, ModalTitle } from "../../styles/theme";
import TitleBox from "../Common/TitleBox";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Typography } from "@mui/material";
import AlertModal from "../Common/AlertModal";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import LongBreakfastPortlet from "../Portlet/long/LongBreakfastPortlet";
import LongCheonanMenuPortlet from "../Portlet/long/LongCheonanMenuPortlet";
import WeatherPortlet from "../Portlet/short/WeatherPortlet";
import OfficeAttendancePortlet from "../Portlet/short/OfficeAttendancePortlet";
import LongPaymentProgressPortlet from "../Portlet/long/LongPaymentProgressPortlet";
import LongPendingDocumentPortlet from "../Portlet/long/LongPendingDocumentPortlet";
import LongPaymentProgressStatusPortlet from "../Portlet/long/LongPaymentProgressStatusPortlet";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 700,
  bgcolor: "#ffffff",
  border: "1px solid #E0E0E0",
  boxShadow: 24,
  px: 4,
};
function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function MovedPortlet() {
  const user = useUserState();
  const userId = user.employeeId;
  // 모달
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  function handleClose() {
    setOpen(false);
    refreshPortlet();
  }

  // 포틀릿 설정
  const [breakfastPortlet, setBreakfastPortlet] = useState(true);
  const [cheonanMenuPortlet, setCheonanMenuPortlet] = useState(true);
  const [paymentProgressPortlet, setPaymentProgressPortlet] = useState(true);
  const [pendingDocumentPortlet, setPendingDocumentPortlet] = useState(true);
  const [paymentProgressStatusPortlet, setPaymentProgressStatusPortlet] =
    useState(true);

  const [portletSettingData, setPortletSettingData]: any = useState<
    PortletSetting[]
  >([]);

  function refreshPortlet() {
    PortletSettingApi.GetPortletSetting(user.employeeId)
      .then((res) => {
        setPortletSettingData(res.data);
        setBreakfastPortlet(res.data.breakfastPortlet);
        setCheonanMenuPortlet(res.data.cheonanMenuPortlet);
        setPaymentProgressPortlet(res.data.paymentProgressPortlet);
        setPendingDocumentPortlet(res.data.pendingDocumentPortlet);
        setPaymentProgressStatusPortlet(res.data.paymentProgressStatusPortlet);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
  }

  useEffect(() => {
    refreshPortlet();
  }, []);

  const savePortlet = () => {
    setOpen(false);

    if (portletSettingData) {
      const newData: PortletSetting = {
        ...portletSettingData,
        portletId: userId,
        breakfastPortlet: breakfastPortlet,
        cheonanMenuPortlet: cheonanMenuPortlet,
        paymentProgressPortlet: paymentProgressPortlet,
        pendingDocumentPortlet: pendingDocumentPortlet,
        paymentProgressStatusPortlet: paymentProgressStatusPortlet,
      };
      PortletSettingApi.UpdatePortletSetting(newData)
        .then(() => {
          // alert("포틀릿 설정이 완료되었습니다.");
          refreshPortlet();
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    } else {
      const newData: PortletSetting = {
        ...portletSettingData,
        portletId: userId,
        breakfastPortlet: breakfastPortlet,
        cheonanMenuPortlet: cheonanMenuPortlet,
        paymentProgressPortlet: paymentProgressPortlet,
        pendingDocumentPortlet: pendingDocumentPortlet,
        paymentProgressStatusPortlet: paymentProgressStatusPortlet,
      };
      const newData2: PortletLocation = {
        portletId: user.employeeId,
        weatherPortlet: 0,
        officeAttendancePortlet: 1,
        breakfastPortlet: 2,
        cheonanMenuPortlet: 3,
        paymentProgressPortlet: 4,
        pendingDocumentPortlet: 5,
        paymentProgressStatusPortlet: 6,
      };
      PortletSettingApi.InsertPortletSetting(newData)
        .then(() => {
          refreshPortlet();
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });

      PortletLocationApi.InsertPortletLocation(newData2)
        .then(() => {
          refreshPortletLocation();
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  };

  const ChangePortlet3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBreakfastPortlet(event.target.checked);
  };
  const ChangePortlet4 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheonanMenuPortlet(event.target.checked);
  };
  const ChangePortlet5 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentProgressPortlet(event.target.checked);
  };
  const ChangePortlet6 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPendingDocumentPortlet(event.target.checked);
  };
  const ChangePortlet7 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentProgressStatusPortlet(event.target.checked);
  };
  // 포틀릿 위치 설정

  const [portletLocationArray, setPortletLocationArray] = useState<
    PortletLocationArray[]
  >([]);
  const portletName = [
    "employeeId",
    "날씨 예보",
    "근태정보",
    "주간 아침 메뉴 (본사)",
    "오늘의 메뉴 (천안)",
    "상신함",
    "미결함",
    "결재 진행 현황",
  ];
  const portletClass = [
    "employeeId",
    <WeatherPortlet />,
    <OfficeAttendancePortlet />,
    <LongBreakfastPortlet />,
    <LongCheonanMenuPortlet />,
    <LongPaymentProgressPortlet />,
    <LongPendingDocumentPortlet />,
    <LongPaymentProgressStatusPortlet />,
  ];
  const portletNameList = [
    "주간 아침 메뉴 (본사)",
    "오늘의 메뉴 (천안)",
    "상신함",
    "미결함",
    "결재 진행 현황",
  ];

  function refreshPortletLocation() {
    PortletLocationApi.GetPortletLocation(user.employeeId)
      .then((res) => {
        var entries = Object.entries(res.data);

        var test_arrays: any = [];
        for (var order = 0; order < entries.length - 1; order++) {
          for (
            var portletnumber = 0;
            portletnumber < entries.length;
            portletnumber++
          ) {
            if (
              entries[portletnumber][1] === order &&
              portletName[portletnumber] !== "날씨 예보" &&
              portletName[portletnumber] !== "근태정보"
            ) {
              var test_dicts: any = {};
              test_dicts["class"] = entries[portletnumber][0];
              test_dicts["order"] = entries[portletnumber][1];
              test_dicts["Name"] = portletName[portletnumber];
              test_dicts["portletclass"] = portletClass[portletnumber];
              test_arrays.push(test_dicts);
            }
          }
        }
        setPortletLocationArray(test_arrays);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
  }
  const _initGrabData = {
    target: null,
    position: null,
    move_up: [],
    move_down: [],
    updateList: [],
  };

  useEffect(() => {
    refreshPortletLocation();
  }, []);

  const [grab, setGrab]: any = useState(_initGrabData);
  const [isDrag, setIsDrag] = useState(false);

  useEffect(() => {}, [grab, portletLocationArray]);
  const _onDragOver = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  const _onDragStart = (e: any) => {
    setIsDrag(true);
    setGrab({
      ...grab,
      target: e.target,
      position: Number(e.target.dataset.position),
      updateList: [...portletLocationArray],
    });

    e.target.classList.add("grabbing");
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target);

    e.target.classList.add("grabbing");
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target);
  };

  const _onDragEnd = (e: any) => {
    setIsDrag(false);
    e.target.classList.remove("grabbing");
    e.dataTransfer.dropEffect = "move";
    setPortletLocationArray([...grab.updateList]);
    setGrab({
      target: null,
      move_up: [],
      move_down: [],
      updateList: [],
    });
    e.target.style.visibility = "visible";
  };

  const _onDragEnter = (e: any) => {
    let grabPosition = Number(grab.target.dataset.position);
    let listPosition = grab.position;
    let targetPosition = Number(e.target.dataset.position);

    let move_up = [...grab.move_up];
    let move_down = [...grab.move_down];

    let data = [...grab.updateList];
    data[listPosition] = data.splice(targetPosition, 1, data[listPosition])[0];

    if (grabPosition > targetPosition) {
      move_down.includes(targetPosition)
        ? move_down.pop()
        : move_down.push(targetPosition);
    } else if (grabPosition < targetPosition) {
      move_up.includes(targetPosition)
        ? move_up.pop()
        : move_up.push(targetPosition);
    } else {
      move_down = [];
      move_up = [];
    }

    setGrab({
      ...grab,
      move_up,
      move_down,
      updateList: data,
      position: targetPosition,
    });
  };

  const _onDragLeave = (e: any) => {
    if (e.target === grab.target) {
      e.target.style.visibility = "hidden";
    }
  };

  // // 버튼 클릭 시 portletdata가 들어있지 않다면 Insert, 들어있으면 Update
  const onSave = (args: any) => {
    var weatherLocation = 0;
    var officeAttendanceLocation = 1;
    var breakfastLocation = 2;
    var cheonanMenuLocation = 3;
    var paymentProgressLocation = 4;
    var pendingDocumentLocation = 5;
    var paymentProgressStatusLocation = 6;

    for (var i = 0; i < 5; i++) {
      for (var j = 0; j < 5; j++) {
        if (portletLocationArray[i].Name === portletNameList[j]) {
          if (portletLocationArray[i].Name === "주간 아침 메뉴 (본사)") {
            breakfastLocation = i + 2;
          } else if (portletLocationArray[i].Name === "오늘의 메뉴 (천안)") {
            cheonanMenuLocation = i + 2;
          } else if (portletLocationArray[i].Name === "상신함") {
            paymentProgressLocation = i + 2;
          } else if (portletLocationArray[i].Name === "미결함") {
            pendingDocumentLocation = i + 2;
          } else if (portletLocationArray[i].Name === "결재 진행 현황") {
            paymentProgressStatusLocation = i + 2;
          }
        }
      }
    }

    const newData = {
      ...args.data,
      PortletId: user.employeeId,
      WeatherPortlet: weatherLocation,
      OfficeAttendancePortlet: officeAttendanceLocation,
      BreakfastPortlet: breakfastLocation,
      CheonanMenuPortlet: cheonanMenuLocation,
      PaymentProgressPortlet: paymentProgressLocation,
      PendingDocumentPortlet: pendingDocumentLocation,
      PaymentProgressStatusPortlet: paymentProgressStatusLocation,
    };

    PortletLocationApi.UpdatePortletLocation(newData)
      .then(() => {
        refreshPortlet();
        setOpen(false);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
  };

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <FixedPortletMainBox>
      <FixedBox>
        {portletLocationArray.map((item: any, index: number) => (
          <li key={index} className="flex-itemList">
            {portletSettingData[item["class"]] ? (
              <MoveMenuPortlet>{item.portletclass}</MoveMenuPortlet>
            ) : (
              ""
            )}
          </li>
        ))}
        <EmptyPortletBox></EmptyPortletBox>
      </FixedBox>
      <FixedBox>
        <EmptyPortletBox></EmptyPortletBox>{" "}
        <EmptyPortletBox>
          {" "}
          <CustomButtonWhite onClick={handleOpen}>
            포틀릿 설정
          </CustomButtonWhite>
        </EmptyPortletBox>
      </FixedBox>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseButton>
            <X onClick={handleClose} size={40} />
          </CloseButton>
          <ModalTitle>
            <TitleBox title="포틀릿 관리" />
          </ModalTitle>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="inherit"
                aria-label="inherit tabs example"
              >
                <Tab
                  className="tabtitle"
                  label="포틀릿 설정"
                  {...a11yProps(0)}
                />
                <Tab
                  className="tabtitle"
                  label="포틀릿 순서"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <div className="tabcomponent">
              {value === 0 ? (
                <div>
                  <Typography variant="subtitle1" component="div">
                    원하시는 포틀릿을 선택해 주세요.
                  </Typography>
                  <div className="portletSettingList">
                    <Box sx={{ display: "flex" }}>
                      <FormControl
                        sx={{ m: 2 }}
                        component="fieldset"
                        variant="standard"
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!!breakfastPortlet}
                                sx={{
                                  color: "#971B2F",
                                  "&.Mui-checked": { color: "#971B2F" },
                                }}
                                onChange={ChangePortlet3}
                              />
                            }
                            label="주간 아침 메뉴 (본사)"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!!cheonanMenuPortlet}
                                sx={{
                                  color: "#971B2F",
                                  "&.Mui-checked": { color: "#971B2F" },
                                }}
                                onChange={ChangePortlet4}
                              />
                            }
                            label="오늘의 메뉴 (천안)"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!!paymentProgressPortlet}
                                sx={{
                                  color: "#971B2F",
                                  "&.Mui-checked": { color: "#971B2F" },
                                }}
                                onChange={ChangePortlet5}
                              />
                            }
                            label="상신함"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!!pendingDocumentPortlet}
                                sx={{
                                  color: "#971B2F",
                                  "&.Mui-checked": { color: "#971B2F" },
                                }}
                                onChange={ChangePortlet6}
                              />
                            }
                            label="미결함"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!!paymentProgressStatusPortlet}
                                sx={{
                                  color: "#971B2F",
                                  "&.Mui-checked": { color: "#971B2F" },
                                }}
                                onChange={ChangePortlet7}
                              />
                            }
                            label="결재 진행 현황"
                          />
                        </FormGroup>
                      </FormControl>
                    </Box>
                  </div>
                  <div className="tab_bt_wrap">
                    <CustomButtonGrey onClick={handleClose}>
                      취소
                    </CustomButtonGrey>
                    <CustomButtonBlackSpace onClick={savePortlet}>
                      저장
                    </CustomButtonBlackSpace>
                  </div>
                </div>
              ) : (
                ""
              )}
              {value === 1 ? (
                <div>
                  <div className="portletlist">
                    <div className="portletLocationList">
                      <div onDragOver={_onDragOver} className="movePortletList">
                        {portletLocationArray
                          ? portletLocationArray.map((item: any, index) => (
                              <li
                                key={index}
                                className="movePortlet"
                                draggable={true}
                                data-position={index}
                                onDragStart={_onDragStart}
                                onDragEnd={_onDragEnd}
                                onDragEnter={_onDragEnter}
                                onDragLeave={_onDragLeave}
                              >
                                {item.Name}
                              </li>
                            ))
                          : " "}
                        <div className="disabledPortlet"> </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab_bt_wrap">
                    <CustomButtonGrey onClick={handleClose}>
                      취소
                    </CustomButtonGrey>
                    <CustomButtonBlackSpace onClick={onSave}>
                      저장
                    </CustomButtonBlackSpace>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </Box>
        </Box>
      </Modal>
    </FixedPortletMainBox>
  );
}

export default MovedPortlet;
