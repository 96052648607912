import "../../../src/styles/Components/BreakfastTable/BreakfastTable.css";

import { useEffect, useState } from "react";
import { BreakfastRanking, MenuList } from "../../system/types/interface";
import {
  InitBreakfastRanking,
  InitMenuList,
} from "../../system/types/initObject";
import {
  BreakfastRatingApi,
  ErrorHandler,
  MenuApi,
} from "../../system/ApiService";
import CloseIcon from "@mui/icons-material/Close";
import TitleBox from "../Common/TitleBox";
import { Box, Modal, Typography } from "@mui/material";
import Rating from "@mui/material/Rating";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useUserState } from "../../system/context/UserContext";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { DetailReview, EmptyBox } from "../../styles/theme";
import {
  CustomButtonBlackSpace,
  CustomButtonGreen,
  CustomButtonGrey,
  CustomButtonRed,
} from "../../styles/Common/CommonComponent";
import AlertModal from "../Common/AlertModal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 850,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function BreakfastTable(refreshData: any) {
  const user = useUserState();
  const [data, setData] = useState<MenuList>(InitMenuList);
  const [nextdata, setNextData] = useState<MenuList>(InitMenuList);
  const [todayData, setTodayData] = useState("");
  var currentDay = new Date();
  var theYear = currentDay.getFullYear();
  var theMonth = currentDay.getMonth();
  var theDate = currentDay.getDate();
  var theDayOfWeek = currentDay.getDay();
  var thisWeek: any = [];
  var nextWeek: any = [];
  var weekDate: any = [];
  var nextWeekDate: any = [];

  var dayoftheweek = ["일", "월", "화", "수", "목", "금", "토"];

  var pretodaymonth = theMonth + 1;
  var todaymonth =
    String(pretodaymonth).length === 1
      ? "0" + `${pretodaymonth}`
      : `${pretodaymonth}`;
  var todayDay =
    String(theDate).length === 1 ? "0" + `${theDate}` : `${theDate}`;
  var today =
    todaymonth + "월 " + todayDay + "일 " + `(${dayoftheweek[theDayOfWeek]})`;
  var todayDate = Number(theYear + "" + todaymonth + "" + todayDay);

  for (var i = 1; i < 6; i++) {
    var resultDay = new Date(theYear, theMonth, theDate + (i - theDayOfWeek));
    var yyyy = resultDay.getFullYear();
    var premm = Number(resultDay.getMonth()) + 1;
    var predd = resultDay.getDate();

    var mm = String(premm).length === 1 ? "0" + `${premm}` : `${premm}`;
    var dd = String(predd).length === 1 ? "0" + `${predd}` : `${predd}`;

    thisWeek[i - 1] = mm + "월 " + dd + "일 " + `(${dayoftheweek[i]})`;
    weekDate[i - 1] = yyyy + mm + dd;
  }

  for (var i = 8; i < 13; i++) {
    var resultDay = new Date(theYear, theMonth, theDate + (i - theDayOfWeek));
    var yyyy = resultDay.getFullYear();
    var premm = Number(resultDay.getMonth()) + 1;
    var predd = resultDay.getDate();

    var mm = String(premm).length === 1 ? "0" + `${premm}` : `${premm}`;
    var dd = String(predd).length === 1 ? "0" + `${predd}` : `${predd}`;

    nextWeek[i - 8] = mm + "월 " + dd + "일 " + `(${dayoftheweek[i - 7]})`;
    nextWeekDate[i - 8] = yyyy + mm + dd;
  }
  var menuDate = Number(weekDate[0]);
  var menuNextDate = Number(nextWeekDate[0]);

  const mainmenu = [
    data.mainMon,
    data.mainTue,
    data.mainWed,
    data.mainThu,
    data.mainFri,
  ];
  const nextmainmenu = [
    nextdata.mainMon,
    nextdata.mainTue,
    nextdata.mainWed,
    nextdata.mainThu,
    nextdata.mainFri,
  ];
  const detailmenu = [
    data.detailMon,
    data.detailTue,
    data.detailWed,
    data.detailThu,
    data.detailFri,
  ];
  const detailName = [
    "detailMon",
    "detailTue",
    "detailWed",
    "detailThu",
    "detailFri",
  ];
  const nextdetailmenu = [
    nextdata.detailMon,
    nextdata.detailTue,
    nextdata.detailWed,
    nextdata.detailThu,
    nextdata.detailFri,
  ];

  useEffect(() => {
    if (menuDate) {
      MenuApi.GetMenuList(menuDate)
        .then((res: any) => {
          setData(res.data);
          if (res.data.menuId !== 0) {
            if (theDayOfWeek === 1) {
              setTodayData(res.data.detailMon);
            } else if (theDayOfWeek === 2) {
              setTodayData(res.data.detailTue);
            } else if (theDayOfWeek === 3) {
              setTodayData(res.data.detailWed);
            } else if (theDayOfWeek === 4) {
              setTodayData(res.data.detailThu);
            } else if (theDayOfWeek === 5) {
              setTodayData(res.data.detailFri);
            }
          }
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
    if (menuNextDate) {
      MenuApi.GetMenuList(menuNextDate)
        .then((res) => {
          setNextData(res.data);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  }, [refreshData]);

  // 모달
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  function handleClose() {
    setOpen(false);
  }
  function emptyBox() {}

  // 평가
  const [myratingId, setMyRatingId] = useState(0);
  function onSave() {
    if (myReviewData.selectMenu === "") {
      AlertModal("check", "오늘 선택한 메뉴를 입력해주세요.");
      return;
    }

    if (myReviewData.ratingId === 0) {
      const newData: BreakfastRanking = {
        ...myReviewData,
        menuDate: todayDate,
        mainMenu: mainmenu[theDayOfWeek - 1],
        employeeId: user.employeeId,
        employeeName: user.name,
        anonymity: anonymity,
      };
      BreakfastRatingApi.InsertBreakfastRating(newData)
        .then(() => {
          console.log("리뷰 작성 완료");
          setCheckWrite(true);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    } else {
      const newData: BreakfastRanking = {
        ...myReviewData,
        ratingId: myratingId,
        menuDate: todayDate,
        mainMenu: mainmenu[theDayOfWeek - 1],
        employeeId: user.employeeId,
        employeeName: user.name,
        anonymity: anonymity,
      };
      BreakfastRatingApi.UpdateBreakfast(newData)
        .then(() => {
          console.log("리뷰 수정 완료");
          setCheckEdit(true);
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  }
  function onDelete() {
    BreakfastRatingApi.DeleteBreakfast(myratingId)
      .then(() => {
        console.log("리뷰 삭제 완료");
        setMyReviewData(InitBreakfastRanking);
        setCheckWrite(false);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
  }
  // rating
  const [myReviewData, setMyReviewData] =
    useState<BreakfastRanking>(InitBreakfastRanking);
  const [reviewData, setReviewData] = useState<BreakfastRanking[]>([]);
  const [reviewPageData, setReviewPageData] = useState<BreakfastRanking[]>([]);
  const [checkWrite, setCheckWrite] = useState(false);
  const [checkEdit, setCheckEdit] = useState(false);
  const [anonymity, setAnonymity] = useState(false);

  const OnChange = (args: any) => {
    if (args.target.name === "starRating") {
      setMyReviewData({
        ...myReviewData,
        [(args.target as HTMLInputElement).name]: Number(args.target.value),
      });
    } else {
      setMyReviewData({
        ...myReviewData,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    }
    setCheckEdit(false);
  };

  const onChangeChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAnonymity(e.target.checked);
  };

  useEffect(() => {
    BreakfastRatingApi.GetTotalBreakfastRating(todayDate).then((res) => {
      setReviewData(res.data);

      if (
        res.data.filter((dt: BreakfastRanking) => {
          return dt.employeeId === user.employeeId;
        }).length > 0
      ) {
        setMyReviewData(
          res.data.filter((dt: BreakfastRanking) => {
            return dt.employeeId === user.employeeId;
          })[0]
        );
        setMyRatingId(
          res.data.filter((dt: BreakfastRanking) => {
            return dt.employeeId === user.employeeId;
          })[0].ratingId
        );
        setCheckWrite(true);
      }
    });
  }, [checkWrite]);
  // 페이징 처리
  const [page, setPage] = useState(1);
  const dataLength = Number(reviewData.length);
  const [pageRange, setPageRange] = useState(3);
  const lastPage = Math.ceil(dataLength / pageRange);

  useEffect(() => {
    BreakfastRatingApi.GetReviewPageBreakfastRating(page, 3, todayDate).then(
      (res) => {
        setReviewPageData(res.data);
      }
    );
  }, [page, checkWrite, checkEdit]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // css
  const ratingBox = {
    marginTop: "15px",
    fontWeight: "700",
  };

  const ratingdescription = {
    display: "flex",
    alignItems: "center",
  };

  const reviewBox = {
    margin: "10px 0px",
    height: "160px",
  };

  const titleBox = {
    textAlign: "center" as const,
    margin: "40px",
  };

  return (
    <div className="breakfasttablemain">
      <div>
        <div style={titleBox}>
          <Typography variant="h6" style={{ fontWeight: "700" }}>
            이번 주 아침 메뉴
          </Typography>
        </div>
        <div className="thisweekend">
          {thisWeek.map((day: any, index: number) => {
            return (
              <div key={"thisWeek" + index} className="menuinfo">
                <div className="day">{day}</div>
                <div className="menubox">
                  <div className="mainMenu">
                    {mainmenu[index] ? mainmenu[index] : "추후 업데이트 예정"}
                  </div>
                  {detailmenu[index] ? (
                    detailmenu[index].split(",").map((menuinfo: any) => {
                      return (
                        <div key={menuinfo} className="subMenu">
                          {menuinfo}
                        </div>
                      );
                    })
                  ) : (
                    <div key={"thisWeekmenuempty" + index}></div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {theDayOfWeek !== 0 &&
        theDayOfWeek !== 6 &&
        todayData &&
        user.workplace === "본사" ? (
          <div className="thisweekend">
            {thisWeek.map((day: any, index: number) => {
              return (
                <div
                  key={day + index}
                  className={day === today ? "todayrankinfo" : "rankinfo"}
                  onClick={day === today ? handleOpen : emptyBox}
                >
                  {day === today ? (
                    <div>오늘 아침 메뉴 평가</div>
                  ) : (
                    <div className="rankinfo"></div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="modalTitle">
              <TitleBox
                title={pretodaymonth + "월 " + theDate + "일 아침 메뉴 평가"}
              />
              <CloseIcon
                onClick={handleClose}
                style={{ cursor: "pointer" }}
                sx={{ fontSize: 40 }}
              />
            </div>
            <div style={ratingBox}>
              <Typography variant="subtitle1">
                오늘 아침 메뉴{" "}
                <b style={{ fontSize: "20px" }}>{mainmenu[theDayOfWeek - 1]}</b>
                의 만족도를 별점으로 표시해 주세요.
              </Typography>

              <div style={ratingdescription}>
                <Rating
                  name="starRating"
                  value={myReviewData.starRating}
                  onChange={OnChange}
                  sx={{ fontSize: "50px" }}
                />
                ( {myReviewData.starRating} / 5 )
              </div>
            </div>
            <div style={ratingBox}>오늘 선택한 메뉴 (필수)</div>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="selectMenu"
                value={myReviewData.selectMenu}
                onChange={OnChange}
              >
                {todayData
                  ? todayData.split(",").map((menuinfo: any) => {
                      return (
                        <div key={menuinfo}>
                          <FormControlLabel
                            value={menuinfo}
                            control={<Radio />}
                            label={menuinfo}
                          />
                        </div>
                      );
                    })
                  : ""}
                <FormControlLabel
                  value="기타"
                  control={<Radio />}
                  label="기타"
                />
              </RadioGroup>
            </FormControl>

            <div style={ratingBox}>
              리뷰 작성 (선택)
              <input
                name="anonymity"
                type="checkbox"
                onChange={onChangeChecked}
                checked={anonymity}
                style={{
                  marginLeft: "10px",
                  fontWeight: "100",
                  accentColor: "#971B2F",
                }}
              />
              {"  "}
              익명으로 작성
            </div>

            <textarea
              style={{
                resize: "none",
                width: "930px",
                height: "100px",
                borderRadius: "8px",
                marginTop: "10px",
              }}
              name="review"
              value={myReviewData.review}
              onChange={OnChange}
            />
            <div className="tab_bt_wrap ">
              <CustomButtonGrey
                className="sample-btn e-control e-btn"
                type="submit"
                onClick={handleClose}
              >
                취소
              </CustomButtonGrey>
              {checkWrite ? (
                <>
                  <CustomButtonRed
                    className="sample-btn e-control e-btn"
                    type="submit"
                    onClick={onDelete}
                    style={{ marginLeft: "10px" }}
                  >
                    삭제
                  </CustomButtonRed>
                  <CustomButtonGreen
                    style={{ marginLeft: "10px" }}
                    className="sample-btn e-control e-btn"
                    type="submit"
                    onClick={onSave}
                  >
                    수정
                  </CustomButtonGreen>
                </>
              ) : (
                <CustomButtonBlackSpace
                  className="sample-btn e-control e-btn"
                  type="submit"
                  onClick={onSave}
                >
                  저장
                </CustomButtonBlackSpace>
              )}
            </div>
            <div style={ratingBox}>
              <div style={ratingBox}>리뷰</div>
              <div style={reviewBox}>
                {reviewPageData.length > 0 ? (
                  <div>
                    {reviewPageData.map((dt: BreakfastRanking) => {
                      return (
                        <DetailReview key={dt.ratingId}>
                          <Rating
                            name="starRating"
                            value={dt.starRating}
                            sx={{ fontSize: "20px" }}
                            readOnly
                          />
                          <Typography variant="subtitle2">
                            {dt.anonymity === true ? (
                              "익명"
                            ) : (
                              <>{dt.employeeName}</>
                            )}

                            <Typography
                              component="span"
                              variant="body2"
                              style={{
                                marginLeft: "5px",
                                paddingLeft: "5px",
                                color: "#81848B",
                                borderLeft: "2px solid #DCDFE3",
                              }}
                            >
                              {dt.selectMenu}
                            </Typography>
                          </Typography>
                          <div
                            style={{
                              marginTop: "5px",
                              fontWeight: "200",
                              fontSize: "17px",
                            }}
                          >
                            <pre
                              style={{
                                whiteSpace: "pre-wrap",
                                overflow: "auto",
                                wordBreak: "break-all",
                              }}
                            >
                              {dt.review}
                            </pre>
                          </div>
                        </DetailReview>
                      );
                    })}
                  </div>
                ) : (
                  <EmptyBox>리뷰를 작성해주세요.</EmptyBox>
                )}
              </div>
              <Stack spacing={2} alignItems="center">
                <Pagination
                  count={lastPage}
                  defaultPage={1}
                  page={page}
                  siblingCount={2}
                  boundaryCount={2}
                  onChange={handleChange}
                />
              </Stack>
            </div>
          </Box>
        </Modal>
        <div style={titleBox}>
          <Typography variant="h6" style={{ fontWeight: "700" }}>
            다음 주 아침 메뉴
          </Typography>
        </div>

        <div className="nextweekend">
          {nextWeek.map((day: any, index: number) => {
            return (
              <div key={"nextWeek" + index} className="menuinfo">
                <div className="day">{day}</div>

                <div className="menubox">
                  <div className="mainMenu">
                    {nextmainmenu[index]
                      ? nextmainmenu[index]
                      : "추후 업데이트 예정"}
                  </div>
                  {nextdetailmenu[index] ? (
                    nextdetailmenu[index]
                      .split(",")
                      .map((menuinfo: any, i: number) => {
                        return (
                          <div key={menuinfo + i} className="subMenu">
                            {menuinfo}
                          </div>
                        );
                      })
                  ) : (
                    <div key={"nextmenuempty" + i} className="subMenu"></div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default BreakfastTable;
