import { ReactElement, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Content from "../pages/Layout/Content";
import Footer from "../pages/Layout/Footer";
import Header from "../pages/Layout/Header";
import LandingMain from "../pages/Layout/LandingMain";
import NoticeMain from "../pages/Notice/NoticeMain";
import NoticeDetail from "../pages/Notice/NoticeDetail";
import NoticeEdit from "../pages/Notice/NoticeEdit";
import DepartmentNoticeMain from "../pages/DepartmentNotice/DepartmentNoticeMain";
import DepartmentNoticeEdit from "../pages/DepartmentNotice/DepartmentNoticeEdit";
import DepartmentNoticeDetail from "../pages/DepartmentNotice/DepartmentNoticeDetail";
import QnaMain from "../pages/Qna/QnaMain";
import QnaDetail from "../pages/Qna/QnaDetail";
import QnaEdit from "../pages/Qna/QnaEdit";
import Error from "../pages/Error/Error";
import { CommonRoutes } from "../system/types/type";
import BreakfastTableMain from "../pages/InbodyMenu/BreakfastTable";
import Mypage from "../pages/Mypage/Mypage";
import Cheonan from "../pages/InbodyMenu/Cheonan";
import MenuAdmin from "../pages/Admin/MenuAdmin";
import YeonsuwonAdmin from "../pages/Admin/YeonsuwonAdmin";
import CeoScheduleMain from "../pages/CeoSchedule/CeoScheduleMain";

function Routes(): ReactElement {
  const [open, setOpen] = useState<boolean>(true);
  return (
    <>
      <div
        style={
          open
            ? { minHeight: "100%", marginLeft: "100px" }
            : { minHeight: "100%" }
        }
      >
        {/* Header - Navigation Menu */}
        <Header open={open} setOpen={setOpen}></Header>
        {/* Body - Content */}
        <Content open={open}>
          <Switch>
            {/* 사이트 메인 페이지 */}
            <Route
              path={CommonRoutes.root}
              component={LandingMain}
              exact
            ></Route>
            {/* 공지사항 */}
            <Route
              path={CommonRoutes.notice}
              component={NoticeMain}
              exact
            ></Route>

            <Route
              path={CommonRoutes.noticedetail}
              component={NoticeDetail}
            ></Route>
            <Route
              path={CommonRoutes.noticeedit}
              component={NoticeEdit}
            ></Route>
            {/* 부서별 공지사항 */}
            <Route
              path={CommonRoutes.departmentnotice}
              component={DepartmentNoticeMain}
              exact
            ></Route>
            <Route
              path={CommonRoutes.departmentnoticedetail}
              component={DepartmentNoticeDetail}
            ></Route>
            <Route
              path={CommonRoutes.departmentnoticeedit}
              component={DepartmentNoticeEdit}
            ></Route>
            {/* QnA */}
            <Route path={CommonRoutes.qna} component={QnaMain} exact></Route>
            <Route path={CommonRoutes.qnadetail} component={QnaDetail}></Route>
            <Route path={CommonRoutes.qnaedit} component={QnaEdit}></Route>
            {/* 아침주간메뉴 */}
            <Route
              path={CommonRoutes.breakfasttable}
              component={BreakfastTableMain}
              exact
            ></Route>
            <Route
              path={CommonRoutes.cheonanMenu}
              component={Cheonan}
              exact
            ></Route>
            {/* CEO 스케줄표 */}
            <Route
              path={CommonRoutes.ceoschedule}
              component={CeoScheduleMain}
              exact
            ></Route>

            {/* Admin */}
            <Route
              path={CommonRoutes.menuAdmin}
              component={MenuAdmin}
              exact
            ></Route>
            <Route
              path={CommonRoutes.yeonsuwonAdmin}
              component={YeonsuwonAdmin}
              exact
            ></Route>
            {/* 마이페이지 */}
            <Route path={CommonRoutes.mypage} component={Mypage} exact></Route>

            {/* error */}
            <Route path={CommonRoutes.error} component={Error} exact></Route>
          </Switch>
        </Content>
      </div>
      {/* Footer */}
      <Footer></Footer>
    </>
  );
}

export default Routes;
