import "../../../src/styles/Components/Main/Portlet.css";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import {
  NoticeBoxLayout,
  NoticeBoxTitle,
  PaymentProgressStatusBox,
  PaymentProgressStatusItem,
} from "../../styles/Components/Main/MainPortal";
import { Avatar, Grid, Skeleton, Typography } from "@mui/material";
import {
  GetDocumentListDto,
  GetPaymentProgressStatus,
} from "../../system/types/interface";

import { CustomText } from "../../styles/Components/Portlet/CustomText";
import { InitGetPaymentProgressStatus } from "../../system/types/initObject";
function PaymentProgressStatusPortlet() {
  const [data, setData] = useState<GetPaymentProgressStatus>(
    InitGetPaymentProgressStatus
  );
  useEffect(() => {
    let token = `Bearer ${sessionStorage.getItem("id_token")}` || "";

    fetch(`https://groupware.weareinbody.com/api/Document/count/dashboard`, {
      headers: {
        Authorization: token,
      },
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res);
      });
  }, []);

  function moveToInProgress() {
    window.open(
      "https://groupware.weareinbody.com/document/submit-document-box?filter=결재중"
    );
  }
  function moveToAwaintingApproval() {
    window.open(
      "https://groupware.weareinbody.com/document/pending-approval-box"
    );
  }
  function moveToUpcomingApproval() {
    window.open("https://groupware.weareinbody.com/document/pre-approval-box");
  }
  function moveToUnreadReference() {
    window.open("https://groupware.weareinbody.com/document/reference-box");
  }

  return (
    <div className="PortletLayout">
      <div className="PortletTitle">
        <Typography
          variant="h5"
          component="div"
          style={{ padding: "5px 0px 5px 5px" }}
        >
          결재 진행 현황
        </Typography>
        <div className="menuportlet_content">
          <PaymentProgressStatusBox>
            <Grid
              item
              container
              justifyContent={"space-evenly"}
              sx={{ flexWrap: "nowrap" }}
              xs={12}
            >
              <PaymentProgressStatusItem onClick={moveToInProgress}>
                <IconWrapper>
                  <Avatar
                    sx={{
                      backgroundColor: "rgb(71, 185, 152)",
                      width: 85,
                      height: 85,
                      margin: "0 auto",
                      pl: "10px",
                    }}
                  >
                    <svg
                      id="fi_2822656"
                      enableBackground="new 0 0 512 512"
                      fill="white"
                      height="45"
                      width="45"
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m501.757 125.977-14.143-14.142c-13.646-13.646-35.85-13.647-49.497 0l-28.284 28.284-19.825 19.825v-44.942c0-3.978-1.58-7.793-4.393-10.606l-99.995-100.002c-2.814-2.813-6.629-4.394-10.608-4.394h-230.004c-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h300c24.813 0 45-20.187 45-45v-179.777l83.459-83.459.005-.005 28.286-28.285c13.645-13.646 13.645-35.85-.001-49.497zm-211.749-74.764 48.786 48.787h-48.786zm70 415.787c0 8.271-6.729 15-15 15h-300c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h215v85c0 8.284 6.716 15 15 15h85v59.945l-82.056 82.055h-192.944c-8.284 0-15 6.716-15 15s6.716 15 15 15h169.197l-27.273 60h-141.924c-8.284 0-15 6.716-15 15s6.716 15 15 15h151.577.01c2.097 0 4.212-.44 6.203-1.345l77.782-35.356.003-.016c1.591-.724 3.088-1.724 4.396-3.033l35.028-35.028v149.778zm-71.547-130.054-21.662 9.847 9.847-21.662zm25.913-16.515-21.214-21.213 127.28-127.279 21.213 21.213zm166.17-166.17-17.679 17.678-21.213-21.213 17.678-17.678c1.95-1.95 5.123-1.948 7.071 0l14.142 14.141c1.951 1.951 1.951 5.124.001 7.072z"></path>
                      <path d="m85.008 212h220c8.284 0 15-6.716 15-15s-6.716-15-15-15h-220c-8.284 0-15 6.716-15 15s6.716 15 15 15z"></path>
                    </svg>
                  </Avatar>
                </IconWrapper>
                <CustomText type="subtitle">상신함(진행중)</CustomText>

                <CustomText type="title" bold500>
                  {data.inProgress}
                </CustomText>
              </PaymentProgressStatusItem>
              <PaymentProgressStatusItem onClick={moveToAwaintingApproval}>
                <IconWrapper>
                  <Avatar
                    sx={{
                      backgroundColor: "rgb(255, 169, 71)",
                      width: 85,
                      height: 85,
                      margin: "0 auto",
                    }}
                  >
                    <svg
                      id="fi_2822676"
                      fill="white"
                      height="45"
                      width="45"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m506.607 104.396-99.996-100.002c-2.813-2.814-6.629-4.394-10.607-4.394h-230.004c-24.813 0-45 20.187-45 45v197.838c-67.406 7.486-120 64.793-120 134.162 0 74.439 60.561 135 135 135h330c24.813 0 45-20.187 45-45v-351.997c0-3.979-1.58-7.793-4.393-10.607zm-95.607-53.183 32.59 32.591 16.196 16.196h-48.786zm-380 325.787c0-57.897 47.103-105 105-105s105 47.103 105 105-47.103 105-105 105-105-47.103-105-105zm435 105h-245.251c26.963-21.805 45.389-53.759 49.414-90h155.837c8.284 0 15-6.716 15-15s-6.716-15-15-15h-155.838c-2.446-22.02-10.204-42.459-21.968-60h177.806c8.284 0 15-6.716 15-15s-6.716-15-15-15h-205.251c-19.521-15.786-43.511-26.248-69.749-29.162v-197.838c0-8.271 6.729-15 15-15h215v85c0 8.284 6.716 15 15 15h85v337c0 8.271-6.729 15-15 15z"></path>
                      <path d="m426 182h-220c-8.284 0-15 6.716-15 15s6.716 15 15 15h220c8.284 0 15-6.716 15-15s-6.716-15-15-15z"></path>
                      <path d="m195.606 339.394c-5.857-5.857-15.355-5.857-21.213 0l-59.393 59.393-19.394-19.394c-5.857-5.857-15.355-5.857-21.213 0s-5.858 15.355 0 21.213l30 30c2.929 2.929 6.768 4.394 10.607 4.394s7.678-1.465 10.606-4.394l70-70c5.858-5.857 5.858-15.355 0-21.212z"></path>
                    </svg>
                  </Avatar>
                </IconWrapper>
                <CustomText type="subtitle">미결함</CustomText>

                <CustomText type="title" bold500>
                  {data.awaitingApproval}
                </CustomText>
              </PaymentProgressStatusItem>
              <PaymentProgressStatusItem onClick={moveToUpcomingApproval}>
                <IconWrapper>
                  <Avatar
                    sx={{
                      backgroundColor: "rgb(76, 141, 245)",
                      width: 85,
                      height: 85,
                      margin: "0 auto",
                    }}
                  >
                    <svg
                      id="fi_2822682"
                      enableBackground="new 0 0 512 512"
                      height="45"
                      width="45"
                      fill="white"
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m506.607 104.396-99.996-100.002c-2.813-2.814-6.629-4.394-10.607-4.394h-230.004c-24.813 0-45 20.187-45 45v197.838c-67.406 7.486-120 64.793-120 134.162 0 74.439 60.561 135 135 135h330c24.813 0 45-20.187 45-45v-351.997c0-3.979-1.58-7.793-4.393-10.607zm-95.607-53.183 48.786 48.787h-48.786zm-380 325.787c0-57.897 47.103-105 105-105s105 47.103 105 105-47.103 105-105 105-105-47.103-105-105zm435 105h-245.251c26.963-21.805 45.389-53.759 49.414-90h155.837c8.284 0 15-6.716 15-15s-6.716-15-15-15h-155.838c-2.446-22.02-10.204-42.459-21.968-60h177.806c8.284 0 15-6.716 15-15s-6.716-15-15-15h-205.251c-19.521-15.786-43.511-26.248-69.749-29.162v-197.838c0-8.271 6.729-15 15-15h215v85c0 8.284 6.716 15 15 15h85v337c0 8.271-6.729 15-15 15z"></path>
                      <path d="m426 182h-220c-8.284 0-15 6.716-15 15s6.716 15 15 15h220c8.284 0 15-6.716 15-15s-6.716-15-15-15z"></path>
                      <path d="m176 392c8.284 0 15-6.716 15-15s-6.716-15-15-15h-25v-45c0-8.284-6.716-15-15-15s-15 6.716-15 15v60c0 8.284 6.716 15 15 15z"></path>
                    </svg>
                  </Avatar>
                </IconWrapper>
                <CustomText type="subtitle">예결함</CustomText>

                <CustomText type="title" bold500>
                  {data.upcomingApproval}
                </CustomText>
              </PaymentProgressStatusItem>
              <PaymentProgressStatusItem onClick={moveToUnreadReference}>
                <IconWrapper>
                  <Avatar
                    sx={{
                      backgroundColor: "rgb(129, 132, 139)",
                      width: 85,
                      height: 85,
                      margin: "0 auto",
                      pl: "10px",
                    }}
                  >
                    <svg
                      id="fi_2356572"
                      enableBackground="new 0 0 512 512"
                      height="45"
                      width="45"
                      fill="white"
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <path d="m75 190h170c8.284 0 15-6.716 15-15s-6.716-15-15-15h-170c-8.284 0-15 6.716-15 15s6.716 15 15 15z"></path>
                        <path d="m205 220h-130c-8.284 0-15 6.716-15 15s6.716 15 15 15h130c8.284 0 15-6.716 15-15s-6.716-15-15-15z"></path>
                        <path d="m205 280h-130c-8.284 0-15 6.716-15 15s6.716 15 15 15h130c8.284 0 15-6.716 15-15s-6.716-15-15-15z"></path>
                        <path d="m205 340h-130c-8.284 0-15 6.716-15 15s6.716 15 15 15h130c8.284 0 15-6.716 15-15s-6.716-15-15-15z"></path>
                        <path d="m507.606 422.886-75.007-75.007c30.769-41.096 27.492-99.787-9.845-137.125-9.575-9.575-20.678-17.067-32.754-22.261v-73.493c0-3.978-1.58-7.793-4.394-10.606l-99.996-100c-2.812-2.813-6.628-4.394-10.606-4.394h-220.004c-30.327 0-55 24.673-55 55v402c0 30.327 24.673 55 55 55h280c30.327 0 55-24.673 55-55v-75.493c7.56-3.252 14.737-7.404 21.396-12.406l74.997 74.997c2.929 2.929 6.768 4.394 10.606 4.394s7.678-1.464 10.606-4.394c5.86-5.857 5.86-15.354.001-21.212zm-106.065-84.853c-8.677 8.677-19.11 14.952-30.493 18.523-.349.095-.693.203-1.031.322-6.891 2.052-14.117 3.122-21.509 3.122-20.033 0-38.868-7.801-53.033-21.967-14.166-14.166-21.967-33-21.967-53.033s7.801-38.867 21.967-53.033c14.165-14.166 33-21.967 53.033-21.967 7.394 0 14.622 1.07 21.514 3.123.335.118.674.224 1.02.318 11.385 3.571 21.821 9.847 30.5 18.525 29.241 29.244 29.24 76.825-.001 106.067zm-62.754-238.033h-48.787v-48.786zm21.213 357c0 13.785-11.215 25-25 25h-280c-13.785 0-25-11.215-25-25v-402c0-13.785 11.215-25 25-25h205v85c0 8.284 6.716 15 15 15h85v50.625c-3.791-.41-7.625-.625-11.492-.625-28.047 0-54.415 10.922-74.246 30.754-19.832 19.832-30.754 46.2-30.754 74.246 0 28.047 10.922 54.415 30.754 74.246 19.831 19.832 46.199 30.754 74.246 30.754 3.867 0 7.701-.215 11.492-.625z"></path>
                      </g>
                    </svg>
                  </Avatar>
                </IconWrapper>
                <CustomText type="subtitle">통보/열람함</CustomText>

                <CustomText type="title" bold500>
                  {data.unreadReference}
                </CustomText>
              </PaymentProgressStatusItem>
            </Grid>
          </PaymentProgressStatusBox>
        </div>
      </div>
    </div>
  );
}

export default PaymentProgressStatusPortlet;
/** Desktop */

const IconWrapper = styled.div`
  margin-bottom: 15px;
  & svg {
    font-size: 50px;
  }
`;
