import { styled } from "@mui/material/styles";
import {
  BLUESTROKE,
  GRAYPALEACCENT,
  GRAYPALEBORDER,
  GRAYPALECONTENTS,
  GRAYPALEDISABLED,
  GRAYPALETITLE,
  REDCONTENTS,
  REDSTROKE,
  REDTITLE,
} from "../../Color";
import { Grid, TableHead, TableRow, Typography } from "@mui/material";

export const MainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
  paddingBottom: "10px",
}));

export const ProfileBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
}));

export const ProfileTextBox = styled("div")(({ theme }) => ({
  margin: "0px 10px",
}));

export const SubTextBox = styled("div")(({ theme }) => ({
  marginRight: "5px",
}));

export const DisabledText = styled("div")(({ theme }) => ({
  color: `${GRAYPALEDISABLED}`,
}));

export const MainText = styled("div")(({ theme }) => ({
  color: `${GRAYPALETITLE}`,
}));

export const SubText = styled("div")(({ theme }) => ({
  color: `${GRAYPALECONTENTS}`,
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-end",
}));

export const PhotoBox = styled("div")(({ theme }) => ({
  width: "80px",
  height: "80px",
  borderRadius: "8px",
  overflow: "hidden",
}));

export const WeatherBox = styled("div")(({ theme }) => ({
  textAlign: "right",
}));

export const WeatherImage = styled("img")(({ theme }) => ({
  width: "30px",
  height: "30px",
  margin: "0px 10px",
}));

export const ForecastWeathERImage = styled("img")(({ theme }) => ({
  width: "30px",
  height: "30px",
}));

export const PortletContent = styled("div")(({ theme }) => ({
  borderTop: `1px solid ${GRAYPALEBORDER}`,
}));

export const TmrForecast = styled("div")(({ theme }) => ({
  display: "inline-block",
  margin: "5px",
  border: `1px solid ${GRAYPALEBORDER}`,
  borderRadius: "8px",
}));

export const TmrForecastContent = styled("div")(({ theme }) => ({
  display: "inline-block",
  padding: "5px 10px",
}));

export const MaximumTemperature = styled(Typography)(({ theme }) => ({
  color: `${REDSTROKE}`,
  fontWeight: "700",
}));

export const MinimumTemperature = styled(Typography)(({ theme }) => ({
  color: `${BLUESTROKE}`,
  fontWeight: "700",
}));

export const ForecastImg = styled("div")(({ theme }) => ({
  display: "inline-block",
  verticalAlign: "top",
  marginTop: "10px",
}));

export const TemperatureTitle = styled("span")(({ theme }) => ({
  fontWeight: "700",
  color: `${GRAYPALETITLE}`,
}));

export const TemperatureContent = styled("span")(({ theme }) => ({
  color: `${GRAYPALECONTENTS}`,
  marginRight: "10px",
}));

export const SettingText = styled("span")(({ theme }) => ({
  color: `${REDTITLE}`,
  fontWeight: "700",
  cursor: "pointer",
}));
export const MainProfileBox = styled("div")(({ theme }) => ({
  margin: "-30px auto 0px auto",
  maxWidth: "1600px",
  padding: "5px",
}));
///////////////////////////// 고정 포틀릿 ////////////////////////////
// fixed portlet
export const FixedPortletBox = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
}));
export const FixedPortletMainBox = styled("div")(({ theme }) => ({
  maxWidth: "1800px",
  overflow: "scroll",
  margin: "5px 0px"
}));

export const GridLeftBox = styled("div")(({ theme }) => ({
  display: "flex",
  width: "800px",
}));
export const GridRightBox = styled("div")(({ theme }) => ({
  display: "flex",
  width: "800px",
}));
// 캘린더
export const CalendarBox = styled("div")(({ theme }) => ({
  border: `1px solid ${GRAYPALEBORDER}`,
  borderRadius: "8px",
  width: "390px",
  height: "380px",
  margin: "5px",
  padding: "10px 5px",
  justifyContent: "center",
  display: "flex",
  alignItems: "center",
}));
// 오늘의 일정
export const ScheduleBox = styled("div")(({ theme }) => ({
  border: `1px solid ${GRAYPALEBORDER}`,
  borderRadius: "8px",
  width: "390px",
  margin: "5px",
  padding: "20px 30px 10px 30px",
  height: "380px",
  overflow: "scroll",
}));
// 오늘의 출퇴근 현황, 근태 정보
export const CommuteBox = styled("div")(({ theme }) => ({
  border: `1px solid ${GRAYPALEBORDER}`,
  borderRadius: "8px",
  width: "390px",
  height: "230px",
  margin: "5px",
  padding: "20px 30px 10px 30px",
}));
// 공지사항
export const NoticeBox = styled("div")(({ theme }) => ({
  border: `1px solid ${GRAYPALEBORDER}`,
  borderRadius: "8px",
  width: "790px",
  height: "770px",
  padding: "20px 30px 10px 30px",
  margin: "5px",
}));
// 누적 초과 근무 시간
export const OwTimeBox = styled("div")(({ theme }) => ({
  border: `1px solid ${GRAYPALEBORDER}`,
  borderRadius: "8px",
  width: "790px",
  height: "140px",
  padding: "20px 30px 10px 30px",
  backgroundColor: "lavendar",
  margin: "5px",
}));
export const FixedBox = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
}));
export const BoxSpacing = styled("div")(({ theme }) => ({
  margin: "0px",
}));

export const BoxTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
  paddingBottom: "10px",
}));

export const WorkingTimeAlign = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  margin: "10px 0px",
}));

export const WorkingTimeTitle = styled("div")(({ theme }) => ({
  color: `${GRAYPALETITLE}`,
  fontWeight: "700",
}));

export const WorkingTimeContent = styled("div")(({ theme }) => ({
  color: `${GRAYPALECONTENTS}`,
  fontWeight: "700",
}));

export const AttendanceAlign = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  margin: "15px 0px",
  paddingBottom: "15px",
}));
export const RedTitleText = styled(Typography)(({ theme }) => ({
  color: `${REDCONTENTS}`,
  fontWeight: "700",
}));


export const ScheduleSubBox = styled("div")(({ theme }) => ({
  border: `1px solid ${GRAYPALEBORDER}`,
  borderRadius: "8px",
  margin: "15px 0px",
  padding: "10px 20px",
}));

export const ScheduleEmptyBox = styled("div")(({ theme }) => ({
  padding: "10px 0px",
  textAlign: "center",
}));

// 공지사항

export const NoticeBoxLayout = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: "5px",
  alignItems: "flex-end",
}));

export const NoticeViewAll = styled("div")(({ theme }) => ({
  color: `${GRAYPALETITLE}`,
  cursor: "pointer",
}));

export const NoticeBoxTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
}));

export const PortletMainTitle = styled("div")(({ theme }) => ({
  marginBottom: "18px",
}));

export const PortletType = styled("div")(({ theme }) => ({
  textAlign: "center",
  width: "13%",
  paddingBottom: "3px",
  display: "inline-block",
  fontSize: "1rem",
  marginTop: "5px",
}));

export const PortletTitle = styled("div")(({ theme }) => ({
  cursor: "pointer",
  width: "48%",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  margin: "5px 0px -5px 14px",
  display: "inline-block",
  fontSize: "1rem",
}));

export const DeptPortletTitle = styled("div")(({ theme }) => ({
  cursor: "pointer",
  width: "63%",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  margin: "5px 0px -5px 14px",
  display: "inline-block",
  fontSize: "1rem",
}));

export const NoticeContent = styled("div")(({ theme }) => ({
  padding: "1px 0px 1px 0px",
  marginTop: "5px"
}));

export const NoticeContentChild = styled("div")(({ theme }) => ({
  display: "inline-block",
  fontSize: "1rem",
  marginTop: "5px",
}));

export const NoticeWriter = styled("div")(({ theme }) => ({
  width: "25%",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  color: "#4b4f5a",
  textAlign: "right",
  marginBottom: "-5px",
  display: "inline-block",
  fontSize: "1rem",
  marginTop: "5px",
  paddingRight: "5px",
}));

export const DeptWriter = styled("div")(({ theme }) => ({
  width: "10%",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  color: "#4b4f5a",
  textAlign: "right",
  marginBottom: "-5px",
  display: "inline-block",
  fontSize: "1rem",
  marginTop: "5px",
  paddingRight: "5px",
}));

export const NoticeDate = styled("div")(({ theme }) => ({
  width: "12%",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  color: "#4b4f5a",
  textAlign: "right",
  marginBottom: "-5px",
  display: "inline-block",
  fontSize: "1rem",
  marginTop: "5px",
  paddingLeft: "5px",
}));

export const PortletLayout = styled("div")(({ theme }) => ({
  marginTop: "5px",
}));

export const EmptyBox = styled("div")(({ theme }) => ({
  margin: "5px 0px",
  padding: "8px 0px 4px 0px",
  textAlign: "center",
}));

export const EmptyNotice = styled("div")(({ theme }) => ({
  fontSize: "20px",
  marginTop: "150px",
}));

export const NoticeSelectTitle = styled("div")(({ theme }) => ({
  padding: "5px",
  display: "flex",
}));

export const SelectedNotice = styled("div")(({ theme }) => ({
  fontWeight: "700",
  padding: "10px 15px",
  borderBottom: `1px solid ${GRAYPALEACCENT}`,
}));

export const DisabledNotice = styled("div")(({ theme }) => ({
  cursor: "pointer",
  color: `${GRAYPALEDISABLED}`,
  padding: "10px 15px",
}));

// 주간 메뉴표 포틀릿
export const MovePortletBox = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

export const MoveMenuPortlet = styled("div")(({ theme }) => ({
  width: "790px",
  border: `1px solid ${GRAYPALEBORDER}`,
  borderRadius: "8px",
  margin: "0px 5px 10px 5px",
  padding: "20px 30px 10px 30px",
}));

export const MenuPortletThisWeekend = styled("div")(({ theme }) => ({
  textAlign: "center",
  margin: "4px 0px",
}));

export const MenuPortletMenuInfo = styled("div")(({ theme }) => ({
  display: "inline-block",
  width: "19%",
  height: "200px",
  margin: "10px 3px",
  border: "1px solid #e0e0e0",
  borderRadius: "8px",
  textAlign: "center",
  verticalAlign: "top",
  overflow: "scroll",
}));

export const MenuPortletDay = styled("div")(({ theme }) => ({
  padding: "10px 0px",
  color: "#000000",
  fontWeight: "700",
  backgroundColor: "#f8f8f8",
}));

export const MenuPortletMenuBox = styled("div")(({ theme }) => ({
  margin: "",
}));

export const MenuPortletMainMenu = styled("div")(({ theme }) => ({
  textAlign: "center",
  fontSize: "1rem",
  padding: "5px 0px 10px 0px",
  margin: "0px ",
  borderBottom: "2px solid #e6e6e6",
}));

export const MenuPortletSubMenu = styled("div")(({ theme }) => ({
  textAlign: "center",
  fontSize: "16px",
  margin: "10px 0",
  color: "#a8a8a8",
}));

// 천안 메뉴표
export const CheonanPortletMenuInfo = styled("div")(({ theme }) => ({
  display: "inline-block",
  width: "32%",
  height: "200px",
  margin: "10px 3px",
  border: "1px solid #e0e0e0",
  borderRadius: "8px",
  textAlign: "center",
  verticalAlign: "top",
  overflow: "scroll",
}));

export const CheonanPortletEmptyBox = styled("div")(({ theme }) => ({
  textAlign: "center",
  marginTop: "58px",
  color: "#a8a8a8",
}));

export const CheonanPortletItem = styled("div")(({ theme }) => ({
  display: "block",
  padding: "3px",
}));

// 포틀릿 설정
export const PortletSettingAlign = styled("div")(({ theme }) => ({
  textAlign: "right",
  padding: "3px",
  marginRight: "15px",
}));

export const PortletSettingAlignNoMargin = styled("div")(({ theme }) => ({
  textAlign: "right",
  padding: "3px",
}));

export const EmptyPortletBox = styled("div")(({ theme }) => ({
  width: "790px",
  border: "0px",
  color: "transparent",
  margin: "0px 5px 10px 5px",
  textAlign: "right",
}));

// 상신함, 미결함
export const PaymentProgressBox = styled("div")(({ theme }) => ({
  marginTop: "8px",
  height: "220px",
}));

export const PaymentProgressStatusBox = styled("div")(({ theme }) => ({
  paddingTop: "30px",
  margin: "4px 0px",
  height: "220px",
}));

export const PaymentPortletHeader = styled(TableHead)(({ theme }) => ({
  backgroundColor: "#F0F2F5",
}));

export const PaymentPortletTableRow = styled(TableRow)(({ theme }) => ({
  "&:hover": { backgroundColor: "#F7F8FA" },
}));

export const searchBoxProfile = {
  position: "absolute" as "absolute",
  top: "315px",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 200,
  width: 370,
  bgcolor: "#ffffff",
  border: "1px solid #E0E0E0",
  borderRadius: "8px",
  boxShadow: 24,
  py: 1,
  overflow: "scroll",
};
// 결재 진형 현황

export const PaymentProgressStatusItem = styled("div")(({ theme }) => ({
  textAlign: "center",
  padding: "5px",
  width: "120px",
  cursor: "pointer",
  "&:hover": { color: "rgb(30, 102, 217)" },
}));

// 임직원 검색

export const EmployeeModalstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "100%",
  height: 600,
  width: 800,
  bgcolor: "#ffffff",
  border: "1px solid #E0E0E0",
  boxShadow: 24,
  px: 4,
};

export const modalTitle = {
  display: "flex",
  marginBottom: "30px",
  alignItems: "flex-end",
  paddingTop: "50px",
};

export const employeeTable = {
  display: "flex",

  borderTop: "1px solid #ECECEC",
};

export const emptyEmployeeTable = {
  display: "flex",
};

export const tableTitle = {
  display: "border-box",
  width: "200px",
  borderRight: "1px solid #ECECEC",
  color: "#A8A8A8",
  padding: "10px",
};

export const tableContent = {
  display: "border-box",
  width: "600px",
  marginLeft: "20px",
  padding: "10px",
};

export const contactContant = {
  width: "100px",
  paddingRight: "35px",
  marginRight: "30px",
  borderRight: "2px solid #ECECEC",
};

export const callContant = {
  width: "100px",
  paddingRight: "20px",
  marginRight: "30px",
  borderRight: "2px solid #ECECEC",
};

export const tableBox = {
  borderTop: "1px solid #D9D9D9",
  borderBottom: "1px solid #D9D9D9",
};
