import { useEffect, useState } from "react";
import "../../../src/styles/Components/Main/Portlet.css";
import {
  NoticeBoxLayout,
  NoticeBoxTitle,
  NoticeViewAll,
  PaymentProgressBox,
  PaymentPortletHeader,
  PaymentPortletTableRow,
} from "../../styles/Components/Main/MainPortal";
import { Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { isoDateFormatter } from "../Common/IsoDateFormatter";
import { CustomText } from "../../styles/Components/Portlet/CustomText";
import TableRow from "@mui/material/TableRow";
import { GetDocumentListDto } from "./PaymentProgressPortlet";
import CustomTextTooltip from "../../styles/Components/Portlet/CustomTextTooltip";
import { ErrorHandler } from "../../system/ApiService";
import AlertModal from "../Common/AlertModal";

function PendingDocumentPortlet() {
  const [data, setData] = useState<GetDocumentListDto[]>([]);
  function moveToView() {
    window.open("https://groupware.weareinbody.com/");
  }
  useEffect(() => {
    let token = `Bearer ${sessionStorage.getItem("id_token")}` || "";

    fetch(`https://groupware.weareinbody.com/api/Document/list/dashboard/2`, {
      headers: {
        Authorization: token,
      },
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);
  return (
    <div className="PortletLayout">
      <div className="PortletTitle">
        <Typography
          variant="h5"
          component="div"
          style={{ padding: "5px 0px 5px 5px" }}
        >
          미결함
          <Typography
            variant="subtitle2"
            component="span"
            onClick={moveToView}
            style={{
              cursor: "pointer",
              paddingTop: "10px",
              paddingRight: "5px",
              color: "#81848B",
              float: "right",
              display: "inline-block",
            }}
          >
            [바로가기]
          </Typography>
        </Typography>
        <div className="menuportlet_content">
          <PaymentProgressBox>
            <TableContainer component={Paper}>
              <Table size="small" sx={{ tableLayout: "fixed" }}>
                <PaymentPortletHeader>
                  <TableRow>
                    <TableCell align="center" width={"23%"}>
                      <CustomText type="subtitle">소속</CustomText>
                    </TableCell>
                    <TableCell align="center" width={"15%"}>
                      <CustomText type="subtitle">기안자</CustomText>
                    </TableCell>
                    <TableCell align="center" width={"42%"}>
                      <CustomText type="subtitle">문서명</CustomText>
                    </TableCell>
                    <TableCell align="center" width={"20%"}>
                      <CustomText type="subtitle">기안일</CustomText>
                    </TableCell>
                  </TableRow>
                </PaymentPortletHeader>
                <TableBody>
                  {data && data.length < 1 && (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <CustomText type="body" align="center" fullWidth>
                          조회된 데이터가 없습니다.
                        </CustomText>
                      </TableCell>
                    </TableRow>
                  )}

                  {data?.map((data) => {
                    return (
                      <PaymentPortletTableRow
                        key={data.id}
                        onClick={() => {
                          if (!!data.v4Id) {
                            window.open(
                              `https://inbodykr.sharepoint.com/sites/HQ/EApproval//Lists/ApprovalDocuments/DispForm.aspx?ID=${String(
                                data.v4Id
                              )}`
                            );
                          } else {
                            window.open(
                              `https://groupware.weareinbody.com/detail-document/` +
                                String(data.id)
                            );
                          }
                        }}
                      >
                        <TableCell align="center">
                          <CustomText type="body">{data.team}</CustomText>
                        </TableCell>
                        <TableCell align="center">
                          <CustomText type="body">{data.name}</CustomText>
                        </TableCell>
                        <TableCell align="left">
                          <CustomTextTooltip type="body">
                            {data.title}
                          </CustomTextTooltip>
                        </TableCell>
                        <TableCell align="center">
                          <CustomText type="body">
                            {isoDateFormatter(new Date(data.created))}
                          </CustomText>
                        </TableCell>
                      </PaymentPortletTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </PaymentProgressBox>
        </div>
      </div>
    </div>
  );
}

export default PendingDocumentPortlet;
