import Alert from '@mui/material/Alert';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function Error() {
    let history = useHistory();
    // useEffect(() => {
    //     history.push('/')
    // })

    return (
        <div>
            <Alert severity="error">비정상적인 접근입니다. 잠시 후, InBody 업무 포털로 이동합니다.</Alert>

        </div>
    );
}

export default Error;