import React, { useCallback, useEffect, useState } from "react";
import { Notice, DepartmentNotice } from "../../../system/types/interface";
import {
  NoticeApi,
  DepartmentNoticeApi,
  ErrorHandler,
} from "../../../system/ApiService";
import { Link, useHistory } from "react-router-dom";
import "../../../../src/styles/Components/Portlet/BoardPortlet.css";
import Typography from "@mui/material/Typography";
import { useUserState } from "../../../system/context/UserContext";
import NoticeComponent from "../../Common/NoticeComponent";
import AlertModal from "../../Common/AlertModal";

function NoticePortlet() {
  const [noticeClass, setNoticeClass] = useState(true);
  const [data, setData] = useState<Notice[]>([]);
  const [departmentData, setDepartmentData] = useState<DepartmentNotice[]>([]);
  let history = useHistory();
  const user = useUserState();

  useEffect(() => {
    let isComponentMoutned = true;

    NoticeApi.GetNoticeTotalList()
      .then((res) => {
        if (res.data) {
          if (isComponentMoutned) {
            setData(res.data.slice(0, 5));
          }
        }
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
    return () => {
      isComponentMoutned = false;
    };
  }, []);

  useEffect(() => {
    let isComponentMoutned = true;
    DepartmentNoticeApi.GetDepartmentNoticeTotalList(user.parentTeam)
      .then((res) => {
        if (isComponentMoutned) {
          setDepartmentData(res.data.slice(0, 5));
        }
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
    return () => {
      isComponentMoutned = false;
    };
  }, []);

  function toNotice() {
    setNoticeClass(true);
  }

  function toDepartmentNotice() {
    setNoticeClass(false);
  }

  return (
    <>
      {noticeClass === true ? (
        <div className="PortletLayout">
          <div className="portlet_maintitle">
            <Typography
              variant="h5"
              component="div"
              style={{ paddingTop: "5px", paddingLeft: "5px" }}
            >
              전사 공지
              {user.parentTeam === "공통파트" || !user.parentTeam ? (
                ""
              ) : (
                <Typography
                  onClick={toDepartmentNotice}
                  variant="subtitle1"
                  component="span"
                  style={{
                    cursor: "pointer",
                    paddingTop: "10px",
                    paddingRight: "5px",
                    color: "#81848B",
                  }}
                >
                  / {user.parentTeam} 공지
                </Typography>
              )}
              <Typography
                onClick={() => {
                  history.push("/notice/board/1");
                }}
                variant="subtitle2"
                component="span"
                style={{
                  cursor: "pointer",
                  paddingTop: "10px",
                  paddingRight: "5px",
                  color: "#81848B",
                  float: "right",
                  display: "inline-block",
                }}
              >
                [전체보기]
              </Typography>
            </Typography>
          </div>
          {data
            ? data.map((board: any) => {
                return (
                  <div
                    className="Content"
                    key={board.noticeId}
                    id={board.noticeId}
                  >
                    <div className="portlet_type">
                      <NoticeComponent type={board.type} />{" "}
                    </div>
                    <div
                      className="portlet_title"
                      onClick={() => {
                        history.push({
                          pathname: `/notice/detail/${board.noticeId}`,
                          state: { displays: board.noticeId },
                        });
                      }}
                    >
                      {board.noticeTitle}
                    </div>
                    <div className="writer">{board.noticeWriter}</div>
                    {board.noticeCreatedAt.split(" ")[1].split("월")[0]
                      .length === 1 &&
                    board.noticeCreatedAt.split(" ")[2].split("일")[0]
                      .length === 2 ? (
                      <div className="date">
                        {board.noticeCreatedAt.split("년")[0] +
                          ".0" +
                          board.noticeCreatedAt.split(" ")[1].split("월")[0] +
                          "." +
                          board.noticeCreatedAt.split(" ")[2].split("일")[0]}
                      </div>
                    ) : (
                      ""
                    )}
                    {board.noticeCreatedAt.split(" ")[1].split("월")[0]
                      .length === 2 &&
                    board.noticeCreatedAt.split(" ")[2].split("일")[0]
                      .length === 1 ? (
                      <div className="date">
                        {board.noticeCreatedAt.split("년")[0] +
                          "." +
                          board.noticeCreatedAt.split(" ")[1].split("월")[0] +
                          ".0" +
                          board.noticeCreatedAt.split(" ")[2].split("일")[0]}
                      </div>
                    ) : (
                      ""
                    )}
                    {board.noticeCreatedAt.split(" ")[2].split("일")[0]
                      .length === 1 &&
                    board.noticeCreatedAt.split(" ")[1].split("월")[0]
                      .length === 1 ? (
                      <div className="date">
                        {board.noticeCreatedAt.split("년")[0] +
                          ".0" +
                          board.noticeCreatedAt.split(" ")[1].split("월")[0] +
                          ".0" +
                          board.noticeCreatedAt.split(" ")[2].split("일")[0]}
                      </div>
                    ) : (
                      ""
                    )}
                    {board.noticeCreatedAt.split(" ")[2].split("일")[0]
                      .length === 2 &&
                    board.noticeCreatedAt.split(" ")[1].split("월")[0]
                      .length === 2 ? (
                      <div className="date">
                        {board.noticeCreatedAt.split("년")[0] +
                          "." +
                          board.noticeCreatedAt.split(" ")[1].split("월")[0] +
                          "." +
                          board.noticeCreatedAt.split(" ")[2].split("일")[0]}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })
            : ""}
          {data.length > 0 ? (
            ""
          ) : (
            <div className="emptyBox">
              <div className="emptynotice">작성된 공지사항이 없습니다.</div>
            </div>
          )}
        </div>
      ) : (
        <div className="PortletLayout">
          <div className="portlet_maintitle">
            <Typography
              variant="h5"
              component="div"
              style={{ paddingTop: "5px", paddingLeft: "5px" }}
            >
              {user.parentTeam} 공지
              <Typography
                onClick={toNotice}
                variant="subtitle1"
                component="span"
                style={{
                  cursor: "pointer",
                  paddingTop: "10px",
                  paddingRight: "5px",
                  color: "#81848B",
                }}
              >
                / 전사 공지
              </Typography>
              <Typography
                onClick={() => {
                  history.push("/departmentnotice/board/1");
                }}
                variant="subtitle2"
                component="span"
                style={{
                  cursor: "pointer",
                  paddingTop: "10px",
                  paddingRight: "5px",
                  color: "#81848B",
                  float: "right",
                  display: "inline-block",
                }}
              >
                [전체보기]
              </Typography>
            </Typography>
          </div>

          {departmentData
            ? departmentData.map((board: any) => {
                return (
                  <div
                    className="Content"
                    key={board.departmentNoticeId}
                    id={board.departmentNoticeId}
                  >
                    <div className="portlet_type">
                      <NoticeComponent type={board.type} />{" "}
                    </div>
                    <div
                      className="deptPortlet_title"
                      onClick={() => {
                        history.push({
                          pathname: `/departmentnotice/detail/${board.departmentNoticeId}`,
                          state: { displays: board.departmentNoticeId },
                        });
                      }}
                    >
                      {board.departmentNoticeTitle}
                    </div>
                    <div className="deptWriter">
                      {board.departmentNoticeWriter.split(" ")[1]}
                    </div>

                    {board.departmentNoticeCreatedAt
                      .split(" ")[1]
                      .split("월")[0].length === 1 &&
                    board.departmentNoticeCreatedAt.split(" ")[2].split("일")[0]
                      .length === 2 ? (
                      <div className="date">
                        {board.departmentNoticeCreatedAt.split("년")[0] +
                          ".0" +
                          board.departmentNoticeCreatedAt
                            .split(" ")[1]
                            .split("월")[0] +
                          "." +
                          board.departmentNoticeCreatedAt
                            .split(" ")[2]
                            .split("일")[0]}
                      </div>
                    ) : (
                      ""
                    )}
                    {board.departmentNoticeCreatedAt
                      .split(" ")[1]
                      .split("월")[0].length === 2 &&
                    board.departmentNoticeCreatedAt.split(" ")[2].split("일")[0]
                      .length === 1 ? (
                      <div className="date">
                        {board.departmentNoticeCreatedAt.split("년")[0] +
                          "." +
                          board.departmentNoticeCreatedAt
                            .split(" ")[1]
                            .split("월")[0] +
                          ".0" +
                          board.departmentNoticeCreatedAt
                            .split(" ")[2]
                            .split("일")[0]}
                      </div>
                    ) : (
                      ""
                    )}
                    {board.departmentNoticeCreatedAt
                      .split(" ")[2]
                      .split("일")[0].length === 1 &&
                    board.departmentNoticeCreatedAt.split(" ")[1].split("월")[0]
                      .length === 1 ? (
                      <div className="date">
                        {board.departmentNoticeCreatedAt.split("년")[0] +
                          ".0" +
                          board.departmentNoticeCreatedAt
                            .split(" ")[1]
                            .split("월")[0] +
                          ".0" +
                          board.departmentNoticeCreatedAt
                            .split(" ")[2]
                            .split("일")[0]}
                      </div>
                    ) : (
                      ""
                    )}
                    {board.departmentNoticeCreatedAt
                      .split(" ")[2]
                      .split("일")[0].length === 2 &&
                    board.departmentNoticeCreatedAt.split(" ")[1].split("월")[0]
                      .length === 2 ? (
                      <div className="date">
                        {board.departmentNoticeCreatedAt.split("년")[0] +
                          "." +
                          board.departmentNoticeCreatedAt
                            .split(" ")[1]
                            .split("월")[0] +
                          "." +
                          board.departmentNoticeCreatedAt
                            .split(" ")[2]
                            .split("일")[0]}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })
            : ""}
          {departmentData.length > 0 ? (
            ""
          ) : (
            <div className="emptyBox">
              <div className="emptynotice">작성된 공지사항이 없습니다.</div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default NoticePortlet;
