import {
  Box,
  Stack,
  styled,
  Typography,
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";
import React from "react";

interface ProgressTemplateProps {
  overWorkHours: number;
  maximumWorkingHours: number;
  weekPercentage: number;
  dayPercentage: number;
}

export default function ProgressTemplate({
  overWorkHours,
  maximumWorkingHours,
  weekPercentage,
  dayPercentage,
}: ProgressTemplateProps) {
  const BorderLinearProgress = styled(LinearProgress)<{
    value: number;
    percentage: number;
  }>(({ percentage }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#E0E0E0",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor:
        percentage >= 100
          ? "#CC1F3B"
          : percentage >= 70
          ? "#EF8100"
          : "#47B998",
    },
  }));

  return (
    <Stack sx={{ flexGrow: 1 }}>
      <Box>
        <Typography variant={"body2"} style={{ margin: "1px" }}>
          {"이번 주"}
          {`(${overWorkHours}${"시간"} / ${maximumWorkingHours}${"시간"})`}
        </Typography>
        <Box mt={"10px"}>
          <BorderLinearProgress
            variant="determinate"
            value={Math.min(100, weekPercentage)}
            percentage={Math.max(weekPercentage, dayPercentage)}
          />
        </Box>
      </Box>
    </Stack>
  );
}
