import React, { useCallback, useEffect, useState } from "react";
import "../../styles/Mypage/Board.css";
import { DepartmentNotice } from "../../system/types/interface";
import { DepartmentNoticeApi, ErrorHandler } from "../../system/ApiService";
import { Link, useHistory } from "react-router-dom";
import { useUserState } from "../../system/context/UserContext";

// syncfusion
import { enableRipple } from "@syncfusion/ej2-base";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

// material ui
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  StyledBody,
  StyledBody2,
  StyledBodyTitle,
  StyledHead,
  StyledHead2,
  StyledHeadTitle,
} from "../../styles/theme";
import NoticeComponent from "../Common/NoticeComponent";
import AlertModal from "../Common/AlertModal";

function MypageDeptNotice() {
  const user = useUserState();
  const [data, setData] = useState<DepartmentNotice[]>([]);
  let history = useHistory();
  // 버튼
  enableRipple(true);

  // 페이징 처리
  const [totalData, setTotalData] = useState<DepartmentNotice[]>([]);
  const [page, setPage] = useState(1);
  const [dataLength, setDataLength] = useState(1);
  const pageRange = 10; // 목록에 나타날 게시글 수
  const lastPage = Math.ceil(dataLength / pageRange);

  // 게시판 정보 불러오기

  useEffect(() => {
    DepartmentNoticeApi.GetMypageDeptNoticeList(
      page,
      10,
      user.employeeId,
      user.parentTeam
    )
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });

    DepartmentNoticeApi.GetDepartmentNoticeTotalList(user.parentTeam)
      .then((res) => {
        setTotalData(res.data);
        var datacnt = 0;
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].employeeId === user.employeeId) {
            datacnt += 1;
          }
        }
        setDataLength(datacnt);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
  }, [page]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Grid item xs={12} style={{ marginTop: "10px" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledHead align="center">번호</StyledHead>
              <StyledHead align="center">주요 공지 여부</StyledHead>
              <StyledHead2 align="center">유형</StyledHead2>
              <StyledHeadTitle align="center">제목</StyledHeadTitle>
              <StyledHead2 align="center">글쓴이</StyledHead2>
              <StyledHead2 align="center">작성일</StyledHead2>
              <StyledHead align="center">조회수</StyledHead>
            </TableRow>
          </TableHead>

          {data
            ? data.map((board: any) => {
                return (
                  <TableBody key={board.noticeId} id={board.noticeId}>
                    <TableRow>
                      <StyledBody align="center">
                        {board.departmentBoardId}
                      </StyledBody>
                      <StyledBody align="center">
                        {board.departmentNoticeMain ? "주요 공지" : "일반"}
                      </StyledBody>
                      <StyledBody2 align="center">
                        <NoticeComponent type={board.type} />
                      </StyledBody2>
                      <StyledBodyTitle
                        className="mypage_title"
                        onClick={() => {
                          history.push({
                            pathname: `/departmentnotice/detail/${board.departmentNoticeId}`,
                            state: { displays: board.departmentNoticeId },
                          });
                        }}
                      >
                        {board.departmentNoticeTitle}
                      </StyledBodyTitle>
                      <StyledBody2 align="center">
                        {board.departmentNoticeWriter}
                      </StyledBody2>
                      <StyledBody2 align="center">
                        {board.departmentNoticeCreatedAt}
                      </StyledBody2>
                      <StyledBody align="center">
                        {board.departmentNoticeViews}
                      </StyledBody>
                    </TableRow>
                  </TableBody>
                );
              })
            : ""}
        </Table>
      </TableContainer>
      {dataLength == 0 ? (
        <div className="mypage_emptyBox">
          <div className="mypage_emptynotice">작성된 공지사항이 없습니다.</div>
        </div>
      ) : (
        ""
      )}

      {dataLength > 0 ? (
        <div className="mypage_Pagination">
          <Stack spacing={2} alignItems="center">
            <Pagination
              count={lastPage}
              defaultPage={1}
              page={page}
              siblingCount={2}
              boundaryCount={2}
              onChange={handleChange}
            />
          </Stack>
        </div>
      ) : (
        ""
      )}
    </Grid>
  );
}

export default MypageDeptNotice;
